import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { Category, Product } from '../_model/product';
import { TableParameters, TableResponse, UrlParameters } from '../_model/shared';
// import { Allergen, Nutrient } from '../_model/recipe'; // WHY is this in this service ?
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from './RedirectService';

@Injectable()
export class ProductService {

  constructor(
    private _http: HttpClient,
    private _translate: TranslateService,
    private _redirectService: RedirectService,
  ) {
    this._translate.onLangChange.subscribe(next => {
      this.products = [];
    });

  }

  private products: Product[] = [];

  getProductTableByName(tblparams: TableParameters, name: string, from: string, to: string): Observable<TableResponse<Product>> {
    const params = tblparams.getUrlParameters();
    params.addParameter('searchstring', name);
    params.addParameter('from', from);
    params.addParameter('to', to);

    return this._http.get(environment.apiserver + 'productbff/v1/product/find/string' + params.toString()).pipe(
      map((response: TableResponse<Product>) => {
        this.products = this.products.concat(response.content);
        return response as TableResponse<Product>;
      })
      , catchError(this.handleError)
    );
  }

  getWebshopProductsListByName(name: string, from: string, to: string): Observable<Product[]> {
    const params = new UrlParameters();
    params.addParameter('searchstring', name);
    params.addParameter('from', from);
    params.addParameter('to', to);

    return this._http.get(environment.apiserver + 'productbff/v1/product/find/webshop' + params.toString()).pipe(
      map((response: Product[]) => {
        this.products = this.products.concat(response);
        return response as Product[];
      })
      , catchError(this.handleError)
    );
  }

  getProductList(productids: number[], fromdate: string = null, todate: string = null): Observable<Product[]> {
    if (productids.length === 0 || productids.filter(v => !isNaN(v)).length === 0) { return of([]); }
    const params = new UrlParameters();
    params.addParameter('ids', productids.toString());
    params.addParameter('from', fromdate);
    params.addParameter('to', todate);

    return this._http.get(environment.apiserver + 'productbff/v1/product/list' + params.toString()).pipe(
      map((response: Product[]) => {
        this.products = this.products.concat(response);
        return response as Product[];
      })
      , catchError(this.handleError)
    );
  }
  getProductListBySKU(productSKUs: string[], fromdate: string = null, todate: string = null): Observable<Product[]> {
    productSKUs = productSKUs.filter(v => v !== "" && v !== "undefined" && v);

    if (productSKUs.length === 0) { return of([]); }

    const params = new UrlParameters();
    params.addParameter('sku', productSKUs.toString());
    params.addParameter('from', fromdate);
    params.addParameter('to', todate);

    return this._http.get(environment.apiserver + 'productbff/v1/product/sku/list' + params.toString()).pipe(
      map((response: Product[]) => {
        this.products = this.products.concat(response);
        return response as Product[];
      })
      , catchError(this.handleError)
    );
  }
  getProduct(productid: number): Observable<Product> {

    const product = this.products.filter(p => p.id === productid)[0];
    if (product) {
      //this._redirectService.getEntityUrl(Entitytype.LIFESTYLE, product.id, product.redirectid).then(value => product['producturl'] = value);
      ////product['producturl'] = this._redirectService.getEntityUrl(Entitytype.PRODUCT, product.id, product.redirectid);
      return of(product);
    }

    const productids = [productid];
    const params = new UrlParameters();
    params.addParameter('ids', productids.toString());

    return this._http.get(environment.apiserver + 'productbff/v1/product/list' + params.toString()).pipe(
      map((response: Product[]) => {
        if (response.length > 0) {
          //this._redirectService.getEntityUrl(Entitytype.LIFESTYLE, response[0].id, response[0].redirectid).then(value => response[0]['producturl'] = value);
          //response[0]['producturl'] = this._redirectService.getEntityUrl(Entitytype.PRODUCT, response[0].id);
          this.products.push(response[0]);
          return response[0] as Product;
        }
        return null;
      })
      , catchError(this.handleError)
    );
  }
  getProductBySKU(sku: string): Observable<Product> {
    if (sku === '' || sku === 'undefined' || !sku) { return of(null); }

    const product = this.products.filter(p => p.sku === sku)[0];
    if (product) {
      //this._redirectService.getEntityUrl(Entitytype.LIFESTYLE, product.id, product.redirectid).then(value => product['producturl'] = value);
      //product['producturl'] = this._redirectService.getEntityUrl(Entitytype.PRODUCT, product.id);
      return of(product);
    }

    const skus = [sku];
    const params = new UrlParameters();
    params.addParameter('sku', skus.toString());

    return this._http.get(environment.apiserver + 'productbff/v1/product/sku/list' + params.toString()).pipe(
      map((response: Product[]) => {
        if (response.length > 0) {
          //this._redirectService.getEntityUrl(Entitytype.LIFESTYLE, response[0].id, response[0].redirectid).then(value => response[0]['producturl'] = value);
          //response[0]['producturl'] = this._redirectService.getEntityUrl(Entitytype.PRODUCT, response[0].id);
          this.products.push(response[0]);
          return response[0] as Product;
        }
        return null;
      })
      , catchError(this.handleError)
    );
  }
  getActionProductsByDate(from: string, to: string): Observable<Product[]> {
    const params = new UrlParameters();
    params.addParameter('from', from);
    params.addParameter('to', to);

    return this._http.get(environment.apiserver + 'productbff/v1/product/get-action-product' + params.toString()).pipe(
      map((response: Product[]) => {
        this.products = this.products.concat(response);
        return response as Product[];
      })
      , catchError(this.handleError)
    );
  }

  getProductPromise(productid: number): Promise<Product> {
    return new Promise(resolve => {
      if (productid == null || productid === 0) {
        resolve(null);
        return;
      }
      this.getProduct(productid).subscribe(
        {
          next: (result) => {
            resolve(result);
          },
          error: (error) => console.log(error)
        });
    });
  }
  getProductBySKUPromise(sku: string): Promise<Product> {
    return new Promise(resolve => {
      this.getProductBySKU(sku).subscribe(
        {
          next: (result) => {
            resolve(result);
          },
          error: (error) => console.log(error)
        });
    });
  }

  getCategories(): string[] {
    return Object.keys(Category);
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }

}
