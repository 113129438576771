import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../_service/GlobalService';
import { PricingService } from '../../../_service/PricingService';
import { RedirectService } from '../../../_service/RedirectService';
import { BaseorderlineComponent } from '../baseorderline.component';

@Component({
    selector: 'emptycard',
    templateUrl: './emptycard.component.html',
    styleUrls: ['./emptycard.component.scss'],
    standalone: true
})
export class EmptycardComponent extends BaseorderlineComponent implements OnInit, OnDestroy {

  disableShowAction: boolean;
  
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public _translate: TranslateService,
    public _pricingService: PricingService,
    public _globalService: GlobalService,
    private _redirectService: RedirectService
  ) {
    super(route, router, _translate, _pricingService, _globalService);
  }

  ngOnInit(): void {
    super.ngOnInit();
   
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  
}
