<div class="flex flex-col p-3 md:p-6 bg-white rounded-lg">
  <div class="flex flex-row flex-wrap justify-between mb-2">
    <span class="my-auto me-2 whitespace-nowrap">{{'subscription.number_of_persons' |translate}}</span>
    <div class="flex flex-row w-1/2 min-w-[160px] rounded-full border border-e-dark-green">
      <button *ngFor="let person_count of personChoices; let i = index"
              (click)="setDefaultPersons(person_count)"
              class="transition-colors w-full duration-75 px-3 py-1 mx-1 rounded-full text-e-dark-green"
              [ngClass]="{'bg-e-mid-green': pricingform.get('defaultpersons')?.value === person_count, 'ms-0': i===0, 'me-0': i=== personChoices.length-1 } ">
        {{ person_count }}
      </button>
    </div>
  </div>
  <div class="flex flex-row justify-between">
    <span class="my-auto me-2 whitespace-nowrap">{{'subscription.meals_per_week' |translate}}</span>
    <div class="flex flex-row w-1/2 min-w-[160px] rounded-full border border-e-dark-green">
      <button *ngFor="let qty of recipeQuantities;let i = index"
              (click)="setDefaultRecipes(qty)"
              class="transition-colors w-full duration-75 px-3 py-1 mx-1 rounded-full text-e-dark-green"
              [ngClass]="{'bg-e-mid-green': pricingform.get('defaultrecipes')?.value === qty, 'ms-0': i===0, 'me-0': i=== recipeQuantities.length-1 } ">
        {{ qty }}
      </button>
    </div>
  </div>
</div>

