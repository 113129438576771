import { Inject, Injectable, SecurityContext } from '@angular/core';
import { richTextResolver } from '@storyblok/richtext'
import Client from 'storyblok-js-client';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WINDOW } from './WindowService';

const { render } = richTextResolver()

@Injectable({ providedIn: 'root' }) export class CmsService {
  
  
  private sbClient = new Client({
    // accessToken: 'eIlcIWiyDRFlyuQQFTrKXgtt' // Add your token here
    accessToken: '0zrgJZNmDQLYVRO28fnqKQtt'
  });


  
  constructor(
    private sanitizer: DomSanitizer,
    @Inject(WINDOW) private window: Window,
  ) { }

  getPageOrStory(slug: string, params?: object): Promise<any> {
    return this.getStory(slug, params);
  }

  getStory(slug: string, params?: object): Promise<any> {
    // this is only needed ir you are in the SB live editor !?
    // debugger;
    const path = slug.split('/');
    if (path.length > 2 && path[1] != 'general') { // something like /fr/slug
      params['language'] = path[1];
      slug = '/' + path.slice(2).join('/'); // do we need the leading / ?
    }
    if (!!params['language']) {
      if (params['language'] === 'fr') {
        // TODO: fix this depending on the settings in SB 
        params['language'] = 'fr-be';
      }
      if (params['language'] === 'nl') {
        // TODO: find out the main domain using a setting or config ?
        if (!!this.window && !!this.window.location && this.window.location.hostname.split('.').slice(-1)[0] == 'be') {
          params['language'] = 'nl-be';
        } else {
          // if we don't specify a lang we get the fallback versio
          // of the version that is associated with the translated slug
          delete params['language'];
        }
      }
    }
    return this.sbClient.getStory(slug, params)
      .then(res => res.data);
  }

  getStories(params?: object): Promise<any> {
    return this.sbClient.getStories(params)
      .then(res => res.data);
  }

  render_rt(rt_item) {
    return this.get_sanitized_html( render(rt_item));
    //return this.sbClient.richTextResolver.render(rt_item);
  }

  get_src_as_css(field, contain, height?) {
    if (height) {
      return `url(${this.get_src(field, contain, height)}/m/)`;
    } else {
      return `url(${this.get_src(field, contain)}/m/)`;
    }
  }

  get_src(field, contain, height?) {
    let compress = '';
    if (!!field?.filename?.includes('.svg')) {
      compress = '';
    } else if (contain) {
      // contain image, so only optimize, dont resize
      compress = '/m/';
    } else if (height) {
      compress = `/m/0x${height}`;
    } else {
      compress = '/m/800x0';
    }
    if (field.filename) return field.filename + compress;
    if (field.url) return field.url + compress;
    return '/missing_image.png'
  }

  get_alt(field) {
    if (field.alt) return field.alt;
    if (field.title) return field.title;
    return 'no alt or title set in CMS';
  }

  get_direction(section) {
    let flexProp = " md:flex-row";
    if (!!section.layout) {
      switch (section.layout) {
        case 'IMG_LEFT':
          flexProp = " md:flex-row-reverse";
          break;
        case 'IMG_CONNECTED_LEFT':
          flexProp = " md:flex-row-reverse";
        default:
          break;
      }
    }
    return flexProp
  }

  get_content(field) {
    if (!field || field.length === 0) return null;
    // abstract field render can be used for content from different (CMS) sources
    // if (Array.isArray(field) && field[0].text) return field[0].text; // prismic style
    if (!!field.text) return field.text; // sb style
    // no extra work need
    return field;
  }

  get_sanitized_url(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  get_sanitized_html(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  get_href(field) {
    if (!field || field.length === 0) return null;
    // abstract field render can be used for content from different (CMS) sources
    // if (Array.isArray(field) && field[0].text) return field[0].text; // prismic style
    if (!!field.text) return field.text; // sb style
    // no extra work need
    return field;
  }

  get_classes(block) {
    let classes = '';
    if (block.rounded) {
      classes = classes + "rounded-2xl";
    }
    if (!!block.bgcolor) {
      classes = classes + ' bg-' + block.bgcolor;
      if (block.bgcolor.includes('darkgreen')) {
        classes = classes + " text-e-white";
      }
    }
    return classes;
  }
}
