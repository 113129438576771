import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../_environments/environment';
import {catchError, debounceTime, filter, map} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {Countrycode, UrlParameters} from '../_model/shared';
import {Address} from '../_model/address';
import { AuthService } from './AuthService';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from './GlobalService';
import { SessionstorageService } from './SessionstorageService';

@Injectable({ providedIn: 'root'})
export class AddressService {

  /**
 * @deprecated This form is replaced by the shippingAddressForm which should be used for the order shippingaddresses
 */
  public addressForm = this.initiateAddressForm();
  public shippingAddressForm = this.initiateAddressForm();

  constructor(
    private _http: HttpClient,
    private _authenticationService: AuthService,
    private sessionStorage: SessionstorageService,
  ) {
    this._authenticationService.isAuthenticatedSubject.subscribe(
      next => {
        // gets and sets or removes customer based on authenticationstatus
        if (!next) {
          this.addresses = [];
          this.shippingAddressForm.reset();
          this.addressForm.reset();
        }
      },
      error => { },
      () => {
        this.sessionStorage.removeItem('shippingaddress')
        this.addresses = [];
        this.shippingAddressForm.reset();
        this.addressForm.reset();
      }
    );
    

    const sessionAddress = this.sessionStorage.getObject('shippingaddress')
    if (sessionAddress)
      this.shippingAddressForm.patchValue(sessionAddress)
      
    this.shippingAddressForm.valueChanges.subscribe(res => {
      if (!this._authenticationService.isAuthenticatedSubject.value) {
        /*console.log('addressform valuechanges', res)*/
        this.sessionStorage.setObject('shippingaddress', res)
      }
    })

    this.shippingAddressForm.valueChanges.pipe(
      debounceTime(500),
      filter(() =>
        this.shippingAddressForm.dirty
        && this.shippingAddressForm.get('number').valid
        && this.shippingAddressForm.get('postalcode').valid
        && this.shippingAddressForm.get('countrycode').valid
        && this.shippingAddressForm.get('addition').valid
      )
    ).subscribe((address: Address) => {
      const urlParams = new UrlParameters();
      urlParams.addParameter('countrycode', address.countrycode);
      urlParams.addParameter('postalcode', address.postalcode);
      urlParams.addParameter('number', address.number.toString());
      urlParams.addParameter('addition', address.addition);

      this.getAddressInfo(urlParams).subscribe(result => {
        //this.shippingAddressForm.get('_showFullAddress').setValue(result === null);

        address['_showFullAddress'] = result === null

        const new_address = { ...address, ...result }; // keep all values that are not overridden by the address service
        this.shippingAddressForm.markAsPristine();
        this.shippingAddressForm.patchValue(new_address);
      }, error => {
        this.shippingAddressForm.get('_showFullAddress').setValue(true);
        this.shippingAddressForm.markAsPristine();
      })
    })

  }
  addresses: Address[] = [];

  initiateAddressForm(): FormGroup{
    const fg = new FormGroup({
      id: new FormControl(null),
      street: new FormControl(null, { validators: [Validators.required, Validators.maxLength(100)] }),
      number: new FormControl(null, { validators: [Validators.required], updateOn: 'blur' }),
      addition: new FormControl(null, { validators: [Validators.maxLength(20)], updateOn: 'blur' }),
      postalcode: new FormControl(null, { validators: [Validators.required, Validators.maxLength(20)] }),
      city: new FormControl(null, { validators: [Validators.required, Validators.maxLength(100)] }),
      countrycode: new FormControl(null, Validators.required),
      region: new FormControl(null, { validators: [Validators.maxLength(50)] }),
      longitude: new FormControl(null),
      latitude: new FormControl(null),
      _showFullAddress: new FormControl(false)
    })
    fg.get('countrycode').valueChanges.subscribe(val => {
      if (val === Countrycode.BE) {
        fg.get('postalcode').setValidators([Validators.required, Validators.maxLength(20), Validators.pattern(new RegExp(/^[1-9][0-9]{3}$/i))]);
      } else {
        fg.get('postalcode').setValidators([Validators.required, Validators.maxLength(20), Validators.pattern(new RegExp(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i))]);
      }
      fg.get('postalcode').updateValueAndValidity();
    })

    fg.get('countrycode').valueChanges.pipe(
     filter(()=> this.shippingAddressForm.get('countrycode').valid && this.shippingAddressForm.get('countrycode').dirty)
    ).subscribe(() => {
      this.clearAutofillData(fg)
    })

    fg.get('postalcode').valueChanges.pipe(
      filter(() => this.shippingAddressForm.get('postalcode').valid && this.shippingAddressForm.get('postalcode').dirty)
    ).subscribe(() => {
      this.clearAutofillData(fg)
    })
    fg.get('number').valueChanges.pipe(
      filter(() => this.shippingAddressForm.get('number').valid && this.shippingAddressForm.get('number').dirty)
    ).subscribe(() => {
      this.clearAutofillData(fg)
    })

    return fg;
  }
  private clearAutofillData(form: FormGroup) {
    if (form.get('countrycode').value == Countrycode.NL) {
      form.get('street').reset(null);
      form.get('city').reset(null);
    }

    form.get('region').reset(null);
    form.get('longitude').reset(null);
    form.get('latitude').reset(null);

  }

  getAddress(id: number): Observable<Address> {
    const address = this.addresses.filter(a => a.id === id)[0];
    if (address) {
      return of(address);
    }

    return this._http.get(environment.apiserver + 'address/v1/address/' + id).pipe(
      map((response: Address) => {
        this.addresses.push(response);
        return response as Address;
      })
      , catchError(this.handleError)
    );
  }

  getAddressList(ids: number[]): Observable<Address[]> {
    //const address = this.addresses.filter(a => a.id === id)[0];
    //if (address) {
    //  return of(address);
    //}

    if (ids.filter(i => i).length == 0)return of([])

    let params = new UrlParameters();
    params.addParameter('ids', ids.join(','));

    return this._http.get(environment.apiserver + 'address/v1/address/list' + params.toString()).pipe(
      map((response: Address[]) => {
        this.addresses = this.addresses.concat(response);
        return response as Address[];
      })
      , catchError(this.handleError)
    );
  }

  getAddressInfo(urlParameters: UrlParameters): Observable<Address> {
    return this._http.get(environment.apiserver + 'address/v1/address/info' + urlParameters.toString()).pipe(
      map((response: Response) => <any>response)
      , catchError(this.handleError)
    );
  }

  putAddress(address: Address): Observable<Address> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {headers: headers};

    const body = JSON.stringify(address);
    return this._http.put(environment.apiserver + 'address/v1/address', body, options).pipe(
      map((response: Address) => {
        this.addresses.push(response);
        return response
      })
      , catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');
  }

}
