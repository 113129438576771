import { Address } from './address';
import {Allergen} from './recipe';
import {Customercountrycode, Language} from './shared';

export class Customer {
  constructor(
    public id: number,
    public firstname: string,
    public prefix: string,
    public lastname: string,
    public email: string,
    public phonenumber: string,
    public gender: Gender,
    public birthdate: any,
    public defaultcustomershippingaddress: number,
    public defaultcustomerbillingaddress: number,
    public brandid: number,
    public preferredlanguage: Language,
    public countrycode: Customercountrycode,
    public mandateid: string,
    public mandateenabled: boolean,
    public mandatedisablereason: string,
    public donotcontact: boolean,
    public created: number,
    public updated: number,
    public customerLifestyles: CustomerLifestyle[],
    public customerAllergens: CustomerAllergen[],
    public customerAddresses: CustomerAddress[],
    // public customerOptins: CustomerOptin[]
  ) {
  }
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER_DO_NOT_WANT_TO_SAY = 'OTHER_DO_NOT_WANT_TO_SAY',
  UNKNOWN = 'UNKNOWN'
}

export class CustomerAllergen {
  constructor(
    public id: number,
    public name: Allergen
  ) {
  }
}

export class CustomerLifestyle {
  constructor(
    public id: number,
    public lifestyleid: number
  ) {
  }
}

export class CustomerAddress {
  constructor(
    public id: number,
    public name: string,
    public addressid: number,
    public shippingaddress: boolean,
    public billingaddress: boolean,
    public address: Address
  ) {

  }
}

//export class CustomerOptin {
//  constructor(
//    public id: Number,
//    public customeroptintype: Optintype,
//    public isoptedin: boolean
//  ) { }
//}

export enum Optintype {
  MARKETING_EMAIL = "MARKETING_EMAIL"
}
