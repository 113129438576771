import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {environment} from '../../_environments/environment';
import { isPlatformServer, NgIf } from '@angular/common';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['image.component.scss'],
    standalone: true,
    imports: [NgIf]
})

export class ImageComponent {

  environment = environment;

  @Input() class = '';

  @Input() set size(value: string) {
    switch (value) {
      case 'sm':
        this.thumbmax = 1280;
        this.mediummax = 2000;
        this.largemax = 4000;
        break;
      case 'md':
        this.thumbmax = 200;
        this.mediummax = 2000;
        this.largemax = 5000;
        break;
      case 'lg':
        this.thumbmax = 200;
        this.mediummax = 350;
        this.largemax = 1280;
        break;
      default:
        this.thumbmax = 200;
        this.mediummax = 768;
        this.largemax = 1280;
        break;
    }

  }

  thumbmax = 200;
  mediummax = 768;
  largemax = 1280;


  @Input() src = '';
  @Input() alt = '';
  @Input() thumb = '';
  @Input() medium = '';
  @Input() large = '';

  @Input() set imageobject(obj: any) {
    if (obj) {
      if (obj['websitethumbimageurl']) {
        this.thumb = obj.websitethumbimageurl;
      }
      if (obj['thumburlpath']) {
        this.thumb = obj.thumburlpath;
      }

      if (obj['websitemediumimageurl']) {
        this.medium = obj.websitemediumimageurl;
      }
      if (obj['mediumurlpath']) {
        this.medium = obj.mediumurlpath;
      }

      if (obj['websitelargeimageurl']) {
        this.large = obj.websitelargeimageurl;
      }
      if (obj['largeurlpath']) {
        this.large = obj.largeurlpath;
      }

      if (obj['websiteimageurl']) {
        if(isPlatformServer(this.platformId) && this.medium){
          this.src = this.medium;
        }else{
          this.src = obj.websiteimageurl;
        }
      }
      if (obj['urlpath']) {
        if(isPlatformServer(this.platformId) && this.medium){
          this.src = this.medium;
        }else{
          this.src = obj.urlpath;
        }
      }
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,


  ) {
  }



}
