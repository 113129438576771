import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalService } from '../../_service/GlobalService';
import { environment } from '../../_environments/environment';
import { ColorScheme } from '../extensions/colorscheme';
import { CommonModule, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-quantityselection',
  standalone: true,
  imports: [
    NgClass, NgIf, NgTemplateOutlet
  ],
  templateUrl: './quantityselection.component.html',
  styleUrl: './quantityselection.component.scss'
})
export class QuantityselectionComponent extends ColorScheme {
  _isPersons = false

  @Input() min: number = 0;
  @Input() max: number = 9999999999;
 
  _quantity: number = 0;
  @Input() set quantity(value: number) {
    if (value)
      this._quantity = value;
  }
  _small = false;
  @Input() set small(value: boolean |string) {
    if (value || value === '')
      this._small = true
  }
  @Input() editable = true;

  @Output() quantityChange: EventEmitter<number> = new EventEmitter<number>()

  constructor(
  ) {
    super()
  }

  changeQty(qty: number) {
    const newqty = this._quantity + qty
    if (newqty >= this.min && newqty <= this.max) {
      this._quantity = newqty;
    }
    
    this.quantityChange.emit(this._quantity)
  }

}
