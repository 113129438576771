import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import {NotifydialogData} from '../../_model/shared';
import { NgTemplateOutlet } from '@angular/common';


@Component({
    selector: 'notify-dialog',
    templateUrl: './notifydialog.component.html',
    styleUrls: ['notifydialog.component.scss'],
    standalone: true,
    imports: [MatDialogContent, NgTemplateOutlet]
})
export class NotifydialogComponent {

  constructor(
    public dialogRef: MatDialogRef<NotifydialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotifydialogData
  ) {
  }



}
