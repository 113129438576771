import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgSwitch, NgSwitchCase } from '@angular/common';

export enum TexttransformType {
  CO2 = "CO2",
    EXKI = "EXKI",
    ENCODING = "ENCODING"
}


@Component({
    selector: 'texttransform',
    templateUrl: './texttransform.component.html',
    standalone: true,
    imports: [NgSwitch, NgSwitchCase]
})
export class TexttransformComponent {

  @Input() textToTransform: TexttransformType;

  constructor(private _translate: TranslateService) {

  }

}
