import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'localizedDate',
    pure: false,
    standalone: true
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService

  ) { }

  transform(value: any, pattern: string = 'mediumDate'): any {
    if (this.translateService.currentLang) {
      const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
      return datePipe.transform(value, pattern);
    } else if (this.translateService.defaultLang) {
      const datePipe: DatePipe = new DatePipe(this.translateService.defaultLang);
      return datePipe.transform(value, pattern);
    } else {
      const datePipe: DatePipe = new DatePipe("EN");
      return datePipe.transform(value, pattern);
    }
  }

}
