import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-chip',
    templateUrl: './chip.component.html',
    standalone: true,
    imports: [NgClass]
})
export class ChipComponent {
 
  @Input() clickable = false;
  @Input() selected = false;
  @Input() colorscheme = 'light';
  
  scheme = {
    light: 'bg-e-mid-green text-e-dark-green',
    contrast: 'bg-white text-e-dark-green cursor-pointer',
    contrast_with_border: 'border-[1px] bg-white text-e-dark-green border-e-dark-green cursor-pointer hover:bg-e-dark-green hover:text-e-reg-green transition-colors hover:border-transparent',
    selected: 'bg-e-dark-green text-e-reg-green cursor-pointer border-[1px] border-transparent',
    alert:"bg-e-gold/70 text-white"
  }

  constructor(

  ) {
  }




}



