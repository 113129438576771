// shared settings
export const environment = {
  production: false,
  logo: '/assets/images/ekomenu-logo-rgb.svg',
  // to be used in all page titles (will not be translated)
  title_prefix : '', // 'Ekomenu |',
  title_postfix : 'Ekomenu',
  subtitle: 'common.payoff', // we want this to be translated

  oauthserver: 'https://api.tst.ecom.ekomenu.nl/auth',
  apiserver: 'https://api.tst.ecom.ekomenu.nl/',
  assetserver: 'https://static.tst.ecom.ekomenu.nl',
  etna: 'https://admin.tst.ecom.ekomenu.nl',

  clientid: 'admin-frontend', // TODO: is this correct ?
  clientsecret: 'secret',
  brandid: 1,

  webshoptaxonomyid: 8,
  stopsubscriptiontaxonomyid: 2,
  webshop_actionproducts_nodeid: 930, //is tst taxonomy node id

  subscriptionPersonChoices: [1, 2, 3, 4],
  personCountDefault: 2,
  subscriptionRecipeChoices: [3, 4],
  mealCountDefault: 4,
  singleorderNoMOVRecipecount: 3,

  ALLOW_BIG_LOGO: ['', 'recipe'],
  assumedPostalcode: {
    NL: '1234AA',
    BE: '1234'
  },

  dutchmealscores: {
    _TWOPERSMEALPRICE: 14.65, //also change price in _truecosts calculation 
    _TRUECOSTS: (((14.65/1.2) * 2.995)/2),
    _VETGETABLESCORE: 126,
/*    VITASCORE: 478,*/ //isnt the correct score
    VITASCORERISCPOINTS: 478,
    CARBONSCORE: 1690,
    SCARCEWATERUSAGE: 64
  }
  
}
