import { Component, OnInit, Input, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-cms-script',
    templateUrl: './cms-script.component.html',
    standalone: true
})
export class CmsScriptComponent implements OnInit {

  @Input() script: any;
  

  constructor(@Inject(DOCUMENT) private document) {

  }


  ngOnInit() {
    let node = this.document.createElement('script');    
    let appendTo;
    if (this.script.inline) {
      appendTo = this.document.getElementsByClassName('cms-script-content')[0];
    } else {
      appendTo = this.document.getElementsByTagName('body')[0];
    }

    this.script?.id ? node.id = this.script.id : null;
    node.src = this.script.src;
    node.type = this.script.type;
    node.async = this.script.async;
    
    appendTo.appendChild(node);
  }
  
}



