import { Component, Input } from '@angular/core';
import { CalculatedPrice, Minimumordervalue, Minimumordervaluetype } from '../../_model/pricing';
import { Countrycode } from '../../_model/shared';
import { Shippingmethod } from '../../_model/order';
import { PricingService } from '../../_service/PricingService';
import { CurrencyPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-movlabel',
  standalone: true,
  imports: [NgIf, CurrencyPipe, TranslateModule],
  templateUrl: './movlabel.component.html',
  styleUrl: './movlabel.component.scss'
})
export class MovlabelComponent {

  shippingMOVs: Minimumordervalue[] = [];
  minimumOrderValue: Minimumordervalue

  _calculatedPrice: CalculatedPrice
  @Input() set calculatedPrice(value: CalculatedPrice) {
    this._calculatedPrice = value;
    if (value)
      this._calculatedPrice['priceincvatexshipping'] = this._calculatedPrice.priceincvat - this._calculatedPrice.shippingcost
    this.setMOV();
  }

  _showShipping = true
  @Input() set showShipping(value: boolean) {
    this._showShipping = value;
    this.getShippingMOVvalues();
  }
  _countrycode: Countrycode = null;
  @Input() set countrycode(value: Countrycode) {
    this._countrycode = value;
    this.getShippingMOVvalues()
  }
  _shippingmethod: Shippingmethod = null;
  @Input() set shippingmethod(value: Shippingmethod) {
    this._shippingmethod = value;
    this.getShippingMOVvalues()
  }

  constructor(
    private _pricingService: PricingService
  ) {
  }


  getShippingMOVvalues() {
    if (!this._countrycode) { this.shippingMOVs = []; return; }
    if (!this._showShipping) { this.shippingMOVs = []; return; }
    if (!this._shippingmethod) { this.shippingMOVs = []; console.warn('shippingmethod is required for showing shippingmovs'); return; }

    this.shippingMOVs = [];
    this._pricingService.getMinimumOrderValues(this._countrycode, Minimumordervaluetype.SHIPPING, this._shippingmethod).subscribe(res => {
      this.shippingMOVs = res;
      this.setMOV();
    }, error => {
      console.log(error);
    })
  }

  setMOV() {
    if (this.shippingMOVs.length === 0) {
      this.minimumOrderValue = null;
      return;
    }
    if (!this._calculatedPrice) {
      this.minimumOrderValue = null;
      return;
    }
    this.minimumOrderValue = this.shippingMOVs.filter(m => m.minimumtotalvalue > this._calculatedPrice.priceincvat - this._calculatedPrice.shippingcost)[0]

  }

}
