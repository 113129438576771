import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { CouponcodeCode } from '../_model/couponcode';
import { UrlParameters } from '../_model/shared';
import { Originentitytype } from '../_model/subscription';
import { Snackbarerrorformat, Snackbarformat } from '../_theme/snackbarformats';
import { LocalstorageService } from './LocalstorageService';
import { ToastService } from './Toast.service';
import { GlobalService } from './GlobalService';

@Injectable()
export class CouponcodeService {

  couponcode: CouponcodeCode = null;

  constructor(
    private _http: HttpClient,
    private _toastService: ToastService,
    private _translate: TranslateService,
    private _localstorage: LocalstorageService,
    private _globalService: GlobalService,
  ) {
    this.couponcode = _localstorage.getObject('couponcode');

  }

  setCouponcode(code: string, couponcode: CouponcodeCode = null, email: string = null) {
    if (!couponcode)
      couponcode = new CouponcodeCode(null, code, null, null, true, email, null);

    if (this.couponcode == null || !this.couponcode.code) {

      this._localstorage.setObject('couponcode', couponcode);
      this.couponcode = couponcode;
      if (this._globalService.isInBrowser)
        this._translate.get(['couponcode.code_added_to_session', 'common.ok_dismiss']).subscribe(result => {
          this._toastService.open(result['couponcode.code_added_to_session'], result['common.ok_dismiss'], Snackbarformat);
        })
    }
  }
  removeCouponcode() {
    this.couponcode = null;
    this._localstorage.removeItem('couponcode');
  }

  checkCouponcode(couponcode: string, customerid: number = null, email: string = null, orginentitytype: Originentitytype = null): Observable<boolean> {
    if (!couponcode || couponcode === '') { return of(false) }

    const params = new UrlParameters();

    params.addParameter('couponcodecode', couponcode)
    if (email)
      params.addParameter('email', email)
    if (customerid)
      params.addParameter('customerid', customerid.toString())
    if (orginentitytype)
      params.addParameter('originentitytype', orginentitytype.toString())

    return this._http.get(environment.apiserver + 'couponcode/v1/couponcode/code/isvalidandusable' + params.toString()).pipe(
      map((response: boolean) => {
        return response
      })
      , catchError(this.handleError)
    );
  }

  getMagentoCouponcode(magentoid: number): Observable<CouponcodeCode> {
    return this._http.get(environment.apiserver + 'couponcode/v1/couponcode/magentoid/' + magentoid).pipe(
      map((result: CouponcodeCode) => {
        if (result.isusable) {
          this.setCouponcode(null, result)
        } else {
          this._translate.get(['couponcode.code_not_valid', 'common.ok_dismiss']).subscribe(result => {
            this._toastService.open(result['couponcode.code_not_valid'], result['common.ok_dismiss'], Snackbarerrorformat);
          })
        }
        return result as CouponcodeCode
      })
      , catchError(this.handleError)
    );

  }





  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');
  }

}
