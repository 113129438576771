import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../_environments/environment';
import { Faq } from '../../_model/faq';
import { FaqService } from '../../_service/FaqService';
import { CmsService } from 'src/_service/CmsService';
import { collapse } from '../../_theme/animations';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['faq.component.scss'],
  animations: [collapse],
  standalone: true,
  imports: [NgFor, NgClass, NgIf]
})
export class FaqComponent {

  environment = environment;

  @Input() class = '';
  expanded = -1;

  faq: Faq
  @Input() set faqid(value: number) {
    this.faqService.getFaq(value).subscribe(
      result => {
        result.faqquestions.sort((a, b) => {
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
          return 0;
        })

        this.faq = result;
      },
      error => { console.log(error) }
    )
  };

  @Input() colorscheme = 'contrast';

  scheme = {
    light: 'bg-e-light-green text-e-dark-green',
    contrast: 'bg-white text-e-dark-green cursor-pointer',
    contrast_with_border: 'bg-white text-e-dark-green border-e-dark-green cursor-pointer',
    contrast_white: "bg-e-white",
    selected: 'bg-e-dark-green text-e-reg-green cursor-pointer'
  }


  constructor(
    private faqService: FaqService,
    private _cmsService: CmsService
  ) {
  }

  setExpanded(i) {
    this.expanded === i ? this.expanded = null : this.expanded = i;
  }

  render_rt(block) {
    return this._cmsService.render_rt(block);
  }



}
