import { Injectable } from '@angular/core';
import { HookBindings, HookComponentData, HookFinder, HookParser, HookPosition, HookValue } from 'ngx-dynamic-hooks';
import { TexttransformComponent, TexttransformType } from './texttransform.component';

@Injectable({
  providedIn: 'root'
})
export class TexttransformHookParser implements HookParser {

  constructor(private hookFinder: HookFinder) { }

  public findHooks(content: string, context: any): Array<HookPosition> {
    // As an example, this regex finds the emoticons :-D, :-O and :-*
    const parserRegex = /((?![^<]*>)CO2|(?![^<]*>)exki)/igm;
    //const parserRegex = /((?![^<]*>)CO2|(?![^<]*>)exki|(&#(\d+)))/igm;

    // We can use the HookFinder service from ngx-dynamic-hooks library to easily
    // find the HookPositions of any regex in the content string
    return this.hookFinder.findStandaloneHooks(content, parserRegex);
  }

  public loadComponent(hookId: number, hookValue: HookValue, context: any, childNodes: Array<Element>): HookComponentData {
    // Simply return the component class here
    return {
      component: TexttransformComponent
    };
  }

  public getBindings(hookId: number, hookValue: HookValue, context: any): HookBindings {

    let transformType: TexttransformType;

    //console.log(hookValue.openingTag.toLowerCase());
    switch (hookValue.openingTag.toLowerCase()) {
      case 'co2': transformType = TexttransformType.CO2; break;
      case 'exki': transformType = TexttransformType.EXKI; break;
      //case '&#': transformType = TexttransformType.ENCODING; break;
    }

    // Set the 'type'-input in the EmojiComponent correspondingly
    return {
      inputs: {
        textToTransform: transformType
      }
    };
  }
}
