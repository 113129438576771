import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, concatMap, debounce, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { UrlParameters } from '../_model/shared';
import { Taxonomy, TaxonomyNode } from '../_model/taxonomy';

@Injectable()
export class TaxonomyService {

  constructor(
    private _http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
  }

  taxonomyNodes: TaxonomyNode[] = [];
  private objectData$: BehaviorSubject<TaxonomyNode[]> = new BehaviorSubject([]);
  private _obsArray: Array<Subject<TaxonomyNode[]>> = []
  private objectIdArray: number[] = [];
  private timeout;


  getTaxonomy(id: number): Observable<Taxonomy> {

    return this._http.get<Taxonomy>(environment.apiserver + 'taxonomy/v1/taxonomy/' + id).pipe(
      map((response: Taxonomy) => {
        //this.taxonomies.push(response);
        return <Taxonomy>response
      })
      , catchError(this.handleError)
    );
  }

  getTaxonomynode(id: number): Observable<TaxonomyNode> {

    let node = this.taxonomyNodes.filter(n => n.id == id)[0]
    if (node) { return of(node) }

    let params = new UrlParameters();
    params.addParameter('ids', id.toString());

    return this._http.get<TaxonomyNode>(environment.apiserver + 'taxonomybff/v1/taxonomy/node/list' + params.toString()).pipe(
      map((response: TaxonomyNode) => {
        this.taxonomyNodes = this.taxonomyNodes.concat(response);

        const node = response[0] as TaxonomyNode;
        node.taxonomyNodeChildNodes.sort((a, b) => a.sequenceid - b.sequenceid);
        return node;
      })
      , catchError(this.handleError)
    );
  }
  getTaxonomynodeList(ids: number[]): Observable<TaxonomyNode[]> {
    let nodes = [];
    ids.forEach(id => {
      let node = this.taxonomyNodes.filter(node => node.id == id)[0];
      if (node) { nodes.push(node) };
    })
    if (nodes.length == ids.length) { return of(nodes); }


    let params = new UrlParameters();
    params.addParameter('ids', ids.join(','))

    return this._http.get<TaxonomyNode[]>(environment.apiserver + 'taxonomybff/v1/taxonomy/node/list' + params.toString()).pipe(
      map((response: TaxonomyNode[]) => {
        this.taxonomyNodes = this.taxonomyNodes.concat(response);
        return <TaxonomyNode[]>response
      })
      , catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }

}
