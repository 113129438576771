import {Box} from './box';
import {Product} from './product';
import {Language} from './shared';

export class Taxonomy {
  constructor(
    public id: number,
    public name: string,
    public enabled: boolean,
    public brandid: number,
    public entrynode: number
  ) {
  }
}

export class TaxonomyNode {
  constructor(
    public id: number,
    public brandid: number,
    public taxonomyid: number,
    public displayname: string,
    public enabled: boolean,
    public hidden: boolean,
    public websiteimageurl: string,
    public websitethumbimageurl: string,
    public websitemediumimageurl: string,
    public websitelargeimageurl: string,
    public websiteimageasset: any,
    public languagecode: Language,
    public redirectid: number,
    public taxonomynodetranslations: TaxonomyNodeTranslation[],
    public taxonomyNodeProducts: TaxonomyNodeProduct[],
    public taxonomyNodeBoxes: TaxonomyNodeBox[],
    public taxonomyNodeChildNodes: TaxonomyNodeChildNode[],
  ) {

  }
}

export class TaxonomyNodeChildNode {
  constructor(
    public id: number,
    public childtaxonomynodeid: number,
    public childtaxonomynode: TaxonomyNode,
    public sequenceid: number
  ) {
  }
}

export class TaxonomyNodeProduct {
  constructor(
    public id: number,
    public productid: number,
    public product: Product,
    public sequenceid: number
  ) {
  }
}

export class TaxonomyNodeBox {
  constructor(
    public id: number,
    public boxid: number,
    public box: Box,
    public sequenceid: number
  ) {
  }
}

export class TaxonomyNodeTranslation {
  constructor(
    public id: number,
    public displayname: string,
    public languagecode: Language,
    public redirectid: number
  ) {
  }
}



