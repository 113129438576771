import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
const DEFAULT_DURATION = 150;
const DEFAULT_LONG_DURATION = 400;

export const slideInOut = trigger('slideInOut', [
  state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, overflow: 'hidden' })),
  state('false', style({ height: '0', visibility: 'hidden', overflow: 'hidden' })),
  transition('false => true', animate(300 + 'ms ease-in-out')),
  transition('true => false', animate(300 + 'ms ease-in-out'))
]);

export const clickAnimation = trigger('clickAnimation', [
  state('true', style({ transform: "scale(1)", opacity: "0.2", })),
  state('false', style({ transform: "scale(0)", opacity: '0',  })),
  transition('false => true', animate(300 + 'ms ease-in-out')),
  transition('true => false', animate(50 + 'ms ease-in'))
]);

export const collapse = trigger('collapse', [
  transition(':enter', [
    style({ display: 'block', height: '0', visibility: 'hidden', overflow: 'hidden' }),
    animate(
      DEFAULT_DURATION + 'ms ease-in',
      style({ display: 'block', height: AUTO_STYLE, visibility: AUTO_STYLE, overflow: 'hidden' })
    )
  ]),
  transition(':leave',
    [
      style({ display: 'block', height: AUTO_STYLE, visibility: AUTO_STYLE, overflow: 'hidden' }),
      animate(
        DEFAULT_DURATION + 'ms ease-in',
        style({ display: 'block', height: '0', visibility: 'hidden', overflow: 'hidden' }),
      )
    ])
])

export const horCollapse = trigger('horCollapse', [
  transition(':enter', [
    style({ display: 'block', width: 0, transform: 'translateX(-100%)', visibility: 'hidden', overflow: 'hidden' }),
    animate(
      DEFAULT_DURATION + 'ms ease-in',
      style({ display: 'block', width: AUTO_STYLE,transform: 'translateX(0%)', visibility: AUTO_STYLE, overflow: 'hidden' })
    )
  ]),
  transition(':leave',
    [
      style({ display: 'block', width: AUTO_STYLE, transform: 'translateX(0%)', visibility: AUTO_STYLE, overflow: 'hidden' }),
      animate(
        DEFAULT_DURATION + 'ms ease-in',
        style({ display: 'block', width: 0, transform: 'translateX(-100%)', visibility: 'hidden', overflow: 'hidden' }),
      )
    ])
])

export const fadeIn = trigger('fadeIn', [
  transition('void => *', [
    style({ opacity: 0 }), 
    animate(DEFAULT_DURATION, style({opacity: 1}))
  ]), 
  transition('* => void', [
    style({ opacity: 1 }), 
    animate(DEFAULT_DURATION, style({opacity: 0 }))
  ]), 
])

export const collapseTrigger = trigger('collapseTrigger', [
  state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, overflow: 'hidden' })),
  state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, overflow: 'hidden' })),
  transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
  transition('true => false', animate(DEFAULT_DURATION + 'ms ease-in'))
])




