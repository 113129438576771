import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { CmsService } from 'src/_service/CmsService';
import { collapse } from '../../_theme/animations';
import { NgFor, NgClass, NgIf, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-cms-accordeon',
    templateUrl: './cms-accordeon.component.html',
    animations: [collapse],
    standalone: true,
    imports: [NgFor, NgClass, NgIf]
})

export class CmsAccordeonComponent{
    private _accordion: any
    @Input() set accordeon(value: any){
        this._accordion = value;
        // if(isPlatformBrowser(this.platformId))
        this._accordion.blocks.forEach(block => {
            if(block.accordeon_content)
                block['accordeon_content_rendered'] = this._cmsService.render_rt(block.accordeon_content);
        });
    };
    get accordeon(){return this._accordion}
    expanded = -1;

    constructor(
        private _cmsService: CmsService,

        @Inject(PLATFORM_ID) private platformId: any,
        ) {

    }


    setExpanded(i) {
        this.expanded === i ? this.expanded = null : this.expanded = i;
    }

    render_rt(block) {
        return this._cmsService.render_rt(block);
    }

    get_src_as_css(field, contain) {
        return this._cmsService.get_src_as_css(field, contain)
    }

    get_src(field, contain) {
        return this._cmsService.get_src(field, contain);
    }

    get_alt(field) {
        return this._cmsService.get_alt(field);
    }


}
