import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, FormControl } from '@angular/forms';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { UrlParameters } from '../_model/shared';
import { AuthService } from '../_service/AuthService';

@Injectable({
  providedIn: 'root',
})
export class MyValidators implements AsyncValidator {

  constructor(
    private _http: HttpClient,
    private _authService: AuthService
  ) {
  }

  ConfirmPassword = (ConfirmPwdControl: AbstractControl) => {
    const confirmPassword = ConfirmPwdControl.value;
    if (ConfirmPwdControl.parent) {
      const PwdControl = ConfirmPwdControl.parent.get('password') as FormControl;
      const password = PwdControl.value;
      return password === confirmPassword ? null : { 'notsame': true };
    }
    return null;
  }

  validate = (control: AbstractControl) => {
    return of(null);
  }

  minLengthArray = (min: number) => {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min)
        return null;

      return { MinLengthArray: true };
    }
  }
  minLengthArrayWithPropery = (min: number, propertyname:string) => {
    return (c: AbstractControl): { [key: string]: any } => {

      if (c.value.filter(p => p[propertyname]).length >= min)
        return null;

      return { MinLengthArray: true };
    }
  }


  Iban = (control: AbstractControl) => { // : { [key: string]: boolean } | null
    const params = new UrlParameters();
    params.addParameter('iban', control.value);
    if (control.value !== '') {
      return this._http.get(environment.apiserver + 'customer/v1/customer/ibanvalid' + params.toString()).pipe(
        map((result) => {
          if (result === true) {
            return null;
          } else {
            return { 'invalidiban': true };
          }
        })
        // , catchError(  )
      );
    } else {
      return of(null);
    }
  }

  private _emailvalidSubj:  Subject<boolean> = new Subject<boolean>()
  private _checkedemaillist: Map<string, boolean> = new Map<string, boolean>();

  Email = (control: AbstractControl) => {
    if (!control.dirty) { return of(null); }

    if (this._checkedemaillist && this._checkedemaillist.has(control.value)) {
      if (this._checkedemaillist.get(control.value)) { return of(null) };
      return of({ 'invalidemail': true })
    }

    return this._authService.isEmailValid(control.value).pipe(
      map(a => {
        this._checkedemaillist.set(control.value, a);
        if (a) { return null };
        return { 'invalidemail': true }
      })
    ).toPromise();
  }

  doIsEmailValidCheck(email: string): Observable<boolean> {
    if (this._checkedemaillist && this._checkedemaillist.has(email)) {
      return of(this._checkedemaillist.get(email))
    }

    //this._emailvalidSubj.subscribe({
    //  complete: () => {
    //    this._emailvalidSubj = new Subject<boolean>();
    //  }
    //})

    //if (this._emailvalidSubj.observers.length === 1) {
      //return this._authService.isEmailValid(email).subscribe(
      //  result => {
      //    this._checkedemaillist.set(email, result);
      //    //this._emailvalidSubj.next(result);
      //    //this._emailvalidSubj = new Subject<boolean>();
      //    return result as boolean;
      //  }, error => {
      //    /*this._emailvalidSubj.error(error);*/
      //  }
      //)
    //}
    //return this._emailvalidSubj;
  }


  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');
  }

}
