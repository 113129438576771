import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { filter, Subject, switchMap, takeUntil } from 'rxjs';
import { environment } from '../../_environments/environment';
import { Address } from '../../_model/address';
import { CustomerAddress } from '../../_model/customer';
import { Countrycode, UrlParameters } from '../../_model/shared';
import { AddressService } from '../../_service/AddressService';
import { GlobalService } from '../../_service/GlobalService';
import { collapse } from '../../_theme/animations';


@Component({
    selector: 'addressedit',
    templateUrl: './addressedit.component.html',
    styleUrls: ['./addressedit.component.scss'],
    animations: [collapse    ],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, NgClass, NgFor, TranslateModule]
})
export class AddresseditComponent implements OnInit, OnDestroy {

  environment = environment;

  customerAddressForm: FormGroup;
  showFullAddress = false;

  customercountries: string[];

  _addAddress = false
  @Input() set addAddress(value: boolean) {
    if (value == false && this.customerAddressForm) this.customerAddressForm.reset();
    this._addAddress = value
  }
  @Input() shippingaddress: boolean = false;
  @Input() billingaddress: boolean = false;
  @Input() colorscheme = 'contrast';
  @Input() showdescription = true;

  scheme = {
    light: 'bg-e-white text-e-dark-green',
    contrast: 'bg-white',
  }

  @Output() addAddressChange = new EventEmitter<boolean>();
  @Output() addressChange: EventEmitter<Address> = new EventEmitter();
  @Output() customerAddressChange: EventEmitter<CustomerAddress> = new EventEmitter();

  private unsubscribe = new Subject<void>();

  constructor(
    private _addressService: AddressService,
    private _globalService: GlobalService,
    public fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {

    this.customercountries = this._globalService.getCustomercountrycodes();

    this.customerAddressForm = new FormGroup({
      id: new FormControl(null),
      addressid: new FormControl(null),
      shippingaddress: new FormControl(true, { updateOn: 'change' }),
      billingaddress: new FormControl(true, { updateOn: 'change' }),
      name: new FormControl(''),
      address: new FormGroup({
        id: new FormControl(''),
        street: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        number: new FormControl('', [Validators.required]),
        addition: new FormControl('', [Validators.maxLength(20)]),
        postalcode: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]),
        city: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        countrycode: new FormControl('', [Validators.required]),
        region: new FormControl('', [Validators.maxLength(50)]),
        longitude: new FormControl(''),
        latitude: new FormControl('')
      })
    });

    this.customerAddressForm.valueChanges.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(val => {
      if (this.customerAddressForm.valid) {
        this.customerAddressChange.emit(val)
      } else {
        this.customerAddressChange.emit(null)
      }
    })

    this.customerAddressForm.get('address').valueChanges.subscribe(value => {
      if (!this.showdescription)
        this.customerAddressForm.get('name').setValue(`${value.street} ${value.number}${value.addition? '-' + value.addition: ''}, ${value.postalcode} ${value.city}`)
      this.addressChange.emit(value);
    })

    this.customerAddressForm.get('address').get('countrycode').valueChanges.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(value => {
      if (value === Countrycode.NL) {
        this.customerAddressForm.get('address').get('postalcode').setValidators([Validators.required, Validators.pattern(new RegExp(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i))]);
      } else if (value === Countrycode.BE) {
        this.customerAddressForm.get('address').get('postalcode').setValidators([Validators.required, Validators.pattern(new RegExp(/^[1-9][0-9]{3,4}$/i))]);
      }
      this.customerAddressForm.get('address').get('postalcode').updateValueAndValidity();

    });
    this.customerAddressForm.get('address').valueChanges.pipe(
      filter(() => {
        return this.customerAddressForm.get('address').dirty
          && this.customerAddressForm.get('address').get('postalcode').valid
          && this.customerAddressForm.get('address').get('number').valid
          && this.customerAddressForm.get('address').get('countrycode').valid;
      }),
      switchMap(value => {
        const urlParams = new UrlParameters();
        urlParams.addParameter('postalcode', value.postalcode);
        urlParams.addParameter('number', value.number);
        urlParams.addParameter('countrycode', value.countrycode);
        urlParams.addParameter('addition', value.addition);
        return this._addressService.getAddressInfo(urlParams);
      }),
      takeUntil(this.unsubscribe)
    ).subscribe((result: Address) => {
      //console.log('addressresult', result);
      this.customerAddressForm.get('address').markAsPristine();
      if (result === null) {
        this.showFullAddress = true;
        this.customerAddressForm.get('addressid').reset();
        this.customerAddressForm.get('address').get('id').reset();
      } else {
        this.showFullAddress = false;
        this.customerAddressForm.get('addressid').setValue(result.id);
      }
      this.customerAddressForm.get('address').patchValue(result);
      this.customerAddressForm.get('address').markAsPristine();
    }, error => {
      console.log('error', error);
      this.showFullAddress = true;
    });

  }

  ngOnDestroy() {
    this.unsubscribe.next();
  }

  toggleAddAddress() {
    this._addAddress = !this._addAddress;
    this.addAddressChange.emit(this._addAddress);
  }
  clearAddress() {
    this.customerAddressForm.reset();
  }
  hasError = (controlName: string, errorName: string) => {
    if (this.customerAddressForm.get(controlName).touched)
      return this.customerAddressForm.get(controlName).hasError(errorName);
  }



}
