import { Component, OnInit } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
import { CmsService } from 'src/_service/CmsService';
import { environment } from '../../_environments/environment';
import { GlobalService } from 'src/_service/GlobalService';
import { TranslateModule } from '@ngx-translate/core';
import { CmsButtonComponent } from '../cms-button/cms-button.component';
import { NgIf, NgFor } from '@angular/common';
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        CmsButtonComponent,
        TranslateModule,
    ],
})
export class FooterComponent implements OnInit {

  // lang = this._translate.currentLang;
  env = { ...environment };
  currentYear = new Date().getFullYear();
  footer_slug = '/general/footer';
  content: any;
  socials = [
    { title: 'Facebook', url: 'https://www.facebook.com/EKOMENU/', src: '/assets/images/homepage/fb.svg' },
    { title: 'Instagram', url: 'https://www.instagram.com/ekomenu/', src: '/assets/images/homepage/ig.svg' },
    { title: 'Pinterest', url: 'https://nl.pinterest.com/ekomenu1', src: '/assets/images/homepage/pi.svg' }
  ];

  constructor(
    private _globalService: GlobalService,
    private _cmsService: CmsService
    ) {
  }

  ngOnInit(): void {
    const page_lang = this._globalService.getLanguage();

    // debugger;
      this._cmsService.getStory( this.footer_slug, { resolve_links: "1", language: page_lang } ).then(data =>{
        this.content = data.story.content;
      }).catch(error => {
        console.error(error)
      })
  }
  

}
