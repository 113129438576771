import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { Product } from '../../../_model/product';
import { Entitytype } from '../../../_model/redirect';
import { GlobalService } from '../../../_service/GlobalService';
import { PricingService } from '../../../_service/PricingService';
import { RedirectService } from '../../../_service/RedirectService';
import { BaseorderlineComponent } from '../baseorderline.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToggleComponent } from '../../toggle/toggle.component';
import { PricetagComponent } from '../../pricetag/pricetag.component';
import { ImageComponent } from '../../image/image.component';
import { NgIf, CurrencyPipe } from '@angular/common';
import { QuantityselectionComponent } from '../../quantityselection/quantityselection.component';

@Component({
    selector: 'productcard',
    templateUrl: './productcard.component.html',
    styleUrls: ['./productcard.component.scss'],
  standalone: true,
  imports: [NgIf, ImageComponent, RouterLink, PricetagComponent, ToggleComponent, ReactiveFormsModule, CurrencyPipe, TranslateModule, QuantityselectionComponent]
})
export class ProductcardComponent extends BaseorderlineComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() showProduct = new EventEmitter<Product>();
  disableShowAction: boolean;
  
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public _translate: TranslateService,
    public _pricingService: PricingService,
    public _globalService: GlobalService,
    private _redirectService: RedirectService
  ) {
    super(route, router, _translate, _pricingService, _globalService);

    this.loadingsubject.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this._product && !this._product['producturl'])
        this._product['producturl'] = `/product/id/${this._product.id}`;
    })
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disableShowAction = this.showProduct.observers.length === 0;
  }
  ngAfterViewInit() {
    //makes sure that these calls are done after that the images e.a. are loaded
    if (!this._product) return;
    setTimeout(() => {
      this._redirectService.getEntityUrl(Entitytype.PRODUCT, this._product.id, this._product.redirectid).then(value => this._product['producturl'] = value)
    })
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  showProductAction() {
    this.showProduct.emit(this._product);
  }
}
