import { formatDate, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, ɵDEFAULT_LOCALE_ID } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, filter, of, shareReplay, skip, switchMap, throwError } from 'rxjs';
import { catchError, map, takeUntil, throttleTime } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { Box, BoxPeriod } from '../_model/box';
import { CouponcodeCode } from '../_model/couponcode';
import { CustomerLifestyle } from '../_model/customer';
import { Currency, Flowtype, Order, Orderline, Orderstatus, Paymentmethod, Shippingmethod } from '../_model/order';
import { CustomerCreateOrderLine } from '../_model/pricing';
import { Product } from '../_model/product';
import { Recipe } from '../_model/recipe';
import { Entitytype } from '../_model/redirect';
import { Countrycode, TableParameters, TableResponse } from '../_model/shared';
import { Originentitytype } from '../_model/subscription';
import { Role } from '../_model/user';
import { MyValidators } from '../_validators/MyValidators';
import { AddressService } from './AddressService';
import { AuthService } from './AuthService';
import { BoxService } from './BoxService';
import { CustomerService } from './CustomerService';
import { GlobalService } from './GlobalService';
import { LocalstorageService } from './LocalstorageService';
import { PricingService } from './PricingService';
import { ProductService } from './ProductService';
import { RecipeService } from './RecipeService';
import { SessionstorageService } from './SessionstorageService';
import { WINDOW } from './WindowService';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  rnd: any;

  newOrderForm: FormGroup;
  subscriptionOrderflowLines = new FormArray([]);

  telemarketingForm: FormGroup;

  //will be updated by the selected deliverydate to be as accurate as possible
  assumedDeliverydate = formatDate(this._globalService.addDays(new Date(), 8), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);

  //is customercreateorderRequest
  orderFlowForm: FormGroup = this.initCustomercreateorderRequest();

  orderlistRefreshTime: number = 60 * 15 * 1000 // 15 min
  orderlistRefreshTimer: any;

  shownPage: number = 0;
  totalPages: number = 1;
  pagesize: number = 5;
  totalOrders: number = 0;
  private _orders: Order[] = [];
  public OrderlistSubject: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>(null);
  public Orderlist: Subject<{ page: number, pagesize: number }> = new Subject();
  public singleorderRecipeCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private refreshOrderflowForm = new Subject<void>();
  constructor(
    private _http: HttpClient,
    public fb: FormBuilder,
    private _myValidators: MyValidators,
    private _recipeService: RecipeService,
    private _boxService: BoxService,
    private _authService: AuthService,
    private _productService: ProductService,
    private _pricingService: PricingService,
    private _globalService: GlobalService,
    private _customerService: CustomerService,
    private _addressService: AddressService,
    private _translateService: TranslateService,
    private _localStorage: LocalstorageService,
    private _sessionStorage: SessionstorageService,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {

    this.rnd = Math.random();

    let customeraddressForm = this._customerService.initiateCustomerAddress();
    customeraddressForm.setControl('address', this._addressService.addressForm)
    customeraddressForm.get('addressid').removeValidators(Validators.required);

    //should only add controls if is a normal user
    this.setTelemarketingFields();

    this.newOrderForm = new FormGroup({
      steps: this.fb.array([

        // STEP-0
        new FormGroup({
          order: this.fb.array([
            // ORDER-0
            new FormGroup({
              calculatedPrice: new FormControl(null),
              shipping: new FormControl(0),
              shippingmethod: new FormControl(Shippingmethod.DOOR_DELIVERY),
              deliverydate: new FormControl(''),
              recipecard: new FormControl(false),
              orderlines: this.fb.array([], Validators.required),
              shippingaddress: new FormControl(null)
            }),
            //ORDER-1
            new FormGroup({
              postalcode: new FormControl('', [Validators.required, Validators.pattern(new RegExp(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i))]),
              countrycode: new FormControl(null, [Validators.required]),
              isExistingUser: new FormControl(false), //needed for displaying usercreationpage or not
              // donotcontact: new FormControl(true),
            }),
            //ORDER-2
            new FormGroup({
              deliveryinfo: this.fb.array([
                //deliveryinfo-0
                new FormGroup({
                  startdate: new FormControl('', Validators.required),
                  storepickup: new FormControl(false),

                  subscriptionid: new FormControl(null),

                }),
                //deliveryinfo-1
                new FormGroup({
                  store: new FormControl(''),
                  deliverytimeframe: new FormControl('', Validators.required),
                }),
                //deliveryinfo-2
                new FormGroup({
                  storedata: new FormControl(''),
                  deliveryinstructions: new FormControl('', Validators.maxLength(500)),
                }),
              ]),
            })
          ])
        }),

        // STEP-1
        this._customerService.customerForm, 

        // STEP-2
        new FormGroup({
          couponcode: new FormControl(null)
        }),
        // STEP-3
        new FormGroup({
          paymentmethod: new FormControl(null, Validators.required),
          bic: new FormControl(null, Validators.required),
          creditcardnetwork: new FormControl(null, Validators.required)
        })
      ])
    });
    this.telemarketingForm = new FormGroup({
      steps: this.fb.array([
        // STEP-0
        new FormGroup({
          calculatedPrice: new FormControl(null),
          shipping: new FormControl(0),
          shippingmethod: new FormControl(Shippingmethod.DOOR_DELIVERY),
          deliverydate: new FormControl(''),
          recipecard: new FormControl(false),
          orderlines: this.fb.array([], Validators.required),
          shippingaddress: new FormControl(null)
        }),
        //STEP-1
        this._customerService.customerForm,
        //newsletteroptin: new FormControl(false),
        //STEP-2
        customeraddressForm,
        //STEP-3
        new FormGroup({
          startdate: new FormControl(null, Validators.required),
          storepickup: new FormControl(false),
          store: new FormControl(''),
          subscriptionid: new FormControl(null),
          deliverytimeframe: new FormControl(null, Validators.required),
          storedata: new FormControl(''),
          deliveryinstructions: new FormControl('', Validators.maxLength(500)),
        }),
        //STEP-4
        new FormGroup({
          newsletteroptin: new FormControl(false),
          //termsandconditions: new FormControl(false, Validators.requiredTrue),
          couponcode: new FormControl(null),
          paymentmethod: new FormControl(null, Validators.required),
          bic: new FormControl(null, Validators.required),
          creditcardnetwork: new FormControl(null, Validators.required)
        })
      ])
    });

    //this.setLocalstorageCouponcode();
    this.Orderlist.pipe(
      shareReplay(),
      filter(() => { return (this.shownPage <= this.totalPages) }),
      switchMap((params: any) => {
        let tblparams = new TableParameters(params && params.page !== null ? params.page : this.shownPage, params && params.pagesize ? params.pagesize : this.pagesize, 'orderid', 'desc')
        return this.getOrdersTable(this._authService.getCurrentUser().customerid, tblparams as TableParameters);
      })
    ).subscribe(
      result => {
        if (!result) { return; }
        this.OrderlistSubject.next(this._orders);
        this.resetTimer();
        return result;
      }, error => {
        this.OrderlistSubject.error(error);
        return error;
      })

    this._authService.isAuthenticatedSubject.pipe(skip(1)).subscribe(
      value => {
        if (!value) {
          this.clearNewOrderForm();
          this.initOrderflowForm();
          this._orders = [];
          this.OrderlistSubject.next(null)
          if (isPlatformBrowser(this.platformId))
            this.window.clearTimeout(this.orderlistRefreshTimer);
          this.setTelemarketingFields();

        } else {
          //should only add controls if is a normal user
          this.setTelemarketingFields();

        }
      },
      () => {
        this.clearNewOrderForm();
        this._orders = [];
        this.OrderlistSubject.next(null)
        if (isPlatformBrowser(this.platformId))
          this.window.clearTimeout(this.orderlistRefreshTimer);
        this.setTelemarketingFields();
      },
      () => {
        this.clearNewOrderForm();
        this._orders = [];
        this.OrderlistSubject.next(null)
        if (isPlatformBrowser(this.platformId))
          this.window.clearTimeout(this.orderlistRefreshTimer);
        this.setTelemarketingFields();
      });

    this._translateService.onLangChange.pipe(skip(1)).subscribe(res => {
      //removes object from lines to let it retrieve the new translated objects to show the correct translated titles
      this.orderlines.controls.forEach(ctrl => {
        ctrl.get('object').reset();
      })
      //sets the sessionstorage because the valuechanges check takes too long and doesnt write it to the sessionstorage it before the reload
      this.saveNewOrderInSession()
    })

    this.newOrderForm.valueChanges.pipe(
      throttleTime(500, null, { leading: false, trailing: true })
    ).subscribe(() => {
      this.saveNewOrderInSession();
    });

    this.order.get('recipecard').valueChanges.subscribe(() => {
      this.setCalculatedPrices(Flowtype.SINGLEORDER)
    })
    this.TMorder.get('recipecard').valueChanges.subscribe(() => {
      this.setCalculatedPrices(Flowtype.TELEMARKETING)
    })

    this.deliveryinfo.get([0]).get('startdate').valueChanges.subscribe(date => {
      if (!date) return;
      this.assumedDeliverydate = date;
    })

    this.initOrderflowForm();
    
    //Subscriptionorderflow orderlines
    this.subscriptionOrderflowLines.valueChanges.subscribe({
      next: res => {
        this._sessionStorage.setObject('subscriptionorderflowlines', res)
      }
    })
    const SessionSubscriptionOrderFlowlines = _sessionStorage.getObject('subscriptionorderflowlines') as CustomerCreateOrderLine[];
    if (SessionSubscriptionOrderFlowlines) {
      SessionSubscriptionOrderFlowlines.forEach(l => {
        this.addOrderline(l, false, Flowtype.SUBSCRIPTION);
      })
    }

    const session = _sessionStorage.getObject('neworder');
    if (session) {

      session.steps[0].order[0].orderlines.forEach(line => {
        this.addOrderline(line);
      })
      //customer shizzl will be managed by the customerservice if the customer already exists
      if (!session.steps[1].id) {
        session.steps[1].customerAddresses.forEach(rec => {
          //this.addCustomerAddress(rec);
          this._customerService.addCustomerAddress(rec);
        });
        session.steps[1].customerLifestyles.forEach(rec => {
          this._customerService.addLifeStyle(rec);
        });
        session.steps[1].customerAllergens.forEach(rec => {
          this._customerService.addAllergen(rec);
        });
      }
      this.newOrderForm.patchValue(session, { emitEvent: false });

      //updates assumeddeliverydate based on the selected startdate
      if (this.deliveryinfo.get([0]).get('startdate').value) {
        const date = this.deliveryinfo.get([0]).get('startdate').value
        //checks if the date is bigger then today
        if (new Date(date).getTime() < new Date().getTime()) {
          this.deliveryinfo.get([0]).get('startdate').setValue(null);
          return;
        }
        this.assumedDeliverydate = this.deliveryinfo.get([0]).get('startdate').value
      }

      this.setCalculatedPrices();
    } else {
      this.clearNewOrderForm();

    }

    this.setLocalstorageCouponcode();
  }
  get getNewOrderForm(): FormGroup {
    //fills in the basevalues when is empty form
    if (!this.newOrderForm.controls.steps.get([0]).get('order').get([1]).get('countrycode').value) {
      this.newOrderForm.controls.steps.get([0]).get('order').get([0]).get('shippingmethod').setValue(Shippingmethod.DOOR_DELIVERY);
      this.newOrderForm.controls.steps.get([0]).get('order').get([1]).get('countrycode').setValue(this._globalService.getPricecountry() || Countrycode.NL);
      this.newOrderForm.controls.steps.get([1]).get('brandid').setValue(environment.brandid);
      if (this.newOrderForm.controls.steps.get([1]).get('user'))
        this.newOrderForm.controls.steps.get([1]).get('user').get('brandid').setValue(environment.brandid);
      this.newOrderForm.controls.steps.get([0]).get('order').get([2]).get('deliveryinfo').get([0]).get('storepickup').setValue(false);
      //just to be sure, remove it
      this._customerService.customerForm.get('email').removeAsyncValidators(this._myValidators.Email);
    }
    return this.newOrderForm;
  }
  get getNewTMOrderForm(): FormGroup {
    //fills in the basevalues when is empty form
    if (!this.telemarketingForm.controls.steps.get([2]).get('address').get('countrycode').value) {
      this.telemarketingForm.controls.steps.get([0]).get('shippingmethod').setValue(Shippingmethod.DOOR_DELIVERY);
      this.telemarketingForm.controls.steps.get([2]).get('address').get('countrycode').setValue(this._globalService.getPricecountry() || Countrycode.NL);
      this.telemarketingForm.controls.steps.get([1]).get('brandid').setValue(environment.brandid);
      /*      this.telemarketingForm.controls.steps.get([1]).get('user').get('brandid').setValue(environment.brandid);*/
      this.telemarketingForm.controls.steps.get([3]).get('storepickup').setValue(false);
      this._customerService.customerForm.get('email').addAsyncValidators(this._myValidators.Email);
    }
    return this.telemarketingForm;
  }

  private setTelemarketingFields() {
    let customerform = this._customerService.customerForm;
    let userform = new FormGroup({
      password: new FormControl(null, { validators: [Validators.required, Validators.minLength(8)] }),
      confirmPassword: new FormControl(null, { validators: [Validators.required, Validators.minLength(8), this._myValidators.ConfirmPassword] }),
      userroles: this.fb.array([]),
      brandid: new FormControl(environment.brandid, [Validators.required, Validators.min(1)]),
    })
    const currentuser = this._authService.getCurrentUser();
    if (!currentuser || (currentuser && currentuser.userroles && !currentuser.userroles.includes(Role.TELEMARKETING))) {
      customerform.addControl('user', userform)
      customerform.addControl('address', this._addressService.addressForm)
    } else {
      console.log('removing address')
      customerform.removeControl('user');
      customerform.removeControl('address');
    }
  }

  private get steps(): FormArray {
    return this.getNewOrderForm.controls.steps as FormArray;
  }
  get deliveryinfo(): FormArray {
    return this.steps.controls[0].get('order').get([2]).get('deliveryinfo') as FormArray;
  }
  get customer(): FormGroup {
    return this.steps.controls[1] as FormGroup;
  }
  get address(): FormGroup {
    return this.customer.get('address') as FormGroup;
  }
  get customerAddresses(): FormArray {
    return this.customer.get('customerAddresses') as FormArray;
  }
  get customerLifestyles(): FormArray {
    return this.customer.get('customerLifestyles') as FormArray;
  }
  get customerAllergens(): FormArray {
    return this.customer.get('customerAllergens') as FormArray;
  }
  get countrycode(): FormControl {
    return this.steps.controls[0].get('order').get([1]).get('countrycode') as FormControl
  }
  get postalcode(): FormControl {
    return this.steps.controls[0].get('order').get([1]).get('postalcode') as FormControl
  }
  get userRoles(): FormArray {
    return this.steps.controls[1].get('user.userroles') as FormArray;
  }
  get order(): FormGroup {
    return this.steps.controls[0].get('order').get([0]) as FormGroup;
  }
  get orderlines(): FormArray {
    return this.order.get('orderlines') as FormArray;
  }
  get orderrequest(): FormGroup {

    //const dateToday = formatDate(new Date(), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
    const dateNextWeek = formatDate(new Date(new Date().getTime() + 8 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);

    const order = new FormGroup({
      customerid: new FormControl(this.steps.controls[1].get('id').value),
      email: new FormControl(this.steps.controls[1].get('email').value),
      brandid: new FormControl(environment.brandid, [Validators.required, Validators.min(1)]),
      originentitytype: new FormControl(null),
      recipecard: new FormControl(this.order.get('recipecard').value),
      pickupdeliverytimeframe: new FormControl(this.deliveryinfo.controls[1].get('deliverytimeframe').value?.id),
      shippingmethod: new FormControl(this.order.get('shippingmethod').value, Validators.required),
      deliverydate: new FormControl(this.deliveryinfo.controls[0].get('startdate').value ? this.deliveryinfo.controls[0].get('startdate').value : dateNextWeek),
      shippingaddresscountrycode: new FormControl(this.steps.controls[0].get('order').get([1]).get('countrycode').value, Validators.required),
      couponcode: new FormControl(this.steps.controls[2].get('couponcode').value === '' ? null : this.steps.controls[2].get('couponcode').value),
      couponcodedidnotreachmov: new FormControl(false),
      orderlines: this.fb.array([])
    });

    const lines = order.get('orderlines') as FormArray;

    this.orderlines.controls.forEach(val => {
      lines.push(val);
    });
    return order;
  }

  //Telemarketing
  private get TMsteps(): FormArray {
    return this.telemarketingForm.controls.steps as FormArray;
  }
  get TMorder(): FormGroup {
    return this.TMsteps.controls[0] as FormGroup;
  }
  get TMorderlines(): FormArray {
    return this.TMorder.get('orderlines') as FormArray;
  }
  get TMorderrequest(): FormGroup {

    //const dateToday = formatDate(new Date(), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
    const dateNextWeek = formatDate(new Date(new Date().getTime() + 8 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);

    const order = new FormGroup({
      customerid: new FormControl(this.TMsteps.controls[1].get('id').value),
      email: new FormControl(this.TMsteps.controls[1].get('email').value),
      brandid: new FormControl(environment.brandid, [Validators.required, Validators.min(1)]),
      originentitytype: new FormControl(null),
      recipecard: new FormControl(this.TMorder.get('recipecard').value),
      pickupdeliverytimeframe: new FormControl(this.TMsteps.controls[3].get('deliverytimeframe').value?.id),
      shippingmethod: new FormControl(this.TMorder.get('shippingmethod').value, Validators.required),
      deliverydate: new FormControl(this.TMsteps.controls[3].get('startdate').value ? this.TMsteps.controls[3].get('startdate').value : dateNextWeek),
      shippingaddresscountrycode: new FormControl(this.TMsteps.controls[2].get('address').get('countrycode').value, Validators.required),
      couponcode: new FormControl(this.TMsteps.controls[4].get('couponcode').value === '' ? null : this.TMsteps.controls[4].get('couponcode').value),
      couponcodedidnotreachmov: new FormControl(false),
      orderlines: this.fb.array([])
    });

    const lines = order.get('orderlines') as FormArray;

    this.TMorderlines.controls.forEach(val => {
      lines.push(val);
    });
    return order;
  }

  getFlowOrderlines(flowtype: Flowtype): FormArray {
    switch (flowtype) {
      case Flowtype.SINGLEORDER:
        return this.orderlines;
      case Flowtype.TELEMARKETING:
        return this.TMorderlines;
      case Flowtype.SUBSCRIPTION:
        return this.subscriptionOrderflowLines;
    }
  }
  getFlowOrder(flowtype: Flowtype): FormGroup {
    switch (flowtype) {
      case Flowtype.SINGLEORDER:
        return this.order;
      case Flowtype.TELEMARKETING:
        return this.TMorder;
      case Flowtype.SUBSCRIPTION:
        return this.orderFlowForm;
    }
  }

  setOrderFlowFormAddresses() {
    this.orderFlowForm.get('shippingtoname').setValue(this._customerService.getCustomerName);
    this.orderFlowForm.get('billingtoname').setValue(this._customerService.getCustomerName);

    const customerOrderShippingAddress = this._customerService.getCustomerAddressByAddressId(this._addressService.shippingAddressForm.get('id').value)
    if (!customerOrderShippingAddress) return;
    this.orderFlowForm.get('shippingaddress').setValue(customerOrderShippingAddress.id);
    this.orderFlowForm.get('billingaddress').setValue(customerOrderShippingAddress.id);
  }
  clearOrderFlowForm(flowType: Flowtype) {

    this.orderFlowForm.get('deliverydate').reset(this.assumedDeliverydate);
    this.orderFlowForm.get('pickupdeliverytimeframe').reset(null);
    this.orderFlowForm.get('pickupstore').reset(null);
    this.orderFlowForm.get('storeid').reset(null);
    this.orderFlowForm.get('storetype').reset(null);
    this.orderFlowForm.get('couponcode').reset(null);
    this.orderFlowForm.get('couponcodedidnotreachmov').reset(false);

    if (flowType === Flowtype.SUBSCRIPTION) {
      this.orderFlowForm.get('originentityid').reset(null);
      this.orderFlowForm.get('originentitytype').reset(null);
      this.orderFlowForm.get('firstsubscriptionorder').reset(false);
      this.orderFlowForm.get('recipecard').reset(false);

      this.subscriptionOrderflowLines.clear()
    }

  }

  private saveNewOrderInSession() {
    const value = this.newOrderForm.getRawValue();
    if (value.steps[1].user) { //possible blocked by cookiebot
      value.steps[1].user.password = null;
      value.steps[1].user.confirmPassword = null;
    }
    this._sessionStorage.setObject('neworder', value);
  }
  clearNewOrderForm() {
    console.log('clearing orderforms')
    
    this.initOrderflowForm();
    this.newOrderForm.reset();
    //recreates customerform
    this.customer.get('donotcontact').setValue(true);
    this.customerAddresses.clear();
    //this.customerOptins.clear();
    if (this.userRoles)
      this.userRoles.clear();
    this.orderlines.clear();
    this.subscriptionOrderflowLines.clear();

    if (this.newOrderForm.get('steps').get([1]).get('user'))
      this.newOrderForm.get('steps').get([1]).get('user').enable();

    this._sessionStorage.removeItem('neworder');
    this._sessionStorage.removeItem('newordersteps');
    this._sessionStorage.removeItem('orderflowform');

    this.setLocalstorageCouponcode();
  }
  setLocalstorageCouponcode() {
    const couponcodedata = this._localStorage.getObject(('couponcode')) as CouponcodeCode;
    if (couponcodedata && couponcodedata.isusable) {
      console.log('couponcode', couponcodedata);
      if (couponcodedata.personalemail && couponcodedata.personalemail !== '') {
        // always first set email to be able to check the couponcode
        this.customer.get('email').setValue(couponcodedata.personalemail);
        this._customerService.customerFlowform.get('email').setValue(couponcodedata.personalemail);
      }

      this.steps.controls[2].get('couponcode').setValue(couponcodedata.code);
    }
  }

  private initOrderflowForm() {
    this.refreshOrderflowForm.next();
    this.orderFlowForm.reset({ email: '' });

    this.orderFlowForm = this.initCustomercreateorderRequest();

    this.orderFlowForm.valueChanges.pipe(
      takeUntil(this.refreshOrderflowForm)
    ).subscribe({
      next: res => {
        this._sessionStorage.setObject('orderflowform', res)
      }
    })

    const SessionorderFlowForm = this._sessionStorage.getObject('orderflowform');
    if (SessionorderFlowForm) {
      this.orderFlowForm.patchValue(SessionorderFlowForm);
    }
    if (!this.orderFlowForm.get('deliverydate').value) {
      this.orderFlowForm.get('deliverydate').setValue(this.assumedDeliverydate)
    } else {
      this.assumedDeliverydate = this.orderFlowForm.get('deliverydate').value
    }

    this.orderFlowForm.get('recipecard').valueChanges.pipe(
      takeUntil(this.refreshOrderflowForm)
    ).subscribe(() => {
      this.setCalculatedPrices(Flowtype.SUBSCRIPTION)
    })
    this.orderFlowForm.get('couponcode').valueChanges.pipe(
      takeUntil(this.refreshOrderflowForm)
    ).subscribe(() => {
      this.setCalculatedPrices(Flowtype.SUBSCRIPTION)
    })   
  }

  private initCustomercreateorderRequest(): FormGroup {
    const fg = new FormGroup({
      customerid: this._customerService.customerFlowform.get('id'),
      email: this._customerService.customerFlowform.get('email'),
      brandid: new FormControl(environment.brandid, [Validators.required, Validators.min(1)]),
      currency: new FormControl(Currency.EUR),
      originentityid: new FormControl(null),
      originentitytype: new FormControl(null),

      shippingmethod: new FormControl(Shippingmethod.DOOR_DELIVERY, Validators.required),
      deliverydate: new FormControl(null, Validators.required), //later to subscriptionform -> firstdeliverydate?
      pickupdeliverytimeframe: new FormControl(null, Validators.required), //later to subscriptionform?
      pickupstore: new FormControl(null), //later to subscriptionform?
      firstsubscriptionorder: new FormControl(false),
      storeid: new FormControl(null),//later to subscriptionform?
      storetype: new FormControl(null), //later to subscriptionform?
      shippingtoname: new FormControl(this._customerService.getCustomerName),
      billingtoname: new FormControl(this._customerService.getCustomerName),
      shippingaddress: new FormControl(this._customerService.getCustomerShippingAddressId), //
      billingaddress: new FormControl(this._customerService.getCustomerBillingAddressId), //
      shippingaddresscountrycode: this._addressService.shippingAddressForm.get('countrycode'),

      calculatedPrice: new FormControl(null),
      couponcode: new FormControl(null),  //later to subscriptionform?
      couponcodedidnotreachmov: new FormControl(false),
      recipecard: new FormControl(false),  //later to subscriptionform?
      paymentmethod: new FormControl(null, Validators.required),
      bic: new FormControl(null),
      creditcardnetwork: new FormControl(null),
      orderlines: this.fb.array([])
    });

    fg.get('paymentmethod').valueChanges.pipe().subscribe(value => {
      if (value === Paymentmethod.IDEAL) {
        fg.get('bic').setValidators(Validators.required);
      } else {
        fg.get('bic').clearValidators();
      }
      if (value === Paymentmethod.CREDITCARD) {
        fg.get('creditcardnetwork').setValidators(Validators.required);
      } else {
        fg.get('creditcardnetwork').clearValidators();
      }
      fg.get('bic').updateValueAndValidity();
      fg.get('creditcardnetwork').updateValueAndValidity();
    });

    return fg;
  }

  addOrderline(orderline: CustomerCreateOrderLine, emitevent = true, flowtype = Flowtype.SINGLEORDER) {

    orderline.sku = (orderline.sku === "" || orderline.sku === undefined) ? null : orderline.sku;

    const orderlines = this.getFlowOrderlines(flowtype);
    const existingOrderline = orderlines.controls.filter(c => {
      //this is for recipecard lines
      if (!orderline.sku && !orderline.boxid && !orderline.recipeid)
        return;

      if (orderline.sku && orderline.sku !== c.value.sku)
        return;
      if (orderline.boxid && orderline.boxid !== c.value.boxid)
        return;
      if (orderline.recipeid && orderline.recipeid !== c.value.recipeid)
        return;

      if (c.value.persons !== orderline.persons)
        return;
      if (c.value.couponcodeitem !== orderline.couponcodeitem)
        return;
      return orderline;
    })[0]

    //had to update the qty in this workaround because it otherwise didnt trigger the valuechanges on de whole form to save it to the sessionstorage
    if (existingOrderline) {

      const orderlineIndex = orderlines.controls.indexOf(existingOrderline)
      existingOrderline.get('quantity').patchValue(existingOrderline.get('quantity').value + orderline.quantity);
      orderlines.removeAt(orderlineIndex);
      if (orderlines.controls.length == 0) {
        orderlines.push(existingOrderline);
      } else {
        orderlines.insert(orderlineIndex, existingOrderline);
      }
      this.setCalculatedPrices(flowtype);
      return;
    } else {

      const g = new FormGroup({
        sku: new FormControl(null),
        recipeid: new FormControl(null),
        boxid: new FormControl(null),
        persons: new FormControl(null),
        description: new FormControl(''),
        quantity: new FormControl(null, Validators.required),
        webshopitem: new FormControl(false),
        onetimebox: new FormControl(false),
        issubscriptionitem: new FormControl(false),
        calculatedPrice: new FormControl(null),
        couponcodeitem: new FormControl(false),
        doublepersons: new FormControl(false),
        object: new FormControl(null)
      });
      g.patchValue(orderline);

      if (g.get('boxid').value !== null || g.get('recipeid').value !== null) {
        const max = Math.max(...environment.subscriptionPersonChoices);
        const min = Math.min(...environment.subscriptionPersonChoices)
        g.get('persons').setValidators([Validators.min(min), Validators.max(max)]);
        g.updateValueAndValidity();
      }
      g.get('persons').valueChanges.subscribe(() => {
        this.setCalculatedPrices(flowtype);
        this.saveNewOrderInSession();
      });
      g.get('quantity').valueChanges.subscribe(() => {
        this.setCalculatedPrices(flowtype);
        this.saveNewOrderInSession();

      });

      this.setOrderline(g);

      orderlines.push(g, { emitEvent: emitevent });
      if (!orderline.couponcodeitem && (orderline.sku || orderline.boxid || orderline.recipeid))
        this.setCalculatedPrices(flowtype);
    }
    this.setSingleorderRecipesCount();
  }
  createNewOrderline(product: Product, box: Box, recipe: Recipe, persons: number = null, quantity: number = 1, couponcodeitem: boolean = false, subscriptionitem: boolean = false, webshopitem: boolean = false, onetimebox: boolean = false, flowtype = Flowtype.SINGLEORDER) {

    if (box && this._customerService.getCustomerLifestyles.length == 0) {
      this._customerService.addLifeStyle(new CustomerLifestyle(null, box.associatedlifestyle))
      //this.addCustomerLifestyle(new CustomerLifestyle(null, box.associatedlifestyle))
    }

    const line: CustomerCreateOrderLine = {
      sku: product?.sku,
      recipeid: recipe?.id,
      boxid: box?.id,
      persons: persons,
      description: product?.title || box?.title || recipe?.displayname,
      quantity: quantity,
      issubscriptionitem: subscriptionitem, //has to be true to be able to have different prices for different quantities of persons, it should be made dynamic when there also should be able to add webshop products to an singleorder
      webshopitem: webshopitem,
      onetimebox: onetimebox,
      calculatedPrice: null,
      doublepersons: recipe ? recipe?.doublepersons : box ? box?.doublepersons : false,
      couponcodeitem: couponcodeitem,
    }

    line['object'] = product || box || recipe;

    this.addOrderline(line, true, flowtype);
    //this.setCalculatedPrices(flowtype);
  }
  removeOrderline(line: FormGroup, flowtype = Flowtype.SINGLEORDER) {

    const l = line.value;
    let index = this.getOrderlineIndex(l.sku ? l.object : null, l.boxid ? l.object : null, l.recipeid ? l.object : null, l.couponcodeitem, flowtype);

    this.removeOrderlineByIndex(index, flowtype)

  }
  removeOrderlineByIndex(index: number, flowtype = Flowtype.SINGLEORDER) {

    let orderlines = this.getFlowOrderlines(flowtype);

    orderlines.removeAt(index);
    orderlines.markAsDirty();
    this.setCalculatedPrices(flowtype);
    this.setSingleorderRecipesCount();
  }
  private async setOrderline(orderline: FormGroup) {
    // only sets recipeid because that is the only thing available
    if (orderline.get('recipeid').value > 0 && orderline.get('object').value == null) {
      orderline.get('object').setValue(await this._recipeService.getRecipe(orderline.get('recipeid').value).toPromise());
    } else if (orderline.get('boxid').value > 0 && orderline.get('object').value == null) {
      orderline.get('object').setValue(await this._boxService.getBox(orderline.get('boxid').value).toPromise());
    } else if (orderline.get('sku').value !== '' && orderline.get('object').value == null) {
      orderline.get('object').setValue(await this._productService.getProductBySKU(orderline.get('sku').value).toPromise());
    }
    this.setSingleorderRecipesCount();
  }
  getOrderlineIndex(product: Product, box: Box, recipe: Recipe, couponcodeitem: boolean = false, flowtype: Flowtype = Flowtype.SINGLEORDER): number {
    if (!product && !box && !recipe) {
      console.warn('No product, box or recipe given')
      return null;
    }

    let index = this.getOrderlineIndexByElementId(product?.sku, box?.id, recipe?.id, couponcodeitem, flowtype);
    
    if (index == null || index < 0) {
      console.warn('product, box or recipe not found: ', product?.sku, box?.id, recipe?.id);
      return;
    }

    return index;
  }
  getOrderlineIndexByElementId(sku: string, boxid: number, recipeid: number, couponcodeitem: boolean = false, flowtype: Flowtype = Flowtype.SINGLEORDER): number {
    if (!sku && !boxid && !recipeid) {
      console.warn('No sku, boxid or recipeid given')
      return null;
    }

    const lines = this.getFlowOrderlines(flowtype);
    let index = null;
    if (sku)
      index = lines.value.findIndex(i => i.sku === sku && i.couponcodeitem == couponcodeitem);
    if (boxid)
      index = lines.value.findIndex(i => i.boxid === boxid && i.couponcodeitem == couponcodeitem);
    if (recipeid)
      index = lines.value.findIndex(i => i.recipeid === recipeid && i.couponcodeitem == couponcodeitem);

    if (index == null || index < 0) {
      console.warn('product, box or recipe not found: ', sku, boxid, recipeid);
      return;
    }

    return index;
  }

  getOrderlineByIndex(index: number, flowtype: Flowtype = Flowtype.SINGLEORDER): FormGroup {
    const lines = this.getFlowOrderlines(flowtype);
    return lines.at(index) as FormGroup
  }
  getOrderline(product: Product, box: Box, recipe: Recipe, couponcodeitem: boolean = false, flowtype: Flowtype = Flowtype.SINGLEORDER): FormGroup {
    if (!product && !box && !recipe) {
      console.warn('No product, box or recipe given')
      return null;
    }

    const index = this.getOrderlineIndex(product, box, recipe, couponcodeitem, flowtype);

    return this.getOrderlineByIndex(index, flowtype)

  }
  changeOrderlineByIndex(index: number, flowtype: Flowtype = Flowtype.SINGLEORDER, personsvalue: number = null, quantityvalue: number = null) {
    const line = this.getOrderlineByIndex(index, flowtype)
    if (!line) return;

    this.changeOrderline(line, personsvalue, quantityvalue)

  }
  changeOrderlineByContent(product: Product, box: Box, recipe: Recipe, flowtype: Flowtype = Flowtype.SINGLEORDER, personsvalue: number = null, quantityvalue: number = null, couponcodeitem: boolean = false) {

    const line = this.getOrderline(product, box, recipe, couponcodeitem, flowtype)
    if (!line) return;

    this.changeOrderline(line, personsvalue, quantityvalue)

  }
  changeOrderline(line: FormGroup, personsvalue: number = null, quantityvalue: number = null) {
    if (personsvalue) {
      let l = line.get('persons').value
      if (line.get('boxid').value && line.get('object').value) {
        let box = line.get('object').value as Box;
        let personsindex = box._boxpersons.indexOf(personsvalue);
        //if (personsindex + personschange <= box._boxpersons.length - 1 && personsindex + personschange >= 0)
        if (personsindex >= 0)
          line.get('persons').setValue(personsvalue)
      } else {
        if (personsvalue >= Math.min(...environment.subscriptionPersonChoices) && personsvalue <= Math.max(...environment.subscriptionPersonChoices)) {
          line.get('persons').setValue(personsvalue);
        } else if (l < Math.min(...environment.subscriptionPersonChoices) && l > Math.max(...environment.subscriptionPersonChoices)) {
          line.get('persons').setValue(Math.min(...environment.subscriptionPersonChoices));
        }
      }
    }
    const min = 1;
    if (quantityvalue) {
      line.get('quantity').setValue(quantityvalue < min ? min : quantityvalue);
    }
    this.setSingleorderRecipesCount();
  }

  getRecipecount(orderlines: Orderline[]): number {
    let recipecount = 0
    orderlines.forEach(o => {
      if (o.recipeid) recipecount = recipecount + o.quantity;
      if (o.boxid) {
        //also checks if there are recipes in a box period
        if (o['object'] && o['object'].boxPeriods && o['object'].boxPeriods.length > 0) {
          const periods = o['object'].boxPeriods as BoxPeriod[];
          recipecount = recipecount + (o.quantity * periods.map(p => p.boxRecipes.length)[0]);
        }
      }
    });
    return recipecount
  }

  private setSingleorderRecipesCount() {
    //needs timeout on lowering quantity
    setTimeout(() => {
      
      const orderlines = this.orderlines.value as Orderline[];
      const recipecount = this.getRecipecount(orderlines)
      this.singleorderRecipeCount.next(recipecount);

      if (recipecount === 0) {
        if (this.deliveryinfo.get([0]).get('storepickup').value === true)
          this.deliveryinfo.get([0]).get('storepickup').setValue(false);
        if (this.order.get('recipecard').value)
          this.order.get('recipecard').setValue(false);
      }
    }, 100)
  }
  setCalculatedPrices(flowtype = Flowtype.SINGLEORDER) {

    //To let the couponcode be applied, minimum of a empty string is required as email address

    let request;
    let order;
    let orderlines;
    let emitOrderEvent = false;
    switch (flowtype) {
      case Flowtype.SINGLEORDER:
        request = this.orderrequest.getRawValue();
        order = this.order;
        orderlines = this.orderlines;
        break;
      case Flowtype.TELEMARKETING:
        request = this.TMorderrequest.getRawValue();
        order = this.TMorder;
        orderlines = this.TMorderlines;
        break;
      case Flowtype.SUBSCRIPTION:
        request = this.orderFlowForm.getRawValue();
        request.orderlines = this.subscriptionOrderflowLines.getRawValue();
        request.originentitytype = Originentitytype.RECIPE_SUBSCRIPTION;
        request.firstsubscriptionorder = true;
        order = this.orderFlowForm;
        orderlines = this.subscriptionOrderflowLines
        emitOrderEvent = true;
    }

    if (request) {
      request.orderlines = request.orderlines.filter(l => !l.couponcodeitem)
      request.brandid = environment.brandid;

      this._pricingService.calculateOrderprice(request).subscribe(
        result => {
          if (!result) return;


          if (orderlines.controls.filter(l => l.value['couponcodeitem'] || (!l.value.sku && !l.value.boxid && !l.value.recipeid)).length > 0)
            do {
              let index = orderlines.controls.findIndex(l => l.value['couponcodeitem'] || (!l.value.sku && !l.value.boxid && !l.value.recipeid))
              orderlines.removeAt(index, { emitEvent: false });
            } while (orderlines.controls.filter(l => l.value['couponcodeitem'] || (!l.value.sku && !l.value.boxid && !l.value.recipeid)).length > 0)

          order.get('calculatedPrice').setValue(result.calculatedPrice, { emitEvent: emitOrderEvent });
          // updates the available orderlines with the new data
          orderlines.controls.forEach((ctrl: FormGroup) => {
            const val = ctrl.value as Orderline;

            if (result.orderlines) {
              const line = result.orderlines.filter(o => {
                if (val.boxid) {
                  return val.boxid === o.boxid && val.quantity === o.quantity && val.persons === o.persons && o.couponcodeitem === false;
                }
                if (val.recipeid) {
                  return val.recipeid === o.recipeid && val.quantity === o.quantity && val.persons === o.persons && o.couponcodeitem === false;
                }
                if (val.sku && val.sku !== '') {
                  return val.sku === o.sku && val.quantity === o.quantity && (!val.persons && !o.persons || val.persons === o.persons) && o.couponcodeitem === false;
                }
              })[0];

              if (line) {
                ctrl.patchValue(line, { emitEvent: false });
                ctrl.get('calculatedPrice').setValue(line.calculatedPrice);
                //let l = this.subscriptionOrderflowLines.at(0);
                //l.get('calculatedPrice').setValue(null)
                //l.get('calculatedPrice').setValue(l.get('calculatedPrice').value)
              } else {
                console.warn('line for calculatedprice not found', val)
              }
            }
          });

          
          if (result.orderlines) {
            // adds couponcode orderlines
            result.orderlines.filter(o => o.couponcodeitem === true).forEach(val => {
              this.addOrderline(val, false, flowtype);
            });
            // adds recipecard orderlines
            result.orderlines.filter(l => !l.sku && !l.boxid && !l.recipeid).forEach(val => {
              this.addOrderline(val, false, flowtype);
            });
          }

          orderlines.updateValueAndValidity({ emitEvent: false });
        }, error => {
          console.log(error);
        }
      );
    }

  }

  private resetTimer() {
    if (isPlatformBrowser(this.platformId))
      this.window.clearTimeout(this.orderlistRefreshTimer)
    this.orderlistRefreshTimer = setTimeout(() => {
      console.log('executed order timeout', new Date());
      let orderlength = this._orders.length;
      this._orders = [];
      this.Orderlist.next({ page: 0, pagesize: orderlength });
    }, this.orderlistRefreshTime)
  }
  getOrdersTable(customerId: number, parameters: TableParameters): Observable<TableResponse<Order>> {

    //if has orders and page is smaller than already gotton page and quantity of orders is larger than size of page then just return out of memory
    if (this._orders && this._orders.length > 0 && parameters.page < this.shownPage && this._orders.length >= parameters.page + 1 * parameters.size) {
      let tblresponse = new TableResponse(this._orders, false, false, false, parameters.page, this._orders.length, null, this.pagesize, null, this.totalOrders, this.totalPages, null)

      return of(tblresponse);
    }
    const urlparams = parameters.getUrlParameters();
    return this._http.get(environment.apiserver + 'orderbff/v1/order/customerid/' + customerId + urlparams.toString()).pipe(
      map((response: TableResponse<Order>) => {

        this.shownPage++;

        this.totalPages = Math.ceil(response.totalElements / this.pagesize);//makes sure it doesnt fail if there is an other pagesize// response.totalPages;
        this.totalOrders = response.totalElements;
        this._orders = [...new Map([...this._orders, ...response.content].map(order => [order['orderid'], order])).values()];

        //this.OrderlistSubject.next(response.content);

        return response
      })
      , catchError(this.handleError)
    );
  }

  canRateOrderFilter(o: Order): boolean {
    return o.status !== Orderstatus.CANCELED
      && o.status !== Orderstatus.NEW
      && o.status !== Orderstatus.PAYMENTERROR
      && o.status !== Orderstatus.DISPUTED
      && new Date().getTime() >= new Date(o.deliverydate).getTime()
  }
  hasOrderedEntity(entitytype: Entitytype, entityid: number, sku: string = null, activefrom: string = null, activeto: string = null): boolean {
    if (!entitytype || (!entityid && !sku)) return

    //adds deliverydate to orderlines to be used in the boxcheck
    const orders = this._orders.filter(o => this.canRateOrderFilter(o));
    if (orders.length === 0) return;

    const orderlines = [].concat.apply([], orders.map(o => { return o.orderlines.map(l => { l['deliverydate'] = o.deliverydate; return l; }) })) as Orderline[];

    return orderlines.findIndex(o => {
      if (entitytype === Entitytype.RECIPE) {
        if (o.recipeid == entityid) return true;

        //checks if recipe is in a box which is ordered
        const boxes = this._boxService.isInBox(entitytype, entityid);
        let val = false
        boxes.forEach(b => {
          //loops through the periods and checks if the box is sold combined with the period
          b.boxPeriods.forEach(p => {
            if (this.hasOrderedEntity(Entitytype.BOX, b.id, null, p.activefrom, p.activeto)) {
              val = true;
              return;
            }
          })
        })
        return val;
      }
      //checks the box in the period if a period is given
      if (entitytype === Entitytype.BOX && activefrom && activeto) {
        if (o.boxid == entityid) {
          //converts the dates to be compared
          let deliverydate = new Date(o['deliverydate']);
          let fromdate = new Date(activefrom);
          let todate = new Date(activeto);
          if (deliverydate.getTime() >= fromdate.getTime() && deliverydate.getTime() <= todate.getTime())
            return true;
        }
        return false;
      }
      if (entitytype === Entitytype.BOX) { return o.boxid == entityid }
      //when product reviews are added, find a solution for products in a ordered box
      if (entitytype === Entitytype.PRODUCT && sku) { return o.sku == sku }
    }) >= 0;

  }

  getOrder(id: number): Observable<Order> {
    return this._http.get(environment.apiserver + 'orderbff/v1/order/' + id).pipe(
      map((response: Response) => <any>response)
      , catchError(this.handleError)
    );
  }
  getLastOrder(): Observable<Order> {
    return this._http.get(environment.apiserver + 'orderbff/v1/order/customer/last').pipe(
      map((response: Response) => <any>response)
      , catchError(this.handleError)
    );
  }
  getOrderstatusses(): string[] {
    return Object.keys(Orderstatus);
  }
  getPaymentmethods(): string[] {
    return Object.keys(Paymentmethod);
  }
  getCurrencies(): string[] {
    return Object.keys(Currency);
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');
  }


}
