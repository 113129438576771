import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';


function getSessionStorage(): Storage {
  return sessionStorage;
}


@Injectable({ providedIn: 'root' })
export class SessionstorageService {

  private _sessionStorage: Storage = null;
  private _isInBrowser = false;
  private sessionstorageSet = new Map<string, string>();
  constructor(
   
    @Inject(PLATFORM_ID) private platformId: any,

  ) {
    

    if (isPlatformBrowser(this.platformId)) {
      this._isInBrowser = true;
      this._sessionStorage = getSessionStorage()
    }
  }

  setItem(key: string, value: string) {
    if (this._isInBrowser)
      this._sessionStorage.setItem(key, value);
    this.sessionstorageSet.set(key, value);
  }
  setObject(key: string, value: object) {
    this.setItem(key, JSON.stringify(value))
  }

  getItem(key: string): string {
    if (this._isInBrowser)
      return this._sessionStorage.getItem(key)
    if (this.sessionstorageSet.has(key))
      return this.sessionstorageSet.get(key)
    return null;
  }
  getObject<T = any>(key: string): T {
    const value = this.getItem(key)
    if(value)
      return JSON.parse(value) as T
    return null
  }

  removeItem(key: string) {
    if (this._isInBrowser)
      this._sessionStorage.removeItem(key)
    this.sessionstorageSet.delete(key);
  }

  clear() {
    if (this._isInBrowser)
      this._sessionStorage.clear()
    this.sessionstorageSet.clear();
  }

}
