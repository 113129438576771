import {Frequency, Weekday} from './weeks';
import {Shippingmethod} from './order';
import { CalculatedPrice } from './pricing';
import { Storetype } from './postalcodedelivery';


export enum Originentitytype {
  RECIPE_SUBSCRIPTION = 'RECIPE_SUBSCRIPTION',
  BOX_SUBSCRIPTION = 'BOX_SUBSCRIPTION'
}

export class BoxSubscription {
  constructor(
    public id: number,
    public enabled: boolean,
    public customerid: number,
    public deliveryday: Weekday,
    public pickupdeliverytimeframe: number,
    public storeid: number,
    public storetype: Storetype,
    public storedata: string,
    public persons: number,
    public shippingmethod: Shippingmethod,
    public frequency: Frequency,
    public box: number,
    public deliveryinstructions: string,
    public recipecard: boolean,
    public alternateshippingaddress: number,
    public alternatebillingaddress: number,
    public startdate: any,
    public calculatednextdeliverydate: any,
    public terminationdate: any,
    public terminationreason: string,
    public terminationcategory: string,
    public activecouponcode: string,
    public boxSubscriptionRepeatableproducts: SubscriptionRepeatableproduct[],
    public boxSubscriptionDeliveries: BoxSubscriptionDelivery[]
  ) {

  }
}

export class SubscriptionRepeatableproduct {
  constructor(
    public id: number,
    public sku: string,
    public boxid: number,
    public persons: number,
    public quantity: number
  ) {
  }
}

export class BoxSubscriptionDelivery {
  constructor(
    public id: number,
    public deliverydate: any,
    public alternatedeliverydate: any,
    public alternateshippingaddress: number,
    public alternatebillingaddress: number,
    public couponcode: string,
    public pickupdeliverytimeframe: number,
    public storeid: string,
    public storetype: Storetype,
    public storedata: string,
    public shippingmethod: Shippingmethod,
    public orderid: number,
    public reminderemailsent: boolean,
    public ispauzed: boolean,
    public boxSubscriptionProductSelections: SubscriptionProductSelection[],

    public calculatedPrice: CalculatedPrice
  ) {
  }
}

export class SubscriptionProductSelection {
  constructor(
    public id: number,
    public boxid: number,
    public persons: number,
    public sku: string,
    public quantity: number,
    public couponcodeitem: boolean,
    public recipecard: boolean,

    public calculatedPrice: CalculatedPrice
  ) {

  }
}

export class RecipeSubscription {
  constructor(
    public id: number,
    public enabled: boolean,
    public customerid: number,
    public deliveryday: Weekday,
    public pickupdeliverytimeframe: number,
    public store: number,
    public storedata: string,
    public shippingmethod: Shippingmethod,
    public frequency: Frequency,
    public defaultpersons: number,
    public defaultrecipes: number,
    public deliveryinstructions: string,
    public recipecard: boolean,
    public alternateshippingaddress: number,
    public alternatebillingaddress: number,
    public startdate: any,
    public activecouponcode: string,

    public calculatednextdeliverydate: any,
    public terminationdate: any,
    public terminationreason: string,
    public terminationcategory: string,

    public recipeSubscriptionRepeatableproducts: SubscriptionRepeatableproduct[],
    public recipeSubscriptionDeliveries: RecipeSubscriptionDelivery[],
  ) {
  }

}

export class RecipeSubscriptionDelivery {
  constructor(
    public id: number,
    public deliverydate: any,
    public alternatedeliverydate: any,
    public alternateshippingaddress: number,
    public alternatebillingaddress: number,
    public couponcode: string,
    public pickupdeliverytimeframe: number,
    public storeid: string,
    public storetype: Storetype,
    public storedata: string,
    public shippingmethod: Shippingmethod,
    public orderid: number,
    public reminderemailsent: boolean,
    public ispauzed: boolean,
    public recipeSubscriptionRecipeSelections: RecipeSubscriptionRecipeSelection[],
    public recipeSubscriptionProductSelections: SubscriptionProductSelection[],

    public calculatedPrice: CalculatedPrice
  ) {
  }
}

export class RecipeSubscriptionRecipeSelection {
  constructor(
    public id: number,
    public recipeid: number,
    public persons: number,
    public quantity: number,
    public couponcodeItem: boolean,
    public calculatedPrice: CalculatedPrice
  ) {
  }
}





