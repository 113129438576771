<ng-template #modalcontent>
    <ng-container *ngIf="!!popup.sections">

        <div [appStoryblok]="get_content(popup._editable)" class="">
            
            <div *ngIf="popupTitle || popup.closeconfig == 'ALL' || popup.closeconfig == 'CLOSEBUTTON'" class="modal-header flex flex-row justify-between">
                <div><div *ngIf="!!popupTitle" [innerHTML]="popupTitle"></div></div>
                <button *ngIf="popup.closeconfig == 'ALL' || popup.closeconfig == 'CLOSEBUTTON'" class="closebutton" (click)="popupModal.close()"><i class="bi bi-x-lg"></i></button>
            </div>
            <div class="modal-body">
                <ng-container *ngFor="let section of popup.sections; let i = index;">
                    <section [appStoryblok]="get_content(section._editable)" [ngClass]="{'mt-[-10px]' : i === 0}" [id]="!section.anchor ? section._uid : section.anchor">
                        <app-cms-section [section]="section" [index]="i"></app-cms-section>
                    </section>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>