
<div class="loading" *ngIf="!_lifestyle">{{'common.loading' | translate}}</div>

<div class="bg-e-white md:px-[100px]">
  <div class="container-default pt-[50px]">
    <div class="flex flex-col-reverse md:flex-row">
      <div class="w-full md:w-1/3 md:min-h-[500px] p-[25px] md:p-0">
        <h1 class="mb-5 md:my-5 text-left" itemprop="name">{{_lifestyle?.title}}</h1>
        <ngx-dynamic-hooks [content]="_lifestyle?.shortdescription"></ngx-dynamic-hooks>

        <div class="flex flex-row items-center my-[20px]">
          <small class="font-bold w-1/3">{{'common.person' | translate}}</small>
          <div class="flex bg-white rounded-full w-2/3 h-[35px]">
            <button class="w-1/4 h-[35px] disabled:opacity-25" [disabled]="persons.value === _globalService.minSubscriptionPersons" (click)="changePersonQty(-1)">
              <i class="bi bi-dash text-xl leading-[35px]"></i>
            </button>
            <small class="w-2/4 inline-block text-center leading-[35px]">{{persons.value}}</small>
            <button class="w-1/4 disabled:opacity-25" [disabled]="persons.value === _globalService.maxSubscriptionPersons" (click)="changePersonQty(1)">
              <i class="bi bi-plus text-xl leading-[35px]"></i>
            </button>
          </div>
        </div>
        <div class="flex flex-row items-center mb-[20px]">
          <small class="font-bold w-1/3">{{'common.meal' | translate}}</small>
          <div class="flex bg-white rounded-full w-2/3 h-[35px]">
            <button class="w-1/4 disabled:opacity-25" [disabled]="meals.value === _globalService.minSubscriptionMeals" (click)="changeMealQty(-1)">
              <i class="bi bi-dash text-xl leading-[35px]"></i>
            </button>
            <small class="w-2/4 inline-block text-center leading-[35px]">{{meals.value}}</small>
            <button class="w-1/4 disabled:opacity-25" [disabled]="meals.value === _globalService.maxSubscriptionMeals" (click)="changeMealQty(1)">
              <i class="bi bi-plus text-xl leading-[35px]"></i>
            </button>
          </div>
        </div>
        <div class="flex flex-row justify-center p-[15px] rounded-md min-w-[300px]" *ngIf="!authService.isAuthenticated()">
          <button (click)="chooseLifestyle()" class="button-base rounded-full w-full md:w-[200px]" id="chooselifestyle">
            <span>{{'lifestyle.choose_this_lifestyle' | translate}}</span>
          </button>
        </div>
      </div>
      <div class="pt-[25px]relative md:ml-[50px] w-full md:w-2/3 md:min-h-[500px]">
        <div class="flex rounded-3xl overflow-hidden min-h-[300px] h-full">
          <app-image *ngIf="!!imageRecipes" size="lg" [imageobject]="imageRecipes[0]" class="max-h-[600px] object-cover w-full rounded-3xl"></app-image>
        </div>
      </div>
    </div>

    <div class="max-w-[1020px] mx-auto mt-[25px] md:mt-[75px] md:p-0">

      <ngx-dynamic-hooks [content]="_lifestyle?.description"></ngx-dynamic-hooks>

      <div class="mt-[50px]" *ngIf="_lifestyle?.lifestylefaqs">
        <ng-container *ngFor="let faq of _lifestyle?.lifestylefaqs">
          <faq [faqid]="faq.faqid"></faq>
        </ng-container>
      </div>

      <div class="mt-[50px] pb-5">
        <div class="flex flex-row mb-[25px] items-center">
          <p class="mr-[25px] my-0">{{ _lifestyle?.title }}</p>
          <button class="button-base rounded-full mr-[15px]" (click)="activeWeek = 'current'" [ngClass]="{'bg-white text-e-dark-green' : activeWeek !== 'current'}">
            {{'lifestyle.this_week' | translate}}
          </button>
          <button class="button-base rounded-full" (click)="activeWeek = 'nextweek'" [ngClass]="{'bg-white text-e-dark-green' : activeWeek === 'current'}">
            {{'lifestyle.next_week' | translate}}
          </button>
        </div>
        <div>
          <recipe-list [lifestyles]="[_lifestyle?.id]" [deliverydate]="activeWeek === 'current' ? dateToday : dateNextWeek" (shownRecipesChange)="setImageRecipes($event)">
            <ng-template #recipe let-recipe>
              <recipecard [recipe]="recipe" [status]="'PROCESSED'" [persons]="persons.value" (showRecipe)="toggleRecipeDrawer($event)">
              </recipecard>
            </ng-template>
          </recipe-list>
        </div>
      </div>


    </div>
  </div>

  <lifestylechosendialog #lifestylechosendialog></lifestylechosendialog>

  <!-- template for the bottom drawer dialog -->
  <ng-template #bottomdrawerTemplateRef>
    <div class="flex flex-row justify-center p-[15px] rounded-md min-w-[300px]">
      <button (click)="chooseLifestyle()" class="button rounded-full w-full md:w-[200px]" id="chooselifestyle">
        <span>{{'common.continue' | translate}}</span>
      </button>
    </div>
  </ng-template>

  <ng-template #recipedetailsdrawer>
    <button type="button" class="flex items-center justify-center absolute h-[36px] w-[36px] rounded-full border-solid border-[2px] border-e-dark-green top-[25px] right-[35px]" aria-label="Close" (click)="toggleRecipeDrawer()">
      <img src="./assets/images/icons/close.svg" height="22" width="22" />
    </button>
    <div class="bg-white md:px-[25px] py-[75px] md:pt-[75px]">
      <app-recipe [recipe]="shownRecipe" [popup]="true"></app-recipe>
    </div>
  </ng-template>




</div>
