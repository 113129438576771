export class User {
  constructor(
    public id: number,
    public username: string,
    public password: string,
    public enabled: boolean,
    // public role: role,
    public brandid: number,
    public customerid: number,
    public lastmodified: any,
    public userroles: any[],

    public activationkey: string,
  ) {
  }
}

export enum Role {

  ADMIN = 'ADMIN',
  ANONYMOUS = 'ANONYMOUS',
  BRAND_ADMIN = 'BRAND_ADMIN',
  CUSTOMER = 'CUSTOMER',
  CUSTOMERSERVICE = 'CUSTOMERSERVICE',
  MARKETING = 'MARKETING',
  OPERATIONS = 'OPERATIONS',
  RECIPETEAM = 'RECIPETEAM',
  TELEMARKETING = 'TELEMARKETING'
}


