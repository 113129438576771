<div class="row" *ngIf="!_addAddress" [@collapse]>
  <button class="button-base rounded-full" (click)="toggleAddAddress()">{{'address.add_address'| translate}}</button>
</div>
<div class="block " *ngIf="_addAddress" [@collapse] [formGroup]="customerAddressForm" id="addressform">
  <div class="flex flex-col px-2 rounded-lg max-w-[500px]">
    <div class="flex flex-row justify-between">
      <strong class="">{{'address.add_address'| translate}}</strong>
      <button class="" (click)="toggleAddAddress()" >{{'common.cancel_button'| translate}}</button>
    </div>

    <div class="form-field m-0" *ngIf="showdescription">
      <label>{{'address.name' | translate}}</label>
      <input formControlName="name" id="addressname" [ngClass]="scheme[colorscheme]" name="addressname" placeholder="{{'address.name' | translate}}" required type="text">
      <span class="error" [@collapse] *ngIf="hasError('name', 'required')">{{'validation.required' | translate}}</span>
    </div>
    <ng-container formGroupName="address">

      <div class="form-field ">
        <label>{{'mpm.i_live_in' | translate}}</label>
        <select formControlName="countrycode" [ngClass]="scheme[colorscheme]" id="countrycode" type="text">
          <option *ngFor="let code of customercountries" [value]="code">
            {{'countrycode.' + code |translate}}
          </option>
        </select>
      </div>

      <div class="flex flex-wrap justify-between gx-1 max-w-[500px]">
        <div class="form-field w-2/5">
          <label>{{'address.postalcode' | translate}}</label>
          <input formControlName="postalcode" [ngClass]="scheme[colorscheme]" id="postalcode" name="postalcode" placeholder="{{'address.postalcode' | translate}}" required type="text">
          <span class="error" [@collapse] *ngIf="hasError('address.postalcode', 'required')">{{'validation.required' | translate}}</span>
          <span class="error" [@collapse] *ngIf="hasError('address.postalcode', 'pattern')">{{'validation.invalid_postalcode' | translate}}</span>
          <span class="error" [@collapse] *ngIf="hasError('address.postalcode', 'maxlength')">{{'validation.maxlength_value' | translate: {value:20} }}</span>
        </div>
        <div class="form-field w-1/4 ">
          <label class="whitespace-nowrap">{{'address.number' | translate}}</label>
          <input formControlName="number" [ngClass]="scheme[colorscheme]" id="number" name="number" placeholder="{{'address.number' | translate}}" required type="number">
          <span class="error whitespace-nowrap" [@collapse] *ngIf="hasError('address.number', 'required')">{{'validation.required' | translate}}</span>
        </div>
        <div class="form-field w-1/4 ">
          <label>{{'address.addition_abbr' | translate}}</label>
          <input formControlName="addition" [ngClass]="scheme[colorscheme]" id="addition" name="addition" placeholder="{{'address.addition_abbr' | translate}}" type="text">
          <span class="error" [@collapse] *ngIf="hasError('address.addition', 'maxlength')">{{'validation.maxlength_value' | translate: {value:20} }}</span>
        </div>
      </div>

      <small [@collapse] *ngIf="customerAddressForm.get('address').valid && !showFullAddress && customerAddressForm.get('address').get('countrycode').value === 'NL'" class="col-12 mx-2 mb-2 d-block addresslabel">
        {{customerAddressForm.get('address').get('street').value}} {{customerAddressForm.get('address').get('number').value}}<span *ngIf="customerAddressForm.get('address').get('addition').value">-{{customerAddressForm.get('address').get('addition').value}}</span>, {{customerAddressForm.get('address').get('postalcode').value}} {{customerAddressForm.get('address').get('city').value}}
      </small>
      <div [@collapse] *ngIf="showFullAddress || customerAddressForm.get('address').get('countrycode').value !== 'NL'" class="row g-2">
        <div class="row">
          <div class="form-field ">
            <label>{{'address.street' | translate}}</label>
            <input formControlName="street" [ngClass]="scheme[colorscheme]" id="street" name="street" placeholder="{{'address.street' | translate}}" required type="text">
            <span class="error" [@collapse] *ngIf="hasError('address.street', 'maxlength')">{{'validation.maxlength_value' | translate: {value:100} }}</span>
            <span class="error" [@collapse] *ngIf="hasError('address.street', 'required')">{{'validation.required' | translate}}</span>
          </div>
        </div>
        <div class="form-field">
          <label>{{'address.city' | translate}}</label>
          <input formControlName="city" [ngClass]="scheme[colorscheme]" id="city" name="city" placeholder="{{'address.city' | translate}}" required type="text">
          <span class="error" [@collapse] *ngIf="hasError('address.city', 'maxlength')">{{'validation.maxlength_value' | translate: {value:100} }}</span>
          <span class="error" [@collapse] *ngIf="hasError('address.city', 'required')">{{'validation.required' | translate}}</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
