@if(!_env.production){
  <app-cms-popup *ngIf="!popup && modalContent.sections" [popup]="modalContent"></app-cms-popup>
}
<div class="bg-white">
  <div class="bg-e-white md:py-[50px] rounded-xl">

    <div class="container-default grid grid-cols-1 md:grid-cols-[33%,_1fr] md:gap-[25px] px-[25px]">

      <div class="flex flex-col items-start justify-center">
        <h1 class="hyphens-auto mt-[25px]" itemprop="name">{{_recipe?.displayname}} <span class="block text-sm md:text-lg md:font-medium font-sans">{{_recipe?.subtitle}}</span></h1>
        <rating entitytype="RECIPE" *ngIf="!isMobile" [entityid]="_recipe?.id"></rating>
        <div class="flex flex-row flex-wrap my-[15px]">
          <app-chip>
            <time class="text-sm">{{_recipe?.cookingtime}} {{'common.minutes_abbr' | translate}}</time>
          </app-chip>
          <app-chip *ngIf="getCalories(_recipe)">
            <span class="text-sm">{{getCalories(_recipe)}} {{'unit_abbr.KILOCALORIES' | translate}}</span>
          </app-chip>
          <app-chip>
            <span class="text-sm">{{_recipe?.vegetableweight }} {{'unit_abbr.GRAM' |translate}} {{'recipe.vegetableweight'| translate}}</span>
            <!--<span class="text-sm">{{'recipe.difficulty-' + _recipe?.difficulty |translate}}</span>-->
          </app-chip>
        </div>

        <p class="font-serif font-semibold" *ngIf="isMobile">{{_recipe?.intro}}</p>
      </div>

      <app-image alt="{{_recipe?.displayname}}" class="order-first md:order-last h-[300px] sm:h-auto rounded-3xl object-cover w-full" [size]="isMobile ? 'md' : 'lg'" [imageobject]="_recipe"></app-image>
    </div>
    <div class="container-default mt-[25px] px-[25px] flex flex-row" *ngIf="!isMobile">
      <a *ngIf="getRecipecardUrl()" href="{{getRecipecardUrl()}}" target="_blank" class="my-auto border border-e-dark-green py-1 px-2 rounded-lg hover:bg-e-dark-green hover:text-white"><i class="bi bi-printer"></i> {{'common.print' |translate}}</a>
      <a (click)="scrollToMain()" class="w-[39px] h-[39px] icon-round mx-auto cursor-pointer " *ngIf="!popup">
        <img alt="arrow down" class="h-[20px] w-[20px]" src="./assets/images/icons/arrow_down.svg" />
      </a>
    </div>
  </div>

  <!-- INGREDIENTS -->
  <div class="bg-e-white rounded-xl m-[25px] p-[25px]" *ngIf="isMobile">
    <h2>{{'recipe.ingredients' | translate}}</h2>
    <div class="flex flex-row flex-wrap my-[25px]">
      <div *ngFor="let i of _env.subscriptionPersonChoices" [ngClass]="personquantity === i ? 'bg-e-dark-green text-white' : 'bg-white text-e-dark-green'" id="persons-{{i}}" class="chip mr-[10px] cursor-pointer hover:bg-e-dark-green hover:text-white transition-colors" (click)="personquantity = i">
        <span>{{_recipe?.doublepersons ? i * 2: i}} {{'common.person_abbr' | translate}}</span>
      </div>
    </div>

    <ul class="flex flex-col list-disc ml-[25px] mb-[25px]">
      <ng-container *ngFor="let ingredient of _recipe?.ingredients;let i= index">
        <li *ngIf="ingredient.provided" class="py-1">
          <strong><span id="ingredient-{{i}}" *ngIf="getIngredientQuantity(ingredient) !== 0">{{getIngredientQuantity(ingredient)}} </span>{{'unit_abbr.' + ingredient.unit | translate | lowercase}} {{ingredient.displayname}}</strong>
          <small class="block" *ngIf="ingredient.producer"><i>{{'common.from_posessive'| translate}} <span class="text-capitalize">{{ingredient.producer}}</span></i></small>
        </li>
      </ng-container>
    </ul>

    <strong>{{'recipe.add_yourself' | translate}}</strong>
    <ul class="list-disc ml-[25px]">
      <ng-container *ngFor="let ingredient of _recipe?.ingredients">
        <li *ngIf="!ingredient.provided" class="py-1"><ng-container *ngIf="getIngredientQuantity(ingredient) !== 0">{{getIngredientQuantity(ingredient)}} </ng-container>{{'unit_abbr.' + ingredient.unit | translate | lowercase}} {{ingredient.displayname}}</li>
      </ng-container>
    </ul>
  </div>

  <div class="container-default flex flex-col md:flex-row" id="main" [ngClass]="popup ? ' md:mt-[75px]' : 'md:mt-[100px]'">
    <div class="w-full md:w-3/5 md:ml-[25px]">
      <!-- PREPARATION -->
      <div class="flex flex-col bg-white">
        <p class="font-serif font-semibold mb-[30px]" *ngIf="!isMobile">{{_recipe?.intro}}</p>

        <h2>{{'recipe.preparations'| translate}} ({{_recipe?.cookingtime}} {{'common.minutes_abbr' | translate}})</h2>
        <div><small>{{'recipe.click a line to cross off' | translate}}</small></div>
        <ol class="counter">
          <li class="counter-item" *ngFor="let preparation of _recipe?.preparations" (click)="toggleDonePreparation(preparation.sequenceid)">
            <span [ngClass]="{'line-through': donePreparations.indexOf(preparation.sequenceid) >=0 }">{{preparation.description}}</span>
          </li>
        </ol>
      </div>

      <!-- TIP -->
      <div class="md:mx-auto flex items-center bg-e-white rounded-lg p-[20px] my-[25px] md:m-[25px]">
        <div class="flex shrink-0 justify-center items-center bg-e-orange rounded-full text-white font-serif text-base h-[50px] w-[50px] mr-[25px]">
          TIP
        </div>
        <span>{{_recipe?.garnertip}}</span>
      </div>

      <rating *ngIf="isMobile" entitytype="RECIPE" [entityid]="_recipe?.id"></rating>
    </div>

    <div class="w-full md:w-2/5 md:ml-[100px]">
      <div class="bg-e-white rounded-xl m-[25px] p-[25px]" *ngIf="!isMobile">
        <h2 class="text-[28px]">{{'recipe.ingredients' | translate}}</h2>
        <div class="flex flex-row flex-wrap my-[25px]">
          <div *ngFor="let i of _env.subscriptionPersonChoices" [ngClass]="personquantity === i ? 'bg-e-dark-green text-white' : 'bg-white text-e-dark-green'" id="persons-{{i}}" class="chip mr-[10px] cursor-pointer hover:bg-e-dark-green hover:text-white transition-colors" (click)="personquantity = i">
            <span>{{_recipe?.doublepersons ? i * 2: i}} {{'common.person_abbr' | translate}}</span>
          </div>
        </div>

        <ul class="flex flex-col list-disc ml-[25px] mb-[25px]">
          <ng-container *ngFor="let ingredient of _recipe?.ingredients;let i= index">
            <li *ngIf="ingredient.provided" class="py-1">
              <strong><span id="ingredient-{{i}}" *ngIf="getIngredientQuantity(ingredient) !== 0">{{getIngredientQuantity(ingredient)}} </span>{{'unit_abbr.' + ingredient.unit | translate | lowercase}} {{ingredient.displayname}}</strong>
              <small class="block" *ngIf="ingredient.producer"><i>{{'common.from_posessive'| translate}} <span class="text-capitalize">{{ingredient.producer}}</span></i></small>
            </li>
          </ng-container>
        </ul>

        <strong>{{'recipe.add_yourself' | translate}}</strong>
        <ul class="list-disc ml-[25px]">
          <ng-container *ngFor="let ingredient of _recipe?.ingredients">
            <li *ngIf="!ingredient.provided" class="py-1"><ng-container *ngIf="getIngredientQuantity(ingredient) !== 0">{{getIngredientQuantity(ingredient)}} </ng-container>{{'unit_abbr.' + ingredient.unit | translate | lowercase}} {{ingredient.displayname}}</li>
          </ng-container>
        </ul>
      </div>
      <!-- VITASCORE -->
      <div class="container-default flex flex-col bg-e-light-green my-[25px] md:m-[25px] px-[20px] pt-[25px] rounded-lg">
        <h3 class="text-[28px]">{{'score.nice_impact' |translate}}</h3>
        <app-vitascores [recipe]="_recipe"></app-vitascores>
      </div>

      <!-- LIFESTYLES -->
      <div class="flex bg-e-white rounded-lg flex-wrap p-[12px] my-[25px] md:m-[25px]" *ngIf="_recipe?.lifestyles && _recipe?.lifestyles.length > 0">
        <span class="text-sm font-medium leading-[20px] whitespace-wrap" *ngFor="let lifestyle of _recipe?.lifestyles; let l = index">{{getLifestyle(lifestyle.lifestyleid)?.name| capitalizefirst}}<ng-container *ngIf="l !== _recipe?.lifestyles.length-1">,&nbsp;</ng-container></span>
      </div>

      <!-- FOOD VALUES -->
      <div class="flex flex-col bg-e-white rounded-lg p-[20px] my-[25px] md:m-[25px]">
        <div class="flex flex-row flex-grow justify-between">
          <h3 class="text-[28px]">{{ 'nutrient.nutritional values' | translate }}</h3><small>{{'recipe.per portion'| translate}}</small>
        </div>

        <div *ngFor="let nutrient of _recipe?.nutrients" class="flex flex-row justify-between border-b-[1px] border-black mt-[5px] pb-[5px]">
          <span>{{'nutrient.' + nutrient.name | translate}}</span> <span>{{nutrient.quantity}} {{'unit_abbr.' + nutrient.unit |translate}}</span>
        </div>

        <div class="mt-[20px]">
          <span>{{ 'allergen.allergen information' | translate }}</span>
          <div class="flex flex-row flex-wrap mt-[5px]">
            <app-chip *ngFor="let allergen of _recipe?.allergens">
              <span class="text-sm font-medium">{{'allergen.' + allergen.name | translate}}</span>
            </app-chip>
          </div>
        </div>

        <div class="mt-[25px]">
          <img class="w-[120px]" *ngIf="getScore('NUTRISCORE')?.scorerating && getScore('NUTRISCORE')?.scorerating !== '0'" alt="nutriscore-{{getScore('NUTRISCORE')?.scorerating}}" loading="lazy" src="assets/images/nutriscore/nutri-score-{{getScore('NUTRISCORE')?.scorerating.toLowerCase()}}.svg" />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- template for the bottom drawer dialog -->
<ng-template #bottomdrawerTemplateRef>
  <div class="flex flex-row justify-center p-[15px] rounded-md min-w-[300px]">
    <a routerLink="/{{'singleorder.organicwebshop_url' | translate}}" class="button-base rounded-full w-full md:w-[250px]" id="chooselifestyle">
      <span>{{'singleorder.goto_webshop' | translate}}</span>
    </a>
  </div>
</ng-template>
