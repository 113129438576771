import { P } from '@angular/cdk/keycodes';
import { Component, OnInit, Input } from '@angular/core';
import { CmsService } from 'src/_service/CmsService';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'app-cms-card',
    templateUrl: './cms-card.component.html',
    standalone: true,
    imports: [NgIf, NgClass, NgFor]
})
export class CmsCardComponent implements OnInit {

  cardbody: any
  private _card: any;
  @Input()set card (value: any){
    this._card = value;
    this.cardbody = this._cmsService.render_rt(value.body)
  }
  get card(){return this._card}
  video_url: any;
  video_html: any;

  constructor(private _cmsService: CmsService) {
  }


  ngOnInit() {
    
    if( !!this.card.vimeo_video?.vimeo_oembed?.response?.html ){
      if(this.card.vimeo_video?.vimeo_oembed?.response?.html.startsWith('<iframe src="')) {
        let player_src = this.card.vimeo_video?.vimeo_oembed?.response?.html.split('"')[1];
        if( !!this.card.auto_play_video) {
          player_src += '&background=1';
        }
        this.video_url = this.get_sanitized_url(player_src);
      } else {
        this.video_html = this._cmsService.get_sanitized_html(this.card.vimeo_video?.vimeo_oembed?.response?.html);
      }
    } else {
      if (this.card.video?.cached_url && this.card.video?.cached_url.startsWith('https://')) {
        this.video_url = this.get_sanitized_url(this.card.video.cached_url);
      }
    }
  }

  // render_rt(content) {
  //   return this._cmsService.render_rt(content);
  // }

  get_alt(field) {
    return this._cmsService.get_alt(field);
  }

  get_sanitized_url(url) {
    return this._cmsService.get_sanitized_url(url);
  }
  
  get_src(image, contain = null) {
    return this._cmsService.get_src(image, contain);
  }

  get_classes(card) {
    return this._cmsService.get_classes(card);
  }

}



