import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../_environments/environment';
import { Paymentmethod } from '../../_model/order';
import { Countrycode } from '../../_model/shared';
import { PricingService } from '../../_service/PricingService';
import { TranslateModule } from '@ngx-translate/core';
import { ChipComponent } from '../chip/chip.component';
import { NgFor, NgIf, NgSwitch, NgSwitchCase, LowerCasePipe } from '@angular/common';

@Component({
    selector: 'paymentmethod',
    templateUrl: './paymentmethod.component.html',
    styleUrls: ['./paymentmethod.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        NgSwitch,
        NgSwitchCase,
        ChipComponent,
        LowerCasePipe,
        TranslateModule,
  ],
})
export class PaymentmethodComponent implements OnInit, OnDestroy {

  environment = environment;
  idealIssuers: string[];
  creditcardnetworks: string[];

  paymentForm: FormGroup = new FormGroup({
    paymentmethod: new FormControl(null),
    bic: new FormControl(null),
    creditcardnetwork: new FormControl(null)
  });

  paymentmethodOrder: Paymentmethod[] = [Paymentmethod.IDEAL, Paymentmethod.BANCONTACT, Paymentmethod.CREDITCARD, Paymentmethod.EMAIL, Paymentmethod.NONE];

  _countrycode: Countrycode
  @Input() set countrycode(value: Countrycode) {
    if (value) {
      this._countrycode = value;
      if (this.paymentForm)
        this.setPaymentmethod();
      this.sortPaymentMethods();
    }
  }
  _paymentmethod: Paymentmethod
  @Input() set paymentmethod(value: Paymentmethod) {
    if (this.paymentForm)
      this.paymentForm.get('paymentmethod').setValue(value);
    this._paymentmethod = value;
  }
  _bic: string
  @Input() set bic(value: string) {
    if (value && this.paymentForm)
      this.paymentForm.get('bic').setValue(value);
    this._bic = value;
  }
  
  @Input() set creditcardnetwork(value: string) {
    if (value && this.paymentForm)
      this.paymentForm.get('creditcardnetwork').setValue(value);
  }

  _availablePaymentmethods: Paymentmethod[] = [Paymentmethod.BANCONTACT, Paymentmethod.IDEAL, Paymentmethod.CREDITCARD, Paymentmethod.NONE];
  @Input() set availablepaymentmethods(value: Paymentmethod[] | string[]) {
    this._availablePaymentmethods = value.map(p => Paymentmethod[p])
  }

  @Output() paymentmethodChange = new EventEmitter<Paymentmethod>();
  @Output() bicChange = new EventEmitter<string>();
  @Output() creditcardnetworkChange = new EventEmitter<string>();

  private unsubscribe = new Subject<void>();

  constructor(
    private _pricingService: PricingService,
    public fb: FormBuilder
  ) {

  }

  ngOnInit(): void {

    this.idealIssuers = this._pricingService.getIdealIssuers();
    this.creditcardnetworks = this._pricingService.getCreditcardNetworks();

    this.paymentForm.get('paymentmethod').setValue(this._paymentmethod);
    this.paymentForm.get('bic').setValue(this._bic);
    

    this.paymentForm.get('paymentmethod').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      if (result && result !== Paymentmethod.IDEAL) this.paymentForm.get('bic').reset();
      if (result && result !== Paymentmethod.CREDITCARD) this.paymentForm.get('creditcardnetwork').reset();

      this.paymentmethodChange.emit(result);
     
    })
    this.paymentForm.get('bic').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.bicChange.emit(result);
    })
    this.paymentForm.get('creditcardnetwork').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.creditcardnetworkChange.emit(result);
    })

    this.setPaymentmethod();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
  }

  includesPaymentmethod(value: string): boolean {
    return this._availablePaymentmethods.includes(Paymentmethod[value]);
  }

  setPaymentmethod() {
    if (this.paymentForm.get('paymentmethod').value) return;

    if (this._countrycode === Countrycode.NL && this._availablePaymentmethods.includes(Paymentmethod.IDEAL)) {
      this.paymentForm.get('paymentmethod').setValue(Paymentmethod.IDEAL);
    } else if (this._countrycode === Countrycode.BE && this._availablePaymentmethods.includes(Paymentmethod.BANCONTACT)) {
      this.paymentForm.get('paymentmethod').setValue(Paymentmethod.BANCONTACT);
    }

    if (this._availablePaymentmethods.length === 1) {
      this.paymentForm.get('paymentmethod').setValue(this._availablePaymentmethods[0]);
    }

  }
  sortPaymentMethods() {
    //default is NL
    this.paymentmethodOrder = [Paymentmethod.IDEAL, Paymentmethod.BANCONTACT, Paymentmethod.EMAIL, Paymentmethod.CREDITCARD];

    if (this._countrycode === Countrycode.BE) {
      this.paymentmethodOrder = [Paymentmethod.BANCONTACT,  Paymentmethod.CREDITCARD, Paymentmethod.IDEAL, Paymentmethod.EMAIL];
    }

    let methods = this._pricingService.getPaymentmethods();

    methods.forEach(m => {
      if (!this.paymentmethodOrder.includes(Paymentmethod[m]))
        this.paymentmethodOrder.push(Paymentmethod[m])
    })
  }
  hasError = (controlName: string, errorName: string) => {
    return this.paymentForm.get(controlName).hasError(errorName);
  }



}
