import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ToastService } from '../../_service/Toast.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../_environments/environment';
import { Customer } from '../../_model/customer';
import { FavoritesList, Favoriteslisttype } from '../../_model/favorites';
import { Entitytype } from '../../_model/redirect';
import { NotifydialogData } from '../../_model/shared';
import { CustomerService } from '../../_service/CustomerService';
import { FavoritesService } from '../../_service/FavoritesService';
import { collapse } from '../../_theme/animations';
import { NotifydialogComponent } from '../notifydialog/notifydialog.component';
import { Snackbarformat } from '../../_theme/snackbarformats';

@Component({
    selector: 'favorites',
    templateUrl: './favorites.component.html',
    styleUrls: ['./favorites.component.scss'],
    animations: [collapse],
    standalone: true,
    imports: [ NgClass, NgFor, ReactiveFormsModule, NgIf, TranslateModule]
})
export class FavoritesComponent implements OnInit, OnDestroy {

  environment = environment;

  _originentityid: number
  @Input() set entityid(value: number) {
    if (value) {
      this._originentityid = value;
    }
  };
  _originentitytype: Entitytype
  @Input() set entitytype(value: Entitytype | string) {
    if (value) {
      this._originentitytype = Entitytype[ value];
    }
  };

  dialogRef: MatDialogRef<NotifydialogComponent, any>;
  isFavorite: boolean;

  favorites = new FormGroup({
    favouritesLists: new FormArray([])
  })
  
  addFavoritesListCtrl = new FormControl('', Validators.required);
  customer: Customer;

  @ViewChild('favouriteslistTemplateRef', { read: TemplateRef }) favouriteslistTemplateRef: TemplateRef<any>;

  private unsubscribe = new Subject<void>();

  constructor(
    private _customerService: CustomerService,
    private _favoritesService: FavoritesService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private _translate: TranslateService,
    private _toastService: ToastService,
  ) {

  }

  ngOnInit(): void {
    this._customerService.customerSubject.pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.customer = result;
    })
    this.isFavorite = this._favoritesService.isInFavorites(this._originentitytype, this._originentityid);
    this._favoritesService.favoritesListFormArray.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.isFavorite = this._favoritesService.isInFavorites(this._originentitytype, this._originentityid);
      if (this.dialogRef && this.dialogRef.getState() == MatDialogState.OPEN)
        this.setFavoritesLists();
    })
  }

  ngOnDestroy() {
    this.unsubscribe.next();
  }

  setFavorite() {

    if (!this.customer) {
    this._toastService.open
(this._translate.instant('favorites.login_to_add_favorites'), this._translate.instant('common.ok_dismiss'), Snackbarformat);
      return;
    }

    this.setFavoritesLists();

    this.dialogRef = this.dialog.open(NotifydialogComponent, {
      data: new NotifydialogData('assets/images/victory-2.svg', this.favouriteslistTemplateRef)
    });
  }

  get getFavoriteslist(): FormArray {
    return this.favorites.get('favouritesLists') as FormArray
  }

  setFavoritesLists() {
    const favoriteslist = this.getFavoriteslist;
    favoriteslist.clear(); // = new FormArray([])
    this._favoritesService.favoritesListFormArray.value.forEach((ctrl: FavoritesList) => {
      if (ctrl.entitytypereference !== this._originentitytype) return;
      const group = new FormGroup({
        name: new FormControl(ctrl.name),
        listid: new FormControl(ctrl.id),
        checked: new FormControl(this._favoritesService.isInFaroritesList(ctrl, this._originentityid)),
      })
      group.valueChanges.subscribe(() => {
        this._favoritesService.toggleFavoritesListItem(group.get("listid").value, this._originentityid)
      })
      favoriteslist.push(group);

    })
  }

  closeDialog() {
    if (this.dialogRef)
      this.dialogRef.close();
  }
  isInFavoritelist(list: FavoritesList) {
    return this._favoritesService.isInFaroritesList(list, this._originentityid);
  }

  addFavoritesList() {
    const fav = new FavoritesList(null, this.customer.id, this.customer.brandid, this._originentitytype, Favoriteslisttype.GENERIC, this.addFavoritesListCtrl.value, [])
    this._favoritesService.addFavoritesList(fav);
    this.addFavoritesListCtrl.reset();
  }



}
