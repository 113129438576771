import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';  

@Pipe({
    name: 'capitalizefirst',
    standalone: true
})
export class CapitalizeFirstPipe implements PipeTransform {
  constructor() { }  
  transform(value: string):string {
    if (!value) return null;
    const first = value.substr(0, 1).toUpperCase();

    return first + value.substr(1).toLowerCase(); 
  }

}
