<div class="">
  <button title="favorite button" [ngClass]="{'cursor-pointer': customer}" class="favorites-btn"  (click)="setFavorite()" >
    <i class="bi text-xl leading-[35px]" [ngClass]="!isFavorite ? 'bi-heart': 'bi-heart-fill'"></i>
    <ng-template #favorite>
      <i class="bi bi-heart-fill text-xl leading-[35px]"></i>
    </ng-template>
  </button>
  
</div>

<ng-template #favouriteslistTemplateRef>
  <ng-container [formGroup]="favorites">
    <div class="flex flex-row justify-between">
      <h3 class="">{{'favorites.favorites_lists'|translate}}</h3>
      <button class="align-top text-xl mt-[-10px]" (click)="closeDialog()" title="{{'common.close' |translate}}"><i class="bi bi-x-circle"></i></button>
    </div>
    <ng-container formArrayName="favouritesLists">
      <ng-container *ngFor="let ctrl of getFavoriteslist.controls; let i=index ">
        <div [formGroupName]="i" class="cursor-pointer mb-2">
          <input id="favoriteslist-{{i}}" class="cursor-pointer" name="favoriteslist-{{i}}" type="checkbox" formControlName="checked" />
          <label for="favoriteslist-{{i}}" class="ms-2 mt-1">{{ctrl.value.name}}</label>
        </div>
      </ng-container>
    </ng-container>
    <div class="flex flex-row flex-nowrap">
      <div class="form-field ps-0 m-0">
        <label class="ms-0 px-0 sm:px-2">{{'favorites.add_favoriteslist' | translate}}</label>
        <input type="text" class="bg-e-white" name="addfavoritesname" required [formControl]="addFavoritesListCtrl" id="addFavoritesListCtrl">
        <span class="error" @collapse *ngIf="addFavoritesListCtrl.hasError('required') && addFavoritesListCtrl.dirty">{{'validation.required' | translate}}</span>
      </div>
      <div class="">
        <button class="button-base px-[15px] rounded-full mt-[30px] ms-1" [disabled]="!addFavoritesListCtrl.valid" (click)="addFavoritesList()" title="{{'favorites.add_favoriteslist' | translate}}"><i class="bi bi-plus-lg"></i></button>
      </div>
    </div>
  </ng-container>

</ng-template>
