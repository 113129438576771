<div class="flex rounded-lg mt-[20px]" [ngClass]="condensed ? 'flex-col bg-white' : 'flex-row'">
  <div [ngClass]="condensed ? 'w-full' : 'w-1/3 mr-[10px] max-w-[125px]'">
    <app-image [alt]="_product?.name" [src]="_product?.websitethumbimageurl" class="rounded-md w-full"
      [ngClass]="{'grayscale': calculatedPrice?.priceincvat === 0 && (_productOrderline && !_productOrderline['couponcodeitem'] && !_productOrderline['compensationitem'] )}">
    </app-image>
  </div>

  <div class="flex-col" [ngClass]="condensed ? 'flex px-[10px] py-[15px] basis-[130px] shrink-0': 'flex-1'">
    <div class="flex flex-col justify-between mr-[10px]">

      <small>
        <strong>
          <!-- makes sure that a translated version is shown if a box or product is set with the orderdescription as fallback -->
          <ng-container *ngIf="_product; else orderdescription">{{_product?.title || _product?.name}}</ng-container>
          <ng-template #orderdescription>{{_orderline?.description}}</ng-template>
        </strong>
      </small>
      <small>{{_product?.shopquantity}} {{'unit.' + _product?.shopunit | translate }}</small>
    </div>

    <div class="flex flex-row flex-wrap justify-between mt-[15px] items-end">
      <div class=" w-2/3  max-w-[175px]">
        <app-quantityselection small [editable]="qtyeditable" [quantity]="quantity.value" (quantityChange)="setQuantity($event)"
          [contrastColorscheme]="colorscheme"></app-quantityselection>
        <app-toggle *ngIf="repeatableeditable" [formControl]="repeatable">{{'subscription.product_weekly' | translate}}</app-toggle>
      </div>

      <div class="flex flex-wrap justify-end my-auto" *ngIf="showPrice">
        <pricetag *ngIf="_productOrderline" [ngClass]="{'offset-7 mt-2': !qtyeditable && !repeatableeditable}" [calculatedPrice]="_productOrderline['calculatedPrice']"
          [couponcodeItem]="(_productOrderline && _productOrderline['couponcodeitem'])" [compensationItem]="_productOrderline &&_productOrderline['compensationitem']"></pricetag>
        <pricetag *ngIf="_productSelection" [ngClass]="{'offset-7 mt-2': !qtyeditable && !repeatableeditable}" [calculatedPrice]="_productSelection['calculatedPrice']"
          [couponcodeItem]="(_productSelection && _productSelection['couponcodeitem'])" [compensationItem]="_productSelection &&_productSelection['compensationitem']"></pricetag>
        <pricetag *ngIf="_repeatableProduct" [ngClass]="{'offset-7 mt-2': !qtyeditable && !repeatableeditable}" [calculatedPrice]="_repeatableProduct['calculatedPrice']"
          [couponcodeItem]="(_repeatableProduct && _repeatableProduct['couponcodeitem'])" [compensationItem]="_repeatableProduct &&_repeatableProduct['compensationitem']">
        </pricetag>
      </div>
    </div>

    <!--<div *ngIf="!qtyeditable && quantity.value !== 0">
      <small>{{'common.piece' |translate : {count: quantity.value}  }}</small>
    </div>-->
  </div>
</div>