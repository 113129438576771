<div class="mt-[20px] flex flex-col rounded-lg" [ngClass]="condensed ? 'bg-white' : ''">
  <div class="flex" [ngClass]="condensed ? 'flex-col bg-white' : 'flex-row'">
    <div [ngClass]="condensed ? 'w-full' : 'w-1/3 mr-[10px] max-w-[125px]'">
      <app-image [alt]="_box?.name" [src]="_box?.websitethumbimageurl" class="w-full rounded-md"
        [ngClass]="{'grayscale': calculatedPrice?.priceincvat === 0   && (_productOrderline && !_productOrderline['couponcodeitem'] && !_productOrderline['compensationitem'] )}"></app-image>
    </div>
    <div class="flex-col" [ngClass]="condensed ? 'flex px-[10px] py-[15px] basis-[130px] shrink-0': 'flex-1'">
      <div class="mr-[10px] flex flex-col justify-between">
        <strong class="text-sm">
          <!-- makes sure that a translated version is shown if a box or product is set with the orderdescription as fallback -->
          <ng-container *ngIf="_box; else orderdescription">{{_box?.title || _box?.name}}</ng-container>
          <ng-template #orderdescription>{{_orderline?.description}}</ng-template>
        </strong>
        <div><small>{{_box?.subtitle}}</small></div>
      </div>
      <div class="mt-[15px] flex flex-row flex-wrap items-end justify-between">
        <div class="max-w-[175px] flex w-2/3 flex-col flex-wrap">
          <app-quantityselection *ngIf="qtyeditable" class="me-1 mb-1 my-auto" [quantity]="quantity.value" small (quantityChange)="setQuantity($event)"
            [contrastColorscheme]="colorscheme"></app-quantityselection>
          <app-personselection *ngIf="personseditable " class="me-1 mb-1 my-auto" [contrastColorscheme]="colorscheme" small [persons]="persons.value"
            (personsChange)="setPersonQuantity($event)" [personsoptions]="_box?._boxpersons" [doublepersons]="_box?.doublepersons">
            <span>{{'common.person_abbr'|translate}}</span>
          </app-personselection>
          <app-toggle class="w-7/12" *ngIf="repeatableeditable" [formControl]="repeatable" color="success">{{'subscription.product_weekly' | translate}}</app-toggle>
          <div *ngIf="!qtyeditable && quantity.value !== 0">
            <small>{{'common.piece' |translate : {count: quantity.value} }}</small>
          </div>
          <div *ngIf="!personseditable ">
            <small>{{_box?.doublepersons? persons.value * 2 : persons.value}} {{'common.person_abbr'|translate}}</small>
          </div>
        </div>

        <div class="my-auto flex flex-wrap justify-end" *ngIf="showPrice">
          <pricetag *ngIf="_productOrderline" class="col-5 px-0" [ngClass]="{'offset-7': !personseditable && !qtyeditable && !repeatableeditable}"
            [calculatedPrice]="_productOrderline['calculatedPrice']" [couponcodeItem]="(_productOrderline && _productOrderline['couponcodeitem'])"
            [compensationItem]="_productOrderline &&_productOrderline['compensationitem']"></pricetag>
          <pricetag *ngIf="_productSelection" class="col-5 px-0" [ngClass]="{'offset-7': !personseditable && !qtyeditable && !repeatableeditable}"
            [calculatedPrice]="_productSelection['calculatedPrice']" [couponcodeItem]="(_productSelection && _productSelection['couponcodeitem'])"
            [compensationItem]="_productSelection &&_productSelection['compensationitem']"></pricetag>
          <pricetag *ngIf="_repeatableProduct" class="col-5 px-0" [ngClass]="{'offset-7': !personseditable && !qtyeditable && !repeatableeditable}"
            [calculatedPrice]="_repeatableProduct['calculatedPrice']" [couponcodeItem]="(_repeatableProduct && _repeatableProduct['couponcodeitem'])"
            [compensationItem]="_repeatableProduct &&_repeatableProduct['compensationitem']"></pricetag>
        </div>
      </div>

    </div>

  </div>
  <small class="text-xs" [ngClass]="condensed ? 'px-[10px] pb-[15px]': ''" *ngIf="_box?.singleboxsellablefrom || _box?.singleboxsellableto">{{'box.deliverable_between'| translate:
    {from: _box?.boxPeriods[0]?.activefrom |localizedDate: 'd MMMM yyyy', to: _box?.boxPeriods[0]?.activeto|localizedDate: 'd MMMM yyyy' } }}</small>
  <!--<small class="text-xs" *ngIf="_box?.singleboxsellablefrom && _box.singleboxsellableto">{{'box.sellable_between'| translate: {from: _box?.singleboxsellablefrom |localizedDate: 'd MMMM yyyy', to: _box.singleboxsellableto|localizedDate: 'd MMMM yyyy' } }}</small>
  <small class="text-xs" *  ngIf="_box?.singleboxsellablefrom && !_box.singleboxsellableto">{{'box.sellable_from'| translate: {from: _box?.singleboxsellablefrom |localizedDate: 'd MMMM yyyy'} }}</small>
  <small class="text-xs" *ngIf="!_box?.singleboxsellablefrom && _box.singleboxsellableto">{{'box.sellable_to'| translate: {to: _box?.singleboxsellableto |localizedDate: 'd MMMM yyyy'} }}</small>-->
</div>