import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';  

@Pipe({
    name: 'exki',
    standalone: true
})
export class ExkiPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) { }  
  // /((?![^<]*>)CO2|(?![^<]*>)exki)/igm

  transform(value: string): SafeHtml {
    const res = value.replace(/(?![^<]*>)exki/igm, 'EXKi');
    return res;
  }

}
