
<div class="flex flex-col justify-around flex-wrap {{class}}" id="impactscores">
  <div class="flex flex-row justify-around flex-wrap">
    <div class="text-center m-1" *ngIf="getScore('_TRUECOSTS')">
      <div class="pie animate m-auto">
        <img class="w-[40px]" alt="vegetablecounter" src="assets/images/icons/icon_trueprice.svg" />
      </div>
      <small class="flex flex-col mt-[10px]">
        <strong class="d-block">{{'score.truecosts'| translate}}</strong>
        <span class="d-block text-lowercase"><span *ngIf="getScore('_TRUECOSTS')?.scoreimprovement > 0">-</span> {{getScore('_TRUECOSTS')?.scoreimprovement |currency: 'EUR':'symbol':'.2-2':'nl'}}</span>
      </small>
    </div>
    <div class="text-center m-1" *ngIf="getScore('_VETGETABLESCORE')">
      <div class="pie animate m-auto">
        <img class="w-[40px]" alt="vegetablecounter" src="assets/images/icons/icon_DG-groenteteller.svg" />
      </div>
      <small class="flex flex-col mt-[10px]">
        <strong class="d-block">{{'score.veggiescore'| translate}}</strong>
        <span class="d-block text-lowercase"><span *ngIf="getScore('_VETGETABLESCORE')?.scoreimprovement > 0">+</span> {{getScore('_VETGETABLESCORE')?.scoreimprovement | number: '1.0-1'}} {{'unit_abbr.' + 'GRAM' |translate}}</span>
      </small>
    </div>
    <div class="text-center m-1" *ngIf="getScore('VITASCORERISCPOINTS')">
      <div class="pie animate m-auto">
        <img class="w-[50px]" alt="vita-score" src="assets/images/icons/icon_DG-vitaliteit.svg" />
      </div>
      <small class="flex flex-col mt-[10px]">
        <strong class="d-block">{{'score.vitascore'| translate}}</strong>
        <span class="d-block text-lowercase"><span *ngIf="getScore('VITASCORERISCPOINTS')?.scoreimprovement < 0">+</span> {{getScore('VITASCORERISCPOINTS')?.scoreimprovement * -1 | number: '1.0-1'}} {{'unit.' + 'POINTS' |translate}}</span>
      </small>
    </div>
    <div class="text-center m-1" *ngIf="getScore('CARBONSCORE')">
      <div class="pie animate m-auto">
        <img class="w-[50px]" alt="co2-reduction-score" src="assets/images/icons/icon_DG-CO2eq.svg" />
      </div>
      <small class="flex flex-col mt-[10px]">
        <strong class="d-block text-nowrap">CO<sub>2</sub>{{'score.co2eq-score'| translate}}</strong>
        <span class="d-block text-lowercase"><span *ngIf="getScore('CARBONSCORE')?.scoreimprovement > 0">-</span> {{getScore('CARBONSCORE')?.scoreimprovement | number: '1.0-1'}} {{'unit_abbr.' + 'GRAM' |translate}}</span>
      </small>
    </div>
    <div class="text-center m-1" *ngIf="getScore('SCARCEWATERUSAGE')">
      <div class="pie animate m-auto">
        <img class="w-[30px]" alt="water-reduction-score" src="assets/images/icons/icon_DG-water.svg" />
      </div>
      <small class="flex flex-col mt-[10px]">
        <strong class="d-block">{{'score.waterscore'| translate}}</strong>
        <small class="d-block text-lowercase"><span *ngIf="getScore('SCARCEWATERUSAGE')?.scoreimprovement > 0">-</span> {{getScore('SCARCEWATERUSAGE')?.scoreimprovement | number: '1.0-1'}} {{'unit_abbr.' + 'LITER' |translate}}</small>
      </small>
    </div>
  </div>
  <div class="flex flex-row flex-wrap justify-between mt-2">
    <small><a class="hover:underline" routerLink="/{{'score.learn_more_url'| translate}}">{{'score.learn_more'| translate}} <i class="bi bi-arrow-right"></i></a></small>
    <small class="text-end text-xs">{{'score.note'| translate}}</small>
  </div>
</div>



