import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../_environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { TableParameters, TableResponse, UrlParameters } from '../_model/shared';
import { Box } from '../_model/box';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from './RedirectService';
import { Entitytype } from '../_model/redirect';
import { GlobalService } from './GlobalService';

@Injectable()
export class BoxService {

  boxlistobj = {};

  constructor(
    private _http: HttpClient,
    private _translate: TranslateService,
    private _redirectService: RedirectService,
    private _globalService: GlobalService
  ) {
    this._translate.onLangChange.subscribe(next => {
      this.boxes = [];
      this.boxlistobj = {};
    })

  }

  private boxes: Box[] = [];

  private _setBoxpersons(box: Box): Box {
    box._boxpersons = []
    if (box.boxcountryprices && box.boxcountryprices.length > 0) {
      let countryprice = box.boxcountryprices.filter(b => b.countrycode == this._globalService.getPricecountry())[0]
      if (!countryprice) return;
      if (countryprice.onepersonboxsellprice)
        box._boxpersons.push(1);
      if (countryprice.twopersonboxsellprice)
        box._boxpersons.push(2);
      if (countryprice.threepersonboxsellprice)
        box._boxpersons.push(3);
      if (countryprice.fourpersonboxsellprice)
        box._boxpersons.push(4);
    }
    return box;
  }
  private _isAvailableForSingleOrder(box: Box): Box {
    //checks if it is sellable between the dates for a singleorder
    if (box) {
      box._availableforsingleorder = true
      if (box.singleboxsellablefrom && Date.now() < new Date(box.singleboxsellablefrom).getTime())
          box._availableforsingleorder = false;
      if (box.singleboxsellableto && Date.now() > new Date(box.singleboxsellableto).getTime())
        box._availableforsingleorder = false;
    }
    return box;
  }


  getBoxList(boxids: number[], from: string = null, to: string = null): Observable<Box[]> {
    if (!boxids || boxids.length == 0 || boxids.filter(v => !isNaN(v) && v !== null).length == 0) return of([]);

    boxids = boxids.filter(v => !isNaN(v));

    const params = new UrlParameters();
    params.addParameter('ids', boxids.toString());
    params.addParameter('from', from);
    params.addParameter('to', to);

    if (this.boxlistobj[params.toString()])
      return of(this.boxlistobj[params.toString()])

    return this._http.get(environment.apiserver + 'boxbff/v1/box/list' + params.toString()).pipe(
      map((response: Box[]) => {
        response.forEach(b => {
          b = this._setBoxpersons(b);
          b = this._isAvailableForSingleOrder(b);
        })

        this.boxlistobj[params.toString()] = response;
        this.boxes = [...this.boxes, ...response]
        return response as Box[]
      })
      , catchError(this.handleError)
    );
  }

  isInBox(entitytype: Entitytype, entityid: number): Box[] {

    if (!entitytype || !entityid) return
  
    if (entitytype === Entitytype.RECIPE) {
      const boxes = this.boxes.filter(b => {
        return b.boxPeriods.filter(r => r.boxRecipes.map(br => br.recipeid).includes(entityid)).length >0;
      })
      return boxes;
    }
    if (entitytype === Entitytype.PRODUCT) {
      const boxes = this.boxes.filter(b => {
        return b.boxPeriods.filter(r => {
          return (
            r.boxProducts.map(br => br.onepersproductid).includes(entityid)
            || r.boxProducts.map(br => br.twopersproductid).includes(entityid)
            || r.boxProducts.map(br => br.threepersproductid).includes(entityid)
            || r.boxProducts.map(br => br.fourpersproductid).includes(entityid)
          )
        }).length > 0;
      })
      return boxes;
    }
  }

  getBox(boxid: number): Observable<Box> {

    const box = this.boxes.filter(b => b.id == boxid)[0];
    if (box) {
      return of(box);
    }

    const boxids = [boxid];
    const params = new UrlParameters();
    params.addParameter('ids', boxids.toString());

    return this._http.get(environment.apiserver + 'boxbff/v1/box/list' + params.toString()).pipe(
      map((response: Box[]) => {
        if (response.length > 0) {
          response[0] = this._setBoxpersons(response[0])
          response[0] = this._isAvailableForSingleOrder(response[0]);
          this.boxes.push(response[0]);

          this.boxes = [...this.boxes, ...[response[0]]]

          return response[0] as Box;
        }
        return null;

      })
      , catchError(this.handleError)
    );
  }
  getBoxPromise(boxid: number): Promise<Box> {
    return new Promise(resolve => {
      if (boxid == null || boxid === 0) {
        resolve(null);
        return;
      }
      this.getBox(boxid).subscribe(
        {
          next: (result) => {
            resolve(result);
          },
          error: (error) => console.log(error)
        });
    });
  }


  getSubscriptionBoxByPeriod(boxid: number, from: string, to: string): Observable<Box> {
    const params = new UrlParameters()
    params.addParameter('boxid', boxid.toString());
    params.addParameter('from', from);
    params.addParameter('to', to);

    return this._http.get(environment.apiserver + 'boxbff/v1/box/subscription/period' + params.toString()).pipe(
      map((response: Box) => {
        response = this._setBoxpersons(response)
        response = this._isAvailableForSingleOrder(response)
        //this.boxes = this.boxes.concat(response.content)
        return response as Box
      })
      , catchError(this.handleError)
    );
  }
  getWebshopBoxTableByPeriod(tblparams: TableParameters, from: string, to: string): Observable<TableResponse<Box>> {
    const params = tblparams.getUrlParameters();
    params.addParameter('from', from);
    params.addParameter('to', to);

    return this._http.get(environment.apiserver + 'boxbff/v1/box/find/webshop/period' + params.toString()).pipe(
      map((response: TableResponse<Box>) => {
        response.content.forEach(b => {
          b = this._setBoxpersons(b);
          b = this._isAvailableForSingleOrder(b);
        })
        this.boxes = this.boxes.concat(response.content)
        return response as TableResponse<Box>
      })
      , catchError(this.handleError)
    );
  }

  getSubscriptionBoxTableByString(tblparams: TableParameters, string: string, from: string = null, to: string = null): Observable<TableResponse<Box>> {
    const params = tblparams.getUrlParameters();
    params.addParameter('searchstring', string);
    params.addParameter('from', from);
    params.addParameter('to', to);

    return this._http.get(environment.apiserver + 'boxbff/v1/box/find/subscription/string' + params.toString()).pipe(
      map((response: TableResponse<Box>) => {
        response.content.forEach(b => {
          b = this._setBoxpersons(b);
          b = this._isAvailableForSingleOrder(b);
        })
        this.boxes = this.boxes.concat(response.content)
        return response as TableResponse<Box>
      })
      , catchError(this.handleError)
    );
  }
  getWebshopBoxTableByString(tblparams: TableParameters, string: string, from: string = null, to: string = null): Observable<TableResponse<Box>> {
    const params = tblparams.getUrlParameters();
    params.addParameter('searchstring', string);
    params.addParameter('from', from);
    params.addParameter('to', to);

    return this._http.get(environment.apiserver + 'boxbff/v1/box/find/webshop/string' + params.toString()).pipe(
      map((response: TableResponse<Box>) => {
        response.content.forEach(b => {
          b = this._setBoxpersons(b);
          b = this._isAvailableForSingleOrder(b);
        })
        this.boxes = this.boxes.concat(response.content)
        return response as TableResponse<Box>
      })
      , catchError(this.handleError)
    );
  }



  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');
  }

}
