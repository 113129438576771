import { Injectable, ɵDEFAULT_LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../_environments/environment';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, Observable,  of, throwError } from 'rxjs';
import { Countrycode, Sourcetype, UrlParameters } from '../_model/shared';
import { Shippingmethod } from '../_model/order';
import { AvailableDelivery } from '../_model/weeks';
import { Deliveryday, PostalCodeWeekDayTimeFrame } from '../_model/postalcodedelivery';
import { GlobalService } from './GlobalService';
import { formatDate } from '@angular/common';

@Injectable()
export class DeliverydayService {

  private timeframes: PostalCodeWeekDayTimeFrame[] = [];

  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService
  ) {
  }

  getOnetimeboxDelivery(boxid: number, shippingmethod: Shippingmethod, postalcodeNumbers: number, countrycode: Countrycode): Observable<AvailableDelivery[]> {
    const params = new UrlParameters();
    this._globalService.setPricecountry(Sourcetype.BROWSER, countrycode);
    if (boxid)
      params.addParameter('boxid', boxid.toString());
    if (shippingmethod)
      params.addParameter('shippingmethod', shippingmethod.toString());
    if (postalcodeNumbers)
      params.addParameter('postalcodeNumbers', postalcodeNumbers.toString());
    params.addParameter('countryCode', countrycode?.toString());

    if (params.urlParameters.length !== 4) {
      return EMPTY;
    }
    return this._http.get(environment.apiserver + 'deliveryday/v1/deliveryday/onetimebox' + params.toString()).pipe(
      map((response: AvailableDelivery[]) => {
        response.forEach(d => {
          if (!d.originaldate) {
            d.originaldate = d.date;
          }
        });

        return response;
      })
      , catchError(this.handleError)
    );
  }
  getFirstdelivery(shippingmethod: Shippingmethod, postalcodeNumbers: number|string, countrycode: Countrycode): Observable<AvailableDelivery[]> {
    const params = new UrlParameters();

    if (!shippingmethod || !postalcodeNumbers || postalcodeNumbers.toString().length < 4 || !countrycode)
      return EMPTY

    this._globalService.setPricecountry(Sourcetype.BROWSER, countrycode);
    if (shippingmethod)
    params.addParameter('shippingmethod', shippingmethod.toString());
    if (postalcodeNumbers)
    params.addParameter('postalcodeNumbers', postalcodeNumbers.toString());
    params.addParameter('countryCode', countrycode?.toString());

    if (params.urlParameters.length !== 3) {
      return EMPTY;
    }
    return this._http.get(environment.apiserver + 'deliveryday/v1/deliveryday/firstdelivery' + params.toString()).pipe(
      map((response: AvailableDelivery[]) => {
        response.forEach(d => {
          if (!d.originaldate) {
            d.originaldate = d.date;
          }
        });
        return <any>response;
      })
      , catchError(this.handleError)
    );
  }

  getRecipeSubscriptionDeliverydates(subscriptionid: number): Observable<AvailableDelivery[]> {
    return this._http.get(environment.apiserver + 'deliveryday/v1/deliveryday/recipesubscription/' + subscriptionid + '/list').pipe(
      map((response: Response) => <any>response)
      , catchError(this.handleError)
    );
  }
  getBoxSubscriptionDeliverydates(subscriptionid: number): Observable<AvailableDelivery[]> {
    return this._http.get(environment.apiserver + 'deliveryday/v1/deliveryday/boxsubscription/' + subscriptionid + '/list').pipe(
      map((response: Response) => <any>response)
      , catchError(this.handleError)
    );
  }

  getRecipeSubscriptionAlterativeDeliverydates(subscriptionid: number, shippingmethod: Shippingmethod, postalcode: string, countrycode: Countrycode): Observable<Deliveryday[]> {

    if (!postalcode) { return of(null) }

    const params = new UrlParameters();
    postalcode = postalcode.replace(/[^0-9]+/g, "")
    if (postalcode.length < 4) { return of(null) };

    this._globalService.setPricecountry(Sourcetype.BROWSER, countrycode);

    params.addParameter("shippingmethod", shippingmethod);
    params.addParameter("postalcodeNumbers", postalcode.toString());
    params.addParameter("countryCode", countrycode);

    if (params.length !== 3) { return of(null) }

    return this._http.get(environment.apiserver + 'deliveryday/v1/deliveryday/recipesubscription/' + subscriptionid + '/alternatives' + params.toString()).pipe(
      map((response: AvailableDelivery[]) => {
        const weekdays = this._globalService.getWeekdays()
        response = response.sort((a, b) => {
          if (!a.postalCodeWeekDayTimeFrames[0] || !b.postalCodeWeekDayTimeFrames[0]) {return 0}
          if (weekdays.indexOf(a.postalCodeWeekDayTimeFrames[0].weekday) < weekdays.indexOf(b.postalCodeWeekDayTimeFrames[0].weekday)) return -1
          if (weekdays.indexOf(a.postalCodeWeekDayTimeFrames[0].weekday) > weekdays.indexOf(b.postalCodeWeekDayTimeFrames[0].weekday)) return 1
          return 0
        })
        return response
      })
      , catchError(this.handleError)
    );
  }
  getBoxSubscriptionAlterativeDeliverydates(subscriptionid: number, shippingmethod: Shippingmethod, postalcode: string, countrycode: Countrycode): Observable<AvailableDelivery[]> {

    if (!postalcode) { return of(null) }
    const params = new UrlParameters();
    postalcode = postalcode.replace(/[^0-9]+/g, "")
    if (!postalcode || postalcode.length < 4) { return of(null) };

    this._globalService.setPricecountry(Sourcetype.BROWSER, countrycode);

    params.addParameter("shippingmethod", shippingmethod);
    params.addParameter("postalcodeNumbers", postalcode.toString());
    params.addParameter("countryCode", countrycode);

    return this._http.get(environment.apiserver + 'deliveryday/v1/deliveryday/boxsubscription/' + subscriptionid + '/alternatives' + params.toString()).pipe(
      map((response: AvailableDelivery[]) => {
        const weekdays = this._globalService.getWeekdays()
        response = response.sort((a, b) => {
          if (weekdays.indexOf(a.postalCodeWeekDayTimeFrames[0].weekday) < weekdays.indexOf(b.postalCodeWeekDayTimeFrames[0].weekday)) return -1
          if (weekdays.indexOf(a.postalCodeWeekDayTimeFrames[0].weekday) > weekdays.indexOf(b.postalCodeWeekDayTimeFrames[0].weekday)) return 1
          return 0
        })
        return response
      })
      , catchError(this.handleError)
    );
  }

  getTimeframe(id: number): Observable<PostalCodeWeekDayTimeFrame> {
    const timeframe = this.timeframes.filter(f => f.id == id)[0];
    if (timeframe)
      return of(timeframe)

    return this._http.get(environment.apiserver + 'postalcodedelivery/v1/postalcodedelivery/postalcoderange/postalcodeweekdaytimeframe/' + id).pipe(
      map((response: PostalCodeWeekDayTimeFrame) => {
        this.timeframes.push(response)
        return response
      })
      , catchError(this.handleError)
    );
  }


  getDeliveryAlternativeDeliverydates(deliverydate: string, shippingmethod: Shippingmethod, postalcode: string, countrycode: Countrycode): Observable<AvailableDelivery[]> {
    if (!postalcode) { return of(null) }
    const params = new UrlParameters();
    postalcode = postalcode.replace(/[^0-9]+/g, "")
    if (!postalcode || postalcode.length < 4) { return of(null) };

    let fromdate = this.getFirstdayOfDeliveryWeek(deliverydate);
    let todate = this.getLastdayOfDeliveryWeek(deliverydate);

    this._globalService.setPricecountry(Sourcetype.BROWSER, countrycode);

    params.addParameter("shippingmethod", shippingmethod);
    params.addParameter("postalcodeNumbers", postalcode.toString());
    params.addParameter("countryCode", countrycode);
    params.addParameter("from", formatDate(fromdate, "yyyy-MM-dd", ɵDEFAULT_LOCALE_ID ));
    params.addParameter("to", formatDate(todate, "yyyy-MM-dd", ɵDEFAULT_LOCALE_ID ));
    params.addParameter("countryCode", countrycode);

    return this._http.get(environment.apiserver + 'deliveryday/v1/deliveryday/alternativesfordelivery'  + params.toString()).pipe(
      map((response: AvailableDelivery[]) => {
        const weekdays = this._globalService.getWeekdays()
        response = response.sort((a, b) => {
          if (weekdays.indexOf(a.postalCodeWeekDayTimeFrames[0].weekday) < weekdays.indexOf(b.postalCodeWeekDayTimeFrames[0].weekday)) return -1
          if (weekdays.indexOf(a.postalCodeWeekDayTimeFrames[0].weekday) > weekdays.indexOf(b.postalCodeWeekDayTimeFrames[0].weekday)) return 1
          return 0
        })
        return response
      })
      , catchError(this.handleError)
    );
  }

  getFirstdayOfDeliveryWeek(date: any): Date{
    let sd = new Date(date);
    let isoWeekday = sd.getDay() == 0 ? 6 : sd.getDay() - 1;
    let startoffset = isoWeekday >= 3 ? (isoWeekday - 3)* -1 : (isoWeekday + 4) * -1; //moet weekday 3 worden
    let startdate = new Date(sd.setDate(sd.getDate() + startoffset));

    return startdate;
  }
  getLastdayOfDeliveryWeek(date: any): Date {
    let ed = new Date(date);
    let isoWeekday = ed.getDay() == 0 ? 6 : ed.getDay() - 1;
    let endoffset =  isoWeekday <= 2 ? 2 - isoWeekday : 9 - isoWeekday; //moet weekday 2 worden
    let enddate = new Date(ed.setDate(ed.getDate() + endoffset));

    return enddate;
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }

}
