import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { UrlParameters } from '../_model/shared';
import { Token } from '../_model/token';
import { Role, User } from '../_model/user';
import { OnDestroy } from '@angular/core';
import { LocalstorageService } from './LocalstorageService';


export class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string { return standardEncoding(k); }
  encodeValue(v: string): string { return standardEncoding(v); }
  decodeKey(k: string): string { return decodeURIComponent(k); }
  decodeValue(v: string) { return decodeURIComponent(v); }
}
function standardEncoding(v: string): string {
  return encodeURIComponent(v);
}

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {

  // makes sure that the token post is only done once -> it has to be public for tests...
  public _refreshSubject: Subject<Token> = new Subject<Token>();
  public isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any,
    private _localStorage: LocalstorageService
  ) {

    if (this.isAuthenticated()) {
      this.isAuthenticatedSubject.next(true);
    }
  }

  ngOnDestroy() {
    this._refreshSubject.complete();
    this.isAuthenticatedSubject.complete();
  }

  private getTimeDiff(created: number): number {
    return Math.floor(Date.now() / 1000) - created;
  }

  isAuthenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {

      const token: Token = this._localStorage.getObject('token');
      //const user: User = JSON.parse(localStorage.getItem('user')); check goes wrong in the onboarding, user is not jet fully created
      if (!token) { // || !user) {
        return false;
      }
      const tokenCreated = this.getTimeCreated();
      const timeDiff = this.getTimeDiff(tokenCreated);

      //this isnt working => it probably should get the creationdate + expires_in date and compare it to the current datetime
      if (timeDiff >= token.expires_in - 60 && timeDiff < token.expires_in) {
        this.doTokenRefresh();
      } else if (timeDiff >= token.expires_in) {
        this.doTokenRefreshForAuthenticated();
      }
      return true;
    }

    if (isPlatformServer(this.platformId)) {
      return false;
    }
  }

  doLogin(user): Observable<Token> {
    //const params = new HttpParams()
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
      .set('username', user.email)
      .set('password', user.password)
      .set('brandid', environment.brandid)
      .set('grant_type', 'password');
    return this._doTokenPost(params);
  }
  doTokenRefresh(): Observable<Token> {
    if (isPlatformBrowser(this.platformId)) {
      const token: Token = this._localStorage.getObject('token');

      if (token) {
        const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
          .set('grant_type', 'refresh_token')
          .set('refresh_token', token.refresh_token)
          .set('brandid', environment.brandid);
        return this._doTokenPostOnce(params);
      } else {
        return of(null)
      }
    }
    return this._doTokenPostOnce(new HttpParams());

  }

  /**
   * removes all the userdata from the localstorage and routes the user to the loginpage if the current url needs authentication
   */
  logout() {
    this.isAuthenticatedSubject.next(false);

    this._localStorage.removeItem('token');
    this._localStorage.removeItem('tokencreated');
    this._localStorage.removeItem('user');
    this._localStorage.removeItem('pricecountry');
    this._localStorage.removeItem('preferredlanguage');
    this._localStorage.removeItem('paymentReturnUrl');
    this._localStorage.removeItem('couponcode');

    //should return false if it is a route for which authentcation is needed
    if (this._activatedRoute.routeConfig && this._activatedRoute?.routeConfig?.canActivate.length > 0) {
      const reg = RegExp(/returnUrl=.*/gm)
      const url = this.router.url.toString().replace(reg, "");
      this.router.navigate(['login'], { queryParams: { returnUrl: url } });
    }
  }

  /**
   * is a limiter of the number of requests that do a _dotokenpost request. Only the first is passed, the following wil get this request as a response. 
   * @param params 
   * @returns the observable of _dotokenpost
   */
  private _doTokenPostOnce(params: HttpParams): Observable<Token> {
    this._refreshSubject.subscribe({
      complete: () => {
        this._refreshSubject = new Subject<Token>();
      }
    });
    //only does first call of dotokenpost
    if (this._refreshSubject.observers.length === 1) {
      this._doTokenPost(params).subscribe(
        result => {
          //returns value 
          this._refreshSubject.next(result);
          this._refreshSubject = new Subject<Token>();
        }, error => {
          this.logout();
          this._refreshSubject.error(error);
        }
      );

    }
    return this._refreshSubject;
  }

  private async doTokenRefreshForAuthenticated() {
    return await lastValueFrom(this.doTokenRefresh()).catch(
      () => {
        this.logout();
        // this.router.navigate(['login']);
      }
    );
  }

  private _doTokenPost(params: HttpParams): Observable<Token> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'Authorization': 'Basic ' + btoa(environment.clientid + ':' + environment.clientsecret)
      })
    };
    return this.httpClient.post<Token>(environment.oauthserver + '/oauth/token', params, httpOptions).pipe(
      map((token: Token) => {
        // login successful if there's a token in the response
        if (token && token.access_token) {
          // store token in local storage to keep user logged in between page refreshes

          this._localStorage.setObject('token', token);
          this._localStorage.setItem('tokencreated', String(Math.floor(Date.now() / 1000)));

        }
        return token;
      }), catchError(this.handleError)
    );
  }


  getUserDetails(): Observable<User> {
    return this.httpClient.get<User>(environment.oauthserver + '/user/v1/user/details').pipe(
      map((user: User) => {
        if (user && user.userroles.includes(Role.TELEMARKETING)) {
          this.router.navigate(['telemarketing']);
          this._localStorage.setObject('user', user);
          this.isAuthenticatedSubject.next(true);
          return user;
        } else if (user && !user.customerid) {
          //is not a customer user -> should not login to website
          this.logout();
          this.router.navigate(['login']);
          return user;
        } else {
          this._localStorage.setObject('user', user);
          this.isAuthenticatedSubject.next(true);
          return user;
        }
      })
    );
  }
  getCurrentUser(): User {

    if (isPlatformServer(this.platformId))
      return null;

    let user = this._localStorage.getObject('user') as User;
    return user;

  }
  patchUser(userForm: FormGroup): Observable<any> {
    const user = userForm.getRawValue();
    user.lastmodified = Date.now();

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    if (userForm.controls['role'].dirty) {
      const body = JSON.stringify(user.role);
      return this.httpClient.patch(environment.oauthserver + '/user/v1/user/' + user.id + '/role', body, options).pipe(
        map((response: string) => <string>response)
        , catchError(this.handleError)
      );
    }
  }
  createUser(user: User, password: string = null): Observable<User> {
    const params = new UrlParameters();

    if (!user.username)
      return throwError('No username!')

    if (password)
      params.addParameter('password', password)

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    const body = JSON.stringify(user);

    return this.httpClient.post(environment.oauthserver + '/user/v1/user/add' + params.toString(), body, options).pipe(
      map((response: User) => <User>response)
      , catchError(this.handleError)
    );
  }
  updateUser(user: User, password: string = null): Observable<User> {
    const params = new UrlParameters();

    params.addParameter('password', password)
    if (password)
      params.addParameter('encryptPassword', 'true')

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    const body = JSON.stringify(user);

    return this.httpClient.put(environment.oauthserver + '/user/v1/user/' + params.toString(), body, options).pipe(
      map((response: User) => <User>response)
      , catchError(this.handleError)
    );
  }
  addCustomerid(activationkey: string, customerid: number): Observable<string> {

    const customeridactivation = {
      activationKey: activationkey,
      customerId: customerid
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    const body = JSON.stringify(customeridactivation);

    return this.httpClient.patch(environment.oauthserver + '/user/v1/user/add/customerid', body, options).pipe(
      map((response: string) => <string>response)
      , catchError(this.handleError)
    );

  }
  isEmailValid(email: string): Observable<boolean> {

    if (!email || email === '') {
      return of(false);
    }

    const params = new UrlParameters();
    params.addParameter('email', email);

    return this.httpClient.get(environment.apiserver + 'auth/user/v1/user/emailvalid' + params.toString()).pipe(
      map((result: boolean) => { return result })
      , catchError(this.handleError)
    );
  }

  changepassword(oldpassword: string, newpassword: string): Observable<any> {
    const urlparams = new UrlParameters();
    urlparams.addParameter('oldpassword', oldpassword)
    urlparams.addParameter('newpassword', newpassword)

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    const body = '';

    return this.httpClient.post(environment.oauthserver + '/user/v1/user/changepassword/loggedin' + urlparams.toString(), body, options).pipe(
      map((response: any) => <any>response)
      , catchError(this.handleError)
    );

  }
  changepasswordlink(username: string): Observable<any> {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    const obj = {
      username: username,
      brandid: environment.brandid
    }

    const body = JSON.stringify(obj);

    return this.httpClient.post(environment.oauthserver + '/user/v1/user/changepassword/request', body, options).pipe(
      map((response: any) => <any>response)
      , catchError(this.handleError)
    );

  }
  changepasswordkey(newpassword: string, key: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //const options = { headers: headers, observe: 'response', responseType: 'text' };
    const obj = {
      password: newpassword,
      resetpasswordkey: key
    }
    const body = JSON.stringify(obj);

    return this.httpClient.post(environment.oauthserver + '/user/v1/user/changepassword', body, { headers: headers, observe: 'response', responseType: 'text' }).pipe(
      map((response: any) => {
        return response.body
      })
      , catchError(this.handleError)
    );

  }

  hasRole(role: Role): boolean {
    let currentuser = this.getCurrentUser();
    if (currentuser)
      return currentuser.userroles.includes(role);

    return false;
  }


  private getTimeCreated(): number {
    if (isPlatformBrowser(this.platformId)) {
      let tokenCreated: number = parseInt(this._localStorage.getItem('tokencreated'), 10);
      // we have a token try and refresh; the time will be set then
      if (!tokenCreated)
        this.doTokenRefreshForAuthenticated();

      return tokenCreated
      // tokenCreated = parseInt(this._localStorage.getItem('tokencreated'), 10);
      // return tokenCreated;
    }
    return 0
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');
  }
}
