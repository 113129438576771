<div class="flex flex-col items-center min-h-fit pt-[25px] md:pt-[50px] bg-e-white" *ngIf="!hasforgotPassword">
  <h1>{{ 'login.sign-in' | translate }}</h1>
  <form (ngSubmit)="login()" [formGroup]="loginform" class="max-w-sm mt-[25px]" id="loginform">
    <span class="error" *ngIf="loginHasFailed">{{'login.failed' | translate}}</span>
    <div class="form-field">
      <label>{{'common.email' | translate}}</label>
      <input (change)="inputChange('email', $event)" type="email" formControlName="email" id="email" name="{{'common.email' | translate}}" autocomplete="username" placeholder="{{'common.email' | translate}}" required>
      <span class="error" *ngIf="hasError('email', 'required')">{{'validation.email_required' | translate}}</span>
      <span class="error" *ngIf="hasError('email', 'email')">{{'validation.email_invalid' | translate}}</span>
    </div>
    <div class="form-field">
      <label>{{'common.password' | translate}}</label>
      <input formControlName="password"
             (change)="inputChange('password', $event)"
             id="password"
             autocomplete="current-password"
             name="{{'common.password' | translate}}"
             placeholder="{{'common.password' | translate}}"
             required type="{{showpassword ? 'text': 'password'}}">

      <button type="button" (click)="showpassword = !showpassword" class="absolute right-[15px] top-[35px] z-[1]"><i class="text-[24px]" [ngClass]="showpassword ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'"></i></button>
      <span class="error" *ngIf="hasError('password', 'required')">{{'validation.password_required' | translate}}</span>
      <span class="error" *ngIf="hasError('password', 'minlength')">{{'validation.password_minlength' | translate}}</span>
    </div>

    <button class="my-4 tracking-normal" (click)="toggleForgotPassword()" type="button">
      {{'login.forgotpassword' | translate}}
    </button>
    <button class="button-base rounded-full w-full" id="login-button" type="submit">{{'login.login_button' | translate}}</button>
  </form>
</div>

<div *ngIf="hasforgotPassword" class="flex flex-col items-center min-h-fit pt-[25px] md:pt-[50px] bg-e-white">

  <h1>{{ 'login.forgotpassword' | translate }}</h1>

  <form (ngSubmit)="forgotPassword()" [formGroup]="forgotPasswordForm" class="max-w-sm mt-[25px]" id="forgotpasswordform">
    <mat-hint *ngIf="forgotPasswordSend" class="col-12">{{'login.forgotpassword_send' | translate}}</mat-hint>
    <div class="form-field">
      <label>{{'common.email' | translate}}</label>
      <input type="email" formControlName="email" id="email" name="{{'common.email' | translate}}" placeholder="{{'common.email' | translate}}" required>
      <span class="error" *ngIf="hasError('email', 'required')">{{'validation.email_required' | translate}}</span>
      <span class="error" *ngIf="hasError('email', 'email')">{{'validation.email_invalid' | translate}}</span>
    </div>

    <div class="flex flex-row mt-[25px]">
      <button class="button bg-e-mid-green text-e-dark-green rounded-full mr-[10px]" (click)="hasforgotPassword = false" type="button">
        {{'common.back_button' | translate}}
      </button>
      <button class="button-base rounded-full" id="login-button" type="submit">{{'login.reset_password_button' | translate}}</button>
    </div>
  </form>
</div>

<div class="pt-[25px] md:pt-[50px] bg-e-white" *ngIf="isOnLoginpage">
  <app-footer></app-footer>
</div>
