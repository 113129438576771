import { Entitytype } from '../_model/redirect';

export class FavoritesList {
  constructor(
    public id: number,
    public customerid: number,
    public brandid: number,
    public entitytypereference: Entitytype,
    public favoriteslisttype: Favoriteslisttype,
    public name: string,
    public favoriteslistitems: FavoritesListItem[]
  ) {
  }
}

export class FavoritesListItem {
  constructor(
    public id: number,
    public entityid: number,
    public sequenceid: number
  ) {
  }
}

export enum Favoriteslisttype {
  TOPRATED = "TOPRATED",
  GENERIC = "GENERIC"
}

