import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { GlobalService } from '../../../_service/GlobalService';
import { PricingService } from '../../../_service/PricingService';
import { BaseorderlineComponent } from '../baseorderline.component';
import { PricetagComponent } from '../../pricetag/pricetag.component';
import { NgClass, NgIf } from '@angular/common';


@Component({
    selector: 'emptyline',
    templateUrl: './emptyline.component.html',
    styleUrls: ['./emptyline.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, RouterLink, PricetagComponent, TranslateModule]
})
export class EmptylineComponent extends BaseorderlineComponent implements OnInit, OnDestroy {

  //line becomes orderline (orderline in basecomponent migrates to any)
  //repeatable will be removed, it will be set and updated in the orderlines
  //repeatableChange will become repeatalbeproductschange which returns the list of subscriptionrepeatableproducts
  //box or product have to be set as a input variable

  @Input() showPrice: boolean = true;
  @Input() condensed = false;
  @Input() showSubscriptionlink = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public _translate: TranslateService,
    public _pricingService: PricingService,
    public _globalService: GlobalService,
  ) {
    super(route, router, _translate, _pricingService, _globalService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
