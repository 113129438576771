import * as env from './_environment';

let env_prod = {...env.environment};
env_prod['production'] = true;
env_prod['logo'] = '/assets/images/ekomenu-logo-rgb.svg';

env_prod['oauthserver'] =  'https://api.ekomenu.nl/auth';
env_prod['apiserver'] = 'https://api.ekomenu.nl/';
env_prod['assetserver'] =  'https://static.ekomenu.nl';
env_prod['etna'] = 'https://admin.prd.ecom.ekomenu.nl';

env_prod['clientid'] =  'web-frontend';
env_prod['clientsecret'] = '220f1ea0-c46c-11ec-9d64-0242ac120002';
env_prod['webshop_actionproducts_nodeid'] = 1431

export const environment = env_prod;
