import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { environment } from '../../_environments/environment';
import { CustomerLifestyle } from '../../_model/customer';
import { Lifestyle } from '../../_model/recipe';
import { NotifydialogData } from '../../_model/shared';
import { CustomerService } from '../../_service/CustomerService';
import { collapse } from '../../_theme/animations';
import { NotifydialogComponent } from '../notifydialog/notifydialog.component';

@Component({
    selector: 'lifestylechosendialog',
    templateUrl: './lifestylechosendialog.component.html',
    styleUrls: ['./lifestylechosendialog.component.scss'],
    animations: [collapse],
    standalone: true,
    imports: [TranslateModule]
})
export class LifestylechosendialogComponent implements OnDestroy {

  environment = environment;

  mealsCount = 0;
  personsCount = 0;
  lifestyle: Lifestyle = null;

  @ViewChild('chooselifestyleTemplateRef', { read: TemplateRef }) chooselifestyleTemplate: TemplateRef<any>;

  unsubscribe = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _customerService: CustomerService,
  ) {

  }

  ngOnDestroy() {
    this.unsubscribe.next();
  }

  navigateToPersonalmenu(mealsCount: number, personsCount: number, lifestyle: Lifestyle) {
    this.mealsCount = mealsCount;
    this.personsCount = personsCount;
    this.lifestyle = lifestyle;

    let dialogRef = this.dialog.open(NotifydialogComponent, {
      data: new NotifydialogData('assets/images/victory-2.svg', this.chooselifestyleTemplate)
    });

    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
      }, 4500);
    });

    this._router.navigate(['/personalmenu/my_box']);
  }

  setSubscriptionObject() {

    this._customerService.addLifeStyle(new CustomerLifestyle(null, this.lifestyle.id))

  }



}
