import { Injectable } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DrawerService {
  private drawers = [];

  constructor(private offcanvasService: NgbOffcanvas) { }


  setDrawer(position: Drawerposition, drawer, id: string, panelClass:  string = '', showBackdrop = true) {
    //console.log(position, drawer)
    if (this.drawers.filter((drawer) => drawer.id === id).length > 0)
      console.warn('There already exists a drawer with the id of: ' + id);
    this.drawers.push({ position: position, el: drawer, id: id, panelClass: panelClass, showBackdrop: showBackdrop });
  }

  // if a component gets destroyed, remove this local reference as well
  //because it will not work as expected when reinitializing the same component again
  clearDrawer(id: string) {
    this.drawers = this.drawers.filter( (drawer) => drawer.id !== id);
  }

  dismiss(): void {
    this.offcanvasService.dismiss();
  }

  update(id: string, drawer) {
    const d = this.drawers.find((drawer) => drawer.id === id);
    d.drawer = drawer;
  }

  toggle(id): NgbOffcanvasRef {
    const drawer = this.drawers.find((drawer) => drawer.id === id);
    // console.log(x);
    const offcanvas = this.offcanvasService.open(
      drawer.el,
      {
        backdrop: drawer.showBackdrop,
        position: drawer.position,
        ariaLabelledBy: drawer.id ? drawer.id : 'offcanvas-left-menu',
        panelClass: drawer.panelClass
      }
    );
    offcanvas.result.then(
        (result) => {
          // console.log(`Closed with: ${result}`);
        },
        (reason) => {
          // console.log(`Dismissed ${reason}`);
        },
    );

    return offcanvas;
  }
}

export enum Drawerposition {
  start = 'start',
  end = 'end',
  bottom = 'bottom',
  top = 'top'
}
