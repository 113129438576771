import { Component, Input, OnInit } from '@angular/core';
import { Orderline } from '../../_model/order';
import { CalculatedPrice } from '../../_model/pricing';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, CurrencyPipe } from '@angular/common';


@Component({
    selector: 'pricetag',
    templateUrl: './pricetag.component.html',
    styleUrls: ['pricetag.component.scss'],
    standalone: true,
    imports: [NgIf, CurrencyPipe, TranslateModule]
})
export class PricetagComponent {

  _calculatedPrice: CalculatedPrice
  @Input() set calculatedPrice(value: CalculatedPrice) {
    this._calculatedPrice = value;
  }

  @Input() set orderline(value: Orderline) {
    this._calculatedPrice = {
      appliedcompensations: null,
      calculateddiscountpercentage: null,
      currency: null,
      discountexvat: value.discount - value.discountvat,
      discountincvat: value.discount,
      discountvat: value.discountvat,
      priceexvat: value.price -value.vat,
      priceincvat: value.price ,
      shippingcost: null,
      shippingcostvat: null,
      appliedcouponcode: null,
      totalcompensated: null,
      vat: value.vat,
      vatpercentage: null,
      basketmovvalue: null,
      basketmovreached: false
    }
  }

  _productprice: boolean = false;
  @Input() set productprice(value: boolean ) {
    this._productprice = value;
  }
  _couponcodeItem: boolean
  @Input() set couponcodeItem(value: boolean) {
    this._couponcodeItem = value;
  }
  _compensationItem: boolean
  @Input() set compensationItem(value: boolean) {
    this._compensationItem = value;
  }


  constructor(

  ) {
  }

}

