
<div class="mb-[15px]" *ngFor="let question of faq?.faqquestions; let i = index" (click)="setExpanded(i)">
  <div class="flex flex-col cursor-pointer px-[15px] py-[5px] rounded-lg" [ngClass]="scheme[colorscheme]">
    <div class="flex flex-row justify-between items-center">
      <p class="font-bold">{{ question.question }}</p>
      <img class="h-[14px] transform transition duration-300 ease-in-out" [ngClass]="expanded === i ? 'rotate-90' : 'rotate-0'" src="./assets/images/icons/arrow_right.svg" height="14px" />
    </div>
    <div *ngIf="expanded === i" @collapse>
      <div [innerHTML]="question.answer"></div>
    </div>
  <!--<div [ngClass]="expanded === i ? 'flex text-left' : 'hidden'">
      <div [innerHTML]="question.answer"></div>
    </div>-->
  </div>
</div>
