import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, debounceTime, filter, map } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { FavoritesList, FavoritesListItem } from '../_model/favorites';
import { Entitytype } from '../_model/redirect';
import { Snackbarerrorformat, Snackbarformat } from '../_theme/snackbarformats';
import { AuthService } from './AuthService';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './Toast.service';

@Injectable()
export class FavoritesService {

  //favoritesLists: FavoritesList[];
  //public favoritesListSubject: BehaviorSubject<FavoritesList[]> = new BehaviorSubject<FavoritesList[]>(null);

  public favoritesListFormArray: FormArray = new FormArray([]);
  constructor(
    private _http: HttpClient,
    private _authenticationService: AuthService,
    private _fb: FormBuilder,
    private _translate: TranslateService,
    private _toastService: ToastService,
  ) {

    this._authenticationService.isAuthenticatedSubject.subscribe(
      next => {
        // gets and sets or removes customer based on authenticationstatus
        if (!next) { this.favoritesListFormArray.clear() }
        else {
          this.getCustomerFavoritesLists().subscribe(() => {
          })
        }
      },
      error => { },
      () => {
        this.favoritesListFormArray.clear() 
      }
    );
  }

  private initFavoritesList() {
    const fg = new FormGroup({
      id: new FormControl(null),
      customerid: new FormControl(null, Validators.required),
      brandid: new FormControl(null, [Validators.required, Validators.min(1)]),
      entitytypereference: new FormControl(null, [Validators.required]),
      favoriteslisttype: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      favoriteslistitems: new FormArray([])
    })
    return fg;
  }
  private initFavoritesListItem() {
    const fg = new FormGroup({
      id: new FormControl(null),
      entityid: new FormControl(null, Validators.required),
      sequenceid: new FormControl(null, Validators.required),
    })
    return fg;
  }

  addFavoritesList(favoriteslist: FavoritesList) {

    //const favs = this.favoritesListFormArray as FormArray;
    if (this.favoritesListFormArray.controls.map(f => f.get('id').value).indexOf(favoriteslist.id) >= 0)
      return;

    const fg = this.initFavoritesList();

    fg.patchValue(favoriteslist);

    if (favoriteslist.favoriteslistitems) {
      favoriteslist.favoriteslistitems.sort((a, b) => a.sequenceid - b.sequenceid)

      favoriteslist.favoriteslistitems.forEach(f => {
        const g = this.initFavoritesListItem();
        g.patchValue(f);
        let arr = fg.get('favoriteslistitems') as FormArray;
        arr.push(g);
      })
    }

    if (favoriteslist.id === null) {
      this.saveFavoritesList(favoriteslist).subscribe(result => {
        fg.patchValue(result);
      })
    }

    fg.valueChanges.pipe(
      debounceTime(500),
      filter(() => fg.valid && fg.dirty)
    ).subscribe(() => {
      let group = fg.getRawValue() as FavoritesList;
      group.favoriteslistitems.forEach((g,i) => {
        g.sequenceid = i + 1;
      })
      fg.markAsPristine();

      this.saveFavoritesList(group).subscribe(result => {
        delete result.name;
        fg.patchValue(result);
        this._toastService.open(this._translate.instant('common.changes_saved'), this._translate.instant('common.ok_dismiss'), Snackbarformat);
      }, error => {
        console.warn(error);
        this._toastService.open(this._translate.instant('common.changes_saved_error'), this._translate.instant('common.ok_dismiss'), Snackbarerrorformat);
      })
    })

    this.favoritesListFormArray.push(fg);
  }
  getFavoritesListsByEntitytype(entitytype: Entitytype): FormGroup[] {
    if (!this.favoritesListFormArray) return [];
    const arr = this.favoritesListFormArray.controls.filter((f: FormGroup) => f.get('entitytypereference').value == entitytype);
    return arr as FormGroup[];
  }
  toggleFavoritesListItem(listid: number, entityid: number) {
    const favoriteslist = this.favoritesListFormArray.controls.filter(c=> c.value.id == listid)[0]
    let arr = favoriteslist.get('favoriteslistitems') as FormArray;
   
    const index = arr.value.map(m => m.entityid).indexOf(entityid);
    
    if (index >= 0) {
      favoriteslist.markAsDirty();
      arr.removeAt(index);
      
    } else {
      const g = this.initFavoritesListItem();
      const item = new FavoritesListItem(null, entityid, favoriteslist.value.favoriteslistitems.length)
      g.patchValue(item);
      arr.markAsDirty();
      arr.push(g);
    }
  }
  isInFaroritesList(favoriteslist: FavoritesList, entityid) {
    return favoriteslist.favoriteslistitems.map(m => m.entityid).indexOf(entityid) >=0;
  }
  isInFavorites(entitytype: Entitytype, entityid: number): boolean {
    if (!this.favoritesListFormArray || this.favoritesListFormArray.controls.length == 0) return false;
    let entitylists = this.favoritesListFormArray.value.filter(f => f.entitytypereference == entitytype);
    const list = [].concat.apply([], entitylists) as FavoritesList[];
    const listitems = [].concat.apply([], list.map(l => l.favoriteslistitems)) as FavoritesListItem[];

    return listitems.map(l => l.entityid).indexOf(entityid) >= 0
  }

  getCustomerFavoritesLists(): Observable<FavoritesList[]> {
    return this._http.get(environment.apiserver + 'favorites/v1/favorites/customer').pipe(
      map((response: FavoritesList[]) => {
        response.forEach(fl => {
          this.addFavoritesList(fl);
        })
        return response;
      })
      , catchError(this.handleError)
    );
  }
  saveFavoritesList(favoriteslist: FavoritesList): Observable<FavoritesList> {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    const body = JSON.stringify(favoriteslist);

    if (favoriteslist.id > 0) {
      return this._http.put(environment.apiserver + 'favorites/v1/favorites/list/' + favoriteslist.id, body, options).pipe(
        map((response: FavoritesList) => {
          response.favoriteslistitems.sort((a, b) => a.sequenceid - b.sequenceid)
          let list = this.favoritesListFormArray.controls.filter(f => f.get('id').value == response.id)[0] as FormGroup
          if (list)
            list.patchValue(response);

          return response;
        })
        , catchError(this.handleError)
      );
    } else {
      return this._http.post(environment.apiserver + 'favorites/v1/favorites/list', body, options).pipe(
        map((response: FavoritesList) => {
          response.favoriteslistitems.sort((a, b) => a.sequenceid - b.sequenceid)
          let list = this.favoritesListFormArray.controls.filter(f => f.get('id').value == null)[0] as FormGroup
          if (list)
            list.patchValue(response);

          return response;
        })
        , catchError(this.handleError)
      );
    }

  }
  deleteFavoritesList(id: number):Observable<boolean> {
    return this._http.delete(environment.apiserver + 'favorites/v1/favorites/list/' + id).pipe(
      map((response: any) => {
        const index = this.favoritesListFormArray.controls.findIndex(f=> f.get('id').value == id)
        this.favoritesListFormArray.removeAt(index)

        return true;
      })
      , catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }

}
