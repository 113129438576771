import { ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { StepComponent } from './step/step.component';
import { JsonPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { collapse, horCollapse, slideInOut } from '../../_theme/animations';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'stepper',
  standalone: true,
  animations: [collapse],
  imports: [NgIf, NgForOf, NgTemplateOutlet, TranslateModule, JsonPipe],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
  host: { ngSkipHydration: 'true' },
})
export class StepperComponent {

  private _allstepnames: string[];

  private largestWantedStep = 0
  _stepindex = 0;
  @Input() set stepindex(value: number) {

    if (this.largestWantedStep < value) {
      this.largestWantedStep = value;
      //lets page load 1 sec to set all the validity to navigate to the first not valid step
      setTimeout(() => { this._unsubscribe$.next() }, 1000)
    }

    this._stepindex = this.getValidStepIndex(value);
    if (this._allstepnames) {
      this.activestepname.emit(this._allstepnames[this._stepindex]);
    }
  }
  @Output() stepindexChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() stepnames: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() activestepname: EventEmitter<string> = new EventEmitter<string>();

  @ContentChildren(StepComponent) steps: QueryList<StepComponent>;
  constructor(
    private _cd: ChangeDetectorRef
  ) {

  }

  private _unsubscribe$ = new Subject<void>();

  ngAfterContentInit() {
    this.updateSteps(this.steps);
    this.steps.changes.subscribe(value => {
      this.updateSteps(value)
    })
  }
  ngDoCheck() {
    this._cd.detectChanges()
  }

  getValidStepIndex(stepindex): number {
    if (!this.steps) return 0;

    let firstNonValidStepIndex = null;
    this.steps.forEach((step, index) => {
      if (!step.valid && firstNonValidStepIndex === null) {
        firstNonValidStepIndex = index;
      }
    })

    if (stepindex > firstNonValidStepIndex) {
      return firstNonValidStepIndex ? firstNonValidStepIndex : stepindex;

    }
    return stepindex;
  }

  updateSteps(queryList: QueryList<StepComponent>) {
    if (!queryList) return;

    this._unsubscribe$.next();

    const steps = queryList.toArray()

    this._allstepnames = steps.map(m => m.stepname);
    this.stepnames.emit(this._allstepnames);

    steps.forEach(step => {
      //navigates to the last wanted step if it is valid, is terminated 1 sec after stepindex is set
      step.validChange.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
        this.stepindex = this.largestWantedStep;
        console.log('change stepindex')
    }) })

    this.stepindex = this.getValidStepIndex(this._stepindex)
    this.stepindexChange.emit(this._stepindex)
  }
}
