<div *ngIf="store"
     class="m-2 p-2 flex flex-col rounded-xl"
     [ngClass]="{
     'border-e-dark-green bg-white text-e-dark-green': !selected,
     'border-e-dark-green bg-e-dark-green text-white': selected,
     'border-e-light-green bg-e-light-green text-e-dark-green': deliveryday?.ismoved && !selected
     }">
  <div class="flex flex-row" *ngIf="deliverydate">
    <strong class="whitespace-nowrap ">{{deliverydate.date | localizedDate: 'EEEE dd MMM' |capitalizefirst}}</strong>
  </div>
  <div class="flex flex-row justify-between">
    <div [ngClass]="{'font-normal': deliverydate, 'font-bold': !deliverydate} ">
      <span class="block">{{store?.name}}</span>
      <span class="block">{{store?.address}}</span>
      <span class="block">{{store?.postalcode}} {{store?.city}}</span>
    </div>
    <div class="text-end">
      <strong><small class="">{{store?.calculatedDistance}}</small></strong>
    </div>
  </div>
  <small class="" *ngIf="deliveryday">
    <i *ngIf="deliveryday?.ismoved" class="bi bi-arrow-bar-right me-1"></i>
    <i class="bi bi-clock me-1"></i>
    <span class="text-lowercase">{{'subscription.pickup_possible_between' | translate: {day: deliveryday?.date | date: 'EEEE'} }}</span>
    <span *ngIf="deliveryday" class="ms-1">{{deliveryday?.postalCodeWeekDayTimeFrames[0].timefrom.substring(0, 5)}} - {{deliveryday?.postalCodeWeekDayTimeFrames[0].timeto.substring(0, 5)}} </span>

  </small>
  <small *ngIf="timeframe">
    <i class="bi bi-clock me-1"></i>
    <span class="text-lowercase">{{'subscription.pickup_possible_between' | translate: {day: 'weekday.' + timeframe?.weekday | translate} }}</span>
    <span class="ms-1"> {{timeframe.timefrom.substring(0, 5)}} - {{timeframe.timeto.substring(0, 5)}} </span>
  </small>
</div>
