import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CheckdialogData } from '../../_model/shared';


@Component({
    selector: 'check-dialog',
    templateUrl: './checkdialog.component.html',
    styleUrls: ['checkdialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, NgIf, MatDialogContent, MatDialogActions, NgSwitch, NgSwitchCase, MatDialogClose, NgFor, NgClass, TranslateModule]
})
export class CheckdialogComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<CheckdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CheckdialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.dialogRef.addPanelClass(this.data.color.toString());
  }







}
