import { environment } from '../_environments/environment';
import { CalculatedPrice } from './pricing';
import { Unit } from './product';
import { Countrycode, Language } from './shared';

export class Recipe {
  constructor(
    public id: number,
    public displayname: string,
    public garnertip: string,
    public cookingtime: number,
    public subtitle: string,
    public intro: string,
    public vegetableweight: number,
    public doublepersons: boolean,
    public difficulty: number,
    public salessurcharge: number,
    public websiteimageurl: string,
    public websitemediumimageurl: string,
    public websitelargeimageurl: string,
    public websitethumbimageurl: string,
    public expiredays: number,
    public redirectid: number,
    public activefrom: any,
    public activeto: any,
    public youtubeembedcode: string,
    public vimeoembedcode: string,
    public nutrients: RecipeNutrient[],
    public lifestyles: RecipeLifestyle[],
    public ingredients: RecipeIngredient[],
    public preparations: RecipePreparation[],
    public allergens: RecipeAllergen[],
    public recipecards: RecipeCard[],
    public recipeScores: RecipeScore[]
  ) {
  }
}

export class RecipeIngredient {
  constructor(
    public sequenceid: number,
    public displayname: string,
    public onepersquantity: number,
    public twopersquantity: number,
    public threepersquantity: number,
    public fourpersquantity: number,
    public provided: boolean,
    public unit: Unit,
    public producer: string,
    public countrycode: Countrycode
  ) {
  }
}

export class RecipePreparation {
  constructor(
    public id: number,
    public recipeid: number,
    public description: string,
    public sequenceid: number
  ) {
  }
}

export class RecipeLifestyle {
  constructor(
    public lifestyleid: number
  ) {
  }
}

export class RecipeNutrient {
  constructor(
    public name: Nutrient,
    public unit: Unit,
    public quantity: number
  ) {
  }
}

export class RecipeAllergen {
  constructor(
    public name: Allergen
  ) {
  }
}

export class RecipeScore {
  private env = environment

  public dutchscoreaverage: number
  public scoreimprovement: number
  public scoreimprovementpercentage: number
  constructor(
    public scoretype: RecipescoreType,
    public scorerating: string,
    public scorevalue: string,
  ) {
    if (this.env.dutchmealscores[scoretype]) {
      this.dutchscoreaverage = this.env.dutchmealscores[scoretype]
      if (!isNaN(Number(scorevalue)))
        if (scoretype === RecipescoreType._VETGETABLESCORE) {
          this.scoreimprovement = Number(scorevalue) - this.dutchscoreaverage;
          this.scoreimprovementpercentage = this.scoreimprovement / this.dutchscoreaverage;
        } else if (scoretype === RecipescoreType.VITASCORERISCPOINTS) {
          this.scoreimprovement = Number(scorevalue) - this.dutchscoreaverage;
          this.scoreimprovementpercentage = (this.scoreimprovement / this.dutchscoreaverage) * -1;

        } else {
          this.scoreimprovement = (Number(scorevalue) - this.dutchscoreaverage)
          this.scoreimprovementpercentage = (this.scoreimprovement / this.dutchscoreaverage) ;
        }

    }
  }
}

export enum RecipescoreType {
  NUTRISCORE = 'NUTRISCORE',
  VITASCORE = 'VITASCORE',
  VITASCORERISCPOINTS = 'VITASCORERISCPOINTS',
  SCARCEWATERUSAGE = 'SCARCEWATERUSAGE',
  CARBONSCORE = 'CARBONSCORE',
  CARBONREDUCTION = 'CARBONREDUCTION',
  RAINFORESTSCORE = 'RAINFORESTSCORE',
  ANIMALTREATMENTSCORE = 'ANIMALTREATMENTSCORE',
  _VETGETABLESCORE = '_VETGETABLESCORE',
  _TRUECOSTS = '_TRUECOSTS'

}



export class RecipeCard {
  constructor(
    public language: Language,
    public recipecardfronturl: string,
    public recipecardbackurl: string,
    public recipecardfullurl: string,
  ) {

  }
}

// this enum is in the requested order!
export enum Nutrient {
  ENERGY = 'ENERGY',
  CARBOHYDRATES = 'CARBOHYDRATES',
  SUGAR = 'SUGAR',
  FAT = 'FAT',
  SATURATEDFAT = 'SATURATEDFAT',
  PROTEIN = 'PROTEIN',
  FIBERS = 'FIBERS',
  SALT = 'SALT'
}

// this enum is in the requested order!
export enum Allergen {
  GLUTEN = 'GLUTEN',
  WHEAT = 'WHEAT',
  MILK = 'MILK',
  LACTOSE = 'LACTOSE',
  NUTS = 'NUTS',
  PEANUTS = 'PEANUTS',
  CORN = 'CORN',
  LEGUMES = 'LEGUMES',
  SOYBEANS = 'SOYBEANS',
  BELL_PEPPER = 'BELL_PEPPER',
  EGG = 'EGG',
  MUSTARD = 'MUSTARD',
  FISH = 'FISH',
  CRUSTACEANS = 'CRUSTACEANS',
  MOLLUSCS = 'MOLLUSCS',
  BEEF = 'BEEF',
  LAMB = 'LAMB',
  CHICKEN = 'CHICKEN',
  PORK = 'PORK',
  CELERY = 'CELERY',
  SESAMESEED = 'SESAMESEED',
  CORIANDER = 'CORIANDER',
  LUPIN = 'LUPIN',
  GLUTAMATE = 'GLUTAMATE',
  SULFITE = 'SULFITE',
  SULFORDIOXIDE = 'SULFORDIOXIDE',
}

export class Lifestyle {
  constructor(
    public id: number,
    public brandid: number,
    public name: string,
    public title: string,
    public enabled: boolean,
    public shortdescription: string,
    public description: string,
    public redirectid: number,
    public lifestyleboxid: number,
    public seotitle: string,
    public seodescription: string,
    public websiteimageurl: string,
    public websitethumbimageurl: string,
    public websitemediumimageurl: string,
    public websitelargeimageurl: string,
    public sequenceid: number,
    public lifestylefaqs: LifestyleFaq[]
  ) {

  }
}

export class LifestyleFaq {
  constructor(
    public id: number,
    public faqid: number
  ) { }
}






