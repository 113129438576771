import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, catchError, map, of, throwError } from 'rxjs';
import { environment } from '../_environments/environment';
import { Lifestyle } from '../_model/recipe';
import { UrlParameters } from '../_model/shared';

@Injectable()
export class LifestyleService {

  constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any,
    private _translate: TranslateService,
  ) {
    this._translate.onLangChange.subscribe(next => {
      this.lifestyles = [];
    })
  }

  private _getListSubject: Subject<Lifestyle[]> = new Subject<Lifestyle[]>();

  lifestyles: Lifestyle[] = [];

  getLifestyle(id: number): Observable<Lifestyle> {
    if(!id) return of(null)
    const lifestyle = this.lifestyles.filter(l => l.id == id)[0];
    if (lifestyle) {
      return of(lifestyle);
    }
    const params = new UrlParameters();
    params.addParameter('ids', id.toString());

    return this.httpClient.get(environment.apiserver + 'lifestylebff/v1/lifestyle/list' + params.toString()).pipe(
      map((response: Lifestyle[]) => {
        if (response.length > 0) {
          if (this.lifestyles && this.lifestyles.length > 0)
            this.lifestyles.push(response[0])

          return response[0] as Lifestyle;
        }
        return null;
      })
      , catchError(this.handleError)
    );
  }
  getLifestyleList(): Observable<Lifestyle[]> {
    if (this.lifestyles && this.lifestyles.length > 0) {
      return of(this.lifestyles);
    }

    this._getListSubject.subscribe({
      complete: () => {
        this._getListSubject = new Subject<Lifestyle[]>();
      }
    })

    if (this._getListSubject.observers.length === 1) {
      return this.httpClient.get(environment.apiserver + 'lifestylebff/v1/lifestyle').pipe(
        map((response: Lifestyle[]) => {
          this.lifestyles = this.lifestyles.concat(response);

          this.lifestyles = this.lifestyles.sort((a, b) => a.sequenceid - b.sequenceid)

          this._getListSubject.next(this.lifestyles);
          this._getListSubject = new Subject<Lifestyle[]>();

          return this.lifestyles as Lifestyle[];
        })
        , catchError(error => {
          this._getListSubject.error(error);
          return this.handleError(error)
        })
      );
    }
    return this._getListSubject;
  }

  private handleError(error: Response) {

    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }

}
