import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'step',
  standalone: true,
  imports: [NgTemplateOutlet],
  templateUrl: './step.component.html',
  styleUrl: './step.component.scss',

})
export class StepComponent {


  @Input() stepname: string;
  _valid = false;
  @Input() set valid(value: boolean) {
    this._valid = value;
    this.validChange.emit(value);
  };
  get valid(): boolean {
    return this._valid;
  }
  @Output() validChange = new EventEmitter<boolean>()

  @ViewChild('steptemplate') template: TemplateRef<any>

  constructor(
    private _cd: ChangeDetectorRef
  ) { }

}
