import { Component, Input } from '@angular/core';
import { Colorscheme } from '../../_theme/colorscheme';

@Component({
  template: `
    <p>
     colorscheme works!
    </p>
  `,
})
export class ColorScheme {
  private _colorscheme = Colorscheme.light;
  @Input() set colorscheme(value: Colorscheme | string) {
    if (value)
      this._colorscheme = this.getKeyByKeyOrValue(value);
  }
  get colorscheme(): Colorscheme {
    return this._colorscheme
  }

  @Input() set contrastColorscheme(value: Colorscheme | string) {
    const scheme = this.getKeyByKeyOrValue(value)
    switch (scheme) {
      case Colorscheme.light:
        this._colorscheme = Colorscheme.white;
        break;
      case Colorscheme.white:
        this._colorscheme = Colorscheme.light;
        break;
    }
  }

  private getKeyByKeyOrValue(value: string): Colorscheme {
    const indexOfValue = Object.values(Colorscheme).indexOf(value as unknown as Colorscheme);
    if (indexOfValue >= 0)
      return Colorscheme[Object.keys(Colorscheme)[indexOfValue]];

    const indexOfKey = Object.keys(Colorscheme).indexOf(value as unknown as Colorscheme);
    if (indexOfKey >=0)
      return Colorscheme[Object.keys(Colorscheme)[indexOfKey]];

    console.warn(value + ' is not a recognised colorscheme')
  }
    
}
