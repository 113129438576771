import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { Store, Storetype } from '../_model/postalcodedelivery';
import { Countrycode, UrlParameters } from '../_model/shared';
import { Weekday } from '../_model/weeks';

@Injectable()
export class StoreService {

  constructor(
    private _http: HttpClient
  ) {
  }

  findStores(countrycode: Countrycode, postalcode: string, streetnumber: number, addition: string): Observable<Store[]> {
    if (!countrycode || !postalcode) return of(null)

    const params = new UrlParameters();
    params.addParameter('countryCode', countrycode);
    params.addParameter('postalcode', postalcode);
    if (streetnumber)
      params.addParameter('streetnumber', streetnumber.toString());
    params.addParameter('streetnumberaddition', addition);

    return this._http.get(environment.apiserver + 'stores/v1/stores/find' + params.toString()).pipe(
      map((response: Store[]) => {
        response.forEach(r => {
          if (r.distance) {
            r['calculatedDistance'] = r.distance >= 1 ? (Math.round(r.distance * 10) / 10) + 'km' : Math.round(r.distance * 1000) + 'm'
          } else {
            r['calculatedDistance'] = null;
          }
        })
        return <Store[]>response;
      })
      , catchError(this.handleError)
    );
  }

  getStore(storeid: number, storetype: Storetype, countrycode: Countrycode, postalcode: string, streetnumber: number): Observable<Store> {
    const params = new UrlParameters();

    if (!storeid || !storetype || !countrycode || !postalcode || !streetnumber) {
      return of(null)
    }

    params.addParameter('countryCode', countrycode);
    params.addParameter('postalcode', postalcode);
    params.addParameter('streetnumber',  streetnumber?.toString() );

    return this._http.get(environment.apiserver + 'stores/v1/stores/'+ storetype + '/' + storeid + params.toString()).pipe(
      map((response: Store) => {
        if (response.distance) {
          response['calculatedDistance'] = response.distance >= 1 ? (Math.round(response.distance * 10) / 10) + 'km' : Math.round(response.distance * 1000) + 'm'
        } else {
          response['calculatedDistance'] = null;
        }
       
        return <Store>response;
      })
      , catchError(this.handleError)
    );
  }

  getStoreData(store: Store): string {
    if(!store) return ''
    return store.name + ", " + store.address + " " + store.postalcode + " " + store.city
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }

}
