import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../_environments/environment';
import { Orderline } from '../../_model/order';
import { Recipe, RecipeScore, RecipescoreType } from '../../_model/recipe';
import { RecipeService } from '../../_service/RecipeService';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { NgIf, DecimalPipe, CurrencyPipe, JsonPipe } from '@angular/common';
import { WINDOW } from '../../_service/WindowService';


@Component({
    selector: 'impactscores',
    templateUrl: './impactscores.component.html',
    styleUrls: ['impactscores.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, DecimalPipe, CurrencyPipe, TranslateModule, JsonPipe]
})

// TODO: move this into vitascore

export class ImpactscoresComponent implements OnInit, OnDestroy {

  environment = environment;

  scores: RecipeScore[] = [];

  isInView: any[] = [];

  @Input() class = '';

  _lines: Orderline[];
  @Input() set orderlines(lines: Orderline[]) {
    this._lines = lines;
    if (lines)
      this.getRecipes(lines)
  }

  constructor(
    private _recipeService: RecipeService,
    @Inject(WINDOW) private window: Window,
  ) {
  }

  ngOnInit() {
    const scoretypes = this._recipeService.getRecipescoretypes();
    scoretypes.forEach(s => {
      let score = new RecipeScore(RecipescoreType[String(s)], "", "0")
      this.scores.push(score)
    })
    if (this._lines)
      this.getRecipes(this._lines);

    this.window.addEventListener('scroll', this.onScroll, true);
  }

  ngOnDestroy() {
    this.window.removeEventListener('scroll', this.onScroll, true);
    //this.unsubscribe.next();
  }

  onScroll = (event: any): void => {
    this.setObserver();
  }
  setObserver() {
    let pies = document.querySelectorAll('.pie');
    if (this.isInView.length == pies.length) return;

    const observer = new IntersectionObserver(entries => {
      // Loop over the entries
      entries.forEach(entry => {
        if (this.isInView.includes(entry.target)) { return; }
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          entry.target.classList.add('circle-animation');
          this.isInView.push(entry.target);
        }
      });
    });

    if (pies)
      pies.forEach(pie => {
        observer.observe(pie);
      })
  }

  getRecipes(lines: Orderline[]) {
    let recipeids = lines.map(l => Number(l.recipeid))
    this._recipeService.getRecipeList(recipeids).subscribe(
      result => {
        result.forEach(r => this._recipeService.setRecipeScores(r));
        this.calculatescore(result);
      }
    )
  }

  calculatescore(recipes: Recipe[]) {
    this.resetScores();
    
    if (!recipes) return;

    this.scores.forEach(s => {
      const scores = [].concat.apply([], recipes.map(r => r.recipeScores)).filter((rs: RecipeScore) => rs.scoretype == s.scoretype)
      //calculates the total of the normal scores
      if (s && !isNaN(Number(s.scorevalue))) {
        s.scorevalue = scores.reduce((partialSum, s) => {
          return partialSum + Number(s.scorevalue)
        }, 0)
      }
      //calculates the total of te vegetablescore
      if (s.scoretype === RecipescoreType._VETGETABLESCORE) {
        s.scorevalue = recipes.map(r => r.vegetableweight).reduce((partialSum, v) => partialSum + v, 0) + '';
      }

      //calculates the improvement with the dutch score
      if (!isNaN(Number(s.scorevalue))) {
        s.dutchscoreaverage = this.environment.dutchmealscores[s.scoretype] * recipes.length;
        if (s.scoretype === RecipescoreType._VETGETABLESCORE || s.scoretype === RecipescoreType.VITASCORE) {
          s.scoreimprovement = Number(s.scorevalue) - s.dutchscoreaverage
        } else {
          s.scoreimprovement = s.dutchscoreaverage - Number(s.scorevalue)
        }
      }
    })
    setTimeout(() => {
      this.setObserver();
    })

  }

  resetScores() {
    this.scores.forEach(s => {
      s.scorevalue = "0";
      s.scoreimprovement = 0
    })
  }


  getScore(scoretype: string): RecipeScore {
    if (!this.scores || this.scores.length == 0) return null;

    const type = RecipescoreType[scoretype]
    const score = this.scores.filter(s => s.scoretype == type && s.scorevalue !== '0')[0];

    return score;
  }


}
