import { formatDate, isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, ɵDEFAULT_LOCALE_ID } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from './Toast.service';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, skip, switchMap } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { Shippingmethod } from '../_model/order';
import { BoxSubscription, Originentitytype, RecipeSubscription } from '../_model/subscription';
import { AvailableDelivery, Frequency } from '../_model/weeks';
import { Snackbarerrorformat, Snackbarformat } from '../_theme/snackbarformats';
import { MyValidators } from '../_validators/MyValidators';
import { AuthService } from './AuthService';
import { CouponcodeService } from './CouponcodeService';
import { CustomerService } from './CustomerService';
import { DeliverydayService } from './DeliverydayService';
import { GlobalService } from './GlobalService';
import { WINDOW } from './WindowService';
import { SessionstorageService } from './SessionstorageService';
import { OrderService } from './OrderService';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {


  environment = environment;

  orderflowSubscriptionForm = this.initiateSubscription();

  private refreshTime: number = 60 * 15 * 1000 // 15 min
  private subscriptions = this.fb.array([]);

  subscriptionsChanged = 0;

  public recipesubscriptionSubject: BehaviorSubject<RecipeSubscription[]> = new BehaviorSubject<RecipeSubscription[]>(null);
  recipesubscriptions: RecipeSubscription[] = [];

  public boxsubscriptionSubject: BehaviorSubject<BoxSubscription[]> = new BehaviorSubject<BoxSubscription[]>(null);
  boxsubscriptions: BoxSubscription[] = [];

  private subscriptionRefreshTimer: any;

  hasSubscriptions = this.boxsubscriptions.length + this.recipesubscriptions.length > 0

  constructor(
    private _http: HttpClient,
    private _deliverydayService: DeliverydayService,
    private _authService: AuthService,
    private _customerService: CustomerService,
    private _couponcodeService: CouponcodeService,
    private _orderService: OrderService,
    private _myValidators: MyValidators,
    private _globalService: GlobalService,
    public fb: FormBuilder,
  private _toastService: ToastService,

    private _translate: TranslateService,
    private _router: Router,
    private _sessionStorage: SessionstorageService,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {

    this.subscriptions.valueChanges.subscribe(() => { this.subscriptionsChanged++; })

    this._authService.isAuthenticatedSubject.pipe(skip(1)).subscribe(
      value => {
        if (!value) {
          this.subscriptions.clear();
          if (isPlatformBrowser(this.platformId))
            this.window.clearTimeout(this.subscriptionRefreshTimer);

          this.subscriptionsChanged = 0;

          this.recipesubscriptions = [];
          this.recipesubscriptionSubject.next([]);

          this.boxsubscriptions = [];
          this.boxsubscriptionSubject.next([]);

        } else {

          this.getCustomerRecipeSubscriptionList(_authService.getCurrentUser().customerid).subscribe();
          this.getCustomerBoxSubscriptionList(_authService.getCurrentUser().customerid).subscribe();

          //makes sure that the subscriptionform is cleared when the user logs in manually
          if (this._router.url.indexOf('personalmenu') == -1) { }

        }
      },
      () => {
        this.subscriptions.clear();
        this.orderflowSubscriptionForm = this.initiateSubscription();
        if (isPlatformBrowser(this.platformId))
          this.window.clearTimeout(this.subscriptionRefreshTimer);

        this.subscriptionsChanged = 0;

        this.recipesubscriptions = [];
        this.recipesubscriptionSubject.next([]);

        this.boxsubscriptions = [];
        this.boxsubscriptionSubject.next([]);

      },
      () => {
        this.subscriptions.clear();
        if (isPlatformBrowser(this.platformId))
          this.window.clearTimeout(this.subscriptionRefreshTimer);

        this.subscriptionsChanged = 0;

        this.recipesubscriptions = [];
        this.recipesubscriptionSubject.next([]);

        this.boxsubscriptions = [];
        this.boxsubscriptionSubject.next([]);

      });

    const SessionorderFlowForm = _sessionStorage.getObject('orderflowsubscriptionform');
    if (SessionorderFlowForm) {
      this.orderflowSubscriptionForm.patchValue(SessionorderFlowForm);
    }

    //sets elements to other elements so they updata automaticly
    this._globalService.replaceControl(this.orderflowSubscriptionForm, 'customerid', this._customerService.customerForm, 'id')
    this._globalService.setValueOnChanges(this.orderflowSubscriptionForm, 'pickupdeliverytimeframe', this._orderService.orderFlowForm)
    this._globalService.setValueOnChanges(this.orderflowSubscriptionForm, 'storeid', this._orderService.orderFlowForm)
    this._globalService.setValueOnChanges(this.orderflowSubscriptionForm, 'storetype', this._orderService.orderFlowForm)
    this._globalService.setValueOnChanges(this.orderflowSubscriptionForm, 'storedata', this._orderService.orderFlowForm, 'pickupstore')
    this._globalService.setValueOnChanges(this.orderflowSubscriptionForm, 'startdate', this._orderService.orderFlowForm, 'deliverydate')
    this._globalService.setValueOnChanges(this.orderflowSubscriptionForm, 'recipecard', this._orderService.orderFlowForm)
    this._globalService.setValueOnChanges(this.orderflowSubscriptionForm, 'shippingmethod', this._orderService.orderFlowForm)

    this.orderflowSubscriptionForm.valueChanges.subscribe({
      next: res => {
        this._sessionStorage.setObject('orderflowsubscriptionform', res)
      }
    })
  }

  get flowdefaultpersons(): FormControl {
    return this.orderflowSubscriptionForm.get('defaultpersons') as FormControl
  }
  get flowdefaultrecipes(): FormControl {
    return this.orderflowSubscriptionForm.get('defaultrecipes') as FormControl
  }

  private resetTimer() {
    if (isPlatformBrowser(this.platformId))
      this.window.clearTimeout(this.subscriptionRefreshTimer);
    this.subscriptionRefreshTimer = setTimeout(() => {
      console.log('executed subscription timeout', new Date());
      const user = this._authService.getCurrentUser();
      this.recipesubscriptions = [];
      this.boxsubscriptions = [];
      this.subscriptions.clear();
      this.getCustomerRecipeSubscriptionList(user.customerid).subscribe();
      this.getCustomerBoxSubscriptionList(user.customerid).subscribe();
    }, this.refreshTime);
  }

  getCustomerRecipeSubscriptionList(customerid: number, fromServer: boolean = false): Observable<RecipeSubscription[]> {
    if (!customerid) return of([])

    if (this.recipesubscriptions && this.recipesubscriptions.length > 0 && !fromServer) {
      return of(this.recipesubscriptions);
    }

    return this._http.get(environment.apiserver + 'recipesubscriptionbff/v1/recipesubscription/customerid/' + customerid).pipe(
      map((response: RecipeSubscription[]) => {

        response = response.sort((a, b) => {
          if (a.id < b.id) { return 1; }
          if (a.id > b.id) { return -1; }
          return 0;
        });

        //sorts all products by id desc
        response.forEach(rec => {
          rec.recipeSubscriptionDeliveries.forEach(del => {
            del.recipeSubscriptionProductSelections.sort((a, b) => { return b.id - a.id })
          })
        })
        //loads subscriptions into formarray
        this.loadSubscriptions(response, Originentitytype.RECIPE_SUBSCRIPTION);

        this.recipesubscriptions = response;
        this.recipesubscriptionSubject.next(response);
        this.resetTimer();

        return response;
      })
      , catchError(error => this.handleError(error, 'subscription.get_subscriptionlist_error'))
    );
  }
  getCustomerRecipeSubscription(subscriptionid: number): Observable<RecipeSubscription> {
    /*return throwError(() => 'Server error');*/

    return this._http.get(environment.apiserver + 'recipesubscriptionbff/v1/recipesubscription/' + subscriptionid).pipe(
      map((response: RecipeSubscription) => {
        return response;
      })
      , catchError(error => this.handleError(error, 'subscription.get_subscription_error'))
    );
  }
  private updateRecipeSubscriptionWithLastdata(subscriptionForm: FormGroup, changedValues: any = null): Observable<RecipeSubscription> {
    if (subscriptionForm.get('id').value > 0) {
      return this.getCustomerRecipeSubscription(subscriptionForm.get('id').value).pipe(
        switchMap((res: RecipeSubscription) => {
          if (!changedValues)
            changedValues = this._globalService.getDirtyValues(subscriptionForm);
          res = this._globalService.mergeDirtyValues(changedValues, res);
          //subscriptionForm.patchValue(res);
          return of(res);
        })
      );
    } else {
      return of(subscriptionForm.getRawValue());
    }
  }
  getCustomerRecipeSubscriptionDeliverydatesBySubscriptionid(subscriptionid: number): Observable<AvailableDelivery[]> {
    return this._deliverydayService.getRecipeSubscriptionDeliverydates(Number(subscriptionid)).pipe(
      map((response: AvailableDelivery[]) => <any>response),
      catchError(error => this.handleError(error, 'subscription.get_deliverydates_error'))
    );
  }
  saveRecipeSubscription(subscriptionForm: FormGroup, changedValues: any = null): Observable<RecipeSubscription> {

    return this.updateRecipeSubscriptionWithLastdata(subscriptionForm, changedValues).pipe(
      switchMap((subscription: RecipeSubscription) => {

        if (subscription.recipeSubscriptionDeliveries && subscription.recipeSubscriptionDeliveries.length > 0) {
          subscription.recipeSubscriptionDeliveries.forEach((val) => {
            if (val.deliverydate) {
              val.deliverydate = formatDate(val.deliverydate, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
            }
            if (val.alternatedeliverydate) {
              val.alternatedeliverydate = formatDate(val.alternatedeliverydate, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
              // MAG NIET https://ekomenu.atlassian.net/browse/EK-570 -> is achterhaald 15-3-2023
              //val.alternatedeliverydate = undefined;
            }
          });
        }
        if (subscription.terminationdate)
          subscription.terminationdate = formatDate(subscription.terminationdate, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
        if (subscription.startdate)
          subscription.startdate = formatDate(subscription.startdate, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
        if (subscription.calculatednextdeliverydate)
          subscription.calculatednextdeliverydate = formatDate(subscription.calculatednextdeliverydate, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);

        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: headers };
        const body = JSON.stringify(subscription);

        if (subscription.id > 0) {
          return this._http.put(environment.apiserver + 'recipesubscriptionbff/v1/recipesubscription/' + subscription.id, body, options).pipe(
            map((response: RecipeSubscription) => {
              //sorts all products by id desc
              response.recipeSubscriptionDeliveries.forEach(del => {
                del.recipeSubscriptionProductSelections.sort((a, b) => { return b.id - a.id })
              })

              this.recipesubscriptions[this.recipesubscriptions.map(r => r.id).indexOf(response.id)] = response;
              this.recipesubscriptionSubject.next(this.recipesubscriptions);
              this.loadSubscriptions([response], Originentitytype.RECIPE_SUBSCRIPTION);

              return response;
            })
            , catchError(error => this.handleError(error, 'subscription.save_error'))
          );
        } else {
          return this._http.post(environment.apiserver + 'recipesubscriptionbff/v1/recipesubscription', body, options).pipe(
            map((response: RecipeSubscription) => {
              //sorts all deliveryproducts by id desc
              response.recipeSubscriptionDeliveries.forEach(del => {
                del.recipeSubscriptionProductSelections.sort((a, b) => { return b.id - a.id })
              })
              this.recipesubscriptions.push(response);
              this.recipesubscriptionSubject.next(this.recipesubscriptions);
              this.loadSubscriptions([response], Originentitytype.RECIPE_SUBSCRIPTION);

              return response;
            })
            , catchError(error => this.handleError(error, 'subscription.save_error'))
          );
        }
      }));
  }

  getCustomerBoxSubscriptionList(customerid: number, fromServer: boolean = false): Observable<BoxSubscription[]> {
    if (!customerid) return of([])

    if (this.boxsubscriptions && this.boxsubscriptions.length > 0 && !fromServer) {
      return of(this.boxsubscriptions);
    }

    return this._http.get(environment.apiserver + 'boxsubscriptionbff/v1/boxsubscription/customerid/' + customerid).pipe(
      map((response: BoxSubscription[]) => {

        response = response.sort((a, b) => {
          if (a.id < b.id) { return 1; }
          if (a.id > b.id) { return -1; }
          return 0;
        });


        response.forEach(rec => {
          rec.boxSubscriptionDeliveries.forEach(del => {
            del.boxSubscriptionProductSelections.sort((a, b) => { return b.id - a.id })
          })
        })
        this.loadSubscriptions(response, Originentitytype.BOX_SUBSCRIPTION);

        this.boxsubscriptions = response;
        this.boxsubscriptionSubject.next(response);
        this.resetTimer();

        return response
      })
      , catchError(error => this.handleError(error, 'subscription.get_subscriptionlist_error'))
    );
  }
  getCustomerBoxSubscription(subscriptionid: number): Observable<BoxSubscription> {
    /*return throwError(() => 'Server error');*/

    return this._http.get(environment.apiserver + 'boxsubscriptionbff/v1/boxsubscription/' + subscriptionid).pipe(
      map((response: BoxSubscription) => {
        return response;
      })
      , catchError(error => this.handleError(error, 'subscription.get_subscription_error'))
    );
  }
  private updateBoxSubscriptionWithLastdata(subscriptionForm: FormGroup, changedValues: any = null): Observable<BoxSubscription> {
    if (subscriptionForm.get('id').value > 0) {
      return this.getCustomerBoxSubscription(subscriptionForm.get('id').value).pipe(
        switchMap((res: BoxSubscription) => {
          if (!changedValues)
            changedValues = this._globalService.getDirtyValues(subscriptionForm);
          res = this._globalService.mergeDirtyValues(changedValues, res);
          return of(res);
        })
      );
    } else {
      return of(subscriptionForm.getRawValue());
    }
  }
  getCustomerBoxSubscriptionDeliverydatesBySubscriptionid(subscriptionid: number): Observable<AvailableDelivery[]> {
    return this._deliverydayService.getBoxSubscriptionDeliverydates(Number(subscriptionid)).pipe(
      map((response: AvailableDelivery[]) => <any>response),
      catchError(error => this.handleError(error, 'subscription.get_deliverydates_error'))
    );
  }
  saveBoxSubscription(subscriptionForm: FormGroup, changedValues: any = null): Observable<BoxSubscription> {

    return this.updateBoxSubscriptionWithLastdata(subscriptionForm, changedValues).pipe(
      switchMap((subscription: BoxSubscription) => {
        if (subscription.boxSubscriptionDeliveries && subscription.boxSubscriptionDeliveries.length > 0) {
          subscription.boxSubscriptionDeliveries.forEach((val) => {
            if (val.deliverydate) {
              val.deliverydate = formatDate(val.deliverydate, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
            }
            if (val.alternatedeliverydate) {
              val.alternatedeliverydate = formatDate(val.alternatedeliverydate, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
            }
          });
        }
        if (subscription.terminationdate)
          subscription.terminationdate = formatDate(subscription.terminationdate, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
        if (subscription.startdate)
          subscription.startdate = formatDate(subscription.startdate, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
        if (subscription.calculatednextdeliverydate)
          subscription.calculatednextdeliverydate = formatDate(subscription.calculatednextdeliverydate, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);

        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: headers };
        const body = JSON.stringify(subscription);

        if (subscription.id > 0) {
          return this._http.put(environment.apiserver + 'boxsubscriptionbff/v1/boxsubscription/' + subscription.id, body, options).pipe(
            map((response: BoxSubscription) => {

              response.boxSubscriptionDeliveries.forEach(del => {
                del.boxSubscriptionProductSelections.sort((a, b) => { return b.id - a.id })
              })

              this.boxsubscriptions[this.boxsubscriptions.map(r => r.id).indexOf(response.id)] = response;
              this.boxsubscriptionSubject.next(this.boxsubscriptions);
              this.loadSubscriptions([response], Originentitytype.BOX_SUBSCRIPTION);
              return response;
            })
            , catchError(error => this.handleError(error, 'subscription.save_error'))
          );
        } else {
          return this._http.post(environment.apiserver + 'boxsubscriptionbff/v1/boxsubscription', body, options).pipe(
            map((response: BoxSubscription) => {

              response.boxSubscriptionDeliveries.forEach(del => {
                del.boxSubscriptionProductSelections.sort((a, b) => { return b.id - a.id })
              })
              this.boxsubscriptions.push(response);
              this.boxsubscriptionSubject.next(this.boxsubscriptions);
              this.loadSubscriptions([response], Originentitytype.BOX_SUBSCRIPTION);
              return response;
            })
            , catchError(error => this.handleError(error, 'subscription.save_error'))
          );
        }
      }));
  }

  loadSubscriptions(subscriptions: any[], entitytype: Originentitytype) {
    if (entitytype === Originentitytype.RECIPE_SUBSCRIPTION) subscriptions as RecipeSubscription[];
    if (entitytype === Originentitytype.BOX_SUBSCRIPTION) subscriptions as BoxSubscription[];

    subscriptions.forEach(s => {

      const existingSubscription = this.getSubscriptions.controls.filter(ctrl => ctrl.get('_originentitytype').value == entitytype && ctrl.get('id').value === s.id)[0] as FormGroup;
      let fg = this.initiateSubscription();

      //updates if subscription already exists
      if (existingSubscription) {
        fg = existingSubscription;
        fg.patchValue(s);
      } else {
        fg.patchValue(s);
      }
      fg.get('_originentitytype').setValue(entitytype);

      switch (entitytype) {
        case Originentitytype.RECIPE_SUBSCRIPTION:
          if (!s.enabled) {
            fg.get('defaultrecipes').disable();
            fg.get('defaultpersons').disable();
          } else {
            fg.get('defaultrecipes').enable();
            fg.get('defaultpersons').enable();

          }
          fg.get('box').disable();
          fg.get('box').clearValidators();
          fg.get('persons').disable();
          fg.get('persons').clearValidators();

          break;
        case Originentitytype.BOX_SUBSCRIPTION:
          if (!s.enabled) {
            fg.get('box').disable();
            fg.get('persons').disable();
          } else {
            fg.get('box').enable();
            fg.get('persons').enable();
          }
          fg.get('defaultrecipes').disable();
          fg.get('defaultrecipes').clearValidators();
          fg.get('defaultpersons').disable();
          fg.get('defaultpersons').clearValidators();
          fg.get('box').setValidators(Validators.required);
          break;
      }

      if (!s.enabled) { fg.disable() }
      if (!existingSubscription)
        this.getSubscriptions.push(fg);
    })

    this.setCouponcodeOnFirstAvailableSubscription();
  }

  setCouponcodeOnFirstAvailableSubscription() {
    //only do anything if there is a couponcode
    if (!this._couponcodeService.couponcode) return;
    this.getSubscriptions.controls.forEach(async s => {
      //only do anything when the subscription is active
      if (!s.get('enabled').value) return

      //checks if the current couponcode is still valid
      if (s.get('activecouponcode').value) {

        const activecouponcodevalid = await this._couponcodeService.checkCouponcode(s.get('activecouponcode').value, s.get('customerid').value, this._customerService.customer?.email, s.get('_originentitytype').value).toPromise()
        if (activecouponcodevalid) {
          this._translate.get(['couponcode.subscription_couponcode_still_active', 'common.ok_dismiss']).subscribe(result => {
          this._toastService.open
(result['couponcode.subscription_couponcode_still_active'], result['common.ok_dismiss'], Snackbarformat);
          })
          this._couponcodeService.removeCouponcode();
          return;
        }
      }
      //checks if the new couponcode in this subscription is valid
      const newcouponcodevalid = await this._couponcodeService.checkCouponcode(this._couponcodeService.couponcode.code, s.get('customerid').value, this._customerService.customer?.email, s.get('_originentitytype').value).toPromise()
      if (!newcouponcodevalid) {
        this._translate.get(['couponcode.couponcode_not_valid_with_this_subscription', 'common.ok_dismiss']).subscribe(result => {
        this._toastService.open
(result['couponcode.couponcode_not_valid_with_this_subscription'], result['common.ok_dismiss'], Snackbarformat);
        })
        this._couponcodeService.removeCouponcode();
        return;
      }

      //sets couponcode if it is valid and usable and saves the subscription
      s.get('activecouponcode').setValue(this._couponcodeService.couponcode.code);
      s.get('activecouponcode').markAsDirty();
      this._couponcodeService.removeCouponcode()
      if (s.get('_originentitytype').value === Originentitytype.RECIPE_SUBSCRIPTION)
        await this.saveRecipeSubscription(s.getRawValue()).toPromise();
      if (s.get('_originentitytype').value === Originentitytype.BOX_SUBSCRIPTION)
        await this.saveBoxSubscription(s.getRawValue()).toPromise();
      this._translate.get(['couponcode.couponcode_added_to_subscription', 'common.ok_dismiss']).subscribe(result => {
      this._toastService.open
(result['couponcode.couponcode_added_to_subscription'], result['common.ok_dismiss'], Snackbarformat);
      })

    })
  }

  get getSubscriptions(): FormArray {
    return this.subscriptions as FormArray;
  }
  private initiateSubscription(): FormGroup {
    const fg = new FormGroup({
      _originentitytype: new FormControl(null, Validators.required),
      id: new FormControl(null),
      enabled: new FormControl(true),
      brandid: new FormControl(environment.brandid, [Validators.required, Validators.min(1)]),
      customerid: new FormControl(null, [Validators.required, Validators.min(1)]),
      deliveryday: new FormControl(null, Validators.required),
      pickupdeliverytimeframe: new FormControl(null),
      storeid: new FormControl(null),
      storetype: new FormControl(null),
      storedata: new FormControl(''),
      shippingmethod: new FormControl(Shippingmethod.DOOR_DELIVERY, Validators.required),
      frequency: new FormControl(Frequency.EVERY_WEEK, Validators.required),
      defaultpersons: new FormControl(environment.personCountDefault, [Validators.required, Validators.min(1), Validators.max(4)]),
      defaultrecipes: new FormControl(environment.mealCountDefault, [Validators.min(1), Validators.max(4)]),
      persons: new FormControl(environment.personCountDefault, [Validators.min(1), Validators.max(4)]),

      deliveryinstructions: new FormControl(''),
      recipecard: new FormControl(false),
      alternateshippingaddress: new FormControl(null),
      alternatebillingaddress: new FormControl(null),
      startdate: new FormControl(null),
      activecouponcode: new FormControl(null),

      calculatednextdeliverydate: new FormControl(null),
      terminationdate: new FormControl(null),
      terminationcategory: new FormControl(null),
      terminationreason: new FormControl(null),

      recipeSubscriptionRepeatableproducts: new FormControl(null),
      recipeSubscriptionDeliveries: new FormControl(null),

      box: new FormControl(null, Validators.required),
      boxSubscriptionRepeatableproducts: new FormControl(null),
      boxSubscriptionDeliveries: new FormControl(null),

    });

    fg.get("_originentitytype").valueChanges.subscribe(res => {
      if (res === Originentitytype.RECIPE_SUBSCRIPTION) {
        fg.get('box').removeValidators(Validators.required);
        fg.get('box').updateValueAndValidity();
      }
    })

    return fg;
  }

  private handleError(error: Response, msgText: string = null) {
    if (msgText)
    this._toastService.open
(this._translate.instant(msgText), null, Snackbarerrorformat)

    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }
}
