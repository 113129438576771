import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import { throws } from 'assert';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../_environments/environment';
import { Entitytype, Redirect } from '../_model/redirect';

// test
@Injectable({
  providedIn: 'root',
})
export class RedirectService {

  environment = environment;

  entitymap: any;

  redirects: Redirect[] = [];
  redirectSubjects: any[] = [];

  language: string;

  enttype = { // to translate strings to enums
    recipe: Entitytype.RECIPE,
    box: Entitytype.BOX,
    page: Entitytype.STATICPAGE,
    product: Entitytype.PRODUCT,
    lifestyle: Entitytype.LIFESTYLE
  }

  private httpClient: HttpClient;

  constructor(
    httpBackend: HttpBackend,
    private router: Router,
    private _translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {

    this.httpClient = new HttpClient(httpBackend); // why ?

    this._translateService.onLangChange.subscribe(value => {
      this.language = value.lang.toUpperCase();
    })

  }

  getRedirectByUrl(search_info): Observable<Redirect> {
    // expect: {"seourl": "string","shorturl": "string"};

    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Brand', this.environment.brandid.toString());

    return this.httpClient.post(environment.apiserver + 'redirect/v1/redirect/url', search_info, { headers: headers }).pipe(
      map((response: Response) => <any>response)
      , catchError(this.handleError)
    );
  }

  getRedirect(redirectid: number): Observable<Redirect> {
    if (!redirectid) return of(null)

    //returns redirects that already where gotten
    let redirect = this.redirects.find(f => f.id === redirectid);
    if (redirect) return of(redirect);

    //returns a subject when it is beeing retrieved
    let subj = this.redirectSubjects.find(f => f.id === redirectid);
    if (subj) return subj.obs;

    let subject = new Subject<Redirect>();

    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Brand', this.environment.brandid.toString());

    let obs = this.httpClient.get(environment.apiserver + 'redirect/v1/redirect/' + redirectid, { headers: headers }).pipe(
      map((response: Redirect) => {
        this.redirects.push(response);
        //fulfills the subject with a response and completes it
        subject.next(response);
        subject.complete();
        //removes the subject from the array
        this.redirectSubjects.splice(this.redirectSubjects.findIndex(f => f.id === redirectid), 1)

        return response;
      })
      , catchError(this.handleError)
    );

    //adds the subject to the array
    let val = { id: redirectid, obs: subject }
    this.redirectSubjects.push(val);

    return obs;

  }

  async getEntityUrl(entity: Entitytype, identifier: number, redirectid: number = null): Promise<string> {
    let url;
    if (redirectid)
      url = await this.getRedirect(redirectid).toPromise();

    if (url) return url.seourl || url.shorturl;

    switch (entity) {
      case Entitytype.RECIPE:
        return '/recipe/id/' + identifier;
      case Entitytype.BOX:
        return '/box/id/' + identifier;
      case Entitytype.PRODUCT:
        return '/product/id/' + identifier;
      case Entitytype.LIFESTYLE:
        return '/lifestyle/id/' + identifier;
      default:
        return null;
    }
  }

  private handleError(error: Response) {

    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');
  }

  //CODE FOR MERGING MULTIPLE SINGLE ID CALLS TOGETHER TO A SINGLE LIST CALL BUT JUST RETURNING SINGLE OBJECTS

  //   //creates new observable if idarray is empty, otherwise get the last one (thats beeing filled at the moment)
  //    let obs: Subject<TaxonomyNode[]>;
  //if (this.objectIdArray.length === 0) {
  //  obs = new Subject<TaxonomyNode[]>();
  //  this._obsArray.push(obs);
  //} else {
  //  obs = this._obsArray[this._obsArray.length];
  //}
  ////adds the id to the array
  //this.objectIdArray.push(id); // store the ID

  ////starts new timeout when there is none
  //if (!this.timeout) {
  //  this.timeout = setTimeout(() => {
  //    //clears timeout and Id array to make room for the next list
  //    this.timeout = null;
  //    const idsToRequest = [...this.objectIdArray];
  //    this.objectIdArray = []; // reset the array
  //    //retrieves the objects as a list
  //    this.getTaxonomynodeList(idsToRequest).subscribe((response: TaxonomyNode[]) => {
  //      //resolves the subject and completes it because it just have to run once
  //      obs.next(response);
  //      obs.complete();
  //    })
  //    //has a timeout of 250ms
  //  }, 250)
  //}

  ////returns last active observable
  //return obs.pipe(
  //  map((response: TaxonomyNode[]) => {
  //    return response.find(data => data.id === id);
  //  }))

}
