import { Component, OnInit, Input } from '@angular/core';
import { CmsService } from 'src/_service/CmsService';
import { Lifestyle } from '../../_model/recipe';
import { LifestyleService } from '../../_service/LifestyleService';
import { LifestylecardComponent } from '../lifestylecard/lifestylecard.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-cms-lifestyle-cards',
    templateUrl: './cms-lifestyle-cards.component.html',
    standalone: true,
    imports: [NgIf, NgFor, LifestylecardComponent]
})
export class CmsLifestyleCardsComponent implements OnInit {

  @Input() card: any;
  
  all_lifestyles: Lifestyle[] = [];

  selectedLifestyle: Lifestyle;

  showLimit = 6;
  showAll = false;
  selected_lifestyles = [];
  
  constructor(
    private _cmsService: CmsService,
    private _lifestyleService: LifestyleService
    ) {
  }


  ngOnInit() {
    if( this.card.max_visible ){
      this.showLimit = Number(this.card.max_visible);
    }    
    this._lifestyleService.getLifestyleList().subscribe(
      result => {
        // console.log(result);
        result = result.sort((a, b) => {
          if (a.sequenceid < b.sequenceid) { return -1; }
          if (a.sequenceid > b.sequenceid) { return 1; }
          return 0;
        });
        this.all_lifestyles = result;
        if( this.card.lifestyles ){
          this.selected_lifestyles = this.card.lifestyles.map(ls_id => this.all_lifestyles.find(ls => ls.id == ls_id)); 
        } else {
          this.selected_lifestyles = this.all_lifestyles;
        }
      }, error => {
        console.log(error);
      }
    );
  }

  // render_rt(content) {
  //   return this._cmsService.render_rt(content);
  // }

  get_alt(field) {
    return this._cmsService.get_alt(field);
  }

  get_sanitized_url(url) {
    return this._cmsService.get_sanitized_url(url);
  }

  get_src(image, contain) {
    return this._cmsService.get_src(image, contain);
  }

  get_classes(card) {
    return this._cmsService.get_classes(card);
  }

}



