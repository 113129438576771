import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';


function getLocalStorage(): Storage {
  return localStorage;
}


@Injectable({ providedIn: 'root' })
export class LocalstorageService {

  private _localStorage: Storage = null;
  private _isInBrowser = false;
  private localstorageSet = new Map<string, string>();
  constructor(
   
    @Inject(PLATFORM_ID) private platformId: any,

  ) {
    if (isPlatformBrowser(this.platformId)) {
      this._isInBrowser = true; 
      this._localStorage = getLocalStorage()
    }
  }

  setItem(key: string, value: string) {
    if (this._isInBrowser)
      this._localStorage.setItem(key, value);
    this.localstorageSet.set(key, value);
  }
  setObject(key: string, value: object) {
    this.setItem(key, JSON.stringify(value))
  }

  getItem(key: string): string {
    if (this._isInBrowser)
      return this._localStorage.getItem(key)
    if (this.localstorageSet.has(key))
      return this.localstorageSet.get(key)
    return null;
  }
  getObject<T = any>(key: string): T {
    const value = this.getItem(key)
    if(value)
      return JSON.parse(value) as T
    return null
  }

  removeItem(key: string) {
    if (this._isInBrowser)
      this._localStorage.removeItem(key)
    this.localstorageSet.delete(key);
  }

  clear() {
    if (this._isInBrowser)
      this._localStorage.clear()
    this.localstorageSet.clear();
  }

}
