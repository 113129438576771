import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { environment } from '../../_environments/environment';
import { CalculatedPrice } from '../../_model/pricing';
import { Allergen, Lifestyle, Nutrient, Recipe, RecipescoreType } from '../../_model/recipe';
import { Entitytype } from '../../_model/redirect';
import { LifestyleService } from '../../_service/LifestyleService';
import { PricingService } from '../../_service/PricingService';
import { RecipeService } from '../../_service/RecipeService';
import { RedirectService } from '../../_service/RedirectService';
import { CapitalizeFirstPipe } from '../pipe/capitalizefirst.pipe';
import { ChipComponent } from '../chip/chip.component';
import { FavoritesComponent } from '../favorites/favorites.component';
import { RouterLink } from '@angular/router';
import { ImageComponent } from '../image/image.component';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, LowerCasePipe, CurrencyPipe, NgClass } from '@angular/common';
import { GlobalService } from '../../_service/GlobalService';
import { PersonselectionComponent } from '../personselection/personselection.component';


export enum RecipecardStatus {
  PROCESSED = 'PROCESSED',
  SELECTABLE = 'SELECTABLE',
  PERSONEDITABLE = 'PERSONEDITABLE',
  ONBOARD = 'ONBOARD'
}


@Component({
  selector: 'recipecard',
  templateUrl: './recipecard.component.html',
  styleUrls: ['./recipecard.component.scss'],
  standalone: true,
  imports: [
    NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, NgClass,
    LowerCasePipe, CurrencyPipe, CapitalizeFirstPipe,
    ImageComponent, FavoritesComponent, ChipComponent, PersonselectionComponent,
    RouterLink, TranslateModule
  ],
})
export class RecipecardComponent implements OnInit, AfterViewInit {

  environment = environment;
  _recipe: any;
  @Input() set recipe(value: Recipe) {
    this._recipe = value;

    //filters non available lifestyles
    if (this.lifestyles)
      this._recipe.lifestyles = this._recipe.lifestyles.filter(l => this.lifestyles.map(s => s.id).includes(l.lifestyleid))

    if (value) {
      this._recipe['recipeurl'] = `/recipe/id/${value.id}`
    }
  }
  @Input() loading: boolean = false;
  @Input() selectedlifestyles: number[] = [];
  @Input() selectedallergens: Allergen[] = [];
  _status: RecipecardStatus
  @Input() set status(s: RecipecardStatus | string) {
    this._status = RecipecardStatus[s]
  }
  @Input() condensed = false;

  @Input() colorscheme = 'contrast';

  scheme = {
    light: 'bg-e-white',
    contrast: 'bg-white'
  }

  _selected: boolean
  @Input() set selected(value: boolean) {
    this._selected = value;
    this.loading = false;
  }
  @Output() selectedChange = new EventEmitter<boolean>();

  @Input() persons: number;
  @Output() personsChange = new EventEmitter<number>();

  @Output() showRecipe = new EventEmitter<Recipe>();

  lifestyles: Lifestyle[];
  disablePersonsSelection = true;
  disableShowRecipeAction = true;
  calculatedPrice: CalculatedPrice;
  vitascores = [];

  constructor(
    public _translate: TranslateService,
    public _recipeService: RecipeService,
    public _lifestyleService: LifestyleService,
    private _redirectService: RedirectService,
    private _pricingService: PricingService,
    private _globalService: GlobalService,
  ) {
  }

  ngOnInit() {

    this._lifestyleService.getLifestyleList().subscribe(result => {
      this.lifestyles = result;
      //filters non available lifestyles
      if (this._recipe)
        this._recipe.lifestyles = this._recipe.lifestyles.filter(l => this.lifestyles.map(s => s.id).includes(l.lifestyleid))
    });

    if (this.personsChange.observers.length > 0) {
      this.disablePersonsSelection = false;
    }

    if (this.showRecipe.observers.length > 0) {
      this.disableShowRecipeAction = false;
    }


  }

  ngAfterViewInit() {
    //makes sure that these calls are done after that the images e.a. are loaded
    if (!this._recipe) return;
    setTimeout(() => {
      this.getCalculatedPrice(this._recipe);
      this._redirectService.getEntityUrl(Entitytype.RECIPE, this._recipe.id, this._recipe.redirectid).then(value => this._recipe['recipeurl'] = value);
    })

  }



  getCalories(recipe: Recipe) {
    if (!recipe) { return; }
    const calories = recipe.nutrients.filter(val => val.name === Nutrient.ENERGY)[0];
    if (calories) {
      return calories.quantity;
    } else {
      return null;
    }
  }
  getScore(scoretype: string) {
    const type = RecipescoreType[scoretype]
    if (!this._recipe || !this._recipe.recipeScores) {
      return { scorerating: "0", scorevalue: "0" }
    };
    const res = this._recipe.recipeScores.filter(r => r.scoretype == type)[0]
    if (res) {
      return res;
    } else {
      return { scorerating: "0", scorevalue: "0" }
    }
  }
  getLifestyle(lifestyleid: number): Lifestyle {
    if (!this.lifestyles) { return null };
    const lifestyle = this.lifestyles.filter(val => val.id === lifestyleid)[0];
    if (lifestyle) {
      return lifestyle;
    }
    return null;

  }

  fitsInLifestyle(recipe: Recipe): boolean {
    if (!this.selectedlifestyles || !recipe) {
      return false;
    }
    // checks if the userlifestyles are in the recipelifestyle list
    let overlap = 0;
    this.selectedlifestyles.forEach(l => {
      if (recipe.lifestyles.map(v => v.lifestyleid).indexOf(l) >= 0) {
        overlap++;
      }
    });
    return overlap > 0;
  }

  getCalculatedPrice(recipe: Recipe) {
    if (!recipe) return;
    if (!recipe.salessurcharge) { this.calculatedPrice = null; return; }
    this._pricingService.calculateSingleprice(Number(recipe.salessurcharge), false, 1).subscribe(
      result => {
        this.calculatedPrice = result
      }, error => {
        console.log(error);
      }
    )
  }
  overlappingAllergens(): string[] {
    if (!this._recipe || !this._recipe.allergens || !this.selectedallergens) {
      return [];
    }
    return this._recipe.allergens.filter(a => this.selectedallergens.includes(a.name)).map(a => a.name);
  }
  changePersons(personsqty) {
    this.persons = personsqty;
    this.personsChange.emit(this.persons);
    //if (this.persons + qty > this._globalService.minSubscriptionPersons && this.persons + qty < this._globalService.maxSubscriptionPersons) {
    //  this.persons += qty;
    //  this.personsChange.emit(this.persons);
    //}

  }
  toggleSelection() {
    this.loading = true;
    this.selectedChange.emit(!this._selected);
  }
  showRecipeAction() {
    this.showRecipe.emit(this._recipe);
  }



}
