export class Popup{
    constructor(
        public _uid: string,
        public _editable:any,
        public title: any,
        public size: string,
        public bgcolor:string,
        public sections: Section[],
        public closeconfig: PopupCloseConfig,
        public audience: PopupAudience
    ){}
}
export enum PopupCloseConfig{
    ALL = "ALL",
    CLOSEBUTTON = "CLOSEBUTTON",
    DISMISSABLE = "DISMISSABLE"
}
export enum PopupAudience{
    EVERYONE = "EVERYONE",
    CUSTOMERS = "CUSTOMERS",
    NON_CUSTOMERS = "NON_CUSTOMERS"
}

export class Content{
    constructor(
        public _uid: string,
        public _editable:any,
        public sections: Section[],
        public component:string,

    ){}
}

export class Section{
    constructor (
        public _uid: string,
        public _editable:string,
        public body: any[],
        public title: any,
        public subtitle:any,
        public width: string,
        public layout: string,
        public bgcolor: string,
        public rounded: boolean,
        public blocks: any[],
        public anchor: string,
        public sticker:any,
        public component: string,
        public condensed: boolean,
        public main_image: any,
        public main_image_bgcolor: string,
        public main_image_contain: boolean
    ){}
}
