<ng-template #persons>
  <span>{{doublepersons? _persons * 2 : _persons}}</span>
  <ng-content></ng-content>
</ng-template>

<div class="flex flex-row bg-e-white rounded-full h-full w-full" [ngClass]="colorscheme">
  <ng-container *ngIf="editable">
    <button class="disabled:opacity-25 w-1/4 my-auto" [ngClass]="{'h-[35px]': !_small, 'h-[26px]': _small}" [disabled]="_persons === minpersons" (click)="changePersonQty(-1)" id="minuspersonqty">
      <i class="bi bi-dash text-xl" [ngClass]="{'leading-[35px]': !_small, 'leading-[26px]': _small}"></i>
    </button>
    <small class="flex gap-1 flex-row flex-nowrap justify-center w-1/2 my-auto" [ngClass]="{'leading-[35px]': !_small, 'leading-[26px]': _small}">
      <ng-container *ngTemplateOutlet="persons"></ng-container>
    </small>
    <button class="disabled:opacity-25 w-1/4 my-auto" [ngClass]="{'h-[35px]': !_small, 'h-[26px]': _small}" [disabled]="_persons === maxpersons" (click)="changePersonQty(1)" id="pluspersonqty">
      <i class="bi bi-plus text-xl" [ngClass]="{'leading-[35px]': !_small, 'leading-[26px]': _small}"></i>
    </button>
  </ng-container>
  <ng-container *ngIf="!editable">
    <small class="flex gap-1 flex-row flex-nowrap justify-center w-full my-auto" [ngClass]="{'leading-[35px]': !_small, 'leading-[26px]': _small}">
      <ng-container *ngTemplateOutlet="persons"></ng-container>
    </small>
  </ng-container>
</div>
