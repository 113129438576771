import { CurrencyPipe, NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../_environments/environment';
import { Recipe } from '../../_model/recipe';
import { ColorScheme } from '../extensions/colorscheme';
import { ImageComponent } from '../image/image.component';
import { PersonselectionComponent } from '../personselection/personselection.component';
import { PricetagComponent } from '../pricetag/pricetag.component';

export enum Layout {
  NORMAL = 'NORMAL', 
  CONDENSED = 'CONDENSED', //replacement of the condensed input
  ORDERFLOW = 'ORDERFLOW' //for the new design in the orderflow module
}


@Component({
    selector: 'recipeline',
    templateUrl: './recipeline.component.html',
    styleUrls: ['./recipeline.component.scss'],
    standalone: true,
  imports: [
    NgIf, NgClass, NgSwitch, NgSwitchCase,
    CurrencyPipe,
    ImageComponent, PricetagComponent, PersonselectionComponent,
    TranslateModule
  ]
})
export class RecipelineComponent extends ColorScheme implements OnInit {

  environment = environment;

  _line: any;
  @Input() set line(value: any) { //it has to be an any because it is possible to be a customercreateorderline or a recipesubscriptionproductselection
    if (value) {
      this._line = value;
      if (value.object)
        this._line['recipe'] = value.object
    }
  }
  /*  @Input() condensed = false;*/
  condensed = false;

  private _layout = Layout.NORMAL
  @Input() set layout(value: Layout | string) {
    if (value)
      this._layout = Layout[value]
  }
  get layout(): Layout {
    return this._layout
  }

  @Output() removeline = new EventEmitter<Recipe>();
  @Output() personsChange = new EventEmitter<number>();

  editable: boolean;
  removable: boolean;

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    this.editable = this.personsChange.observers.length > 0
    this.removable = this.removeline.observers.length > 0
  }

  hasError = (formGroup: FormGroup, controlName: string, errorName: string) => {
    return formGroup.get(controlName).hasError(errorName);
  }

  changePersonQty(qty = 0) {
    this._line.persons = qty;
    this.personsChange.emit(qty);

  }

  removeRecipeline() {
    this.removeline.emit(this._line.recipe)
  }


}
