import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { WINDOW } from './WindowService';
import { Language } from '../_model/shared';
import { GlobalService } from './GlobalService';

export interface SeoSocialShareData {
  title?: string;
  keywords?: string;
  description?: string;
  image?: string;
  url?: string;
  type?: string;
  author?: string;
  section?: string;
  published?: string;
  modified?: string;
}
declare interface Property {
  name: string,
  value: string
}

export interface JsonLd {
  [param: string]: string | Object;
}

@Injectable()
export class LinkService {

  private metatags: HTMLMetaElement[] = [];

  base_url: string;
  base_nl: string;
  base_be: string;

  constructor(
    private _rendererFactory: RendererFactory2,
    private _metaService: Meta,
    private _translate: TranslateService,
    private _globalService: GlobalService,
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
  ) {

    this.base_url = isPlatformBrowser(this.platformId) && !!this.window.location ? this.window.location.origin : 'https://www.ekomenu.nl';
    const url_parts = this.base_url.split('.');
    this.base_nl = url_parts.length > 1 ? url_parts.slice(0, -1).join('.') + '.nl' : this.base_url;
    this.base_be = url_parts.length > 1 ? url_parts.slice(0, -1).join('.') + '.be' : this.base_url;

  }

  /**
   * Inject the State into the bottom of the <head>
   */
  createLinkTag(lang: Language, url: string, ishome = false, is404 = false) {
    //it shouldnt prerender any tags on the homepage when not in browser
    if ((ishome || is404) && !isPlatformBrowser(this.platformId)) return;

    // sets the countrycode based on the tld
    const path = url.startsWith('/') ? url : '/' + url;

    //removes the /nl/ part and returns a empty string when is home
    const defaultpath = ishome ? '' : path.replace(/^\/nl\//, '/')

    switch (String(lang).toUpperCase()) {
      case 'NL':
      case 'NL-BE':
        this.addLinkTag({ rel: 'alternate', hreflang: 'x-default', href: this.base_nl + defaultpath }, true)
        this.addLinkTag({ rel: 'alternate', hreflang: 'nl-nl', href: this.base_nl + path }, true)
        this.addLinkTag({ rel: 'alternate', hreflang: 'nl-be', href: this.base_be + path }, true)
        break;
      case 'FR':
      case 'FR-BE':
        this.addLinkTag({ rel: 'alternate', hreflang: 'fr-nl', href: this.base_nl + path }, true)
        this.addLinkTag({ rel: 'alternate', hreflang: 'fr-be', href: this.base_be + path }, true)
        break;
      case 'EN':
        this.addLinkTag({ rel: 'alternate', hreflang: 'en-nl', href: this.base_nl + path }, true)
        this.addLinkTag({ rel: 'alternate', hreflang: 'en-be', href: this.base_be + path }, true)
        break;

    }

  }
  addLinkTag(tag: LinkDefinition, atstartofelement: boolean = false) {

    //if (isPlatformServer(this.platformId)) return;

    try {

      const head = this.document.head;
      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }

      let props: Property[] = []
      Object.keys(tag).forEach((prop: string) => {
        props.push({ name: prop, value: tag[prop] })
      });

      this.addElement("link", props, head, null, atstartofelement)

    } catch (e) {
      console.error('Error within linkService : ', e);
    }
  }
  removeLinkTag(attrSelector: string) {
    if (attrSelector) {
      try {


        const head = this.document.head;
        if (head === null) {
          throw new Error('<head> not found within DOCUMENT.');
        }
        this.removeElements('link[' + attrSelector + ']', head)
      } catch (e) {
        console.log('Error while removing tag ' + e.message);
      }
    }
  }

  getJsonLdObject(type: string, object: JsonLd, context: string = "http://schema.org"): JsonLd {
    object = Object.assign({ "@type": type }, object)
    object = Object.assign({ "@context": context }, object)

    return object
  }
  setJsonLdData(data: JsonLd) {
    try {


      const head = this.document.head;
      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }
      this.removeElements('script[type="application/ld+json"]', head)
    } catch (e) {
      console.log('Error while removing tag ' + e.message);
    }
    try {

      const head = this.document.head;
      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }

      let props = [{ name: "type", value: "application/ld+json" }]
      this.addElement("script", props, head, JSON.stringify(data), false)

    } catch (e) {
      console.error('Error within linkService : ', e);
    }
  }

  setMetaData(data: SeoSocialShareData) {

    if (data.title) {
      let title: MetaDefinition[] = [
        { name: "twitter:title", content: data.title },
        { name: "twitter:image:alt", content: data.title },
        { property: "og:title", content: data.title },
        { name: "title", content: data.title },
        { itemprop: "name", content: data.title }
      ]
      this.metatags = this.metatags.concat(this._metaService.addTags(title))
    }
    if (data.description) {
      let description: MetaDefinition[] = [
        { name: "twitter:description", content: data.description },
        { property: "og:description", content: data.description },
        { name: "description", content: data.description },
      ]
      this.metatags = this.metatags.concat(this._metaService.addTags(description))
    }

    if (data.image) {
      let image: MetaDefinition[] = [
        { name: "twitter:image", content: data.image },
        { property: "og:image", content: data.image },
        { itemprop: "image", content: data.image },
      ]
      this.metatags = this.metatags.concat(this._metaService.addTags(image))
    }
    if (data.url) {
      let url: MetaDefinition[] = [
        { property: "og:url", content: data.url },
      ]
      this.metatags = this.metatags.concat(this._metaService.addTags(url))
    }
  }
  removeMetaData() {
    this.metatags.forEach(t => {
      this._metaService.removeTagElement(t);
    })
    this.metatags = [];

    this.removeMetaTag('name="twitter:title"');
    this.removeMetaTag('name="twitter:image:alt"');
    this.removeMetaTag('property="og:title"');
    this.removeMetaTag('name="title"');
    this.removeMetaTag('itemprop="name"');

    this.removeMetaTag('name="twitter:description"');
    this.removeMetaTag('property="og:description"');
    this.removeMetaTag('name="description"');

    this.removeMetaTag('name="twitter:image"');
    this.removeMetaTag('property="og:image"');
    this.removeMetaTag('itemprop="image"');

    this.removeMetaTag('property="og:url"');
  }
  removeMetaTag(attrSelector: string) {
    if (attrSelector) {
      try {


        const head = this.document.head;
        if (head === null) {
          throw new Error('<head> not found within DOCUMENT.');
        }
        this.removeElements('meta[' + attrSelector + ']', head)
      } catch (e) {
        console.log('Error while removing tag ' + e.message);
      }
    }
  }

  private addElement(elementtype: string, properties: Property[], inElement: any, innerHtml: string = null, atStartOfElement = false) {

    const renderer = this.getRenderer()
    const element = renderer.createElement(elementtype);

    properties.forEach(prop => {
      return renderer.setAttribute(element, prop.name, prop.value)
    })

    if (innerHtml) {
      const text = renderer.createText(innerHtml);
      renderer.appendChild(element, text);

    }
    // [TODO]: get them to update the existing one (if it exists) ?
    if (atStartOfElement) {
      renderer.insertBefore(inElement, element, inElement.childNodes[0]);
    } else {
      renderer.appendChild(inElement, element);
    }

  }
  private removeElements(querySelector: string, inElement: any) {
    const renderer = this.getRenderer();
    const linkTags = this.document.querySelectorAll(querySelector);
    for (const link of linkTags) {
      renderer.removeChild(inElement, link);
    }
  }
  private getRenderer(): Renderer2 {
    return this._rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {}
    });
  }


  setTranslatedStaticpageSeoData(translateTitle: string, translateDescription: string, imageurl: string, translateUrl: string = null) {
    this._translate.get([translateTitle, translateDescription]).subscribe(res => {
      this.setStaticpageSeoData(res[translateTitle], res[translateDescription], imageurl, translateUrl)

    })
  }
  setStaticpageSeoData(title: string, description: string, imageurl: string, translateUrl: string = null) {
    title = title || ""
    description = description || "";

    const defaultpath = this.window.location.pathname;
    const page_url = this.base_url + defaultpath;
    this.addLinkTag({ rel: 'canonical', href: page_url }, true)
    if (!translateUrl)
      this.addLinkTag({ rel: 'alternate', hreflang: 'x-default', href: this.base_nl + defaultpath }, true)

    if (translateUrl) {

      //makes sure that the current lang is the last to make sure webpage translations are not showing the wrong translations (/biologischewebwinkel)
      let langs = this._translate.langs.filter(l => l !== this._globalService.getLanguage())
      langs.push(this._globalService.getLanguage())

      const fork = [];
      langs.forEach(lang => {
        fork.push(this._translate.getTranslation(lang))
      })
      forkJoin(fork).subscribe(res => {
        this._translate.langs.forEach(lang => {
          const translatedValue = this._translate.getParsedResult(this._translate.translations[lang], translateUrl)

          if (lang === 'nl')
            this.addLinkTag({ rel: 'alternate', hreflang: 'x-default', href: this.base_nl + '/' + translatedValue }, true)

          this.addLinkTag({ rel: 'alternate', hreflang: lang + '-nl', href: this.base_nl + '/' + translatedValue }, true)
          this.addLinkTag({ rel: 'alternate', hreflang: lang + '-be', href: this.base_be + '/' + translatedValue }, true)

        })
      })
    }

    const image_url = imageurl ? this.base_url + imageurl : ""

    const seoData: SeoSocialShareData = {
      title: title.slice(0, 70),
      description: description.slice(0, 200),
      url: page_url,
      image: image_url
    };
    this.setMetaData(seoData);

    // all object have the samen author
    const author_obj = this.getJsonLdObject('Organisation', { name: 'Ekomenu' });

    const image_obj = this.getJsonLdObject('ImageObject', {
      url: image_url,
      contentUrl: image_url,
    })
    // we may add the language
    const lang_obj = this.getJsonLdObject('Language', {
      name: this._translate.currentLang,
      alternateName: this._translate.currentLang
    });

    // https://schema.org/WebPage
    const jsonLdObject = this.getJsonLdObject('WebPage', {
      image: image_obj, // may be null !?
      url: page_url,
      author: author_obj,
      mainContentOfPage: description,
      isAccessibleForFree: true,
      inLanguage: lang_obj,
      name: title,
    });
    if (!!image_obj) {
      jsonLdObject.primaryImageOfPage = image_obj;
    }
    this.setJsonLdData(jsonLdObject);
  }
}

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};

