import { DatePipe, JsonPipe, NgClass, NgIf } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Address } from '../../_model/address';
import { Deliveryday, PostalCodeDelivery, PostalCodeWeekDayTimeFrame, Store, Storetype } from '../../_model/postalcodedelivery';
import { StoreService } from '../../_service/StoreService';
import { AvailableDelivery } from '../../_model/weeks';
import { CapitalizeFirstPipe } from '../pipe/capitalizefirst.pipe';
import { LocalizedDatePipe } from '../pipe/localizedDate.pipe';

@Component({
    selector: 'store',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.scss'],
    standalone: true,
  imports: [
    NgIf, NgClass,
    DatePipe, LocalizedDatePipe, JsonPipe, CapitalizeFirstPipe,
    TranslateModule]
})
export class StoreComponent implements OnDestroy {

  @Input() store: Store;
  @Input() selected: boolean;
  @Input() deliveryday: Deliveryday
  @Input() timeframe: PostalCodeWeekDayTimeFrame
  @Input() deliverydate: AvailableDelivery

  private _storeid: string
  @Input() set storeid(value: string) {
    this._storeid = value;
    this.getStore();
  }
  private _storetype: Storetype
  @Input() set storetype(value: Storetype) {
    this._storetype = value;
    this.getStore();
  }
  private _address: Address
  @Input() set address(value: Address) {
    this._address = value;
    this.getStore();
  }

  private unsubscribe = new Subject<void>();

  constructor(
    private _storeService: StoreService
  ) {
  }

  ngOnDestroy() {
    this.unsubscribe.next()
  }

  public hasError = (formGroup: FormGroup, controlName: string, errorName: string) => {
    return formGroup.controls[controlName].hasError(errorName);
  }

  getStore() {
    if (this._storeid && this._storetype && this._address) {
      this._storeService.getStore(Number(this._storeid), this._storetype, this._address.countrycode, this._address.postalcode, Number(this._address.number)).subscribe(result => {
        this.store = result;
      })
    } else {
      this.store = null;
    }
  }

}
