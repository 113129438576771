import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { AggregatedCustomerRating, CustomerRating } from '../_model/customerrating';
import { Entitytype } from '../_model/redirect';
import { UrlParameters } from '../_model/shared';
import { AuthService } from './AuthService';

@Injectable()
export class CustomerRatingService {


  ratings: CustomerRating[] = [];
  public customerRatingsSubject: ReplaySubject<CustomerRating[]> = new ReplaySubject<CustomerRating[]>(1);

  constructor(
    private _http: HttpClient,
    private _authenticationService: AuthService,
  ) {

    this._authenticationService.isAuthenticatedSubject.subscribe(
      next => {
        // gets and sets or removes customer based on authenticationstatus
        if (!next) { this.ratings = []; }
        else {
          if (this._authenticationService.getCurrentUser().customerid)
            this.getCustomerratings(this._authenticationService.getCurrentUser().customerid).subscribe(result => {

            })
        }
      },
      error => { },
      () => {
        this.ratings = [];
      }
    );
  }


  getCustomerratings(customerid: number): Observable<CustomerRating[]> {
    //const address = this.addresses.filter(a => a.id === id)[0];
    if (this.ratings && this.ratings.length > 0) {
      return of(this.ratings);
    }

    return this._http.get(environment.apiserver + 'customerrating/v1/customer/' + customerid + '/customerratings').pipe(
      map((response: CustomerRating[]) => {
        this.ratings = this.ratings.concat(response);
        this.customerRatingsSubject.next(this.ratings);
        return response as CustomerRating[];
      })
      , catchError(this.handleError)
    );
  }

  getCustomerrating(entitytype: Entitytype, entityid: number): CustomerRating {
    const r = this.ratings.filter(r => r.originentitytype === entitytype && r.originentityid == entityid)[0];
    return r
  }

  addCustomerrating(rating: CustomerRating): Observable<CustomerRating> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    const body = JSON.stringify(rating);

    return this._http.post(environment.apiserver + 'customerrating/v1/customer/rating', body, options).pipe(
      map((response: any) => {
        this.ratings.push(rating);
        this.customerRatingsSubject.next(this.ratings);
        return response
      })
      , catchError(this.handleError)
    );
  }

  getAggregatedRating(entitytype: Entitytype, entityid: number): Observable<AggregatedCustomerRating> {

    if (!entitytype || (entitytype && ![Entitytype.BOX, Entitytype.PRODUCT, Entitytype.RECIPE].includes(entitytype)) || !entityid) return of(null);

    const params = new UrlParameters();
    params.addParameter('originentitytype', entitytype)
    params.addParameter('originentityid', entityid.toString())

    return this._http.get(environment.apiserver + 'customerrating/v1/aggregated' + params.toString()).pipe(
      map((response: Response) => <any>response)
      , catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');
  }

}
