import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { GlobalService } from '../../../_service/GlobalService';
import { PricingService } from '../../../_service/PricingService';
import { BaseorderlineComponent } from '../baseorderline.component';
import { LocalizedDatePipe } from '../../pipe/localizedDate.pipe';
import { PricetagComponent } from '../../pricetag/pricetag.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToggleComponent } from '../../toggle/toggle.component';
import { ImageComponent } from '../../image/image.component';
import { NgClass, NgIf } from '@angular/common';
import { QuantityselectionComponent } from '../../quantityselection/quantityselection.component';
import { PersonselectionComponent } from '../../personselection/personselection.component';

@Component({
    selector: 'boxline',
    templateUrl: './boxline.component.html',
    styleUrls: ['./boxline.component.scss'],
  standalone: true,
  imports: [NgClass, ImageComponent, NgIf, ToggleComponent, ReactiveFormsModule, PricetagComponent, TranslateModule, LocalizedDatePipe, QuantityselectionComponent, PersonselectionComponent]
})
export class BoxlineComponent extends BaseorderlineComponent implements OnInit, OnDestroy {

  //line becomes orderline (orderline in basecomponent migrates to any)
  //repeatable will be removed, it will be set and updated in the orderlines
  //repeatableChange will become repeatalbeproductschange which returns the list of subscriptionrepeatableproducts
  //box or product have to be set as a input variable

  @Input() showPrice: boolean = true;
  @Input() condensed = false;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public _translate: TranslateService,
    public _pricingService: PricingService,
    public _globalService: GlobalService,
  ) {
    super(route, router, _translate, _pricingService, _globalService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }


}
