import { Component, Input, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [NgClass, ReactiveFormsModule, FormsModule]
})
export class ToggleComponent implements ControlValueAccessor {

  _value = false;
  @Input() set value(value) {
    this._value = value;
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }

  @Input() reversed = false;
  @Input() invertvalue = false


  onChange: any = () => { };
  onTouched: any = () => { };

  _disabled = false;

  get currentValue() {
    return this._value;
  }

  set currentValue(val) {
    this._value = val;
    // console.log(this._value);
    this.onChange(!this.invertvalue ? val : !val);
    this.onTouched();
  }

  constructor() { }

  registerOnChange(fn) {
    this.onChange = fn;
  }
  writeValue(value) {

    if (value !== null && value !== undefined && this.value !== value) {

      this.value = !this.invertvalue ? value : !value; 
    }
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled
  }

  switch() {
    if (!this._disabled)
      //this.value = !this.value;
      this.currentValue = this._value;
  }
}
