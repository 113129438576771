import {Allergen, Nutrient} from './recipe';

export class Product {
  constructor(
    public id: number,
    public name: string,
    public sku: string,
    public enabled: boolean,
    public organic: boolean,
    public brandid: number,
    public category: Category,
    public expiredays: number,
    public websiteimageurl: string,
    public websitethumbimageurl: string,
    // webshop
    public shopunit: Unit,
    public shopquantity: number,
    public ean: string,
    public description: string,
    public shortdescription: string,
    public title: string,
    public subtitle: string,
    public repeatable: boolean,
    public availableforwebshop: boolean,
    public discountpercentage: number,
    public discountprice: number,
    public activefrom: any,
    public activeto: any,
    public consumerprice: number,
    public shopsellprice: number,
    public vattarifhigh: boolean,
    public redirectid: number,
    public nutrients: ProductNutrient[],
    public allergens: ProductAllergen[],
  ) {
  }
}

export enum Category {
  BROOD = 'BROOD',
  DKW = 'DKW',
  KDV = 'KDV',
  VLEES_EN_VIS = 'VLEES_EN_VIS',
  AGF = 'AGF',
  OVERIGEN = 'OVERIGEN'
}

export class ProductAllergen {
  constructor(
    public name: Allergen
  ) {
  }
}

export class ProductNutrient {
  constructor(
    public name: Nutrient,
    public unit: Unit,
    public quantity: number
  ) {
  }
}

export class ProductPrice {
  constructor(
    public id: number,
    public productid: number,
    public buyprice: number,
    public discountpercentage: number,
    public discountprice: number,
    public productsupplierinfoid: number,
    public activefrom: string, // has to be string to be compatible with new Date()
    public activeto: string,
    public consumerprice: number,
    public shopsellprice: number,
    public vattarifhigh: boolean,
    public margin: number,
  ) {
  }
}

export enum Unit {
  PIECE = 'PIECE',
  MILIGRAM = 'MILIGRAM',
  GRAM = 'GRAM',
  KILOGRAM = 'KILOGRAM',
  MILILITER = 'MILILITER',
  LITER = 'LITER',
  BUSHEL = 'BUSHEL',
  KILOCALORIES = 'KILOCALORIES',
  TABLESPOON = 'TABLESPOON',
  TEASPOON = 'TEASPOON',
  TRAY = 'TRAY',
  PINCH = 'PINCH',
  STEM = 'STEM',
  UNKNOWN = 'UNKNOWN',
  IRRELEVANT = 'IRRELEVANT'
}
