<div class="videowrapper">
  <iframe *ngIf="code"
          [src]="safeSrc"
          title="{{title}}"
          height="{{height}}"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
  </iframe>
</div>
