import { formatDate, NgFor, NgIf, NgClass, SlicePipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ɵDEFAULT_LOCALE_ID } from '@angular/core';


@Component({
    selector: 'calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['calendar.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, NgClass, SlicePipe, DatePipe]
})
export class CalendarComponent implements OnInit {


  @Input() activedates: Date[];
  @Input() class: string;
  @Input() dateFilter: (date: Date) => any;
  @Output() dateclicked = new EventEmitter<any>();


  today: any;
  calendardays: any[] = [];
  enabled_calendardays: number = 0;
  currentMonth: number;
  currentYear: number;

  firstDayOfWeek = 6; // 0 for sunday, 6 for monday

  constructor() {
  }

  ngOnInit() {
    this.currentMonth = new Date().getUTCMonth();
    this.currentYear = new Date().getUTCFullYear();

    this.today = new Date(Date.UTC(this.currentYear, this.currentMonth, new Date().getUTCDate()));
    this.getMonthDates(this.today);

    // if datafilter has no dates to will return undefined i.s.o true / false ??
    // if ( !!this.dateFilter && this.dateFilter(this.today) !== undefined){

    if (!!this.dateFilter) {

      if (this.enabled_calendardays < 1) {
        let i = 0;

        do {
          // goto first month that has day you can realy select a day!
          this.nextMonth();
          i++;
        } while (this.enabled_calendardays < 1 && i <= 20)

        if (this.enabled_calendardays == 0) {
          this.currentMonth = new Date().getUTCMonth();
          this.currentYear = new Date().getUTCFullYear();

          this.today = new Date(Date.UTC(this.currentYear, this.currentMonth, new Date().getUTCDate()));
          this.getMonthDates(this.today);
        }

      }
    }
  }

  allowPreviousMonth() {
    const now = new Date();
    return now.getUTCMonth() >= this.currentMonth && now.getUTCFullYear() >= this.currentYear;
  }

  previousMonth() {
    const d = new Date(Date.UTC(this.currentYear, this.currentMonth, 1));
    d.setDate(d.getUTCDate() - 15);

    this.currentMonth = d.getUTCMonth();
    this.currentYear = d.getUTCFullYear();
    this.getMonthDates(d);
  }

  nextMonth() {
    const d = new Date(Date.UTC(this.currentYear, this.currentMonth, 1));
    d.setDate(d.getUTCDate() + 45);
    this.currentMonth = d.getUTCMonth();
    this.currentYear = d.getUTCFullYear();
    this.getMonthDates(d);
  }

  getMonthDates(refDate: Date) {

    const year = refDate.getFullYear();
    const month = refDate.getMonth();

    const firstdateOfMonth = new Date(Date.UTC(year, month, 1));
    const firstCalendarDate = new Date(Date.UTC(year, month, 1));
    // makes sure it has the first day of the month and doesnt show a empty row
    // firstCalendarDate.setDate(firstdateOfMonth.getDate() - (firstdateOfMonth.getDay() + this.firstDayOfWeek))
    if (firstdateOfMonth.getDay() + this.firstDayOfWeek === 7) {
      firstCalendarDate.setDate(firstdateOfMonth.getDate());
    } else if (firstdateOfMonth.getDay() + this.firstDayOfWeek > 7) {
      firstCalendarDate.setDate(firstdateOfMonth.getDate() - (firstdateOfMonth.getDay() - (7 - this.firstDayOfWeek)));
    } else {
      firstCalendarDate.setDate(firstdateOfMonth.getDate() - (firstdateOfMonth.getDay() + this.firstDayOfWeek));
    }

    const lastdateOfMonth = new Date(Date.UTC(year, month, this.getDaysInMonth(year, month)));
    const lastCalendarDate = new Date(Date.UTC(year, month, 1));
    lastCalendarDate.setDate(lastdateOfMonth.getDate() + (7 - lastdateOfMonth.getDay() + this.firstDayOfWeek));

    let date = firstCalendarDate;

    this.enabled_calendardays = 0;
    this.calendardays = [];

    while (date <= lastCalendarDate) {
      let date_info = {
        date: formatDate(date, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID),
        isavailable: false,
        ismoved: false,
        istoday: this.isToday(date),
        month: date.getMonth(),
        formatted_day: formatDate(date, 'dd', ɵDEFAULT_LOCALE_ID),
        day_name: formatDate(date, 'E', ɵDEFAULT_LOCALE_ID),
        month_name: formatDate(date, 'MMMM yyyy', ɵDEFAULT_LOCALE_ID),

        date_obj: new Date(date)
      }
      if (!!this.dateFilter && !!this.dateFilter(date)) {
        // add info we know to our info object
        date_info = { ...date_info, isavailable: true, ...this.dateFilter(date) };
        if (!!date_info.isavailable && date_info.month == month) {
          this.enabled_calendardays++;
        }
      }
      this.calendardays.push(date_info);
      // this.calendardays.push(new Date(date));
      date = this.dateoffset(date, 1);
    }
    //console.debug( this.enabled_calendardays);
  }

  dateoffset(date, days): Date {
    const startdate = new Date(date.valueOf());
    startdate.setDate(startdate.getDate() + days);
    return startdate;
  }

  getDaysInMonth(year: number, month: number) {
    return new Date(year, month + 1, 0).getDate();
  }

  isActivedate(date_info) {
    if (this.activedates && this.activedates.length > 0) {
      const look_for = !!date_info.originaldate ? date_info.originaldate : date_info.date;
      return this.activedates.indexOf(look_for) >= 0;
    } else {
      return false;
    }
  }

  isToday(date) {
    if (date instanceof Date) {
      date = formatDate(date, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
    }
    return formatDate(this.today, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID) === date;
  }

  dateClicked(date_info: any) {
    if (!!date_info.isavailable && !date_info.isdisabled) {
      // this.dateclicked.emit(formatDate(date, 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID));
      this.dateclicked.emit(!!date_info.originaldate ? date_info.originaldate : date_info.date); // expects a formatted date string 
    }
  }

  // isDisabledDate(date: Date): boolean {
  //   if (this.dateFilter) {
  //     const allowed = this.dateFilter(date);
  //     return !allowed;
  //   }
  //   return false;
  // }


}
