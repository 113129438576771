import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { Box } from '../../../_model/box';
import { Entitytype } from '../../../_model/redirect';
import { GlobalService } from '../../../_service/GlobalService';
import { PricingService } from '../../../_service/PricingService';
import { RedirectService } from '../../../_service/RedirectService';
import { BaseorderlineComponent } from '../baseorderline.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToggleComponent } from '../../toggle/toggle.component';
import { PricetagComponent } from '../../pricetag/pricetag.component';
import { ImageComponent } from '../../image/image.component';
import { NgIf, CurrencyPipe } from '@angular/common';
import { PersonselectionComponent } from '../../personselection/personselection.component';
import { QuantityselectionComponent } from '../../quantityselection/quantityselection.component';



@Component({
    selector: 'boxcard',
    templateUrl: './boxcard.component.html',
    styleUrls: ['./boxcard.component.scss'],
  standalone: true,
  imports: [NgIf, ImageComponent, RouterLink, PricetagComponent, ToggleComponent, ReactiveFormsModule, CurrencyPipe, TranslateModule, PersonselectionComponent, QuantityselectionComponent]
})
export class BoxcardComponent extends BaseorderlineComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() showBox = new EventEmitter<Box>();
  disableShowAction: boolean;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public _translate: TranslateService,
    public _pricingService: PricingService,
    public _globalService: GlobalService,
    private _redirectService: RedirectService,
  ) {
    super(route, router, _translate, _pricingService, _globalService);
    this.loadingsubject.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this._box && !this._box['boxurl'])
        this._box['boxurl'] = `/box/id/${this._box.id}`
    })
  }

  ngOnInit(): void {
    super.ngOnInit();
   
    this.disableShowAction = this.showBox.observers.length === 0;

  }
  ngAfterViewInit() {
    //makes sure that these calls are done after that the images e.a. are loaded
    if (!this._box) return;
    setTimeout(() => {
      this._redirectService.getEntityUrl(Entitytype.BOX, this._box?.id).then(value => this._box['boxurl'] = value);
    })
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  showBoxAction() {
    this.showBox.emit(this._box);
  }
}
