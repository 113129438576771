import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Originentitytype } from '../../_model/subscription';
import { CouponcodeService } from '../../_service/CouponcodeService';
import { collapse, collapseTrigger, horCollapse, slideInOut } from '../../_theme/animations';


export enum CouponcodeInputDesign {
  OLD = "OLD",
  NEWORDERFLOW = "NEWORDERFLOW"
}

@Component({
    selector: 'couponcode',
    templateUrl: './couponcode.component.html',
    styleUrls: ['./couponcode.component.scss'],
    animations: [slideInOut, collapse, collapseTrigger, horCollapse],
    standalone: true,
    imports: [NgIf, NgClass, ReactiveFormsModule, TranslateModule]
})
export class CouponcodeComponent implements OnDestroy {

  _originentitytype: Originentitytype
  @Input() set originentitytype(value: Originentitytype | string) {
    this._originentitytype = Originentitytype[value];
  };
  @Input() customerid: number;
  @Input() customeremail: string;
  @Input() showSmallButton: boolean = true;
  _disabled: boolean = false
  @Input() set disabled(value: boolean) {
    this._disabled = value
    if (value) {
      this.couponcodeCtrl.disable();
    } else {
      this.couponcodeCtrl.enable();
    }
  }

  _couponcodevalue: string
  @Input() set couponcode(value: string) {
    if (value !== this._couponcodevalue) {
      this._couponcodevalue = value;
      this.couponcodeCtrl.setValue(value);
      //needs to do check in timeout to make sure that also te customeremail and customerid are set
      setTimeout(() => { this.checkCouponcode(); })
    }
    if (!value) {
      this._couponcodevalue = value;
      this.couponcodeCtrl.enable();
      this.couponcodeCtrl.reset();
    }
  }
  _design: CouponcodeInputDesign = CouponcodeInputDesign.OLD
  @Input() set design(value: string | CouponcodeInputDesign) {
    this._design = CouponcodeInputDesign[value];
  }
  @Input() colorscheme:string = 'light';

  scheme = {
    light: 'bg-e-white text-e-dark-green',
    contrast: 'bg-white',
  }
  @Output() validCouponcode: EventEmitter<string> = new EventEmitter<string>()

  showCouponcode = false;
  couponcodeError = false;
  couponcodeCtrl = new FormControl(null, [Validators.required, Validators.minLength(1)]);

  private unsubscribe = new Subject<void>();

  constructor(
    private _couponcodeService: CouponcodeService
  ) {
  }

  ngOnDestroy() {
    this.unsubscribe.next()
  }

  public hasError = (formGroup: FormGroup, controlName: string, errorName: string) => {
    return formGroup.controls[controlName].hasError(errorName);
  }

  checkCouponcode() {
    this._couponcodevalue = this.couponcodeCtrl.value;
    this._couponcodeService.checkCouponcode(this.couponcodeCtrl.value, this.customerid, this.customeremail, this._originentitytype).subscribe(
      result => {
        if (result) {
          if (this.couponcodeCtrl.touched)
            this.couponcodeError = false;
          this.couponcodeCtrl.disable();
          this.validCouponcode.emit(this.couponcodeCtrl.value);
        } else {
          this.couponcodeCtrl.enable();
          this.couponcodeCtrl.setValue(null);
          if (this.couponcodeCtrl.touched)
            this.couponcodeError = true;
          setTimeout(() => {
            this.couponcodeError = false;
          }, 5000);
          this.validCouponcode.emit(null);
        }
      },
      error => {
        this.couponcodeCtrl.enable();
        this.couponcodeCtrl.setValue(null);
        if (this.couponcodeCtrl.touched)
          this.couponcodeError = true;
        setTimeout(() => {
          this.couponcodeError = false;
        }, 5000);
        this.validCouponcode.emit(null);
        // console.log(error)
      }
    );
  }

}
