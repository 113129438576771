import { AfterContentInit, Component, ContentChild, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../_environments/environment';
import { Entitytype } from '../../_model/redirect';
import { TableParameters } from '../../_model/shared';
import { TaxonomyNode, TaxonomyNodeProduct } from '../../_model/taxonomy';
import { BoxService } from '../../_service/BoxService';
import { GlobalService } from '../../_service/GlobalService';
import { ProductService } from '../../_service/ProductService';
import { RedirectService } from '../../_service/RedirectService';
import { TaxonomyService } from '../../_service/TaxonomyService';
import { slideInOut } from '../../_theme/animations';
import { ImageComponent } from '../image/image.component';
import { LoaderComponent } from '../loader/loader.component';
import { MatInput } from '@angular/material/input';
import { NgClass, NgIf, NgFor, NgTemplateOutlet } from '@angular/common';
import { Box } from '../../_model/box';

@Component({
  selector: 'taxonomynode',
  templateUrl: './taxonomynode.component.html',
  styleUrls: ['./taxonomynode.component.scss'],
  animations: [slideInOut],
  standalone: true,
  imports: [
    NgClass, NgIf, NgFor, NgTemplateOutlet,
    TranslateModule, ReactiveFormsModule,
    LoaderComponent, ImageComponent, MatInput,
  ]
})
export class TaxonomynodeComponent implements OnInit, OnDestroy, AfterContentInit {

  environment = environment;

  _fromdate: any
  @Input() set fromdate(value: any) {
    this._fromdate = value;
    this.loadRoutes();
  }
  _todate: any
  @Input() set todate(value: any) {
    this._todate = value;
    this.loadRoutes();
  }

  //for showing label of subscriptiondiscount in webshop
  @Input() isSubscriptionorder = false;
  //to filter boxes which are not available for delivery in the given period => kerstboxen
  @Input() useDatesAsDeliverydates = false
  @Input() isfullscreen: boolean = false;
  @Output() taxonomynodeChange = new EventEmitter<TaxonomyNode>(); // TODO: remove id we never use this !?

  last_search = null;


  @Input() set search(value: string) { // TODO: looks like this is never used ?
    if (value) {
      //this._search = value;
      this.productSearch.patchValue(value, { emitEvent: true });
    }
  }
  @Output() searchChange = new EventEmitter<string>(); // TODO: remove id we never use this !?

  _taxonomynode: TaxonomyNode = null;
  @Input() set taxonomynode(value: TaxonomyNode) {  // for the initial taxonomynode
    if (!this._taxonomynode) {
      this.loadTaxonomyNode(value);
    } else if (!value || value.id !== this._taxonomynode.id && !this.activatedRoute.snapshot.queryParams.webshop) {
      this.loadTaxonomyNode(value);
    }
  }

  _previousTaxonomyNodes: TaxonomyNode[] = [];

  productSearch = new FormControl(null, { validators: [Validators.minLength(2), Validators.required] });
  searchResults: any[];
  loading = false;
  searchError = false;
  nodeError = false;
  productError = false;
  boxError = false;

  private unsubscribe = new Subject<void>();

  @ContentChild('product', { read: TemplateRef }) productTemplate: TemplateRef<any>;
  @ContentChild('box', { read: TemplateRef }) boxTemplate: TemplateRef<any>;

  constructor(
    private _taxonomyService: TaxonomyService,
    private _boxService: BoxService,
    private _productService: ProductService,
    private _redirectService: RedirectService,
    public _translate: TranslateService,
    private _globalService: GlobalService,
    private _router: Router,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    public dialog: MatDialog
  ) {

  }

  get getChilds() {
    if (!this._taxonomynode) {
      return [];
    }
    const total = [];

    this._taxonomynode.taxonomyNodeChildNodes.forEach(ctrl => {
      total.push(ctrl);
    });
    this._taxonomynode.taxonomyNodeBoxes.forEach(ctrl => {
      total.push(ctrl);
    });
    this._taxonomynode.taxonomyNodeProducts.forEach(ctrl => {
      total.push(ctrl);
    });

    total.sort((a, b) => {
      if (a.sequenceid > b.sequenceid) { return 1; }
      if (a.sequenceid < b.sequenceid) { return -1; }
      return 0;
    });
    return total;

  }

  ngOnInit(): void {

    this.productSearch.valueChanges.pipe(
      tap(() => {
        if (!this.productSearch.value && this.productSearch.dirty) {
          this.navigateToNode(null, true)
          this.productSearch.markAsPristine();
        }
      }),
      filter(() => this.productSearch.valid),
      tap(() => {
        this.searchError = false;
        this.navigateToNode(null, true);
      }),
      debounceTime(500),

      switchMap((filterValue: string) => {
        this.loading = true;
        this.searchResults = [];
        // forkjoin completes all subscriptions before returning the results
        return forkJoin({
          products: this._productService.getWebshopProductsListByName(filterValue, this._fromdate, this._todate),
          //products: this._productService.getProductTableByName(new TableParameters(0, 20, 'id', 'desc'), filterValue, this._fromdate, this._todate),
          boxes: this._boxService.getWebshopBoxTableByString(new TableParameters(0, 20, 'id', 'desc'), filterValue, this._fromdate, this._todate)
        });
      }),
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: results => {
        this.searchResults = [];
        // creates a same structure as the content in the taxonomynodes to be able to use a single html part to show the content
        results.products.forEach(prod => {
          this.searchResults.push({ product: prod });
        });
        results.boxes.content.forEach(b => {
          if (this.useDatesAsDeliverydates && b.boxPeriods && b.boxPeriods[0]) {
            if (this._globalService.periodsOverlap({ from: b.boxPeriods[0].activefrom, to: b.boxPeriods[0].activeto }, { from: this._fromdate, to: this._todate })) {
              //adds if periods overlap
              this.searchResults.push({ box: b });
            }
          } else {
            this.searchResults.push({ box: b });
          }

        });
        //this.navigateToNode(null, true); // we use this to update the active route // TODO: better name or a extra method
        this.loading = false;
        this.searchError = false;
        //this._taxonomynode = null;
      },
      error: error => {
        this.searchError = true;
        this.loading = false;
        console.error(error.message); // TODO: better error handling
      }
    });

    //should be after subscription to make sure that the initial search is done
    this.loadRoutes();

    this.activatedRoute.queryParams.pipe(
      takeUntil(this.unsubscribe)
      //filter((params: any) => params.webshop)
    ).subscribe(() => {
      /*if (this.TaxonomyTemplate && !this.detailsSidenav.opened) {*/
      this.loadRoutes(); // this seems to be called a lot of times ?
      // }
    });

  }

  ngOnDestroy() {
    this.unsubscribe.next();
  }
  ngAfterContentInit() {
    if (!this.productTemplate && this._taxonomynode && this._taxonomynode.taxonomyNodeProducts && this._taxonomynode.taxonomyNodeProducts.length > 0) {
      console.error('There is no producttemplate given: <ng-template #product let-product></ng-template>');
    }
    if (!this.boxTemplate && this._taxonomynode && this._taxonomynode.taxonomyNodeBoxes && this._taxonomynode.taxonomyNodeBoxes.length > 0) {
      console.error('There is no producttemplate given: <ng-template #box let-box></ng-template>');
    }
  }
  hasError = (errorName: string) => {
    return this.productSearch.hasError(errorName);
  }

  loadRoutes() {
    const params = this.activatedRoute.snapshot.queryParams;
    if (params.webshop) {

      const ids = params.webshop.split(',');

      const taxids = ids.filter(id => !id.startsWith('search'));

      this._taxonomyService.getTaxonomynodeList(taxids).subscribe(result => {
        this._previousTaxonomyNodes = [];
        result.forEach(val => {
          this._redirectService.getEntityUrl(Entitytype.TAXONOMY, val.id, val.redirectid).then(value => val['taxonomyurl'] = value);
          if (ids.indexOf(String(val.id)) !== ids.length - 1) {
            this._previousTaxonomyNodes.push(val);
          }
        });

        const last_id = ids.slice(-1)[0];
        if (last_id.startsWith('search')) {    // extract search value from history
          this.last_search = last_id.indexOf('@') > -1 ? last_id.split('@')[1] : '';
          if (!!this._fromdate && this._todate && this.last_search !== this.productSearch.value) { // prevent searching withou a date range
            this.productSearch.setValue(this.last_search); // will trigger an update from the service
            //this.searchFocus();
            //this.mode='search'
          }
          //if (this.productSearch.value === '') {
          if (this.last_search === '') {
            this.loadTaxonomyNode(null);
          }
        } else {
          this.loadTaxonomyNode(result.slice(-1)[0]); // load the last result
        }

      });
    }
  }
  loadTaxonomyNode(node: TaxonomyNode) {

    if (node?.id !== this._taxonomynode?.id) {

      if (this._taxonomynode) //do not reset productsearch when navigating to page
        this.productSearch.setValue('');

      this._taxonomynode = node;

      if (!node) { return; }

      if (this.isfullscreen)
        this._globalService.setTitle(node.displayname);
      this.loading = true;

      if (this._taxonomynode.id == this.environment['webshop_actionproducts_nodeid']) {
        //action node
        this._taxonomynode.taxonomyNodeProducts = [];
        this._productService.getActionProductsByDate(this._fromdate, this._todate).subscribe(next => {
          if (!next || next.length == 0) { this.loading = false; return; }
          next.forEach((el, index) => {
            const prod = new TaxonomyNodeProduct(index, el.id, el, index)
            this._taxonomynode.taxonomyNodeProducts.push(prod)
          })

          this.loading = false;
        }, error => {
          this.loading = false;
        })

      } else {
        //normal node
        if (node.taxonomyNodeChildNodes) {
          node.taxonomyNodeChildNodes.sort((a, b) => {
            if (a.sequenceid > b.sequenceid) { return 1; }
            if (a.sequenceid < b.sequenceid) { return -1; }
            return 0;
          });
        }

        forkJoin({
          nodes: this._taxonomyService.getTaxonomynodeList(node.taxonomyNodeChildNodes.map(m => m.childtaxonomynodeid)).pipe(catchError(error => { return of(error) })),
          products: this._productService.getProductList(node.taxonomyNodeProducts.map(p => p.productid), this._fromdate, this._todate).pipe(catchError(error => { return of(error) })),
          boxes: this._boxService.getBoxList(node.taxonomyNodeBoxes.map(p => p.boxid)).pipe(catchError(error => { return of(error) }))
        }).subscribe(result => {
          if (result.nodes.error) {
            this.nodeError = true;
          } else {
            this.nodeError = false;
            result.nodes.forEach(n => {
              if (!n['taxonomyurl'])
                this._redirectService.getEntityUrl(Entitytype.TAXONOMY, node.id, node.redirectid).then(value => n['taxonomyurl'] = value);
            })

            node.taxonomyNodeChildNodes.forEach(taxonomynode => {
              taxonomynode.childtaxonomynode = result.nodes.find(f => f.id == taxonomynode.childtaxonomynodeid)
            });
          }
          if (result.products.error) {
            this.productError = true;
          } else {
            this.productError = false;
            node.taxonomyNodeProducts.forEach(taxonomynode => {
              taxonomynode.product = result.products.filter(p => p.id === taxonomynode.productid)[0];
            });
          }
          if (result.boxes.error) {
            this.boxError = true;
          } else {
            this.boxError = false;
            node.taxonomyNodeBoxes.forEach(taxonomynode => {
              if (!result.boxes || result.boxes.length === 0) return;
              const b = result.boxes.find(p => p.id === taxonomynode.boxid);
              if (this.useDatesAsDeliverydates && b && b.boxPeriods && b.boxPeriods[0]) {
                if (this._globalService.periodsOverlap({ from: b.boxPeriods[0].activefrom, to: b.boxPeriods[0].activeto }, { from: this._fromdate, to: this._todate })) {
                  //adds if periods overlap
                  taxonomynode.box = b;
                } else {
                  taxonomynode.box = null;
                }
              } else {
                taxonomynode.box = b
              }
            });
          }

          this.loading = false;
        }, error => {
          console.log(error);
          this.loading = false;
        })
      }
    }

  }

  navigateToNode(node: TaxonomyNode, navigateback = false) {
    //console.log(this._previousTaxonomyNodes)
    if (this._taxonomynode && !navigateback || this._previousTaxonomyNodes.length == 0 && !node && this._taxonomynode) {
      this._previousTaxonomyNodes.push(this._taxonomynode);

    }

    this.taxonomynodeChange.emit(node);

    let queryParams;

    if (node) {
      let nodeids = this._previousTaxonomyNodes.map(s => String(s.id)) as string[];
      nodeids.push(String(node.id));
      nodeids = [...new Set(nodeids)];
      queryParams = { webshop: nodeids.join(',') };
    } else {
      const nodeids = this._previousTaxonomyNodes.map(s => String(s.id)) as string[];
      if (this.productSearch.value)
        nodeids.push(['search', this.productSearch.value].join('@')); // remember where searched for
      queryParams = { webshop: nodeids.join(',') };
    }
    this._router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
  }

  gotoPreviousNode(node = null) {
    if (node) {
      this.productSearch.setValue(null);

      for (let i = this._previousTaxonomyNodes.length - 1; i >= 0; i--) {
        if (node === this._previousTaxonomyNodes[i]) {
          this._previousTaxonomyNodes.pop();
          break;
        } else {
          this._previousTaxonomyNodes.pop();
        }
      }
    } else {
      node = this._previousTaxonomyNodes[this._previousTaxonomyNodes.length - 1];
      this._previousTaxonomyNodes.pop();
    }

    this.navigateToNode(node, true);
    this.taxonomynodeChange.emit(node);
  }
}
