<ng-container *ngIf="_design === 'NEWORDERFLOW'">
  <div  *ngIf="!couponcodeCtrl.disabled" class="flex flex-row flex-nowrap couponcodeinput">
    <div class="form-field w-full">
      <label>{{'order.do_you_have_a_couponcode' | translate}}</label>
      <div class="flex flex-row mt-1">      
        <input
              [formControl]="couponcodeCtrl"
              class="{{scheme[colorscheme]}} h-[48px] focus:ring-0 mt-0 rounded-r-none"
              (keydown.enter)="false"
              id="couponcode"
              name="{{'order.couponcode' | translate}}"
              type="text"             
              >
        <button (click)="checkCouponcode()" [disabled]="!couponcodeCtrl.valid" class="button-base rounded-lg" [ngClass]="{'cursor-pointer': couponcodeCtrl.valid}">
          {{'common.add' | translate}}
        </button>
      </div>
      <small class="error" [@collapse] *ngIf="couponcodeError">{{'validation.couponcode_invalid' | translate}}</small>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="_design === 'OLD'">
  <div class="flex flex-col couponcodeinput" [ngClass]="{'justify-center' : showSmallButton, 'justify-start' : !showSmallButton }" @collapse *ngIf="!couponcodeCtrl.disabled">
    <div class="" *ngIf="showSmallButton">
      <button (click)="showCouponcode= !showCouponcode" class="flex flex-wrap justify-between button-base px-[25px] rounded-full" [disabled]="_disabled">
        <small>
          {{'mpm.i_have_a_couponcode'| translate}}
        </small>
      </button>
    </div>
    <ng-container *ngIf="!showSmallButton">
      <div class="flex flex-row flex-nowrap justify-end">
        <button (click)="showCouponcode = true" @horCollapse class="whitespace-nowrap button-base flex button-mid rounded-full relative" *ngIf="!showCouponcode" [disabled]="_disabled">
          {{'mpm.i_have_a_couponcode'| translate}}
          <img class="h-3 my-auto ms-2" src="./assets/images/icons/chevron.svg" />
        </button>
        <button (click)="showCouponcode = false" @horCollapse class="button-base flex rounded-full relative" *ngIf="showCouponcode" [disabled]="_disabled">
          {{'common.cancel_button'| translate}}
          <img class="h-3 my-auto ms-2" src="./assets/images/icons/chevron.svg" />
        </button>
      </div>
    </ng-container>

    <div [@slideInOut]="showCouponcode" class="flex flex-col justify-between px-0 pb-3 gx-1 max-w-[250px]">
      <div class="form-field mx-1">
        <label>{{'order.couponcode' | translate}}</label>
        <input [formControl]="couponcodeCtrl" class="{{scheme[colorscheme]}} " (keydown.enter)="false" id="couponcode" name="{{'order.couponcode' | translate}}" placeholder="{{'order.couponcode' | translate}}" type="text">
        <span class="error" [@collapse] *ngIf="couponcodeError">{{'validation.couponcode_invalid' | translate}}</span>
      </div>
      <button (click)="checkCouponcode()" [disabled]="!couponcodeCtrl.valid" class="button-base rounded-full mt-[15px] disabled:opacity-25" [ngClass]="{'cursor-pointer': couponcodeCtrl.valid}">
        {{'common.add' | translate}}
      </button>
    </div>
  </div>
</ng-container>
