import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { environment } from '../../_environments/environment';
import { Box } from '../../_model/box';
import { Orderline } from '../../_model/order';
import { CalculatedPrice, CustomerCreateOrderLine } from '../../_model/pricing';
import { Product } from '../../_model/product';
import { Nutrient } from '../../_model/recipe';
import { Countrycode } from '../../_model/shared';
import { SubscriptionProductSelection, SubscriptionRepeatableproduct } from '../../_model/subscription';
import { GlobalService } from '../../_service/GlobalService';
import { PricingService } from '../../_service/PricingService';
import { ColorScheme } from '../extensions/colorscheme';


class BoxpersonCountryprice {
  constructor(
    public countrycode: Countrycode,
    public boxsellprice: number,
    public boxdiscountamount: number,
    public boxdiscountpercentage: number,
    public vattarifhigh: boolean
  ) { }
}

@Component({
  selector: 'app-base-orderline',
  template: `
    <p>
      base orderline works!
    </p>
  `,
  styles: []
})
export class BaseorderlineComponent extends ColorScheme implements OnInit, OnDestroy {

  _env = environment;

  @Input() deliverydate: any;
  @Input() isSubscriptionorder: boolean;

  _repeatableProducts: SubscriptionRepeatableproduct[] = [];
  @Input() set repeatableProducts(value: SubscriptionRepeatableproduct[]) {
    if (value) {
      this._repeatableProducts = value;
      if (this._repeatableProducts) {
        if (this._box)
          this._repeatableProduct = this._repeatableProducts.filter(f => f.boxid == this._box.id)[0];
        if (this._product)
          this._repeatableProduct = this._repeatableProducts.filter(f => f.sku === this._product.sku)[0];
      }
      /*      if (this._repeatableProduct && this.repeatable.value !== true) {*/
      if (this._repeatableProduct) {
        this.quantity.setValue(this._repeatableProduct.quantity, { emitEvent: false });
        this.persons.setValue(this._repeatableProduct.persons, { emitEvent: false });
        this.repeatable.setValue(true, { emitEvent: false });
      } else {
        if (!this._productSelection) {
          this.quantity.setValue(0, { emitEvent: false });
          this.persons.setValue(0, { emitEvent: false });
        }
        this.repeatable.setValue(false, { emitEvent: false });
      }
    }
  };
  _selectedProducts: SubscriptionProductSelection[] = [];
  @Input() set selectedProducts(value: SubscriptionProductSelection[]) {
    if (value) {
      this._selectedProducts = value;
      if (this._selectedProducts) {
        if (this._box)
          this._productSelection = this._selectedProducts.filter(f => f.boxid === this._box.id)[0];
        if (this._product)
          this._productSelection = this._selectedProducts.filter(f => f.sku === this._product.sku)[0];
      }
      if (this._productSelection) {
        this.quantity.setValue(this._productSelection.quantity, { emitEvent: false });
        this.persons.setValue(this._productSelection.persons, { emitEvent: false });
      } else {
        if (!this._repeatableProduct) {
          this.quantity.setValue(0, { emitEvent: false });
          this.persons.setValue(2, { emitEvent: false });
        }
      }
    }
  };

  //_orderlines: Orderline[] | SubscriptionProductSelection[] = [];
  _orderlines: any[] = [];
  //@Input() set orderlines(value: Orderline[] | SubscriptionProductSelection[]) {
  @Input() set orderlines(value: any[]) {
    this._orderlines = value;
    this.setOrderline(true);
  }

  _orderline: Orderline | CustomerCreateOrderLine;
  @Input() set orderline(value: Orderline | CustomerCreateOrderLine) { //it has to be an any because it is possible to be a customercreateorderline or a orderline
    this._orderline = value;
    this._productOrderline = value as Orderline;
    this.setOrderline();
  }

  _box: Box;
  _product: Product
  @Input() set box(value: Box) {
    this.loadBox(value)
  }
  @Input() set product(value: Product) {
    this.loadProduct(value)
  }

  @Output() repeatableProductsChange = new EventEmitter<SubscriptionRepeatableproduct[]>();
  @Output() selectedProductsChange = new EventEmitter<SubscriptionProductSelection[]>();
  @Output() addOrderline = new EventEmitter<Orderline>();
  @Output() quantityChange = new EventEmitter<number>();
  @Output() personsChange = new EventEmitter<number>();

  _repeatableProduct: SubscriptionRepeatableproduct;
  _productSelection: SubscriptionProductSelection;
  _productOrderline: Orderline | CustomerCreateOrderLine;

  qtyeditable: boolean;
  repeatableeditable: boolean;
  personseditable: boolean;
  createOrderline: boolean = false;

  repeatable = new FormControl(false);
  quantity = new FormControl(0);
  persons = new FormControl(2);
  calculatedPrice: CalculatedPrice;
  personquantity: number = 2;

  loadingsubject = new Subject<void>();
  unsubscribe = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public _translate: TranslateService,
    public _pricingService: PricingService,
    public _globalService: GlobalService
  ) {
    super();
  }

  ngOnInit() {
    this.repeatable.valueChanges.subscribe(value => {
      if (value) {
        if (this._productSelection) {
          //switch from productselection to repeatable product
          if (!this.isRepeatableProduct()) {
            if (this._box)
              this._repeatableProduct = new SubscriptionRepeatableproduct(null, '', this._box.id, this.persons.value, this._productSelection.quantity);
            if (this._product)
              this._repeatableProduct = new SubscriptionRepeatableproduct(null, this._product.sku, null, null, this._productSelection.quantity);
            this._repeatableProducts.push(this._repeatableProduct);
          }
          let productindex = -1;
          if (this._box)
            productindex = this._selectedProducts.map(m => m.boxid).indexOf(this._box.id)
          if (this._product)
            productindex = this._selectedProducts.map(m => m.sku).indexOf(String(this._product.sku));
          this._selectedProducts.splice(productindex, 1);
          this._productSelection = null;

          this.selectedProductsChange.emit(this._selectedProducts);
          this.repeatableProductsChange.emit(this._repeatableProducts);
        } else {
          if (this._box)
            this.changePersonQty(1);
          this.changeQty(1);
        }
      }
      else {
        if (this.quantity.value === 0)
          this.quantity.setValue(1);

        if (!this.isSelectedProduct()) {
          if (this._box)
            this._productSelection = new SubscriptionProductSelection(null, this._box.id, this.persons.value, '', this.quantity.value, false, false, null);
          if (this._product)
            this._productSelection = new SubscriptionProductSelection(null, null, 0, String(this._product.sku), this.quantity.value, false, false, null);

          this._selectedProducts.push(this._productSelection);
        }
        let repeatableindex = -1;
        if (this._box)
          repeatableindex = this._repeatableProducts.map(m => m.boxid).indexOf(this._box.id)
        if (this._product)
          repeatableindex = this._repeatableProducts.map(m => m.sku).indexOf(String(this._product.sku));
        this._repeatableProducts.splice(repeatableindex, 1);
        this._repeatableProduct = null;

        this.selectedProductsChange.emit(this._selectedProducts);
        this.repeatableProductsChange.emit(this._repeatableProducts);
      }
    });
    this.persons.valueChanges.pipe().subscribe(val => {
      this.getCalculatedBoxPrice(this._box)
      this.personquantity = val;
    })

    setTimeout(() => {
      this.qtyeditable = this.selectedProductsChange.observers.length > 0 || this.quantityChange.observers.length > 0;
      this.personseditable = (this.selectedProductsChange.observers.length > 0 || this.personsChange.observers.length > 0) && (this._box && this._box?._boxpersons.length > 1);
      this.repeatableeditable = this.repeatableProductsChange.observers.length > 0 && (this._product && this._product.repeatable || this._box && this._box?.repeatable);
      this.createOrderline = this.quantityChange.observers.length > 0;
    })

  }
  ngAfterContentInit() {

  }
  ngOnDestroy() {
    this.unsubscribe.next();
  }
  setOrderline(updatedlines = false) {
    //added updatedlines to get the newest values from updated orderlines, otherwise it wil reset to the old qty 
    if (!this._productOrderline || updatedlines) {
      //only gets productorderline based on the box or product when it is not given.
      //This can give the wrong line because there can be a muliple of the same product.
      //it does make sure that it isnt a couponcodeitem because this will only be able in the webshop
      if (!this._orderlines) return
      if (this._box) {
        this._productOrderline = this._orderlines.filter(f => f.boxid === this._box.id && (f.couponcodeitem === undefined || f.couponcodeitem !== undefined && f.couponcodeitem === false))[0];
      }
      if (this._product) {
        this._productOrderline = this._orderlines.filter(f => f.sku === this._product.sku && (f.couponcodeitem === undefined || f.couponcodeitem !== undefined && f.couponcodeitem === false))[0];
      }
    }

    if (this._productOrderline) {
      if (this._productOrderline['couponcodeitem']) {
        this.qtyeditable = false;
        this.repeatableeditable = false;
        this.personseditable = false;
      }
      this.quantity.setValue(this._productOrderline.quantity, { emitEvent: false });
      this.persons.setValue(this._productOrderline.persons, { emitEvent: false })
    } else {
      this.quantity.setValue(0, { emitEvent: false });
      this.persons.setValue(2, { emitEvent: false })
    }
  }
  loadProduct(product: Product) {
    if (!product) return;

    if (product.nutrients) {
      const nutrient_order = Object.keys(Nutrient); // we want the order as defined in the enum
      product.nutrients = product.nutrients.sort((a, b) => {
        return nutrient_order.indexOf(a.name) - nutrient_order.indexOf(b.name);
      });
    }
    this._product = product;
    if (!this._product.repeatable)
      this.repeatableeditable = false;
    this.loadingsubject.next();
    this.getCalculatedProductPrice(product)
  }
  loadBox(box: Box) {
    if (!box) return;
    this._box = box;

    if (!this._box._boxpersons)
      this._box = this._setBoxpersons(this._box)

    if (!this._box.repeatable)
      this.repeatableeditable = false
    if (this._box._boxpersons.length === 1)
      this.personseditable = false;

    this.loadingsubject.next();
    this.getCalculatedBoxPrice(box);
  }
  private _setBoxpersons(box: Box): Box {
    box._boxpersons = []
    if (box.boxcountryprices && box.boxcountryprices.length > 0) {
      let countryprice = box.boxcountryprices.filter(b => b.countrycode == this._globalService.activePricecountry.pricecountry)[0]
      if (!countryprice) return;
      if (countryprice.onepersonboxsellprice)
        box._boxpersons.push(1);
      if (countryprice.twopersonboxsellprice)
        box._boxpersons.push(2);
      if (countryprice.threepersonboxsellprice)
        box._boxpersons.push(3);
      if (countryprice.fourpersonboxsellprice)
        box._boxpersons.push(4);
    }
    return box;
  }

  isRepeatableProduct(): boolean {
    let repeatable = false;
    if (this._box)
      repeatable = this._repeatableProducts.filter(v => v.boxid == this._box.id).length > 0
    if (this._product)
      repeatable = this._repeatableProducts.filter(v => v.sku === this._product.sku).length > 0;
    return repeatable;
  }
  isSelectedProduct(): boolean {
    let selected = false;
    if (this._box)
      selected = this._selectedProducts.filter(v => v.boxid == this._box.id).length > 0
    if (this._product)
      selected = this._selectedProducts.filter(v => v.sku === this._product.sku).length > 0;

    return selected;
  }

  changeQty(value: number) {
    let min = 0;
    
    this.setQuantity(this.quantity.value + value < min ? min : this.quantity.value + value);
  }
  setQuantity(newQuantity: number) {
    let min = 0;
    this.quantity.setValue(newQuantity)
    if (this.createOrderline) {
      if (this._productOrderline) {
        this.quantityChange.emit(this.quantity.value);
      } else {
        if (this.quantity.value == min) return;
        if (this._box)
          this._productOrderline = new Orderline(null, null, null, this._box.id, this.persons.value, null, null, null, null, this._box.title || this._box.name, this.quantity.value, false, true, true, false, this._box.doublepersons);
        if (this._product)
          this._productOrderline = new Orderline(null, this._product.sku, null, null, null, null, null, null, null, this._product.title || this._product.name, this.quantity.value, false, false, true, false, false);

        this.addOrderline.emit(this._productOrderline as Orderline);
        this._orderlines.push(this._productOrderline);
      }

    } else {
      if (this.repeatable.value) {
        //REPEATABLE
        if (this.quantity.value > 0) {
          if (this._repeatableProduct) {
            this._repeatableProduct.quantity = this.quantity.value
          } else {
            if (this.quantity.value == min) return;
            //creates new repeatable product if is new product
            if (this._box)
              this._repeatableProduct = new SubscriptionRepeatableproduct(null, null, this._box.id, this.persons.value, this.quantity.value);
            if (this._product)
              this._repeatableProduct = new SubscriptionRepeatableproduct(null, this._product.sku, null, 0, this.quantity.value);

            this._repeatableProducts.push(this._repeatableProduct);
          }
          this.repeatableProductsChange.emit(this._repeatableProducts);
        } else {
          //Removes repeatable product
          if (this._repeatableProducts) {
            let index = -1;
            if (this._box)
              index = this._repeatableProducts.map(f => f.boxid).indexOf(this._box.id);
            if (this._product)
              index = this._repeatableProducts.map(f => f.sku).indexOf(this._product.sku);

            if (index >= 0) {
              this._repeatableProducts.splice(index, 1);
              this.repeatableProductsChange.emit(this._repeatableProducts);
            }
            this.repeatable.setValue(false, { emitEvent: false })
            this._repeatableProduct = null;
          }
        }
      } else {
        //NORMAL ORDERLINE
        if (this.quantity.value > 0) {
          if (this._productSelection) {
            this._productSelection.quantity = this.quantity.value
          } else {
            //creates new orderline if is new product
            if (this._box)
              this._productSelection = new SubscriptionProductSelection(null, this._box.id, this.persons.value, null, this.quantity.value, false, false, null);
            if (this._product)
              this._productSelection = new SubscriptionProductSelection(null, null, 0, String(this._product.sku), this.quantity.value, false, false, null);
            this._selectedProducts.push(this._productSelection);
          }
          this.selectedProductsChange.emit(this._selectedProducts);
        } else {
          //removes orderline
          if (this._selectedProducts) {
            let index = -1;
            if (this._box)
              index = this._selectedProducts.map(f => f.boxid).indexOf(this._box.id);
            if (this._product)
              index = this._selectedProducts.map(f => f.sku).indexOf(this._product.sku);

            if (index >= 0) {
              this._selectedProducts.splice(index, 1);
              this.selectedProductsChange.emit(this._selectedProducts);
            }
            this._productSelection = null
          }
        }
      }
    }
  }

  changePersonQty(qty: number) {
    let personsindex = this._box?._boxpersons.indexOf(this.persons.value);
    if (personsindex + qty <= this._box?._boxpersons.length - 1 && personsindex + qty >= 0)
      this.setPersonQuantity(this._box?._boxpersons[personsindex + qty])
  }
  setPersonQuantity(newPersonsQuantity: number) {
    this.persons.setValue(newPersonsQuantity)
    //lets change the ingredientquantities also with the number of persons
    this.personquantity = this.persons.value;
    if (this.createOrderline) {
      if (this._productOrderline)
        this.personsChange.emit(this.persons.value);

    } else {
      if (this.repeatable.value) {
        if (this._repeatableProduct) {
          this._repeatableProduct.persons = this.persons.value
          this.repeatableProductsChange.emit(this._repeatableProducts);
        }
      } else {
        if (this._productSelection) {
          this._productSelection.persons = this.persons.value;
          this.selectedProductsChange.emit(this._selectedProducts);
        }
      }
    }
  }

  getCalculatedBoxPrice(box: Box) {
    const boxpersonprice = this.getBoxCountrypersonsprice();

    if (boxpersonprice) {
      this._pricingService.calculateSingleprice(boxpersonprice.boxsellprice, boxpersonprice.vattarifhigh, 1, boxpersonprice.boxdiscountpercentage, boxpersonprice.boxdiscountamount, this.isSubscriptionorder).subscribe(
        result => {
          this.calculatedPrice = result
        }, error => {
          console.log(error);
        }
      )
    }
  }
  getBoxCountrypersonsprice(): BoxpersonCountryprice {
    const pricenumber = ['', 'one', 'two', 'three', 'four'][this.persons.value]
    const price = this._box?.boxcountryprices.filter(b => b.countrycode === this._globalService.getPricecountry())[0];
    if (!price) return null
    const boxprice = new BoxpersonCountryprice(price.countrycode, price[pricenumber + 'personboxsellprice'], price[pricenumber + 'personboxdiscountamount'], price[pricenumber + 'personboxdiscountpercentage'], price.vattarifhigh)
    return boxprice;
  }
  getCalculatedProductPrice(product: Product) {
    this._pricingService.calculateSingleprice(product.shopsellprice, product.vattarifhigh, 1, product.discountpercentage, product.discountprice, this.isSubscriptionorder).subscribe(
      result => {
        this.calculatedPrice = result;
      }, error => {
        console.log(error);
      }
    );
  }

}
