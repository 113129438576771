import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'ngx-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
    standalone: true,
    imports: [NgIf]
})

export class VideoComponent {

  @Input() class: string;
  safeSrc: SafeResourceUrl;
  code: string;
  @Input() set src(value: string) {
    this.code = value;
    this.safeSrc = this._sanitizer.bypassSecurityTrustResourceUrl(value);
  };
  @Input() title: string = "YouTube video player";
  @Input() height: number = 315;

  constructor(
    private _sanitizer: DomSanitizer
  ) {

  }

}
