import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../_environments/environment';
import { Faq } from '../_model/faq';


@Injectable()
export class FaqService {

  constructor(
    private _http: HttpClient
  ) {
  }

  getFaq(faqid: number): Observable<Faq> {

    return this._http.get(environment.apiserver + 'faqbff/v1/faq/' + faqid).pipe(
      map((response: Response) => <any>response)
      , catchError(this.handleError)
    );
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }

}
