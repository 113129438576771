import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Address } from '../../_model/address';

@Component({
    selector: 'address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    standalone: true,
    imports: [TranslateModule]
})
export class AddressComponent implements OnDestroy {

  @Input() address: Address

  private unsubscribe = new Subject<void>();

  constructor(
   
  ) {
  }


  ngOnDestroy() {
    this.unsubscribe.next()
  }

  public hasError = (formGroup: FormGroup, controlName: string, errorName: string) => {
    return formGroup.controls[controlName].hasError(errorName);
  }
}
