import { Storetype } from "./postalcodedelivery";
import { Originentitytype } from "./subscription";

export class Order {
  constructor(
    public orderid: string,
    public customerid: number,
    public brandid: number,
    public originentityid: number,
    public originentitytype: Originentitytype,
    public totalvalue: number,
    public compensationtotal: number,
    public vat: number,
    public shipping: number,
    public shippingcostvat: number,
    public discount: number,
    public discountvat:number,
    public currency: string,
    public status: string,
    public paymenterrormessage: string,
    public shippingmethod: Shippingmethod,
    public deliverydate: any,
    public deliverytimeframe: string,
    public pickupstore: string,
    public firstsubscriptionorder: boolean,

    public storeid: number,
    public storetype: Storetype,
    public shippingtoname: string,
    public carriername: string,
    public shippingaddress: number,
    public billingaddress: number,
    public couponcode: string,
    public refunded: number,
    public affiliateid: string,
    public recipecard: boolean,
    public deliveryinstructions: string,
    public paymentmethod: Paymentmethod,
    public created: number,
    public updated: number,
    public orderlines: Orderline[],
    public creditmemos: Creditmemo[]
  ) {
  }
}

export class Orderline {
  constructor(
    public id: number,
    public sku: string,
    public recipeid: number,
    public boxid: number,
    public persons: number,
    public price: number,
    public vat: number,
    public discount:number,
    public discountvat: number,
    public description: string,
    public quantity: number,
    public hidden: boolean,
    public subscriptionitem: boolean,
    public webshopitem: boolean,
    public onetimebox: boolean,
    public doublepersons:boolean
    
  ) {

  }
}

export class Creditmemo {
  constructor(
    public id: number,
    public refunded: number,
    public description: string,
    public compensationid: number ,
    public creditmemolines: Creditmemoline[]
  ) { }
}
export class Creditmemoline {
  constructor(
    public id: number,
    public sku: string,
    public recipeid: number,
    public boxid: number,
    public persons: number,
    public price: number,
    public refunded: number,
    public description: string,
    public quantity: number,
    public orderlineid: number,
   

  ) { }
}


export enum Orderstatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  PROCESSING_INCASSO = 'PROCESSING_INCASSO',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
  REFUNDED = 'REFUNDED',
  PAYMENTERROR = 'PAYMENTERROR',
  DISPUTED = 'DISPUTED'
}

export enum Shippingmethod {
  PICKUP_STORE = 'PICKUP_STORE',
  DOOR_DELIVERY = 'DOOR_DELIVERY'
}

export enum Paymentmethod {
  INCASSO = 'INCASSO',
  IDEAL = 'IDEAL',
  BANCONTACT = 'BANCONTACT',
  CREDITCARD = 'CREDITCARD',
  INVOICE = 'INVOICE',
  CASH = 'CASH',
  NONE = 'NONE',
  UNKNOWN = 'UNKNOWN',
  EMAIL= 'EMAIL'
}

export enum Currency {
  EUR = 'EUR'
}

export enum Flowtype {
  SUBSCRIPTION = 'SUBSCRIPTION', //orderflow module -  subscription
  PERSONALMENU = 'PERSONALMENU', //personalmenu module
  SINGLEORDER = 'SINGLEORDER', // singleorder module
  TELEMARKETING = 'TELEMARKETING' //telemarketing module
}




