import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {BottomdrawerdialogData} from '../../_model/shared';
import { NgTemplateOutlet } from '@angular/common';


@Component({
    selector: 'bottomdrawer-dialog',
    templateUrl: './bottomdrawerDialog.component.html',
    styleUrls: ['bottomdrawerDialog.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet]
})
export class BottomdrawerDialogComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomdrawerdialogData
  ) {
  }

}
