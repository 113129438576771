import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PricingService } from '../../_service/PricingService';
import { GlobalService } from '../../_service/GlobalService';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../_environments/environment';
import { Price } from '../../_model/pricing';
import { Subject, takeUntil } from 'rxjs';
import { Countrycode } from '../../_model/shared';
import { SubscriptionService } from '../../_service/SubscriptionService';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgClass, CurrencyPipe } from '@angular/common';
import { AddressService } from '../../_service/AddressService';

@Component({
    selector: 'app-subscriptionpricing',
    templateUrl: './subscriptionpricing.component.html',
    standalone: true,
    imports: [NgFor, NgClass, CurrencyPipe, TranslateModule]
})
export class SubscriptionpricingComponent implements OnInit, OnDestroy {

  @Output() portionprice: EventEmitter<number> = new EventEmitter<number>();
  @Output() discountedportionprice: EventEmitter<number> = new EventEmitter<number>();

  recipeQuantities: number[] = environment.subscriptionRecipeChoices;
  personChoices: number[] = environment.subscriptionPersonChoices;

  pricing: Price;

  pricingform = new FormGroup({
    countrycode: this._addressService.shippingAddressForm.get('countrycode'),
    defaultpersons: this._subscriptionService.flowdefaultpersons,
    defaultrecipes: this._subscriptionService.flowdefaultrecipes
  })

  constructor(
    private _pricingService: PricingService,
    private _globalService: GlobalService,
    private _subscriptionService: SubscriptionService,
    private _addressService: AddressService
  ) {
  }

  _destroy$ = new Subject<void>();

  ngOnInit() {

    this.pricingform.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.getRecipeprice();
    })

    this.pricingform.get('countrycode').valueChanges.pipe(takeUntil(this._destroy$)).subscribe(countrycode => {
      this.getSubscriptionpricing(countrycode)
    })

    this.getSubscriptionpricing(Countrycode[this._globalService.getPricecountry()]);

  }
  ngOnDestroy() {
    this._destroy$.next();
  }

  getSubscriptionpricing(countrycode: Countrycode) {
    this._pricingService.getRecipesubscriptionPricing(countrycode).subscribe(res => {
      this.pricing = res;
      this.getRecipeprice();
    })
    
  }

  setDefaultPersons(persons: number) {
    this.pricingform.get('defaultpersons').setValue(persons);
  }
  setDefaultRecipes(recipes: number) {
    this.pricingform.get('defaultrecipes').setValue(recipes);
  }

  getRecipeprice() {
    if (!this.pricing) return null;
    const persons = this.pricingform.get('defaultpersons').value;
    const recipes = this.pricingform.get('defaultrecipes').value;
    const countrycode = this.pricingform.get('countrycode').value;

    const pricing = this._pricingService.getRecipeprice(countrycode, persons, recipes)

    if (!pricing) return;

    this.discountedportionprice.emit(pricing.discountedportionprice)
    this.portionprice.emit(pricing.portionprice)
      

    //let price = 0;
    //let discountedprice = 0;
    //switch (persons) {
    //  case 1:
    //    price = this.pricing.onepersonrecipeprice
    //    break;
    //  case 2:
    //    price = this.pricing.twopersonrecipeprice
    //    break;
    //  case 3:
    //    price = this.pricing.threepersonrecipeprice
    //    break;
    //  case 4:
    //    price = this.pricing.fourpersonrecipeprice
    //    break;
    //  default:
    //    price = 0;
    //}

    //if (recipes === 4) {
    //  discountedprice = price * (1 - (this.pricing.fourbagsdiscountpercentage / 100))
    //} else {
    //  discountedprice = price;
    //}

    //discountedprice = discountedprice * (1 + (this.pricing.vatlow / 100))
    //price = price * (1 + (this.pricing.vatlow / 100))

    //this.discountedportionprice.emit(discountedprice / persons)
    //this.portionprice.emit(price / persons)

  }




}



