import { BoxPeriod } from './box';
import { Order, Orderstatus } from './order';
import { PostalCodeWeekDayTimeFrame } from './postalcodedelivery';
import { Recipe } from './recipe';

export class AvailableDelivery {
  public dayoffset: number = null;

  constructor(
    public date: any,
    public cutoff: any,
    public ismoved: boolean,
    public isdisabled: boolean,
    public originaldate: any,
    public postalCodeWeekDayTimeFrames: PostalCodeWeekDayTimeFrame[],
    public status: any,
    public delivery: any, // RecipeSubscriptionDelivery,
    public order: Order,
    
    public recipes: Recipe[],
    public shownRecipes: Recipe[],
    public shownRecipesPage: number,
    public totalRecipePages: number,
    public boxPeriod: BoxPeriod,

  ) {
    if (status !== "LAST") {
      if (order) {
        //sets status to processed because otherwise it says it is already delivered
        const d = new Date(order.deliverydate);

        if (order.originentitytype && (d.getTime() > new Date().getTime() || this.order.status == Orderstatus.NEW)) {
          this.status = 'PROCESSED';
        } else {
          this.status = 'ORDER';
        }

       /* this.hasgroceries = order.orderlines.filter(l => l.boxid || l.sku).length > 0*/

      } else {
        if (isdisabled) {
          this.status= "DISABLED"
        }else if (delivery && delivery.ispauzed ) {
          this.status = "PAUSE"
        
        } else {
          const cutoffdate = new Date(cutoff);
          if (cutoffdate.getTime() <= new Date().getTime()) {
            this.status = "PROCESSED";
          } else {
            this.status = "DELIVERY"
          }
        }
      }
    }

    if (this.cutoff) {
      this.cutoff = new Date(this.cutoff);
      this.cutoff.setDate(this.cutoff.getDate()-1 )
    }

    if (!this.originaldate) {
      this.originaldate = this.date;
    }
    this.dayoffset = Math.floor((new Date().getTime() - new Date(this.date).getTime()) / (1000 * 60 * 60 * 24))
  }
}


export enum Weekday {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'

}

export enum Frequency {
  EVERY_WEEK = 'EVERY_WEEK',
  EVERY_TWO_WEEKS = 'EVERY_TWO_WEEKS',
  EVERY_THREE_WEEKS = 'EVERY_THREE_WEEKS',
  EVERY_FOUR_WEEKS = 'EVERY_FOUR_WEEKS'

}
