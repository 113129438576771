import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../_environments/environment';
import { GlobalService } from '../../_service/GlobalService';
import { ColorScheme } from '../extensions/colorscheme';

@Component({
  selector: 'app-personselection',
  standalone: true,
  imports: [
    NgClass, NgIf, NgTemplateOutlet
  ],
  templateUrl: './personselection.component.html',
  styleUrl: './personselection.component.scss'
})
export class PersonselectionComponent extends ColorScheme {

  @Input() minpersons: number = this._globalService.minSubscriptionPersons;
  @Input() maxpersons: number = this._globalService.maxSubscriptionPersons;
  @Input() personsoptions: number[] = environment.subscriptionPersonChoices;
  @Input() doublepersons = false;
  _persons: number = environment.personCountDefault
  @Input() set persons(value: number) {
    if (value)
      this._persons = value;
  }
  _small = false;
  @Input() set small(value: boolean |string) {
    if (value || value === '')
      this._small = true
  }
  @Input() editable = true;

  @Output() personsChange: EventEmitter<number> = new EventEmitter<number>()

  constructor(
    private _globalService: GlobalService
  ) {
    super()
  }

  changePersonQty(qty: number) {
    let personsindex = this.personsoptions.indexOf(this._persons);
    if (personsindex + qty <= this.personsoptions.length - 1 && personsindex + qty >= 0)
      this._persons = this.personsoptions[personsindex + qty]

    this.personsChange.emit(this._persons)
  }

}
