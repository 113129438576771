<div class="{{scheme[colorscheme]}} flex w-full flex-col items-stretch rounded-xl">
  <div class="relative">
    <span *ngIf="overlappingAllergens()?.length > 0" class="absolute top-[15px] left-[15px] rounded-full bg-e-gold text-white px-[12px] py-[5px]">
      {{'common.contains' | translate}}
      <ng-container *ngFor="let allergen of overlappingAllergens(); let a = index ">
        {{'allergen.' + allergen | translate | lowercase}}<ng-container *ngIf="a !== overlappingAllergens().length-1">, </ng-container>
      </ng-container>
    </span>
    <ng-container *ngIf="_recipe?.salessurcharge && calculatedPrice">

      <span *ngIf="_recipe?.salessurcharge > 0" class="absolute bottom-[15px] left-[15px] rounded-full bg-e-orange text-e-white px-[12px] py-[4px] font-sans">
        {{'recipe.special' | translate}} +{{calculatedPrice?.priceincvat |currency: 'EUR':'symbol':'.2-2':'nl'}} {{'common.pp_abbr'| translate}}
      </span>
      <!-- <span *ngIf="_recipe?.salessurcharge < 0" class="bottom-[15px] left-[15px] bg-e-dark-green text-e-white pe-[12px] absolute flex flex-row flex-nowrap rounded-full font-sans">
        <img alt="green deal badge" class="h-[1.8em] me-1" src="/assets/images/icons/icon_green_deal_badge_v2.svg " />
        <span class="my-auto whitespace-nowrap capitalize">{{'recipe.budget' | translate}} </span>
      </span> -->
    </ng-container>
    <app-image size="md" (click)="showRecipeAction()" *ngIf="!disableShowRecipeAction; else imagenavigation" [imageobject]="_recipe" alt="{{_recipe?.displayname}}" class="h-[200px] w-full cursor-pointer rounded-t-xl object-cover object-top"></app-image>
    <ng-template #imagenavigation>
      <a [routerLink]="[_recipe?.recipeurl]">
        <app-image size="md" [imageobject]="_recipe" alt="{{_recipe?.displayname}}" class="h-[200px] w-full rounded-t-xl object-cover object-top"></app-image>
      </a>
    </ng-template>
  </div>

  <div class="p-[15px] flex h-full flex-col justify-between">
    <div class="flex flex-col">
      <div class="basis-[69px] shrink-0 flex flex-row" *ngIf="!disableShowRecipeAction; else titlenavigation">
        <div class="text-e-dark-green flex grow flex-col text-left">
          <strong class="block text-base" (click)="showRecipeAction()">{{_recipe?.displayname}}</strong>
          <span class="text-left text-sm">{{_recipe?.subtitle}}</span>
        </div>
        <favorites [entitytype]="'RECIPE'" [entityid]="_recipe?.id"></favorites>
      </div>
      <ng-template #titlenavigation>
        <div class="basis-[69px] shrink-0 flex flex-row">
          <a class="text-e-dark-green flex grow flex-col text-left" [routerLink]="[_recipe?.recipeurl]">
            <strong class="text-base">{{_recipe?.displayname}}</strong>
            <span class="text-left text-sm">{{_recipe?.subtitle}}</span>
          </a>
          <favorites [entitytype]="'RECIPE'" [entityid]="_recipe?.id"></favorites>
        </div>
      </ng-template>
      <div class="my-[10px] flex flex-row overflow-x-scroll" *ngIf="!condensed">
        <app-chip>
          <time class="text-sm">{{_recipe?.cookingtime}} {{'common.minutes_abbr' | translate}}</time>
        </app-chip>
        <app-chip *ngIf="getCalories(_recipe)">
          <span class="text-sm">{{getCalories(_recipe)}} {{'unit_abbr.KILOCALORIES' | translate}}</span>
        </app-chip>
        <app-chip>
          <span class="text-sm">{{_recipe?.vegetableweight |translate}} {{'unit_abbr.GRAM' |translate}} {{'recipe.vegetableweight'| translate}}</span>
          <!--<span class="text-sm">{{'recipe.difficulty-' + _recipe?.difficulty |translate}}</span>-->
        </app-chip>
      </div>

      <div class="mt-[10px] min-h-[63px] flex flex-row flex-wrap content-start items-start" *ngIf="!condensed">
        <small *ngFor="let lifestyle of _recipe?.lifestyles; let l = index">
          {{getLifestyle(lifestyle?.lifestyleid)?.name | capitalizefirst}}<ng-container *ngIf="l !== _recipe?.lifestyles.length-1">, &nbsp;</ng-container>
        </small>
      </div>
    </div>
    <div class="flex flex-col" *ngIf="!condensed">
      <div class="flex flex-row justify-between gap-2" [ngSwitch]="_status">
        <ng-container *ngSwitchCase="'PROCESSED'">
          <ng-container *ngIf="disableShowRecipeAction; else showRecipeButtonFlat">
            <a [routerLink]="[_recipe?.recipeurl]" class="button-base bg-e-reg-green text-e-dark-green w-full rounded-md hover:bg-e-dark-green hover:text-e-reg-green">{{'recipe.show_recipe' | translate}}</a>
          </ng-container>
          <ng-template #showRecipeButtonFlat>
            <a (click)="showRecipeAction()" class="button-base bg-e-reg-green text-e-dark-green w-full rounded-md hover:bg-e-dark-green hover:text-e-reg-green">{{'recipe.show_recipe' | translate}}</a>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'ONBOARD'">
          <ng-container *ngIf="disableShowRecipeAction; else showRecipeButtonFlat">
            <a [routerLink]="[_recipe?.recipeurl]" class="button-base bg-e-white text-e-dark-green w-full rounded-full hover:bg-e-light-green">{{'recipe.show_recipe' | translate}}</a>
          </ng-container>
          <ng-template #showRecipeButtonFlat>
            <a (click)="showRecipeAction()" class="button-base bg-e-white text-e-dark-green w-full rounded-full hover:bg-e-light-green">{{'recipe.show_recipe' | translate}}</a>
          </ng-template>
          <a [routerLink]="['/personalmenu']" class="button-base w-full rounded-full bg-e-orange text-white hover:bg-e-light-green hover:text-e-dark-green">{{'recipe.order' | translate}}</a>
        </ng-container>
        <ng-container *ngSwitchCase="'SELECTABLE'">
          <button [disabled]="loading" (click)="toggleSelection()"
                  class="mr-[10px] w-full rounded-md md:w-3/5"
                  [ngClass]="{'button-light' : _selected, 'button-mid text-e-mid-green bg-e-dark-green': !_selected}">
            <ng-container *ngIf="_selected">{{'recipe.chosen' | translate}}</ng-container>
            <ng-container *ngIf="!_selected">{{'recipe.choose' | translate}}</ng-container>
          </button>
          <ng-container *ngIf="disableShowRecipeAction; else showRecipeButton">
            <a [routerLink]="[_recipe?.recipeurl]" class="button-mid my-auto w-full rounded-md text-center md:w-2/5">
              <small> {{'recipe.more_info' | translate}}</small>
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'PERSONEDITABLE'">
          <app-personselection class="w-full md:w-1/2 md:pe-2" [persons]="persons" (personsChange)="changePersons($event)">
            <span>{{'common.portions'|translate : {count: _recipe?.doublepersons? persons * 2 : persons } }}</span>
          </app-personselection>
          <button [disabled]="loading" (click)="toggleSelection()"
                  class="w-full rounded-full md:w-1/2"
                  [ngClass]="{'button-mid py-[12px]' : _selected, 'button-cta': !_selected}">
            <ng-container *ngIf="_selected">{{'recipe.added' | translate}}</ng-container>
            <ng-container *ngIf="!_selected">+ {{'recipe.add' | translate}}</ng-container>
          </button>

        </ng-container>
        <ng-container *ngSwitchDefault>
          <!--is empty for pauzings e.a.-->
        </ng-container>
        <ng-template #showRecipeButton>
          <button (click)="showRecipeAction()" class="button-clear w-full rounded-md md:w-2/5">
            {{'recipe.more_info' | translate}}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
