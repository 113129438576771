import {Language} from './shared';

export class Redirect {
  constructor(
    public id: number,
    public brandid: number,
    public seourl: string,
    public shorturl: string,
    public entityid: number,
    public entitytype: Entitytype,
    public redirecttype: Redirecttype,
    public shorturlredirecttype: Redirecttype,
    public redirectid: number,
    public preferredlanguage: Language,
    public languagealternatives: { [key: string]: string },
    public enabled: Boolean,
  ) {
  }
}



export enum Entitytype {
  STATICPAGE = 'STATICPAGE',
  PRODUCT = 'PRODUCT',
  RECIPE = 'RECIPE',
  STORY = 'STORY', // StoryBlok CMS 
  BOX = 'BOX',
  BOXPAGE = 'BOXPAGE',
  TAXONOMY = 'TAXONOMY',
  LIFESTYLE = 'LIFESTYLE',
  _OTHER = '_OTHER' //added for use in orderflowmodule.orderlines
}

export enum Redirecttype {
  PERMANENT = 'PERMANENT',
  TEMPORARY = 'TEMPORARY'
}

export class UrlMapping {
  constructor(
    public identifier: string,
    public redirecttype: Redirecttype,
    public entitytype: Entitytype,
    public entityid: number,
    public redirectid: number,
    public seourl: string,
    public preferredlanguage: Language,
  ) {
  }
}
