import { animate, AUTO_STYLE, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, Inject, PLATFORM_ID, OnDestroy, AfterViewChecked } from '@angular/core';
import { isPlatformBrowser, NgIf, DecimalPipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../_environments/environment';
import { Customer } from '../../_model/customer';
import { AggregatedCustomerRating, CustomerRating } from '../../_model/customerrating';
import { Entitytype } from '../../_model/redirect';
import { CustomerRatingService } from '../../_service/CustomerRatingService';
import { CustomerService } from '../../_service/CustomerService';
import { GlobalService } from '../../_service/GlobalService';
import { OrderService } from '../../_service/OrderService';
import { collapse } from '../../_theme/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NgbRating } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'rating',
    templateUrl: './customerrating.component.html',
    styleUrls: ['./customerrating.component.scss'],
    animations: [collapse],
    standalone: true,
    imports: [NgIf, NgbRating, ReactiveFormsModule, DecimalPipe, TranslateModule]
})
export class CustomerratingComponent implements OnInit, OnDestroy, AfterViewChecked {
  show_stars = false;
  environment = environment;

  _originentityid: number
  @Input() set entityid(value: number) {
    if (value) {
      this._originentityid = value;
      this.canRate(this._originentitytype, this._originentityid);
      this.getCustomerRating();
      this.getAggregateRating();
      if (this.ratingForm)
        this.ratingForm.get('originentityid').setValue(value)
    }
  };
  _originentitytype: Entitytype
  @Input() set entitytype(value: Entitytype | string) {
    if (value) {
      this._originentitytype = Entitytype[value];
      this.canRate(this._originentitytype, this._originentityid);
      this.getCustomerRating();
      this.getAggregateRating();
      if (this.ratingForm)
        this.ratingForm.get('originentitytype').setValue(value)
    }
  };

  createrating = false;
  _expanded = false
  @Input() set expanded(value: boolean) {
    this._expanded = value;
    this.createrating = value;
  }

  @Output() rating = new EventEmitter<AggregatedCustomerRating>()

  ratingForm: FormGroup;
  hoverrating = null;

  _customerrating: CustomerRating;
  _rating: AggregatedCustomerRating;

  isInOrderoveriew = false;
  isNavigatedFromOrderoveriew = false;
  isInOrder = false;

  customer: Customer;

  private unsubscribe = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private _router: Router,
    private _customerService: CustomerService,
    private _ratingService: CustomerRatingService,
    private _orderService: OrderService,
    private _globalService: GlobalService,
    public fb: FormBuilder,
  ) {
    
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.show_stars = true;
    }
    const tree = this._router.parseUrl(this._router.url)
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    if (g) {
      const s: UrlSegment[] = g.segments;
      if (s[0].path == 'user') {
        this.isInOrderoveriew = true
      }
    }

    let prevroute = this._globalService.getPreviousUrl();
    const prevtree = this._router.parseUrl(prevroute)
    const prevg: UrlSegmentGroup = prevtree.root.children[PRIMARY_OUTLET];
    if (prevg) {
      const s: UrlSegment[] = prevg.segments;
      if (s[0].path == 'user') {
        this.isNavigatedFromOrderoveriew = true;
      }
    }

    this._customerService.customerSubject.pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.customer = result;
      if (this.ratingForm)
        if (result) {
          this.ratingForm.get('customerid').setValue(result.id);
        } else {
          this.ratingForm.get('customerid').setValue(null);
        }
    })
    this._ratingService.customerRatingsSubject.pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.getCustomerRating();
    })

    this.ratingForm = new FormGroup({
      id: new FormControl(null),
      customerid: new FormControl(this.customer?.id, { validators: [Validators.required, Validators.min(1)] }),
      brandid: new FormControl(environment.brandid, { validators: [Validators.required, Validators.min(1)] }),
      originentitytype: new FormControl(this._originentitytype, { validators: [Validators.required] }),
      originentityid: new FormControl(this._originentityid, { validators: [Validators.required, Validators.min(1)] }),
      ratingvalue: new FormControl(0, { validators: [Validators.required, Validators.min(1)] }),
      ratingdatetime: new FormControl(null),
      ratingremarks: new FormControl('')
    })

  }

  ngOnDestroy() {
    this.unsubscribe.next();
  }

  ngAfterViewChecked() {
    this.canRate(this._originentitytype, this._originentityid);
  }

  hasError = (controlName: string, errorName: string) => {
    return this.ratingForm.get(controlName).hasError(errorName);
  }

  canRate(entitytype: Entitytype, entityid: number) {
    if (entitytype && entityid) {
      this.isInOrder = this._orderService.hasOrderedEntity(entitytype, entityid)
    }
  }
  getCustomerRating() {
    this._customerrating = this._ratingService.getCustomerrating(this._originentitytype, this._originentityid);
    if (this._customerrating) {
      this._expanded = false;
      this.createrating = false;
    }
  }
  getAggregateRating() {
    this._ratingService.getAggregatedRating(this._originentitytype, this._originentityid).subscribe(result => {
      this._rating = result;
      // this._rating.averageratingvalue += 0.4; // TEST half stars
      this.rating.emit(result);
    })
  }

  addRating() {
    this._ratingService.addCustomerrating(this.ratingForm.value).subscribe(result => {
      setTimeout(() => { 
        this.getAggregateRating();
      }, 500)
      this.createrating = false;
      this._expanded = false;
    })
  }

  setRating(){
    this.ratingForm.get('ratingvalue').setValue(this.hoverrating);
  }
}
