<div class="boxcard flex h-full max-h-[450px]">
  <div *ngIf="(getBoxCountrypersonsprice()?.boxdiscountpercentage || getBoxCountrypersonsprice()?.boxdiscountamount) && qtyeditable" class="discountprice rounded-lg text-white px-1 bg-e-gold m-2 absolute">
    <small class="text-dark" *ngIf="getBoxCountrypersonsprice()?.boxdiscountpercentage; else discountprice">
      {{calculatedPrice?.calculateddiscountpercentage }}% {{'common.discount' | translate}}
    </small>
    <ng-template #discountprice>
      <small class="text-dark">
        {{calculatedPrice?.discountincvat |currency: 'EUR':'symbol':'.2-2':'nl'}} {{'common.discount' | translate}}
      </small>
    </ng-template>
  </div>

  <div class="flex flex-col rounded-lg bg-white justify-between w-full p-[15px] md:p-[25px]">
    <div class="flex justify-center items-center h-[170px] overflow-hidden">
      <app-image (click)="showBoxAction()" size="md" *ngIf="!disableShowAction; else imagenavigation" [imageobject]="_box" alt="{{_box?.title}}" class="flex flex-row justify-center object-cover cursor-pointer"></app-image>
      <ng-template #imagenavigation>
        <a *ngIf="_box" [routerLink]="[_box['boxurl']]">
          <app-image [imageobject]="_box" size="md" alt="{{_box?.title}}" class="flex flex-row justify-center object-cover"></app-image>
        </a>
      </ng-template>
    </div>

    <div class="">
      <h3 (click)="showBoxAction()" *ngIf="!disableShowAction; else linknavigation" class="text-[16px] md:text-[20px] font-sans cursor-pointer">
        <!-- makes sure that a translated version is shown if a box or product is set with the orderdescription as fallback -->
        <ng-container *ngIf="_box; else orderdescription">{{_box?.title || _box?.name}}</ng-container>
        <ng-template #orderdescription>{{_orderline?.description}}</ng-template>
      </h3>
      <ng-template #linknavigation>
        <h3 *ngIf="_box" [routerLink]="[_box['boxurl']]" class="text-[16px] md:text-[20px] font-sans">
          <!-- makes sure that a translated version is shown if a box or product is set with the orderdescription as fallback -->
          <ng-container *ngIf="_box; else orderdescription">{{_box?.title || _box?.name}}</ng-container>
          <ng-template #orderdescription>{{_orderline?.description}}</ng-template>
        </h3>
      </ng-template>
      <small class="">{{_box?.subtitle}}</small>
    </div>
    <div class="flex flex-row" *ngIf="qtyeditable || repeatableeditable">
      <pricetag class=" mt-[10px]"  [productprice]="true" [calculatedPrice]="calculatedPrice"></pricetag>
    </div>

    <div class="flex flex-row items-center mt-[15px]" *ngIf="qtyeditable">
      <strong class="capitalize hidden md:block">{{'common.pieces' |translate }}</strong>
      <app-quantityselection class="md:ml-[15px] w-full" [quantity]="quantity.value" (quantityChange)="setQuantity($event)" [contrastColorscheme]="colorscheme"></app-quantityselection>
    </div>

    <div class="flex flex-row items-center mt-[15px]" *ngIf="personseditable">
      <strong class="capitalize hidden md:mr-[10px] md:block">{{'common.person_abbr'|translate}}</strong>
      <app-personselection class="md:ml-[15px] w-full" [contrastColorscheme]="colorscheme" [persons]="persons.value" (personsChange)="setPersonQuantity($event)" [personsoptions]="_box?._boxpersons" [doublepersons]="_box?.doublepersons">
        <span class="md:hidden">{{'common.person_abbr'|translate}}</span>
      </app-personselection>
    </div>
    <span *ngIf="!qtyeditable">{{'common.piece' |translate : {count: quantity.value} }}</span>
    <span *ngIf="!personseditable ">{{_box?.doublepersons? persons.value * 2 : persons.value}} {{'common.person_abbr'|translate}}</span>
    <app-toggle *ngIf="repeatableeditable" class="ms-2" [formControl]="repeatable" color="success">{{'subscription.product_weekly' | translate}}</app-toggle>





  </div>
</div>
