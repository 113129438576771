import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../_environments/environment';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, Observable, of, Subject, throwError } from 'rxjs';

import { CalculatedPrice, Creditcardnetwork, CustomerCreateOrderRequest, IdealIssuers, Minimumordervalue, Minimumordervaluetype, Price } from '../_model/pricing';
import { BoxSubscription, RecipeSubscription } from '../_model/subscription';
import { Countrycode, UrlParameters } from '../_model/shared';
import { GlobalService } from './GlobalService';
import { Paymentmethod, Shippingmethod } from '../_model/order';
import { error } from 'console';

@Injectable()
export class PricingService {

  private _minimumordervalues: Minimumordervalue[] = []
  private _recipesubscriptionPrices: Price[] = [];
  _movSubject = new Subject<Minimumordervalue[]>()

  constructor(
    private _http: HttpClient,
    private _globalService: GlobalService,
  ) {
  }

  pricemapping = new Map<string, CalculatedPrice>();
  priceSubjectmapping = new Map<string, Observable<CalculatedPrice>>();

  calculateSingleprice(price: number, vattarifhigh: boolean, quantity: number = 1, discountpercentage: number = null, discountprice: number = null, issubscriptionorder = false): Observable<CalculatedPrice> {

    if (isNaN(price)) { return of(null); }
    if (!this._globalService.getPricecountry()) { return of(null); }

    const params = new UrlParameters();
    params.addParameter('priceexvat', price.toString());
    params.addParameter('countrycode', this._globalService.getPricecountry());
    params.addParameter('vattarifhigh', vattarifhigh.toString());
    params.addParameter('quantity', quantity.toString());
    if (discountpercentage) {
      params.addParameter('discountpercentage', discountpercentage.toString());
    }
    if (discountprice) {
      params.addParameter('discountprice', discountprice.toString());
    }
    if (issubscriptionorder)
      params.addParameter('issubscriptionorder', issubscriptionorder.toString());

    //returns calculatedprice that already where gotten
    let mapping;
    if (this.pricemapping)
      mapping = this.pricemapping.get(params.toString())
    if (mapping) return of(mapping);

    //returns a subject when it is beeing retrieved
    let subj = this.priceSubjectmapping.get(params.toString());
    if (subj) return subj;

    let subject = new Subject<CalculatedPrice>();

    let obs = this._http.get(environment.apiserver + 'pricing/v1/pricing/calculateprice' + params.toString()).pipe(
      map((response: CalculatedPrice) => {
        //fulfills the subject with a response and completes it
        subject.next(response);
        subject.complete();
        //removes the subject from the array
        this.priceSubjectmapping.delete(params.toString());

        //adds price to mapping to be used later
        this.pricemapping.set(params.toString(), response);
        if (!!discountpercentage && discountpercentage > 0) {
          // prevent unrounded calculated discount percentages like 10.08 !?
          response.calculateddiscountpercentage = discountpercentage;
        }
        return response as CalculatedPrice;
      })
      , catchError(this.handleError)
    );
    //adds the subject to the array
    this.priceSubjectmapping.set(params.toString(), subject);
    return obs;
  }
  calculateOrderprice(orderrequest: CustomerCreateOrderRequest): Observable<CustomerCreateOrderRequest> {

    if (!orderrequest.shippingmethod || orderrequest.shippingmethod.toString() === '' || !orderrequest.deliverydate || orderrequest.deliverydate === '') {// || orderrequest.orderlines.length ==0 //|| !orderrequest.pickupdeliverytimeframe || orderrequest.pickupdeliverytimeframe === '') {
      console.warn('no shippingmethod or deliverydate in calulateprice request')
      return of(null);
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    const body = JSON.stringify(orderrequest);

    return this._http.post(environment.apiserver + 'pricing/v1/pricing/calculateprice', body, options).pipe(
      map((response: CustomerCreateOrderRequest) => {
        response.calculatedPrice['priceincvatexshipping'] = response.calculatedPrice.priceincvat - response.calculatedPrice.shippingcost
        return response
      })
      , catchError(this.handleError)
    );
  }

  /**
   * This fuction will create a paymentlink
   *    - if the originentityid and/or originentitytype and/or firstsubscriptionorder are set a mandate paymentlink will be created
   *    - in the orderlines should only be lines which are added by the customer => NO COUPONCODEITEMS
   *    RETURNS:  a paymentlink if the payment is not 0, then it will return null
   * @param orderrequest 
   * @returns paymentlink if payment is not 0, otherwise it will return null
   */
  customerCreateOrder(orderrequest: CustomerCreateOrderRequest): Observable<string> {

    if (orderrequest.shippingmethod.toString() === '' || orderrequest.deliverydate === '') {
      return EMPTY;
    }

    //removes the couponcodeitems from the customercreateorder request
    orderrequest.orderlines = orderrequest.orderlines.filter(l => !l.couponcodeitem)
    orderrequest['redirecttld'] = this._globalService.getTld();

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers, responseType: 'text' as const };
    const body = JSON.stringify(orderrequest);

    return this._http.post(environment.apiserver + 'customercreateorder/v1/customercreateorder', body, options).pipe(
      map((response: string) => response as string)
      , catchError(this.handleError)
    );
  }
  telemarketingCreateOrder(orderrequest: CustomerCreateOrderRequest): Observable<string> {

    if (orderrequest.shippingmethod.toString() === '' || orderrequest.deliverydate === '') {
      return EMPTY;
    }

    orderrequest.orderlines = orderrequest.orderlines.filter(l => !l.couponcodeitem)

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers, responseType: 'text' as const };
    const body = JSON.stringify(orderrequest);

    return this._http.post(environment.apiserver + 'customercreateorder/v1/telemarketingcreateorder', body, options).pipe(
      map((response: string) => response as string)
      , catchError(this.handleError)
    );
  }

  getRecipesubscriptionprice(subscription: RecipeSubscription, deliverydate: any): Observable<RecipeSubscription> {

    if (!subscription.recipeSubscriptionDeliveries || subscription.recipeSubscriptionDeliveries.length === 0) {
      return of(null);
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    const body = JSON.stringify(subscription);

    const params = new UrlParameters();

    params.addParameter('deliverydate', deliverydate);
    params.addParameter('countrycode', this._globalService.getPricecountry());

    return this._http.post(environment.apiserver + 'pricing/v1/pricing/recipesubscription/calculateprice' + params.toString(), body, options).pipe(
      map((response: Response) => <any>response)
      , catchError(this.handleError)
    );

  }
  getBoxsubscriptionprice(subscription: BoxSubscription, deliverydate: any): Observable<BoxSubscription> {

    if (!subscription.boxSubscriptionDeliveries || subscription.boxSubscriptionDeliveries.length === 0) {
      return of(null);
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    const body = JSON.stringify(subscription);

    const params = new UrlParameters();

    params.addParameter('deliverydate', deliverydate);
    params.addParameter('countrycode', this._globalService.getPricecountry());

    return this._http.post(environment.apiserver + 'pricing/v1/pricing/boxsubscription/calculateprice' + params.toString(), body, options).pipe(
      map((response: Response) => <any>response)
      , catchError(this.handleError)
    );

  }

  getMinimumOrderValues(countrycode: Countrycode, minimumordervalueType: Minimumordervaluetype, shippingmethod: Shippingmethod): Observable<Minimumordervalue[]> {

    if (this._minimumordervalues.length > 0)
      return of(this.filterMinimumOrderValues(countrycode, minimumordervalueType, shippingmethod))

    this._movSubject.subscribe({
      complete: () => {
        this._movSubject = new Subject<Minimumordervalue[]>();
      }
    });

    if (this._movSubject.observers.length === 1) {
      this._http.get(environment.apiserver + 'pricing/v1/minimumordervalue').pipe(
        map((response: Minimumordervalue[]) => response)
        , catchError(this.handleError)
      ).subscribe(response => {
        this._minimumordervalues = response;
        this._movSubject.next(this.filterMinimumOrderValues(countrycode, minimumordervalueType, shippingmethod))
        this._movSubject = new Subject<Minimumordervalue[]>();
      }, error => {
        console.log(error)
        this._movSubject.error(error);
      });
    }
    return this._movSubject.asObservable();
  }

  private filterMinimumOrderValues(countrycode: Countrycode, minimumordervalueType: Minimumordervaluetype, shippingmethod: Shippingmethod): Minimumordervalue[] {
    const types = this._minimumordervalues.filter(v =>
      v.countrycode === countrycode
      && v.minimumordervaluetype === minimumordervalueType
      && v.shippingmethod === shippingmethod
    )
    return types.sort((a, b) => a.minimumtotalvalue - b.minimumtotalvalue)
  }

  getRecipesubscriptionPricing(countrycode: Countrycode): Observable<Price> {

    if (this._recipesubscriptionPrices.length > 0)
      return of(this.filterSubscriptionPricingValues(countrycode))

    return this._http.get(environment.apiserver + 'pricing/v1/pricing').pipe(
      map((response: Price[]) => {
        this._recipesubscriptionPrices = response;
        return this.filterSubscriptionPricingValues(countrycode)
      })
      , catchError(this.handleError)
    );
  }

  private filterSubscriptionPricingValues(countrycode: Countrycode): Price {
    return this._recipesubscriptionPrices.find(v =>
      v.countrycode === countrycode
    )
  }

  getRecipeprice(countrycode: Countrycode, personQty: number, recipeQty: number, inclVat = true): { recipeprice: number, discountedrecipeprice: number, portionprice: number, discountedportionprice: number } {
    if (!countrycode || !personQty || !recipeQty) return null;

    const pricing = this.filterSubscriptionPricingValues(countrycode)
    if (!pricing) return null;

    let price = 0;
    let discountedprice = 0;
    switch (personQty) {
      case 1:
        price = pricing.onepersonrecipeprice
        break;
      case 2:
        price = pricing.twopersonrecipeprice
        break;
      case 3:
        price = pricing.threepersonrecipeprice
        break;
      case 4:
        price = pricing.fourpersonrecipeprice
        break;
      default:
        price = 0;
    }

    if (recipeQty === 4) {
      discountedprice = price * (1 - (pricing.fourbagsdiscountpercentage / 100))
    } else {
      discountedprice = price;
    }


    discountedprice = discountedprice * (1 + (inclVat ? (pricing.vatlow / 100):0))
    price = price * (1 + (inclVat ? (pricing.vatlow / 100) : 0))

    return {
      recipeprice: price,
      discountedrecipeprice: discountedprice,
      portionprice: price / personQty,
      discountedportionprice: discountedprice / personQty
    }

  }


  getIdealIssuers(): string[] {
    return Object.keys(IdealIssuers);
  }
  getCreditcardNetworks(): string[] {
    return Object.keys(Creditcardnetwork);
  }
  getPaymentmethods(): string[] {
    return Object.keys(Paymentmethod);
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }

}
