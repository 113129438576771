import { Component, Input, OnInit } from '@angular/core';
import { CalculatedPrice, Minimumordervalue, Minimumordervaluetype } from '../../_model/pricing';
import { PricingService } from '../../_service/PricingService';
import { Countrycode } from '../../_model/shared';
import { Shippingmethod } from '../../_model/order';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgClass, CurrencyPipe } from '@angular/common';
import { MovlabelComponent } from '../movlabel/movlabel.component';


@Component({
  selector: 'pricetotal',
  templateUrl: './pricetotal.component.html',
  styleUrls: ['pricetotal.component.scss'],
  standalone: true,
  imports: [
    NgIf, NgClass,
    MovlabelComponent,
    CurrencyPipe,
    TranslateModule
  ]
})
export class PricetotalComponent {

  _calculatedPrice: CalculatedPrice
  @Input() set calculatedPrice(value: CalculatedPrice) {
    this._calculatedPrice = value;
    if (value)
      this._calculatedPrice['priceincvatexshipping'] = this._calculatedPrice.priceincvat - this._calculatedPrice.shippingcost
  }
  @Input() showMov = false;

  _showShipping = true
  @Input() set showShipping(value: boolean) {
    this._showShipping = value;
  }
  _countrycode: Countrycode = null;
  @Input() set countrycode(value: Countrycode) {
    this._countrycode = value;
  }
  _shippingmethod: Shippingmethod = null;
  @Input() set shippingmethod(value: Shippingmethod) {
    this._shippingmethod = value;
  }


  

  

  

}

