import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatHint } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalService } from 'src/_service/GlobalService';
import { AuthService } from '../../_service/AuthService';
import { WINDOW } from '../../_service/WindowService';
import { collapse } from '../../_theme/animations';
import { FooterComponent } from '../footer/footer.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [collapse],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, NgClass, MatHint, FooterComponent, TranslateModule]
})
export class LoginComponent implements OnInit {

  @Input() navigateToUser = true;

  loginform = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(1)]),
    keepsignedin: new FormControl(false)
  });
  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  })

  loginHasFailed = false;
  hasforgotPassword = false;
  forgotPasswordSend = false;
  showpassword = false;
  isOnLoginpage = true;

  constructor(
    // public translate: TranslateService,
    private loginService: AuthService,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    // private _translate: TranslateService,
    private _globalService: GlobalService,
    @Inject(WINDOW) private window: Window,
  ) {
  }

  ngOnInit() {
    if (this.navigateToUser) {
      // TODO WHY this if ? HH: if it is not navigateToUser, it is embedded in the onboarding flow
      this._globalService.setTranslatedTitle('login.sign-in');
    }
    if (this.loginService.isAuthenticated()) {
      if (this.navigateToUser)
        this.router.navigate(['user']);
    }

    if (!this.router.url.startsWith('/login')) this.isOnLoginpage = false;

    this.loginform.valueChanges.subscribe(val => {
      this.loginHasFailed = false;
    })

  }

  public hasError = (controlName: string, errorName: string) => {
    return this.loginform.controls[controlName].dirty && this.loginform.controls[controlName].hasError(errorName);
  }

  public login() {
    if (!this.loginform.valid) {
      return;
    }

    this.loginService.doLogin(this.loginform.value).subscribe(
      token => {
        this.getUserDetails()
      },
      error => { this.loginHasFailed = true }

    );
  }

  toggleForgotPassword() {
    this.hasforgotPassword = !this.hasforgotPassword;
    const email = this.loginform.get('email');
    if (email.status === "VALID" && email.value !== "") {
      this.forgotPasswordForm.patchValue({ email: email.value});
    }
  }

  forgotPassword() {
    if (this.forgotPasswordForm.valid) {
      this.forgotPasswordSend = true;
      this.loginService.changepasswordlink(this.forgotPasswordForm.get('email').value).subscribe(
        result => {
          this.forgotPasswordForm.reset();
          this.forgotPasswordForm.markAsPristine();
        },
        error => { console.log(error) }
      )
    }
  }

  getUserDetails() {
    this.loginService.getUserDetails().subscribe(
      result => {
        if (!result.customerid) {
          this.loginHasFailed = true;
        } else {
          this.loginHasFailed = false;
          // with this event we can actually see which user is logged in and which isnt
          this.window['dataLayer'].push({
            event: "custom_event",
            customData: {
                name: "signIn", // name is required
                email: result.username
            },
          });
          if (this.navigateToUser) {
            const params = this._activatedRoute.snapshot.queryParams;
            if (params.returnUrl) {
              this.router.navigateByUrl(params.returnUrl)
            } else {
              this.router.navigate(['user']);
            }
          }
        }
      }
    );
  }


  // to be able to use password managers that fill the password.
  inputChange(formControlName, data) {
    if (data != null && data.target !== undefined) {
      this.loginform.get(formControlName).setValue(data.target.value);
    }
  }

}
