
  <ng-container *ngIf="_calculatedPrice">
      <ng-container *ngIf="_calculatedPrice?.discountincvat === 0; else discountprice">
        <ng-container *ngIf="_calculatedPrice?.priceincvat === 0 && !_couponcodeItem && !_compensationItem; else normalprice">
          <small>{{'order.product_currently_not_available' | translate}}</small>
        </ng-container>
        <ng-template #normalprice>
          <div class="flex flex-col items-end text-right">
            <small>{{_calculatedPrice?.priceincvat |currency: 'EUR':'symbol':'.2-2':'nl'}}</small>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #discountprice>
          <div class="flex flex-col items-end text-right">
            <small><i class="line-through"> {{_calculatedPrice?.priceincvat + _calculatedPrice?.discountincvat |currency: 'EUR':'symbol':'.2-2':'nl'}} </i></small>
            <strong><small>{{_calculatedPrice?.priceincvat   |currency: 'EUR':'symbol':'.2-2':'nl'}}</small></strong>
          </div>
      </ng-template>
  </ng-container>

