import { Injectable } from '@angular/core';

export interface Toast {
	content: string;
	buttontext: string;
	classname?: string;
	duration?: number;
}


@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  toasts: Toast[] = [];

	open(content: string, closebutton: string, format: any): number {
		let c = format.panelClass;

		switch(c){
			case 'mat-success': 
			case 'success':
				c = 'bg-white'
				break;
			case 'mat-warn':
			case 'warn':
				c = 'bg-e-orange text-white'
		}
		

		const toast:Toast = {
			content: content,
			buttontext: closebutton, 
			duration: format.duration,
			classname: c
		}

		return this.toasts.push(toast);
	}

	remove(index: number) {
		this.toasts = this.toasts.filter((t, i) => i !== index);
	}

	clear() {
		this.toasts.splice(0, this.toasts.length);
	}
}
