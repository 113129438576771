import { Component, Input, OnInit } from '@angular/core';
import { Recipe, RecipeScore, RecipescoreType } from 'src/_model/recipe';
import { environment } from '../../_environments/environment';
import { collapse } from '../../_theme/animations';
import { RecipeService } from '../../_service/RecipeService';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf, DecimalPipe, PercentPipe, CurrencyPipe } from '@angular/common';


@Component({
    selector: 'app-vitascores',
    templateUrl: './vitascores.component.html',
    animations: [collapse],
    standalone: true,
    imports: [NgFor, NgIf, RouterLink, DecimalPipe, PercentPipe, CurrencyPipe, TranslateModule]
})

export class VitascoresComponent{

  environment = environment;
  vitascores: any[];
  _recipe: Recipe;
  @Input() set recipe(value: Recipe) {
    this._recipe = value;
    if (this._recipe) {
      this._recipeService.setRecipeScores(this._recipe)

      this.vitascores = [
        {
          showIfSmall: false,
          alt: "truecosts",
          src: "assets/images/icons/icon_trueprice.svg",
          label: "score.truecosts",
          score: this.getScore(RecipescoreType._TRUECOSTS),
          unit: 'CURRENCY',
          unitPrefix: 'null'
        },
        {
          showIfSmall: true,
          alt: "vegetablecounter",
          src: "assets/images/icons/icon_DG-groenteteller.svg",
          label: "score.veggiescore",
          score: new RecipeScore(RecipescoreType._VETGETABLESCORE, null, this._recipe?.vegetableweight + ''),
          unit: 'GRAM',
          unitPrefix: 'unit_abbr.'
        },
        {
          showIfSmall: false,
          alt: "vita-score",
          src: "assets/images/icons/icon_DG-vitaliteit.svg",
          label: "score.vitascore",
          score: this.getScore(RecipescoreType.VITASCORERISCPOINTS),
          unit: 'POINTS',
          unitPrefix: 'unit.'
        },
        {
          showIfSmall: true,
          alt: "co2-reduction-score",
          src: "assets/images/icons/icon_DG-CO2eq.svg",
          label: "score.co2eq-score",
          score: this.getScore(RecipescoreType.CARBONSCORE),
          unit: 'GRAM',
          unitPrefix: 'unit_abbr.'
        },
        {
          showIfSmall: false,
          alt: "water-reduction-score",
          src: "assets/images/icons/icon_DG-water.svg",
          label: "score.waterscore",
          score: this.getScore(RecipescoreType.SCARCEWATERUSAGE),
          unit: 'LITER',
          unitPrefix: 'unit_abbr.'
        },
      ];

      if (this.cols === "2") {
        this.vitascores = this.vitascores.filter((score) => { return score.showIfSmall })
      }
    }
  }
  @Input() cols = "5";

  showAbsoluteValues = false;
  constructor(
    private _recipeService: RecipeService
  ) {
  }


  getScore(scoretype: RecipescoreType) {
    if (!this._recipe || !this._recipe.recipeScores) {
      return { scorerating: '', scorevalue: '0' };
    }
    let res = this._recipe.recipeScores.filter(r => r &&  r.scoretype == scoretype)[0];
    if (res) {
      return res;
    } else {
      return { scorerating: '0', scorevalue: '0' };
    }
  }

}
