<ng-template #chooselifestyleTemplateRef>
  <div class="bg-white rounded-lg p-[25px]">
    <span>
      {{'lifestyle.you_choose' | translate}}
      <strong>{{'common.meals'| translate: {count: mealsCount} }}</strong>
      {{'lifestyle.from_the' | translate}}
      <strong>{{lifestyle.title}}</strong>
      {{'common.for' | translate}}
      <strong>{{'common.persons'| translate: {count: personsCount} }}.</strong>
    </span>
    <br /><span>{{'lifestyle.popup_end' | translate}}</span>
  </div>
</ng-template>
