
<div class="bg-white {{class}}">
  <div class="bg-e-white md:py-[50px] rounded-xl">
    <div class="container-default flex flex-col-reverse md:flex-row md:justify-between px-[25px]">
      <div class="flex flex-col items-start justify-center md:w-1/3 md:mr-[25px]">
        <h1 class="" itemprop="name">{{_product?.title || _product?.name}}</h1>
        <h4 class="">{{_product?.subtitle}}</h4>
        <i class=""><small>{{_product?.shopquantity}} {{'unit.' + _product?.shopunit | translate }}</small></i>
        <p><ngx-dynamic-hooks [content]="_product?.shortdescription"></ngx-dynamic-hooks></p>

        <ng-container *ngIf="(qtyeditable || repeatableeditable) && _product.shopsellprice">
          <!--{{calculatedPrice?.calculateddiscountpercentage}}
          {{getBoxCountrypersonsprice() |json}}-->
          <div *ngIf="_product?.discountprice > 0 ||_product?.discountpercentage > 0" class="bg-e-gold text-white rounded-full px-2">
            <small>
              <ng-container *ngIf="_product?.discountpercentage >0; else discountprice">{{calculatedPrice?.calculateddiscountpercentage}}% {{'common.discount' | translate}}</ng-container>
              <ng-template #discountprice>{{calculatedPrice?.discountincvat |currency: 'EUR':'symbol':'.2-2':'nl'}} {{'common.discount' | translate}}</ng-template>
            </small>
          </div>
          <pricetag [productprice]="true" [calculatedPrice]="calculatedPrice"></pricetag>
          <small *ngIf="!repeatable.value && deliverydate" class="text-secondary">{{'subscription.delivery_of' | translate}} {{deliverydate | localizedDate :'EEEE d MMMM' |lowercase}}</small>

          <div class="flex w-full justify-between mt-[25px]">
            <span class="my-auto me-2">{{'common.quantity' | translate}}</span>
            <app-quantityselection class="w-2/3" [quantity]="quantity.value" (quantityChange)="setQuantity($event)" [contrastColorscheme]="colorscheme"></app-quantityselection>
          </div>
          <app-toggle *ngIf="repeatableeditable" [formControl]="repeatable">{{'subscription.product_weekly' | translate}}</app-toggle>
        </ng-container>
      </div>

      <div class="md:w-2/3">
        <app-image [imageobject]="_product" size="lg" alt="{{_product?.title}}" class="rounded-3xl object-cover w-full"></app-image>
      </div>
    </div>
    <div class="hidden md:flex w-[39px] h-[39px] icon-round mx-auto mt-[25px]">
      <img _ngcontent-serverapp-c211="" src="./assets/images/icons/arrow_down.svg" class="h-[20px] w-[20px]">
    </div>
  </div>
  <div class="container-default flex flex-col md:flex-row px-[25px] mt-[25px]" id="main">
    <div class="w-full md:w-3/5 md:ml-[25px]">
      <ngx-dynamic-hooks class="col-12" [content]="_product?.description"></ngx-dynamic-hooks>
    </div>
    <div class="w-full md:w-2/5 md:ml-[100px]">
      <div class="flex flex-col bg-e-white rounded-lg p-[20px] my-[25px] md:m-[25px]" *ngIf="_product?.nutrients.length >0">
        <div class="flex flex-row justify-between">
          <h4 class="p-0">{{'nutrient.nutritional values' | translate}}</h4><small class="">{{'product.per portion'| translate}}</small>
        </div>
        <div *ngFor="let nutrient of _product?.nutrients" class="flex flex-row justify-between border-b-[1px] border-black mt-[5px] pb-[5px]">
          <span class="px-0">{{'nutrient.' + nutrient.name | translate}}</span> <span class="px-0 text-end ">{{nutrient.quantity}} {{'unit_abbr.' + nutrient.unit |translate}}</span>
        </div>
        <div class="mt-[20px]" *ngIf="_product?.allergens.length >0">
          <span>{{'allergen.allergen information' | translate }}</span>
          <div class="flex flex-row flex-wrap mt-[5px]">
            <app-chip *ngFor="let allergen of _product?.allergens">
              <span class="text-sm font-medium">{{'allergen.' + allergen.name | translate}}</span>
            </app-chip>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


