<div class="image-wrapper {{class}}">

  <div *ngIf="src === '' || !src || src === 'broken'" class="text-gray-400 text-center my-auto pt-2">
    <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-image-alt w-full max-h-[150px] m-auto " viewBox="0 0 16 16">
      <path d="M7 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0m4.225 4.053a.5.5 0 0 0-.577.093l-3.71 4.71-2.66-2.772a.5.5 0 0 0-.63.062L.002 13v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5z" />
    </svg>
  </div>

  <picture>
    <source *ngIf="thumb" media="(max-width: {{thumbmax}}px)" srcset="{{environment.assetserver}}{{thumb}}">
    <source *ngIf="medium" media="(max-width: {{mediummax}}px)" srcset="{{environment.assetserver}}{{medium}}">
    <source *ngIf="large" media="(max-width: {{largemax}}px)" srcset="{{environment.assetserver}}{{large}}">
    <img class="{{class}}" (error)="src='broken'" *ngIf="src" loading="eager" src="{{environment.assetserver}}{{src}}" alt="{{alt}}">
  </picture>

</div>

