
export class CouponcodeCode {
  constructor(
    public id: number,
    public code: string,
    public timesused: number,
    public maxtimesuse: number,
    public isusable: boolean,
    public personalemail: string,
    public referralcustomerid: number

  ) {
  }
}





