<div class="flex h-full max-h-[450px]">

  <div class="flex flex-col rounded-lg bg-white w-full p-[15px] md:p-[25px]">
    <div class="flex justify-center items-center h-[170px]">
  
        <div class="w-full block bg-white rounded-lg text-center">
          <i class="bi bi-card-heading text-[45px]"></i>
        </div>
    </div>
    <div>
      <h3 class="text-[16px] md:text-[20px] font-sans cursor-pointer">
        {{_orderline?.description}}
      </h3>
      <!--<small>
        {{'subscription.printed_recipecards'| translate}}
      </small>-->
    </div>
  </div>
</div>
