<div class="flex rounded-lg mt-[20px] flex-col" [ngClass]="condensed ? ' bg-white' : ''">
  <div class="flex" [ngClass]="condensed ? 'flex-col bg-white' : 'flex-row'">
    <div [ngClass]="condensed ? 'w-full' : 'w-1/3 mr-[10px] max-w-[125px]'">
      <div class="w-full block bg-white rounded-lg text-center">
        <i class="bi bi-card-heading text-[45px]"></i>
      </div>
    </div>

    <div class="flex-1 flex-col" [ngClass]="condensed ? 'px-[10px] py-[15px] basis-[130px] shrink-0': 'w-full'">
      <div class="flex flex-col justify-between mr-[10px] ">
        <strong class="text-sm ">
          {{_orderline?.description}}
        </strong>
        <a class="text-sm hover:underline" *ngIf="showSubscriptionlink" [routerLink]="'/user/settings/subscriptions'">{{'subscription.no_recipecards_goto_settings' | translate }} <i class="bi bi-arrow-right-short"></i></a>
        <!--<small>
          {{'subscription.printed_recipecards'| translate}}
        </small>-->
      </div>

      <div class="flex flex-row flex-wrap justify-between items-end">
        <div class=" w-2/3  max-w-[175px]">

          <!--<small>{{'common.piece' |translate : {count:quantity.value} }}</small>-->
          <!--<app-toggle *ngIf="repeatableeditable" [formControl]="repeatable">{{'subscription.product_weekly' | translate}}</app-toggle>-->

        </div>

        <div class="flex flex-wrap justify-end my-auto" *ngIf="showPrice">
           
          <pricetag *ngIf="_productOrderline" [ngClass]="{'offset-7 mt-2': !qtyeditable && !repeatableeditable}" [calculatedPrice]="_productOrderline['calculatedPrice']" [couponcodeItem]="(_productOrderline && _productOrderline['couponcodeitem'])" [compensationItem]="_productOrderline &&_productOrderline['compensationitem']"></pricetag>
          <pricetag *ngIf="_productSelection" [ngClass]="{'offset-7 mt-2': !qtyeditable && !repeatableeditable}" [calculatedPrice]="_productSelection['calculatedPrice']" [couponcodeItem]="(_productSelection && _productSelection['couponcodeitem'])" [compensationItem]="_productSelection &&_productSelection['compensationitem']"></pricetag>
          <!--<pricetag *ngIf="_repeatableProduct" [ngClass]="{'offset-7 mt-2': !editable && !repeatableeditable}" [calculatedPrice]="_repeatableProduct?.calculatedPrice" [couponcodeItem]="(_repeatableProduct && _repeatableProduct['couponcodeitem'])" [compensationItem]="_repeatableProduct &&_repeatableProduct['compensationitem']"></pricetag>-->
        </div>
      </div>
    </div>
  </div>
</div>
