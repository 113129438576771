<!-- DISCOUNT & APPLIEDCOUPONCODE -->
<div *ngIf="_calculatedPrice?.discountincvat && _calculatedPrice?.discountincvat !== 0 || _calculatedPrice?.appliedcouponcode" class="flex flex-row justify-between">
  <small class="capitalize">{{'common.discount' | translate}} <ng-container *ngIf="_calculatedPrice?.appliedcouponcode">({{_calculatedPrice?.appliedcouponcode}})</ng-container></small>
  <small *ngIf="_calculatedPrice?.discountincvat && _calculatedPrice?.discountincvat !== 0">{{_calculatedPrice?.discountincvat * -1 |currency: 'EUR':'symbol':'.2-2':'nl'}}</small>
</div>

<!-- COMPENSATION VALUE -->
<div *ngIf="_calculatedPrice?.totalcompensated > 0" class="flex flex-row justify-between">
  <span><small>{{'common.compensation' | translate}}</small></span>
  <span><small>{{_calculatedPrice?.totalcompensated * -1 |currency: 'EUR':'symbol':'.2-2':'nl'}}</small></span>
</div>

<!-- SUBTOTAL -->
<div class="pt-[5px] flex flex-row justify-between">
  <span [ngClass]="{ 'font-bold': !_showShipping}">{{'common.subtotal' | translate}}</span>
  <span [ngClass]="{ 'font-bold': !_showShipping}" *ngIf="_calculatedPrice; else pricezero">{{_calculatedPrice?.priceincvat - _calculatedPrice?.shippingcost |currency: 'EUR':'symbol':'.2-2':'nl'}}</span>

  <ng-template #pricezero>
    <span [ngClass]="{ 'font-bold': !_showShipping}">{{0 |currency: 'EUR':'symbol':'.2-2':'nl'}}</span>
  </ng-template>
</div>
<div class="flex flex-row justify-between" *ngIf="_showShipping && _calculatedPrice?.shippingcost !== null">
  <div class="flex flex-row">
    <small>{{'order.shipping' | translate}}</small>
  </div>

  <ng-container *ngIf="_calculatedPrice?.shippingcost !== 0; else free">
    <span>{{_calculatedPrice?.shippingcost |currency: 'EUR':'symbol':'.2-2':'nl'}}</span>
  </ng-container>
  <ng-template #free>
    <span class="font-bold">{{'common.free' |translate}}</span>
  </ng-template>
</div>

<hr class="opacity-10 my-3 border border-black" />

<!-- TOTAL -->
<div class="text-[20px] flex flex-row justify-between" *ngIf="_showShipping">
  <strong>{{'common.total' | translate}}</strong>
  <div class="flex flex-row justify-end" *ngIf="_calculatedPrice; else pricezero">
    <strong>{{_calculatedPrice?.priceincvat  |currency: 'EUR':'symbol':'.2-2':'nl'}}</strong>
  </div>
  <ng-template #pricezero>
    <strong>{{0 |currency: 'EUR':'symbol':'.2-2':'nl'}}</strong>
  </ng-template>
</div>

<!-- MOV -->
<div class="flex flex-row flex-wrap justify-between" id="baseketmov" *ngIf="_calculatedPrice?.basketmovvalue && showMov">
  <small class="fst-italic small mt-1 whitespace-nowrap pe-0">{{'mov.you_can_already_order_from' |translate }} {{_calculatedPrice?.basketmovvalue |currency: 'EUR':'symbol':'.2-2':'nl'}}</small>
  <small *ngIf="!_calculatedPrice?.basketmovreached && _calculatedPrice?.priceincvat !== 0" class="fst-italic small mt-1 pe-0"><i class="bi bi-exclamation-triangle-fill"></i> {{'mov.you_did_not_reach_mov_yet' |translate : { value: _calculatedPrice?.basketmovvalue - _calculatedPrice?.priceincvat |currency: 'EUR':'symbol':'.2-2':'nl'} }}</small>
</div>

<div class="flex flex-row flex-wrap justify-between" id="baseketmov" *ngIf="_countrycode">
  <small>
    <app-movlabel [calculatedPrice]="_calculatedPrice"
                  [countrycode]="_countrycode"
                  [showShipping]="_showShipping"
                  [shippingmethod]="_shippingmethod">
    </app-movlabel>
  </small>
</div>
