/**
 * name: @storyblok/richtext
 * (c) 2024
 * description: Storyblok RichText Resolver
 * author: Alvaro Saburido <hola@alvarosaburido.dev> (https://github.com/alvarosabu/)
 */
var o = /* @__PURE__ */(e => (e.DOCUMENT = "doc", e.HEADING = "heading", e.PARAGRAPH = "paragraph", e.QUOTE = "blockquote", e.OL_LIST = "ordered_list", e.UL_LIST = "bullet_list", e.LIST_ITEM = "list_item", e.CODE_BLOCK = "code_block", e.HR = "horizontal_rule", e.BR = "hard_break", e.IMAGE = "image", e.EMOJI = "emoji", e.COMPONENT = "blok", e))(o || {}),
  u = /* @__PURE__ */(e => (e.BOLD = "bold", e.STRONG = "strong", e.STRIKE = "strike", e.UNDERLINE = "underline", e.ITALIC = "italic", e.CODE = "code", e.LINK = "link", e.ANCHOR = "anchor", e.STYLED = "styled", e.SUPERSCRIPT = "superscript", e.SUBSCRIPT = "subscript", e.TEXT_STYLE = "textStyle", e.HIGHLIGHT = "highlight", e))(u || {}),
  N = /* @__PURE__ */(e => (e.TEXT = "text", e))(N || {}),
  _ = /* @__PURE__ */(e => (e.SELF = "_self", e.BLANK = "_blank", e))(_ || {}),
  A = /* @__PURE__ */(e => (e.URL = "url", e.STORY = "story", e.ASSET = "asset", e.EMAIL = "email", e))(A || {});
function w(e, r) {
  if (!r) return {
    src: e,
    attrs: {}
  };
  let a = 0,
    d = 0;
  const m = {},
    s = [];
  function h(c, g, $, n, R) {
    typeof c != "number" || c <= g || c >= $ ? console.warn(`[StoryblokRichText] - ${n.charAt(0).toUpperCase() + n.slice(1)} value must be a number between ${g} and ${$} (inclusive)`) : R.push(`${n}(${c})`);
  }
  if (typeof r == "object") {
    if (typeof r.width == "number" && r.width > 0 ? (m.width = r.width, a = r.width) : console.warn("[StoryblokRichText] - Width value must be a number greater than 0"), r.height && typeof r.height == "number" && r.height > 0 ? (m.height = r.height, d = r.height) : console.warn("[StoryblokRichText] - Height value must be a number greater than 0"), r.loading && ["lazy", "eager"].includes(r.loading) && (m.loading = r.loading), r.class && (m.class = r.class), r.filters) {
      const {
          filters: c
        } = r || {},
        {
          blur: g,
          brightness: $,
          fill: n,
          format: R,
          grayscale: O,
          quality: I,
          rotate: L
        } = c || {};
      g && h(g, 0, 100, "blur", s), I && h(I, 0, 100, "quality", s), $ && h($, 0, 100, "brightness", s), n && s.push(`fill(${n})`), O && s.push("grayscale()"), L && [0, 90, 180, 270].includes(r.filters.rotate || 0) && s.push(`rotate(${L})`), R && ["webp", "png", "jpeg"].includes(R) && s.push(`format(${R})`);
    }
    r.srcset && (m.srcset = r.srcset.map(c => {
      if (typeof c == "number") return `${e}/m/${c}x0/${s.length > 0 ? "filters:" + s.join(":") : ""} ${c}w`;
      if (Array.isArray(c) && c.length === 2) {
        const [g, $] = c;
        return `${e}/m/${g}x${$}/${s.length > 0 ? "filters:" + s.join(":") : ""} ${g}w`;
      }
    }).join(", ")), r.sizes && (m.sizes = r.sizes.join(", "));
  }
  let E = `${e}/m/`;
  return a > 0 && d > 0 && (E = `${E}${a}x${d}/`), s.length > 0 && (E = `${E}filters:${s.join(":")}`), {
    src: E,
    attrs: m
  };
}
const x = (e = {}) => Object.keys(e).map(r => `${r}="${e[r]}"`).join(" "),
  P = (e = {}) => Object.keys(e).map(r => `${r}: ${e[r]}`).join("; ");
function U(e) {
  return e.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
}
function D(e, r = {}, a) {
  const d = x(r);
  return `<${d ? `${e} ${d}` : e}>${Array.isArray(a) ? a.join("") : a || ""}</${e}>`;
}
function G(e = {}) {
  let r = 0;
  const {
      renderFn: a = D,
      textFn: d = U,
      resolvers: m = {},
      optimizeImages: s = !1
    } = e,
    h = t => i => a(t, {
      ...i.attrs,
      key: `${t}-${r}`
    }, i.children || null),
    E = t => {
      const {
        src: i,
        alt: l,
        ...f
      } = t.attrs || {};
      let b = i,
        S = {};
      if (s) {
        const {
          src: y,
          attrs: H
        } = w(i, s);
        b = y, S = H;
      }
      const C = {
        src: b,
        alt: l || "",
        key: `img-${r}`,
        ...f,
        ...S
      };
      return a("img", C, "");
    },
    c = t => {
      const {
        level: i,
        ...l
      } = t.attrs || {};
      return a(`h${i}`, {
        ...l,
        key: `h${i}-${r}`
      }, t.children);
    },
    g = t => {
      var i, l, f, b;
      return a("span", {
        "data-type": "emoji",
        "data-name": (i = t.attrs) == null ? void 0 : i.name,
        emoji: (l = t.attrs) == null ? void 0 : l.emoji,
        key: `emoji-${r}`
      }, a("img", {
        src: (f = t.attrs) == null ? void 0 : f.fallbackImage,
        alt: (b = t.attrs) == null ? void 0 : b.alt,
        style: "width: 1.25em; height: 1.25em; vertical-align: text-top",
        draggable: "false",
        loading: "lazy"
      }, ""));
    },
    $ = t => a("pre", {
      ...t.attrs,
      key: `code-${r}`
    }, a("code", {
      key: `code-${r}`
    }, t.children || "")),
    n = (t, i = !1) => ({
      text: l,
      attrs: f
    }) => a(t, i ? {
      style: P(f),
      key: `${t}-${r}`
    } : {
      ...f,
      key: `${t}-${r}`
    }, l),
    R = t => v(t),
    O = t => {
      const {
        marks: i,
        ...l
      } = t;
      return "text" in t ? i ? i.reduce((f, b) => R({
        ...b,
        text: f
      }),
      // Fix: Ensure render function returns a string
      R({
        ...l,
        children: l.children
      })
      // Fix: Cast children to string
      ) : d(l.text) : "";
    },
    I = t => {
      const {
        linktype: i,
        href: l,
        anchor: f,
        ...b
      } = t.attrs || {};
      let S = "";
      switch (i) {
        case A.ASSET:
        case A.URL:
          S = l;
          break;
        case A.EMAIL:
          S = `mailto:${l}`;
          break;
        case A.STORY:
          S = l;
          break;
      }
      return f && (S = `${S}#${f}`), a("a", {
        ...b,
        href: S,
        key: `a-${r}`
      }, t.text);
    },
    L = t => {
      var i, l;
      return console.warn("[StoryblokRichtText] - BLOK resolver is not available for vanilla usage"), a("span", {
        blok: (i = t == null ? void 0 : t.attrs) == null ? void 0 : i.body[0],
        id: (l = t.attrs) == null ? void 0 : l.id,
        key: `component-${r}`,
        style: "display: none"
      }, "");
    },
    j = new Map([[o.DOCUMENT, h("div")], [o.HEADING, c], [o.PARAGRAPH, h("p")], [o.UL_LIST, h("ul")], [o.OL_LIST, h("ol")], [o.LIST_ITEM, h("li")], [o.IMAGE, E], [o.EMOJI, g], [o.CODE_BLOCK, $], [o.HR, h("hr")], [o.BR, h("br")], [o.QUOTE, h("blockquote")], [o.COMPONENT, L], [N.TEXT, O], [u.LINK, I], [u.ANCHOR, I], [u.STYLED, n("span", !0)], [u.BOLD, n("strong")], [u.TEXT_STYLE, n("span", !0)], [u.ITALIC, n("em")], [u.UNDERLINE, n("u")], [u.STRIKE, n("s")], [u.CODE, n("code")], [u.SUPERSCRIPT, n("sup")], [u.SUBSCRIPT, n("sub")], [u.HIGHLIGHT, n("mark")], ...Object.entries(m).map(([t, i]) => [t, i])]);
  function T(t) {
    r += 1;
    const i = j.get(t.type);
    if (!i) return console.error("<Storyblok>", `No resolver found for node type ${t.type}`), "";
    if (t.type === "text") return i(t);
    const l = t.content ? t.content.map(v) : void 0;
    return i({
      ...t,
      children: l
      // Fix: Update the type of 'children' to Node[]
    });
  }
  function v(t) {
    return Array.isArray(t) ? t.map(T) : T(t);
  }
  return {
    render: v
  };
}
export { o as BlockTypes, _ as LinkTargets, A as LinkTypes, u as MarkTypes, N as TextTypes, G as richTextResolver };