<ng-container *ngIf="minimumOrderValue">
  <ng-container *ngIf="minimumOrderValue.shippingpercentage; else shippingcosts">
    <span *ngIf="minimumOrderValue.shippingpercentage !== 100">{{'mov.shipping_mov_percentage' |translate : { extraordervalue: minimumOrderValue.minimumtotalvalue - _calculatedPrice['priceincvatexshipping'] |currency: 'EUR':'symbol':'.2-2':'nl', percentage: minimumOrderValue.shippingpercentage } }}</span>
    <span *ngIf="minimumOrderValue.shippingpercentage === 100">{{'mov.shipping_mov_price_free' |translate : { extraordervalue: minimumOrderValue.minimumtotalvalue - _calculatedPrice['priceincvatexshipping'] |currency: 'EUR':'symbol':'.2-2':'nl'} }}</span>
  </ng-container>
  <ng-template #shippingcosts>
    <span *ngIf="minimumOrderValue.shippingcost">{{'mov.shipping_mov_price' |translate : { extraordervalue: minimumOrderValue.minimumtotalvalue - _calculatedPrice['priceincvatexshipping'] |currency: 'EUR':'symbol':'.2-2':'nl', value: minimumOrderValue.shippingcost |currency: 'EUR':'symbol':'.2-2':'nl' } }}</span>
    <span *ngIf="!minimumOrderValue.shippingcost">{{'mov.shipping_mov_price_free' |translate : { extraordervalue: minimumOrderValue.minimumtotalvalue - _calculatedPrice['priceincvatexshipping'] |currency: 'EUR':'symbol':'.2-2':'nl'} }}</span>

  </ng-template>
</ng-container>
