import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { CmsService } from 'src/_service/CmsService';

@Component({
    selector: 'app-cms-embed',
    templateUrl: './cms-embed.component.html',
    standalone: true,
    imports:[TranslateModule]
})
export class CmsEmbedComponent implements OnInit {

  @Input() embed: any;
  embed_url: SafeResourceUrl;

  embed_class: string;
  showAcceptCookies = false

  @ViewChild('classembed') classEmbedElement;


  constructor(private _cmsService: CmsService) {
  }


  ngOnInit() {
    this.embed_class = this.embed.embed_class;
    this.embed_url = this.get_sanitized_url(this.embed.embed_url);
  }

  ngAfterViewInit(){
    if(this.embed_class){
      setTimeout(() => {
        this.showAcceptCookies = this.classEmbedElement.nativeElement.childNodes.length === 0
      }, 2000);
    }
  }


  get_sanitized_url(url) {
    return this._cmsService.get_sanitized_url(url);
  }
  
}



