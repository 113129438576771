<h4 class="my-[25px] text-left font-medium">{{ accordeon.title }}</h4>
<div class="mb-[15px]" *ngFor="let block of accordeon.blocks; let i = index" (click)="setExpanded(i)">
  <div class="flex flex-col cursor-pointer px-[15px] py-[5px] rounded-lg" [ngClass]="accordeon?.bg_color ? accordeon.bg_color : 'bg-white' ">
    <div class="flex flex-row justify-between items-center">
      <p class="font-bold">{{ block.title }}</p>
      <img class="h-[14px] transform transition duration-300 ease-in-out" [ngClass]="expanded === i ? 'rotate-90' : 'rotate-0'" src="./assets/images/icons/arrow_right.svg" height="14px" />
    </div>
    <div *ngIf="expanded === i" [@collapse]>
      <div [innerHTML]="block.accordeon_content_rendered"></div>
    </div>
  </div>
</div>
