import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../_environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError, switchMap } from 'rxjs';
import { TableParameters, TableResponse, UrlParameter, UrlParameters } from '../_model/shared';
import { Recipe, Nutrient, Allergen, RecipescoreType, RecipeScore } from '../_model/recipe';
import { TranslateService } from '@ngx-translate/core';
// import { RedirectService } from './RedirectService';

@Injectable()
export class RecipeService {

  constructor(
    private _http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any,
    private _translate: TranslateService,
  ) {
    this._translate.onLangChange.subscribe(next => {
      this.recipes = [];
    })
  }

  private recipes: Recipe[] = []; // cached list of recipes

  getNutrients(): string[] {
    return Object.keys(Nutrient);
  }
  getAllergens(): string[] {
    return Object.keys(Allergen);
  }
  getRecipescoretypes(): string[] {
    return Object.keys(RecipescoreType);
  }

  getRecipeTableByPeriod(params: UrlParameters, from: string, to: string): Observable<TableResponse<Recipe>> {
    /* let params = tblparams.getUrlParameters();*/
    if (!from || !to) { return of(null) }

    params.addParameter('from', from);
    params.addParameter('to', to);

    return this._http.get(environment.apiserver + 'recipebff/v1/recipe/find/period' + params.toString()).pipe(
      map((response: TableResponse<Recipe>) => {
        this.recipes = this.recipes.concat(response.content)
        response.params = [];
        response.params.push(new UrlParameter('from', from));
        response.params.push(new UrlParameter('to', from));
        return response as TableResponse<Recipe>
      })
      , catchError(this.handleError)
    );
  }
  getRecipeTableByLifestyleAndPeriod(tblparams: TableParameters, from: string, to: string, lifestyleid: number): Observable<TableResponse<Recipe>> {
    let params = tblparams.getUrlParameters();
    if (!from || !to) { return of(null) }

    params.addParameter('from', from);
    params.addParameter('to', to);
    params.addParameter('lifestyleid', lifestyleid.toString());

    return this._http.get(environment.apiserver + 'recipebff/v1/recipe/find/period/lifestyle' + params.toString()).pipe(
      map((response: TableResponse<Recipe>) => {
        this.recipes = this.recipes.concat(response.content)
        response.params = [];
        response.params.push(new UrlParameter('from', from));
        response.params.push(new UrlParameter('to', from));
        response.params.push(new UrlParameter('lifestyleid', lifestyleid.toString()));
        return response as TableResponse<Recipe>
      })
      , catchError(this.handleError)
    );
  }
  getRecipeTableByName(tblparams: TableParameters, name: string): Observable<TableResponse<Recipe>> {
    const params = tblparams.getUrlParameters();
    params.addParameter('searchstring', name.toLowerCase());
    return this._http.get(environment.apiserver + 'recipebff/v1/recipe/find/text' + params.toString()).pipe(
      map((response: TableResponse<Recipe>) => {
        this.recipes = this.recipes.concat(response.content)

        return response as TableResponse<Recipe>
      })
      , catchError(this.handleError)
    );
  }

  getRecipeList(recipeids: number[]): Observable<Recipe[]> {
    if (recipeids.length == 0 || recipeids.filter(v => !isNaN(v)).length == 0) return of([]);
    recipeids = recipeids.filter(v => v && !isNaN(v));

    //checks if all recipes are already retrieved to overcome a server trip
    let recipes = this.recipes.filter(r => recipeids.includes(r.id)); //can contain duplicates
    recipes = [...new Map(recipes.map((r) => [r.id, r])).values()]; //makes a unique list of recipes
    let notavailableRecipeids = recipeids.filter(rid => !recipes.map(r => r.id).includes(rid))
    if (notavailableRecipeids.length === 0)
      //returns recipes if all recipes are available
      return of(recipes);

    const params = new UrlParameters();
    params.addParameter('ids', recipeids.toString());

    return this._http.get(environment.apiserver + 'recipebff/v1/recipe/list' + params.toString()).pipe(
      map((response: Recipe[]) => {
        this.recipes = this.recipes.concat(response);
        //recipes = recipes.concat(response);
        //return recipes as Recipe[]
        return response;
      })
      , catchError(this.handleError)
    );
  }
  getRecipeListByIngredients(tblparams: TableParameters, ingredients: string[]): Observable<TableResponse<Recipe>> {
    if (ingredients.length == 0) return of(null);
    ingredients = ingredients.filter(v => v !== '')
    const params = tblparams.getUrlParameters();
    params.addParameter('ingredient', ingredients.join(','));

    return this._http.get(environment.apiserver + 'recipebff/v1/recipe/find/ingredient' + params.toString()).pipe(
      map((response: TableResponse<Recipe>) => {
        this.recipes = this.recipes.concat(response.content);
        return response as TableResponse<Recipe>
      })
      , catchError(this.handleError)
    );
  }
  getRecipe(recipeid: number): Observable<Recipe> {

    // use cached version if exists
    if (this.recipes.length > 0) {
      const recipe = this.recipes.filter(r => r.id == recipeid)[0];
      if (recipe) {
        return of(recipe);
      }
    }

    const recipeids = [recipeid];
    const params = new UrlParameters();
    params.addParameter('ids', recipeids.toString());

    return this._http.get(environment.apiserver + 'recipebff/v1/recipe/list' + params.toString()).pipe(
      map((response: Recipe[]) => {
        if (response.length > 0) {
          this.recipes.push(response[0]); // add to cache
          return response[0] as Recipe;
        }
        return null;
      })
      , catchError(this.handleError)
    );
  }

  getRecipePromise(recipeid: number): Promise<Recipe> {
    return new Promise(resolve => {
      if (recipeid == null || recipeid === 0) {
        resolve(null);
        return;
      }
      this.getRecipe(recipeid).subscribe(
        {
          next: (result) => {
            resolve(result);
          },
          error: (error) => console.log(error)
        });
    });
  }

  setRecipeScores(recipe: Recipe) {
    if (!recipe || !recipe.recipeScores) return;
    //should only run once
    if (recipe.recipeScores.map(s => s.scoretype).includes(RecipescoreType._VETGETABLESCORE)) return;

    let scores: RecipeScore[] = []

    recipe.recipeScores.forEach(s => {
      scores.push(new RecipeScore(s.scoretype, s.scorerating, s.scorevalue));
    })
    //reverse calculates the vitascorepoints if they are not returned with the recipe
    if (!scores.map(s => s.scoretype).includes(RecipescoreType.VITASCORERISCPOINTS)) {
      const vitascore = scores.find(f => f.scoretype === RecipescoreType.VITASCORE)
      if (vitascore) {
        const riscpointScore = this.calculateRecipeRiscPoints(Number(vitascore.scorevalue), vitascore.scorerating);
        scores.push(riscpointScore);
      }
    }


    const vegetablescore = new RecipeScore(RecipescoreType._VETGETABLESCORE, null, recipe?.vegetableweight.toString());
    scores.push(vegetablescore);

    const truecostscore = this.calculateTrueCostsScore(scores);
    scores.push(truecostscore);

    recipe.recipeScores = scores;
  }
  private calculateTrueCostsScore(recipescores: RecipeScore[]): RecipeScore {
    const env = environment

    const twopersonsmealprice = env.dutchmealscores._TWOPERSMEALPRICE

    const environmentalDamageUNPerc = 0.7;
    const healthDamageUNPerc = 1.1;
    const economicDamageUNPerc = 0.1;
    const wasteUNPerc = 0.095

    const environmentalDamageEMPerc = this.calcPercentage(recipescores, environmentalDamageUNPerc, 0.1, [RecipescoreType.CARBONSCORE, RecipescoreType.SCARCEWATERUSAGE])
    const healdamageDamageEMPerc = this.calcPercentage(recipescores, healthDamageUNPerc, 0.1, [RecipescoreType.VITASCORERISCPOINTS])
    const economicDamageEMPerc = this.calcPercentage(recipescores, economicDamageUNPerc, 0.1, [RecipescoreType.VITASCORERISCPOINTS])
    const wasteEMPerc = 0.01

    //it needs all scores
    if (!environmentalDamageEMPerc || !healdamageDamageEMPerc || !economicDamageEMPerc) return;

    const totalEMPerc = 1 + environmentalDamageEMPerc + healdamageDamageEMPerc + economicDamageEMPerc + wasteEMPerc
    const totalEMPrice = ((twopersonsmealprice) * totalEMPerc) / 2
    let score = new RecipeScore(RecipescoreType._TRUECOSTS, null, totalEMPrice.toString());

    return score;
  }
  private calcPercentage(recipescores: RecipeScore[], UNpercentage: number, organicpercentage: number, scoretypes: RecipescoreType[]): number {
    let scores: RecipeScore[] = [];

    scoretypes.forEach(type => {
      let s = recipescores.filter(t => t.scoretype == type)[0];
      if (s)
        scores.push(s);
      else
        //should return nothing if a scoretype is missing
        return null;
    })

    const avgscore = 1 + (scores.map(s => s.scoreimprovementpercentage).reduce((accumulator, currentValue) => accumulator + (currentValue > 0 ? currentValue * -1 : currentValue), 0) / scores.length);
    //return UNpercentage * (avgscore - organicpercentage)
    return UNpercentage * (avgscore * (1 + organicpercentage))

  }
  private calculateRecipeRiscPoints(vitascore: number, scorerating: string): RecipeScore {

    let vitarisicoscore = null
    //this is the reversed calculation which is done in Etna to return the eaternity vitascore because this score wasnt saved in the beginning
    switch (true) {
      case (vitascore >= 80):
        vitarisicoscore = (((vitascore - 80) / (20 / 319)) - 319) * -1
        break;
      case (vitascore >= 60 && vitascore <= 79):
        vitarisicoscore = (((vitascore - 60) / (20 / 80)) - 399) * -1
        break;
      case (vitascore >= 40 && vitascore <= 59):
        vitarisicoscore = (((vitascore - 40) / (20 / 53)) - 453) * -1
        break;
      case (vitascore >= 20 && vitascore <= 39):
        vitarisicoscore = (((vitascore - 20) / (20 / 15)) - 468) * -1
        break;
      case (vitascore <= 19):
        vitarisicoscore = (((vitascore - 0) / (20 / 6)) - 475) * -1
        break;
    }

    if (!vitarisicoscore) return null;

    return new RecipeScore(RecipescoreType.VITASCORERISCPOINTS, scorerating, vitarisicoscore)



  }

  private handleError(error: Response) {
    //if (isPlatformServer(this.platformId)) {
    //  console.log('Recipe service error:' + error);
    //}
    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }

}
